import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Panel, Toolbar } from '@lib/ui-components';

export const DeclarationChangesDetails = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <Panel borderType='raised'>
      <Panel header={t('contactForm')} borderType='none'>
        <Toolbar right={[]} />
        <DataTable
          refreshKey={tableReloadKey}
          modelDef={{ modelName: '' }}
          noPagination
          noFilters
          columns={[
            {
              key: 'phoneNumber',
              label: t('phoneNumber'),
              type: 'numeric',
            },
          ]}
        />
        <DataTable
          refreshKey={tableReloadKey}
          modelDef={{ modelName: '' }}
          noPagination
          noFilters
          columns={[
            {
              key: 'message',
              label: t('message'),
              type: 'text',
            },
          ]}
        />
      </Panel>
      <Panel header={t('sender')} borderType='none'>
        <DataTable
          refreshKey={tableReloadKey}
          modelDef={{ modelName: '' }}
          noPagination
          noFilters
          columns={[
            {
              key: 'senderLogin',
              label: t('senderLogin'),
              type: 'text',
            },
            {
              key: 'senderClient',
              label: t('senderClient'),
              type: 'text',
            },
            {
              key: 'senderBranch',
              label: t('senderBranch'),
              type: 'text',
            },
            {
              key: 'dateSent',
              label: t('dateSent'),
              type: 'text',
            },
          ]}
        />
      </Panel>
    </Panel>
  );
};
