import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CompanyViewModel } from '@lib/api-interface';
import {
  AddModelButton,
  ApiCallButton,
  DataTable,
  FileUploadButton,
  HStack,
  ImportFromExcelIcon,
  Panel,
  RenderIf,
  ToggleButtons,
  Toolbar,
  UsecaseButton,
} from '@lib/ui-components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useQueryClient } from '@tanstack/react-query';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import EditArrow from '../../../../../../libs/ui-components/components/action-buttons/EditArrow';
import { EditLocationAlt } from '@mui/icons-material';
import { useWebSocketState } from '../../../../../../state/web-socket-state';

export interface CompanyBranchesProps {
  company: CompanyViewModel;
  onCentralChanged?: () => void;
}

export const CompanyBranches = (props: CompanyBranchesProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [activePage, setActivePage] = React.useState(0);
  const [dataRowCount, setDataRowCount] = React.useState(0);
  const queryClient = useQueryClient();
  const webSocketState = useWebSocketState();

  function isCorrespondenceAddressVisible(data: any): boolean {
    return data['correspondenceAddressEqRegistrationAddress'] !== true;
  }

  const CompanyDataTable = React.useMemo(() => {
    return (
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyBranchViews' }}
        fetchFilters={{ companyId: props.company?.companyId, active: activePage === 0 }}
        rowLink={{ linkPath: '', linkRowId: 'branchId' }}
        onTableLoaded={(rows) => {
          setDataRowCount(rows.length);
        }}
        columns={[
          {
            key: 'branchNestedNumber',
            label: t('branchNestedNumber'),
            type: 'numeric',
            align: 'right',
          },
          {
            key: 'name',
            label: t('branch'),
            type: 'text',
          },
          {
            key: 'central',
            label: t('headquarter'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'registrationAddress.houseNum',
            label: t('houseNum'),
            type: 'text',
          },
          {
            key: 'registrationAddress.apartmentNum',
            label: t('apartmentNum'),
            type: 'text',
          },
          {
            key: 'registrationAddress.postalCode',
            label: t('postcode'),
            type: 'text',
          },
          {
            key: 'registrationAddress.commune',
            label: t('commune'),
            type: 'text',
          },
          {
            key: 'registrationAddress.province',
            label: t('province'),
            type: 'text',
          },
          {
            key: 'verifiedBdo',
            label: t('mpdStatus'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoIdentificationNumber',
            label: t('Model.Company.bdoIdentificationNumber'),
            type: 'text',
          },
        ]}
      />
    );
  }, [props.company.companyId, activePage, setDataRowCount, t, tableReloadKey,
    webSocketState.branchesImportRefreshKey, webSocketState.updateCompanyMpdBdoRefreshKey]);

  return (
    <Panel borderType='flat'>
      <HStack align='center'>
        <ToggleButtons labels={[t('active'), t('notActive')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      <RenderIf true={activePage === 0}>
        <Toolbar
          right={[
            <AddModelButton
              buttonLabel={t('addNewBranch')}
              dialogTitle={t('addNewBranch')}
              modelName={'branches'}
              fields={[
                { label: t('Model.CompanyBranch.name'), type: 'text', field: 'name' },
                { label: t('addressInformation'), type: 'header', field: '-' },
                {
                  rowItems: [
                    {
                      label: t('locality'),
                      type: 'text',
                      field: 'registrationAddress.town',
                    },
                    {
                      label: t('postcode'),
                      type: 'text',
                      field: 'registrationAddress.postalCode',
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('street'),
                      type: 'text',
                      field: 'registrationAddress.street',
                    },
                    {
                      label: t('houseNum'),
                      type: 'text',
                      field: 'registrationAddress.houseNum',
                    },
                    {
                      label: t('apartmentNum'),
                      type: 'text',
                      field: 'registrationAddress.apartmentNum',
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('commune'),
                      type: 'table-select',
                      field: 'registrationAddress.communeId',
                      tableSelectParams: {
                        modelName: 'communes',
                        dialogTitle: t('communes'),
                        displayFormat: '{name}',
                        columns: [
                          { key: 'name', label: t('commune'), type: 'text' },
                          { key: 'province', label: t('province'), type: 'text' },
                        ],
                        otherValuesMap: {
                          name: 'registrationAddress.commune',
                          province: 'registrationAddress.province',
                        },
                      },
                    },
                    {
                      label: t('country'),
                      type: 'text',
                      field: 'registrationAddress.country',
                      defaultValue: 'Polska',
                    },
                  ],
                },
                {
                  rowItemsJustify: 'left',
                  rowItems: [
                    { label: t('correspondenceAddress'), type: 'header', field: '-' },
                    {
                      label: t('correspondenceAddressEqRegistrationAddress'),
                      type: 'boolean',
                      field: 'correspondenceAddressEqRegistrationAddress',
                      defaultValue: true,
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('locality'),
                      type: 'text',
                      field: 'correspondenceAddress.town',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                    {
                      label: t('postcode'),
                      type: 'text',
                      field: 'correspondenceAddress.postalCode',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('street'),
                      type: 'text',
                      field: 'correspondenceAddress.street',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                    {
                      label: t('houseNum'),
                      type: 'text',
                      field: 'correspondenceAddress.houseNum',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                    {
                      label: t('apartmentNum'),
                      type: 'text',
                      field: 'correspondenceAddress.apartmentNum',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('district'),
                      type: 'text',
                      field: 'correspondenceAddress.district',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                    {
                      label: t('commune'),
                      type: 'table-select',
                      field: 'correspondenceAddress.communeId',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      tableSelectParams: {
                        modelName: 'communes',
                        dialogTitle: t('communes'),
                        displayFormat: '{name}',
                        columns: [
                          { key: 'name', label: t('commune'), type: 'text' },
                          { key: 'province', label: t('province'), type: 'text' },
                        ],
                        otherValuesMap: {
                          name: 'correspondenceAddress.commune',
                          province: 'correspondenceAddress.province',
                        },
                      },
                    },
                    {
                      label: t('country'),
                      type: 'text',
                      field: 'correspondenceAddress.country',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('contractConclusionDate'),
                      type: 'date',
                      field: 'contractConclusionDate',
                    },
                  ],
                },
              ]}
              validationSchema={{
                name: Yup.string().required(t('requiredField')),
                registrationAddress: Yup.object({
                  street: Yup.string().required(t('requiredField')),
                  postalCode: Yup.string().required(t('requiredField')),
                  houseNum: Yup.string().required(t('requiredField')),
                  town: Yup.string().required(t('requiredField')),
                  communeId: Yup.string().required(t('requiredField')),
                }),
                correspondenceAddressEqRegistrationAddress: Yup.boolean(),
                correspondenceAddress: Yup.object().when('correspondenceAddressEqRegistrationAddress', {
                  is: (correspondenceAddressEqRegistrationAddress: boolean) => !correspondenceAddressEqRegistrationAddress,
                  then: Yup.object({
                    street: Yup.string().required(t('requiredField')),
                    postalCode: Yup.string().required(t('requiredField')),
                    houseNum: Yup.string().required(t('requiredField')),
                    town: Yup.string().required(t('requiredField')),
                    communeId: Yup.string().required(t('requiredField')),
                  }),
                }),
              }}
              onBeforeSave={(formData) => {
                formData.companyId = props.company!.companyId!;
                const registrationCommuneIdParts = formData.registrationAddress.communeId.split('/');
                formData.registrationAddress!.communeId = registrationCommuneIdParts[registrationCommuneIdParts.length - 1];
                if (formData.correspondenceAddressEqRegistrationAddress === true) {
                  formData.correspondenceAddress = {} as any;
                  formData.correspondenceAddress!.street = formData.registrationAddress!.street;
                  formData.correspondenceAddress!.town = formData.registrationAddress!.town;

                  formData.correspondenceAddress!.commune = formData.registrationAddress!.commune;
                  formData.correspondenceAddress!.communeId = formData.registrationAddress!.communeId;
                  formData.correspondenceAddress!.district = formData.registrationAddress!.district;

                  formData.correspondenceAddress!.province = formData.registrationAddress!.province;
                  formData.correspondenceAddress!.country = formData.registrationAddress!.country;

                  formData.correspondenceAddress!.postalCode = formData.registrationAddress!.postalCode;
                  formData.correspondenceAddress!.houseNum = formData.registrationAddress!.houseNum;
                  formData.correspondenceAddress!.apartmentNum = formData.registrationAddress!.apartmentNum;
                } else {
                  const correspondenceCommuneIdParts = formData.correspondenceAddress.communeId.split('/');
                  formData.correspondenceAddress!.communeId = correspondenceCommuneIdParts[correspondenceCommuneIdParts.length - 1];
                }
                delete formData.correspondenceAddressEqRegistrationAddress;
                return formData;
              }}
              overrideSaveCall={async (payload: any) => {
                try {
                  await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'createCompanyBranch', props.company!.companyId!, payload);
                  return new DataResultsDto(false, 200, undefined, undefined, undefined);
                } catch (error: any) {
                  return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                }
              }}
              onSaved={async () => {
                setTableReloadKey(tableReloadKey + 1);
              }}
            />,
          ]}
        />
        <Toolbar
          right={[
            <FileUploadButton
              uploadUrl={`/api/branches/import?companyId=${props.company?.companyId}`}
              onLoaded={async (fileData) => {
                console.log(fileData);
              }}
              label={t('importFromExcel')}
              allowedExtensions='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              startIcon={<ImportFromExcelIcon />}
              dontChangeLabel={true}
              disableErrorSnackbar={true}
            />,
            <ApiCallButton
              url={'/api/organization/bdo/update-eups'}
              payload={{ companyId: props.company!.companyId }}
              buttonLabel={t('updatePoW-BDO')}
              disableErrorSnackbar={true}
              startIcon={<CloudDownloadIcon color='secondary' />}
              onCompleted={async () => {
                setTableReloadKey(tableReloadKey + 1);
                await queryClient.invalidateQueries(['companyViews', props.company!.companyId]);
              }}
            />,
            <RenderIf true={dataRowCount > 1}>
              <UsecaseButton
                groupName='company'
                useCaseName='update-company-central-branch'
                buttonLabel={t('updateCompanyCentralBranch')}
                dialogTitle={t('updateCompanyCentralBranch')}
                startIcon={<EditLocationAlt color='secondary' />}
                dialogIcon={<EditArrow />}
                fields={[
                  {
                    field: 'newCentralBranchId',
                    label: t('branch'),
                    type: 'table-select-by-api',
                    tableSelectByApiParams: {
                      apiPostParams: { companyId: props.company?.companyId },
                      apiPostEndpoint: '/api/usecase/company-branches/fetch-non-central-branches-for-company',
                      dialogTitle: t('branch'),
                      displayFormat: '{name}',
                      selectedValueField: 'branchId',
                      columns: [
                        {
                          key: 'branchNestedNumber',
                          label: t('branchNestedNumber'),
                          type: 'numeric',
                          align: 'right',
                        },
                        {
                          key: 'name',
                          label: t('branchName'),
                          type: 'text',
                          minWidth: '15rem',
                        },
                        {
                          key: 'nip',
                          label: t('nip'),
                          type: 'text',
                          linkPath: '',
                        },
                        {
                          key: 'registrationAddress.town',
                          label: t('locality'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.street',
                          label: t('street'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.houseNum',
                          label: t('houseNum'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.apartmentNum',
                          label: t('apartmentNum'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.postalCode',
                          label: t('postcode'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.commune',
                          label: t('commune'),
                          type: 'text',
                        },
                        {
                          key: 'registrationAddress.province',
                          label: t('province'),
                          type: 'text',
                        },
                      ],
                    },
                  },
                ]}
                hiddenValues={{
                  companyId: props.company?.companyId,
                }}
                validationSchema={{ newCentralBranchId: Yup.string().required(t('fieldRequired')) }}
                onSaved={async () => {
                  setTableReloadKey(tableReloadKey + 1);
                  props.onCentralChanged && props.onCentralChanged();
                }}
              />
            </RenderIf>,
          ]}
        />
      </RenderIf>
      {CompanyDataTable}
    </Panel>
  );
};
