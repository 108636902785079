import { BreadcrumbDataQuery } from '@lib/ui-components';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';

export const ProjectBranchBreadcrumbProvider: BreadcrumbDataQuery = {
  fetchFunction(id: string): () => Promise<string> {
    return function () {
      return ReactQueryHateoasClientService.findOneByAggregateId<any>('projectBranchViews', 'projectBranchId', id);
    };
  },

  mapToBreadcrumb(data: any): string {
    return data.companyBranchName;
  },

  query: 'projectBranchViews',
};
