import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';
import { numericToStringFormatter } from '../../../common-sdk/utils/numeric-to-string.formatter';

export interface FormInputNumericProps extends FormInputProps {
  precision?: number;
  min?: number;
  max?: number;
}

function valueAsNumber(value: string) {
  return Number(value.replace(',', '.'));
}

export const FormInputNumeric = (props: FormInputNumericProps) => {
  const precision = props.precision != null ? props.precision : 0;

  function formatValue(value: string, onChange: (...event: any[]) => void) {
    if (value !== null && value !== undefined && `${value}`.trim() !== '') {
      const val = value.replace(/[^\d,.-]/g, '');
      let transformedValue = numericToStringFormatter(val, precision!);

      if (props.max != null || props.min != null) {
        const valAsNumber = valueAsNumber(transformedValue);
        if (props.max != null) {
          if (valAsNumber > props.max) {
            transformedValue = numericToStringFormatter(props.max, precision!);
          }
        }

        if (props.min != null) {
          if (valAsNumber < props.min) {
            transformedValue = numericToStringFormatter(props.min, precision!);
          }
        }
      }
      onChange(transformedValue);
    } else {
      onChange('');
    }
  }

  function handleChange(onChange: (...event: any[]) => void, value: any): void {
    formatValue(value?.toString(), onChange);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>, onChange: (...event: any[]) => void, value: any) {
    if (event.key === 'Enter') {
      formatValue(value?.toString(), onChange);
    }
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div data-test-id={`input-${props.name}-wrapper`}>
          <TextField
            inputProps={{ 'data-test-id': `input-${props.name}` }}
            helperText={error ? error.message : ' '}
            size='small'
            error={!!error}
            value={value?.toString()?.replace('.', ',')}
            fullWidth
            label={props.label}
            variant={props.variant != null ? props.variant : 'outlined'}
            margin='normal'
            onBlur={(event) => handleChange(onChange, value)}
            onChange={onChange}
            onKeyPress={(event) => handleKeyDown(event, onChange, value)}
            disabled={props.isDeactivated}
          />
        </div>
      )}
    />
  );
};
