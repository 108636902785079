import React from 'react';
import { DataTable, HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';

export const ProjectBranches = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <Panel borderType='flat'>
      <HStack align='center'>
        <ToggleButtons labels={[t('active'), t('notActive')]} activeIndex={activeIndex} onChange={(index) => setActiveIndex(index)} />
      </HStack>
      <DataTable
        rowLink={{ linkPath: '', linkRowId: 'projectBranch.projectBranchId' }}
        refreshKey={1}
        fetchUrl={'/api/project-branches'}
        fetchFilters={{ active: activeIndex === 0 }}
        columns={[
          {
            key: 'projectBranch.companyBranchName',
            label: t('branchName'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'projectBranch.registrationAddress.commune',
            label: t('commune'),
            type: 'text',
          },
          {
            key: 'projectBranch.registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'projectBranch.registrationAddress.houseNum',
            label: t('houseNum'),
            type: 'text',
          },
          {
            key: 'projectBranch.registrationAddress.apartmentNum',
            label: t('apartmentNum'),
            type: 'text',
          },
          {
            key: 'projectBranch.registrationAddress.postalCode',
            label: t('postcode'),
            type: 'text',
          },
          {
            key: 'projectBranch.registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'bdoUsed',
            label: t('bdo'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'contactPerson.firstName',
            label: t('contactPerson'),
            type: 'text',
            joinedColumns: [
              {
                key: 'contactPerson.lastName',
                label: t('lastName'),
                type: 'text',
              },
            ],
          },
          {
            key: 'contactPerson.phone',
            label: t('phone'),
            type: 'text',
          },
          {
            key: 'contactPerson.email',
            label: t('email'),
            type: 'text',
          },
          {
            key: 'projectBranch.contractConclusionDate',
            label: t('contractConclusionDate'),
            type: 'date',
          },
        ]}
      />
    </Panel>
  );
};
