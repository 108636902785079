import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralTab from './general-tab';
import ProjectSettingsTab from './project-settings-tab';
import PixTab from './pix-tab';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';

export function TermsTab() {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('general'), t('Model.Terms.projectSettings'), t('pix')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 0 && <GeneralTab />}
      {activePage === 1 && <ProjectSettingsTab />}
      {activePage === 2 && <PixTab />}
    </Panel>
  );
}
