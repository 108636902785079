export default function EditArrow() {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M77.3389 13.3335V33.3335H57.3389" stroke="#F2C94C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M68.9721 49.9999C66.8054 56.1329 62.704 61.3956 57.286 64.9949C51.868 68.5942 45.427 70.3351 38.9335 69.9552C32.4401 69.5753 26.246 67.0953 21.2846 62.8888C16.3233 58.6823 12.8635 52.9773 11.4267 46.6334C9.98982 40.2895 10.6537 33.6505 13.3183 27.7168C15.983 21.7831 20.5039 16.8761 26.2 13.7353C31.896 10.5946 38.4586 9.39022 44.8987 10.3037C51.3388 11.2172 57.3075 14.199 61.9055 18.7999L77.3388 33.3332"
                stroke="#F2C94C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
