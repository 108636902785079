import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { useParams } from 'react-router-dom';
import { ProjectTab } from './project-tab/project-tab';
import { ClientsTab } from './clients-tab/clients-tab';
import { SubcontractorsTab } from './subcontractors-tab/subcontractors-tab';
import { ProjectInfoPanel } from './project-info-panel';
import { ProjectContextProvider } from '@context';
import { useProjectView } from '@data-source-hooks';
import { FetchErrorIndicator } from '../../../../libs/ui-components/components/fetch-error-indicator';

export const ViewProject = () => {
  const { t } = useTranslation();
  const pathParams = useParams();

  const [projectQuery] = useProjectView(pathParams['projectId']?.substring(1));

  if (projectQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (projectQuery.isError) {
    return <FetchErrorIndicator error={projectQuery.error} />;
  }

  return (
    <ProjectContextProvider value={projectQuery.data!}>
      <ProjectInfoPanel project={projectQuery.data!} />
      <Tabs
        marginTop='1rem'
        panels={[
          {
            label: t('Projects.ViewProject.projectsTab'),
            component: <ProjectTab />,
          },
          { label: t('client'), component: <ClientsTab projectId={projectQuery.data.projectId!} /> },
          { label: t('subcontractors'), component: <SubcontractorsTab project={projectQuery.data!} /> },
        ]}
        borderType='none'
      />
    </ProjectContextProvider>
  );
};
