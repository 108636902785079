import { AddIcon, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OtherReportType } from '../../../../libs/api-interface/models/report/other/other-report-type.enum';
import { SubcontractorDecisionStatus } from '../../../../libs/api-interface/models/report/other/subcontractor-decision-status.enum';
import * as Yup from 'yup';

export interface CreateReportButtonProps {
  onSaved: () => Promise<any>;
  reportType: OtherReportType;
}

export const CreateOtherReportButton = ({ onSaved, reportType }: CreateReportButtonProps) => {
  const { t } = useTranslation();
  const reportTypeSpecificFilters: (GeneralFormItem | GeneralFormRowOfItems)[] = [];
  const validationSchema = reportType === OtherReportType.INFRASTRUCTURES ? { projectId: Yup.string().required(t('fieldRequired')) } : {};

  switch (reportType) {
    case OtherReportType.SUBCONTRACTOR_DECISIONS:
      reportTypeSpecificFilters.push({
        field: 'status',
        label: t('decisionStatus'),
        type: 'select',
        isDeactivated: true,
        selectOptions: Object.values(SubcontractorDecisionStatus).map((val) => {
          return { label: t(`SubcontractorDecisionStatus.${val}`), value: val };
        }),
      });
      break;
    case OtherReportType.INFRASTRUCTURES:
      reportTypeSpecificFilters.push(
        {
          field: 'projectId',
          label: t('project'),
          type: 'table-select',
          isDeactivated: (data) => data.allProjects,
          tableSelectParams: {
            modelName: 'projectViews',
            dialogTitle: t('project'),
            displayFormat: '{name}',
            selectedValueField: 'projectId',
            columns: [
              {
                label: t('name'),
                key: 'name',
                type: 'text',
              },
              {
                label: t('projectNumber'),
                key: 'projectNumber',
                type: 'text',
              },
            ],
          },
        },
        {
          field: 'companyId',
          label: t('company'),
          type: 'table-select',
          isDeactivated: (data) => data.allProjects,
          tableSelectParams: {
            modelName: 'companyViews',
            search: 'byProjectId',
            dialogTitle: t('project'),
            displayFormat: '{name}',
            selectedValueField: 'companyId',
            fetchFilters: {
              projectId: '$projectId',
            },
            columns: [
              {
                label: t('companyName'),
                key: 'name',
                type: 'text',
              },
            ],
          },
        },
      );
  }

  return (
    <UsecaseButton
      variant='contained'
      startIcon={<AddIcon />}
      onSaved={onSaved}
      groupName='reports'
      useCaseName='generate-report'
      buttonLabel={t('generateReport')}
      fields={reportTypeSpecificFilters}
      validationSchema={validationSchema}
      hiddenValues={{ reportType }}
    />
  );
};
