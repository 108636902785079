import { GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { DateTimeService } from '../../../../services/date-time.service';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface ProjectBranchHeaderProps {
  projectBranchView: any;
}

export const ProjectBranchHeader = (props: ProjectBranchHeaderProps) => {
  const { t } = useTranslation();
  const [emailsForEInvoice, setEmailsForEInvoice] = React.useState('');

  const fetchEmailsForEInvoice = async () => {
    await axios.get(`/api/branches/${props.projectBranchView.branchId}/emails-for-e-invoice`).then((response) => setEmailsForEInvoice(response.data?.join(', ')));
  };
  fetchEmailsForEInvoice();

  return (
    <Panel borderType={'raised'} header={props.projectBranchView?.companyBranchName}>
      <GridLabel gridTemplateColumns={'auto auto auto'} justifyContent={'flex-start'} gap={'6rem'}>
        <TextLabel
          label={t('contractConclusionDate')}
          value={props.projectBranchView?.contractConclusionDate ? new Date(props.projectBranchView.contractConclusionDate).toISOString() : undefined}
          dateFormat={DateTimeService.DEFAULT_FORMAT_DATE}
        />
        <TextLabel label={t('emailForNotifications')} value={''} />
        <TextLabel label={t('emailForEInvoice')} value={emailsForEInvoice} />
      </GridLabel>
    </Panel>
  );
};
