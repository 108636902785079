import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { DataTableColumn } from '../data-table';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { FormControl, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { AddIcon, Button, FormInputDatePlain, TrashcanIcon } from '@lib/ui-components';
import { useStateIfMounted } from 'use-state-if-mounted';

export interface DataTableFilterProps {
  columns: Array<DataTableColumn>;
  onFiltersChange: (filters: Array<TableFilterItem>) => void;
  initialFilters?: Array<TableFilterItem>;
}

export interface TableFilterItem {
  column: DataTableColumn;
  value: string | boolean | Date | [Date | null, Date | null];
}

export const DataTableFilter: (props: DataTableFilterProps) => JSX.Element = (props: DataTableFilterProps) => {
  const [activeFilters, setActiveFilters] = useStateIfMounted(new Array<TableFilterItem>());
  const [availableFilterColumns, setAvailableFilterColumns] = useStateIfMounted(new Array<DataTableColumn>());
  const originalColumnsOrder = useMemo(() => {
    const columnNames: Array<string> = [];
    props.columns.forEach((column) => {
      if (column.type === 'string-array-boolean-aggregate') {
        column.enumValues?.map((enumValue) => columnNames.push(column.key + '.' + enumValue.value));
      } else {
        columnNames.push(column.key);
      }
    });
    return columnNames;
  }, props.columns);
  const { t } = useTranslation();

  useEffect(() => {
    initializeAvailableFilterColumns();
    // eslint-disable-next-line
  }, [t, props.columns]);

  const debouncedValueChange = useDebouncedCallback((value) => {
    props.onFiltersChange(activeFilters);
  }, 300);

  function initializeAvailableFilterColumns() {
    const newFilters = new Array<DataTableColumn>();
    props.columns.forEach((column) => {
      if (column.excludeFromFilter === true) return;

      if (column.type === 'string-array-boolean-aggregate') {
        column.enumValues?.forEach((value) => {
          newFilters.push({
            type: 'string-array-boolean-aggregate',
            label: value.label,
            key: column.key + '.' + value.value,
          });
        });
      } else {
        newFilters.push(column);
      }
    });

    if (props.initialFilters == null || props.initialFilters.length === 0) {
      if (newFilters.length === 1) {
        setAvailableFilterColumns(newFilters.slice(1));
        setActiveFilters([
          {
            column: newFilters[0],
            value: getDefaultValue(newFilters[0]) ? 'true' : '',
          },
        ]);
      } else if (newFilters.length > 1) {
        setAvailableFilterColumns(newFilters.slice(1));
        const column = newFilters.shift()!;
        setActiveFilters([
          {
            column: column,
            value: getDefaultValue(column) ? 'true' : '',
          },
        ]);
      }
    } else {
      const newActiveFilters: TableFilterItem[] = [];
      for (const initialFilter of props.initialFilters) {
        const indexOfColumn = newFilters.findIndex((column) => column.key === initialFilter.column.key);
        if (indexOfColumn > -1) {
          const deleted = newFilters.splice(indexOfColumn, 1)[0];
          newActiveFilters.push({
            column: deleted,
            value: initialFilter.value,
          });
        }
      }
      setAvailableFilterColumns(newFilters);
      setActiveFilters(newActiveFilters);
      props.onFiltersChange(newActiveFilters);
    }
  }

  function addNewFilterItem() {
    if (availableFilterColumns.length > 0) {
      const columnToInsert: DataTableColumn = availableFilterColumns.shift()!;
      setActiveFilters([
        ...activeFilters,
        {
          column: columnToInsert,
          value: getDefaultValue(columnToInsert) ? 'true' : '',
        },
      ]);
      setAvailableFilterColumns([...availableFilterColumns]);
      props.onFiltersChange(activeFilters);
    }
  }

  function removeActiveFilter(index: number) {
    const removedFilter: TableFilterItem = activeFilters[index];
    activeFilters.splice(index, 1);
    setActiveFilters([...activeFilters]);
    availableFilterColumns.push(removedFilter.column);
    const sortedAvailableFilterColumns = [...availableFilterColumns].sort((a, b) => originalColumnsOrder.indexOf(a.key) - originalColumnsOrder.indexOf(b.key));
    setAvailableFilterColumns(sortedAvailableFilterColumns);

    props.onFiltersChange(activeFilters);
  }

  function getDefaultValue(column: DataTableColumn) {
    if (column.type === 'boolean') {
      return true;
    } else if (column.type === 'string-array-boolean') {
      return true;
    } else if (column.type === 'string-array-boolean-aggregate') {
      return true;
    } else if (column.type === 'date') {
      return [null, null];
    }
    return false;
  }

  function changeFilter(columnName: string, activeFilterIndex: number) {
    const newFilterColumnIndex = availableFilterColumns.findIndex((item) => item.key === columnName)!;
    const newFilterColumn = availableFilterColumns[newFilterColumnIndex];
    const oldFilterColumn = activeFilters[activeFilterIndex].column;
    activeFilters[activeFilterIndex].value = getDefaultValue(newFilterColumn) ? 'true' : '';
    activeFilters[activeFilterIndex].column = newFilterColumn;

    setActiveFilters([...activeFilters]);
    availableFilterColumns.splice(newFilterColumnIndex, 1);
    availableFilterColumns.push(oldFilterColumn);
    const sortedAvailableFilterColumns = [...availableFilterColumns].sort((a, b) => originalColumnsOrder.indexOf(a.key) - originalColumnsOrder.indexOf(b.key));
    setAvailableFilterColumns(sortedAvailableFilterColumns);

    props.onFiltersChange(activeFilters);
  }

  function updateValue(item: TableFilterItem, value: string | boolean | Date) {
    item.value = value;
    debouncedValueChange(item.value);
  }

  function updateDateRangeValue(item: TableFilterItem, value: Date, isStartDate: boolean) {
    if (Array.isArray(item.value) && item.value.length === 2) {
      item.value = isStartDate ? [value, item.value[1]] : [item.value[0], value];
    } else {
      item.value = isStartDate ? [value, null] : [null, value];
    }
    debouncedValueChange(item.value);
  }

  function renderFilterComponent(item: TableFilterItem, index: number) {
    switch (item.column.type) {
      case 'boolean':
      case 'string-array-boolean':
      case 'string-array-boolean-aggregate':
        return (
          <FormControl variant='standard'>
            <Select
              inputProps={{ 'data-test-id': `data-table-filter-value-select-${item.value}` }}
              key={`value.${index}`}
              value={`${item.value}`}
              onChange={(event) => updateValue(item, event.target.value)}
              autoWidth
            >
              <MenuItem data-test-id={`data-table-filter-value-select-option-${item.column.key}-yes`} value={'true'}>
                {t('yes')}
              </MenuItem>
              <MenuItem data-test-id={`data-table-filter-value-select-option-${item.column.key}-no`} value={'false'}>
                {t('no')}
              </MenuItem>
            </Select>
          </FormControl>
        );
      case 'date':
      case 'date-time':
        return (
          <div style={{ display: 'flex' }}>
            <FormInputDatePlain
              key={`value.${index}-from`}
              dataTestId={`data-table-filter-value-date-${item.column.key}-from`}
              defaultValue={`${Array.isArray(item.value) && item.value.length === 2 ? item.value[0] : ''}`}
              variant='standard'
              onChange={(val) => updateDateRangeValue(item, val, true)}
              hideHelperTextIfEmpty={true}
            />
            <FormInputDatePlain
              key={`value.${index}-to`}
              dataTestId={`data-table-filter-value-date-${item.column.key}-to`}
              defaultValue={`${Array.isArray(item.value) && item.value.length === 2 ? item.value[1] : ''}`}
              variant='standard'
              onChange={(val) => updateDateRangeValue(item, val, false)}
              hideHelperTextIfEmpty={true}
            />
          </div>
        );
      case 'date-year-month':
        return (
          <FormInputDatePlain
            key={`value.${index}`}
            dataTestId={`data-table-filter-value-date-year-${item.column.key}`}
            defaultValue={`${item.value}`}
            variant='standard'
            onChange={(val) => updateValue(item, val)}
            dateVariant='year-month'
            hideHelperTextIfEmpty={true}
          />
        );
      case 'enum': {
        let enumOptions;
        if (item.column.customValueMappings != null) {
          enumOptions = item.column.customValueMappings
            ?.filter((mapping) => mapping.excludeFromFilter !== true)
            .map((enumItem, index) => {
              return (
                <MenuItem data-test-id={`data-table-filter-value-select-option-${item.column.key}-${enumItem.value}`} key={index} value={enumItem.value}>
                  {enumItem.label}
                </MenuItem>
              );
            });
        } else {
          enumOptions = item.column.enumValues?.map((enumItem, index) => {
            return (
              <MenuItem data-test-id={`data-table-filter-value-select-option-${item.column.key}-${enumItem.value}`} key={index} value={enumItem.value}>
                {enumItem.label}
              </MenuItem>
            );
          });
        }

        return (
          <FormControl variant='standard'>
            <Select
              inputProps={{ 'data-test-id': `data-table-filter-value-select-${item.column.key}-${item.value}` }}
              key={`value.${index}`}
              value={`${item.value}`}
              onChange={(event) => updateValue(item, event.target.value)}
              autoWidth
            >
              {enumOptions}
            </Select>
          </FormControl>
        );
      }
      default:
        return (
          <TextField
            inputProps={{ 'data-test-id': `data-table-filter-value-${item.column.key}-text` }}
            key={`value.${index}`}
            variant='standard'
            defaultValue={item.value}
            onChange={(event) => updateValue(item, event.target.value)}
          />
        );
    }
  }

  function removeTags(str: string | null) {
    if (str === null || str === '') return '';
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, ' ');
  }

  const filters = activeFilters.map((item, index) => {
    const options = availableFilterColumns.map((column, columnIndex) => {
      return (
        <MenuItem data-test-id={`data-table-filter-option-${column.key}`} key={columnIndex} value={column.key}>
          {removeTags(column.label)}
        </MenuItem>
      );
    });

    return (
      <div key={index} className={styles['filterItemContainer']} data-test-id={`data-table-filter-wrapper-${item.column.key}`}>
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <Select
            inputProps={{ 'data-test-id': 'data-table-filter-select' }}
            key={`column.${index}`}
            value={item.column.key}
            onChange={(event) => {
              changeFilter(event.target.value, index);
            }}
            autoWidth
          >
            <MenuItem data-test-id={`data-table-filter-option-${item.column.key}`} value={item.column.key}>
              {removeTags(item.column.label)}
            </MenuItem>
            {options}
          </Select>
        </FormControl>
        <div data-test-id={`data-table-filter-value-wrapper-${item.column.key}`}>{renderFilterComponent(item, index)}</div>
        <IconButton
          data-test-id={`data-table-remove-filter-${item.column.key}-btn`}
          onClick={() => {
            removeActiveFilter(index);
          }}
        >
          <TrashcanIcon large />
        </IconButton>
      </div>
    );
  });

  return (
    <div className={styles['filtersContainer']}>
      {filters}
      {availableFilterColumns.length > 0 ? (
        <Button
          dataTestId='data-table-add-filter-btn'
          label={t('addFilter')}
          color='primary'
          variant='text'
          onClick={async () => {
            addNewFilterItem();
          }}
          startIcon={<AddIcon />}
        />
      ) : (
        false
      )}
    </div>
  );
};
