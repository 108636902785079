import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandClientService } from '../../../../common-sdk';
import { Button } from '../../button/button';
import { Snackbar } from '../../snackbar/snack-bar';
import { useStateIfMounted } from 'use-state-if-mounted';
import { TrashcanIcon } from '../TrashcanIcon';

export interface DeleteOnAggregateButtonProps {
  aggregateId: string;
  aggregateName: string;
  commandName: string;
  payload: any;
  tooltip?: string;
  onDeleted: () => void;
  variant?: 'text' | 'outlined' | 'contained';
}

export const DeleteOnAggregateButton = (props: DeleteOnAggregateButtonProps) => {
  const { t } = useTranslation();
  const [generalFailure, setGeneralFailure] = useStateIfMounted(false);

  async function handleDelete() {
    try {
      await AggregateCommandClientService.callCommand<any>(props.aggregateName, props.commandName, props.aggregateId, props.payload);
      props.onDeleted();
    } catch (error: any) {
      setGeneralFailure(true);
    }
  }

  return (
    <>
      <Button
        dataTestId={`${props.aggregateName}-delete-btn`}
        label={t('Common.delete')}
        startIcon={<TrashcanIcon />}
        tooltip={props.tooltip}
        color='primary'
        forceConfirmation={true}
        variant={props.variant}
        onClick={async () => handleDelete()}
      />
      <Snackbar open={generalFailure} message={t('Common.error')} severity='error' onClose={() => setGeneralFailure(false)} />
    </>
  );
};
