import React from 'react';
import { Panel } from '@lib/ui-components';
import { useCompanyBranchContext } from '../../../../../../../../contexts/company-branch.context';
import { CompanyBranchAccounts } from '../../../company-branch-accounts';
import { RolesEnum } from '../../../../../../../../domain/enums/roles.enum';

export const CompanyBranchLoginAccounts = () => {
  const companyBranch = useCompanyBranchContext();
  const roles: RolesEnum[] = [];
  if (companyBranch.roleContractor) {
    roles.push(RolesEnum.CONTRACTOR);
  }
  if (companyBranch.roleClient) {
    roles.push(RolesEnum.CLIENT_USER);
  }

  return (
    <Panel borderType='flat'>
      <CompanyBranchAccounts companyId={companyBranch.companyId!} role={roles} branchId={companyBranch.branchId} hasBranch={true} hasProjects={true} />
    </Panel>
  );
};
