import { ReactNode } from 'react';
import './YellowIcon.css';

interface YellowIconProps {
  children: ReactNode;
  sizePx?: number;
}

export function YellowIcon(props: YellowIconProps) {
  const sizePx = props.sizePx != undefined ? props.sizePx : 24;
  return (
    <div className='yellow-icon-wrapper' style={{ color: '#F2C94C', width: sizePx, height: sizePx }}>
      {props.children}
    </div>
  );
}
