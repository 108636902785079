import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';

export enum InfrastructureCategory {
  BALER = 'BALER', // Belownica
  CONTAINER = 'CONTAINER', // Kontener
  BARREL = 'BARREL', // Beczka
  STORAGE_BIN = 'STORAGE_BIN', // Pojemnik
  BIG_BAG = 'BIG_BAG', // Big Bag
  PRESS_CONTAINER = 'PRESS_CONTAINER', // Prasokontener
  PRESS_TRAY = 'PRESS_TRAY', // Zasobnik do prasy
  STATIONARY_PRESS = 'STATIONARY_PRESS', // Prasa stacjonarna
  TRAY = 'TRAY', // Paleta
  BAG = 'BAG', // worek
  IN_BULK = 'IN_BULK', // Luzem
  OTHER = 'OTHER', // inne
  ALL = 'ALL',
}

export class InfrastructureModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  category: InfrastructureCategory | undefined;

  @Expose()
  mass?: number; // in kg

  @Expose()
  volume?: number; // in cm2

  @IsNotEmpty()
  @Expose()
  name: string | undefined;

  @Expose()
  comment?: string;

  get baler(): boolean {
    return this.category === InfrastructureCategory.BALER;
  }

  get createsResource(): boolean {
    return InfrastructureModel.isGatheringResource(this.category);
  }

  static isGatheringResource(category: InfrastructureCategory | undefined) {
    return (
      category !== InfrastructureCategory.BALER &&
      category !== InfrastructureCategory.STATIONARY_PRESS &&
      category !== InfrastructureCategory.IN_BULK &&
      category !== InfrastructureCategory.OTHER
    );
  }

  static isBaller(category: InfrastructureCategory | undefined) {
    return category === InfrastructureCategory.BALER;
  }

  static isPress(category: InfrastructureCategory | undefined) {
    return category === InfrastructureCategory.STATIONARY_PRESS;
  }
}
