import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddModelButton, DataTable, DataTableRowOptionItem, DeleteModelButton, EditModelButton, Header, Toolbar } from '@lib/ui-components';

export const Wastes = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const rowOptions: DataTableRowOptionItem[] = [
    {
      isVisible: (row) => row['bdoWasteId'] == null,
      renderer: (row) => (
        <EditModelButton
          id={row['id']}
          entity={row}
          modelName='wastes'
          buttonLabel={t('edit')}
          dialogTitle={t('Button.WasteModel.edit.dialog.title')}
          fields={[
            { field: 'code', label: t('wasteCode'), type: 'text' },
            { field: 'description', label: t('wasteDescription'), type: 'text' },
            { field: 'municipal', label: t('Model.Waste.municipal'), type: 'boolean' },
          ]}
          validationSchema={{
            code: Yup.string().required(t('Model.Waste.code.required')),
            description: Yup.string().required(t('Model.Waste.description.required')),
          }}
          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          yellowIcon
        />
      ),
    },
    {
      isVisible: (row) => row['bdoWasteId'] != null,
      renderer: (row) => (
        <EditModelButton
          id={row['id']}
          entity={row}
          modelName='wastes'
          buttonLabel={t('edit')}
          dialogTitle={t('Button.WasteModel.edit.dialog.title')}
          fields={[{ field: 'municipal', label: t('Model.Waste.municipal'), type: 'boolean' }]}
          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          yellowIcon
        />
      ),
    },
    {
      renderer: (row) => <DeleteModelButton id={row['id']} modelName='wastes' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
    },
  ];

  const rightButtons: JSX.Element[] = [
    <AddModelButton
      modelName='wastes'
      buttonLabel={t('Button.WasteModel.add')}
      dialogTitle={t('Button.WasteModel.add.dialog.title')}
      fields={[
        {
          label: t('wasteCode') + ' - BDO',
          type: 'table-select-by-api',
          field: 'wasteCodeId',
          tableSelectByApiParams: {
            apiPostEndpoint: '/api/organization/bdo/search/waste',
            dialogTitle: t('wasteCode'),
            displayFormat: '{code}',
            selectedValueField: 'wasteCodeId',
            columns: [
              { label: t('wasteCode'), key: 'code', type: 'text' },
              { label: t('wasteDescription'), key: 'description', type: 'text' },
              { label: t('Model.Waste.hazardous'), key: 'hazardous', type: 'boolean' },
            ],
            otherValuesMap: {
              code: 'bdoCode',
              description: 'bdoDescription',
              hazardous: 'bdoHazardous',
            },
            queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
          },
          isVisible: (data) => data.code === '',
        },
        { field: 'code', label: t('wasteCode'), type: 'text', isVisible: (data) => data.wasteCodeId === '' },
        {
          field: 'description',
          label: t('wasteDescription'),
          type: 'text',
          isVisible: (data) => data.wasteCodeId === '',
        },
        { field: 'municipal', label: t('Model.Waste.municipal'), type: 'boolean' },
      ]}
      validationSchema={{
        wasteCodeId: Yup.lazy(() => {
          return Yup.string().when('code', {
            is: (code: string) => code === '',
            then: Yup.string().required(t('Model.Waste.code.required')),
          });
        }),
        code: Yup.string().when('wasteCodeId', {
          is: (wasteCodeId: string) => wasteCodeId === '',
          then: Yup.string().required(t('Model.Waste.code.required')),
        }),
        description: Yup.string().when('wasteCodeId', {
          is: (wasteCodeId: string) => wasteCodeId === '',
          then: Yup.string().required(t('Model.Waste.description.required')),
        }),
      }}
      onBeforeSave={(formData) => {
        formData.nonWaste = formData.wasteCodeId === '';
        formData.hazardous = false;
        if (!formData.nonWaste) {
          formData.bdoWasteId = formData.wasteCodeId;
          formData.code = formData.bdoCode;
          formData.description = formData.bdoDescription;
          formData.hazardous = formData.bdoHazardous;

          delete formData.wasteCodeId;
          delete formData.bdoCode;
          delete formData.bdoDescription;
          delete formData.bdoHazardous;
        }
        return formData;
      }}
      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
    />,
  ];

  return (
    <>
      <Header label={t('GlobalSettings.wastes.header')} />
      <Toolbar right={rightButtons} />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'wastes' }}
        columns={[
          {
            key: 'code',
            label: t('wasteCode'),
            type: 'text',
          },
          {
            key: 'description',
            label: t('wasteDescription'),
            type: 'text',
          },
          {
            key: 'hazardous',
            label: t('Model.Waste.hazardous'),
            type: 'boolean',
          },
          {
            key: 'municipal',
            label: t('Model.Waste.municipal'),
            type: 'boolean',
          },
          {
            key: 'nonWaste',
            label: t('Model.Waste.nonWaste'),
            type: 'boolean',
          },
        ]}
        rowOptions={rowOptions}
      />
    </>
  );
};
