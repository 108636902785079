import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientUsers } from './client-users';
import { ClientAdmins } from './client-admins';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';

export interface ClientUserAccountsProps {
  company: CompanyViewModel;
}

// TODO: Add regional admins
export const ClientUserAccounts = (props: ClientUserAccountsProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        {/* <ToggleButtons labels={[t('users'), t('admin'), t('regionalAdmins')]} activeIndex={activePage} onChange={(index) => setActivePage(index)}/>*/}
        <ToggleButtons labels={[t('users'), t('admin')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>

      {activePage === 0 && <ClientUsers company={props.company} />}
      {activePage === 1 && <ClientAdmins company={props.company} />}
      {/* {activePage === 2 && <ClientRegionalAdmins />}*/}
    </Panel>
  );
};
