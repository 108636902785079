import { useTranslation } from 'react-i18next';
import React from 'react';
import { getCompanyViewForContractor } from '@data-source-hooks';
import { DecisionsConclusions } from './decisions-conclusions';
import Agreements from './agreements';

interface DocumentsProps {
  agreements?: boolean;
}

export const Documents = ({ agreements }: DocumentsProps) => {
  const { t } = useTranslation();
  const [companyView, setCompanyView] = React.useState(null);
  React.useEffect(() => {
    const fetchCompanyView = async () => {
      try {
        const companyView = await getCompanyViewForContractor();
        setCompanyView(companyView);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchCompanyView();
  }, []);

  if (companyView === null) {
    return <div>{t('Common.loading')}</div>;
  }

  if (agreements) {
    return <Agreements company={companyView} />;
  }
  return <DecisionsConclusions company={companyView} />;
};
