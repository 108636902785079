import * as React from 'react';
import { CSSProperties } from 'react';
import { Paper } from '@mui/material';
import styles from './styles.module.css';
import { Property } from 'csstype';
import { Header } from '../header/header';

export type PanelBorderType = 'none' | 'raised' | 'flat';

export interface PanelProps {
  header?: string;
  borderType?: PanelBorderType;
  children?: React.ReactNode;
  direction?: 'row' | 'column';
  justifyContent?: Property.JustifyContent;
  gap?: string;
  contentMarginTop?: string;
  contentMarginLeft?: string;
  contentMarginRight?: string;
  contentMarginBottom?: string;
  alignItems?: string;
  flex?: string;
  rightItem?: React.ReactNode;
  onClick?: () => void;
}

export const Panel = (props: PanelProps) => {
  function renderInsides() {
    const style: CSSProperties = {
      flexDirection: props.direction !== undefined ? props.direction : 'column',
      justifyContent: props.justifyContent !== undefined ? props.justifyContent : 'left',
      gap: props.gap !== undefined ? props.gap : '0.3rem',
      marginTop: props.contentMarginTop !== undefined ? props.contentMarginTop : '0.3rem',
      marginBottom: props.contentMarginBottom !== undefined ? props.contentMarginBottom : '0.3rem',
      marginLeft: props.contentMarginLeft !== undefined ? props.contentMarginLeft : '0.3rem',
      marginRight: props.contentMarginRight !== undefined ? props.contentMarginRight : '0.3rem',
      alignItems: props.alignItems !== undefined ? props.alignItems : 'unset',
      flex: props.flex !== undefined ? props.flex : 'unset',
    };

    if (props.header) {
      return (
        <div className={styles['headerContainer']}>
          <div className={styles['headerItems']}>
            <Header label={props.header} />
            {props.rightItem && props.rightItem}
          </div>
          <div style={style} onClick={props.onClick}>{props.children}</div>
        </div>
      );
    }

    return (
      <div className={styles['innerContainer']} style={style} onClick={props.onClick}>
        {props.children}
      </div>
    );
  }

  if (props.borderType === 'raised') {
    return <Paper>{renderInsides()}</Paper>;
  }

  if (props.borderType === 'flat') {
    return <div className={styles['flatContainer']}>{renderInsides()}</div>;
  }

  return renderInsides();
};
