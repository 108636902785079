import React from 'react';
import { CompanyViewModel } from '@lib/api-interface';
import { useTranslation } from 'react-i18next';
import { DataTable, DeleteModelButton, EditModelButton, GetContentButton, Panel } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';

export interface ContractorRdrProps {
  company: CompanyViewModel;
}

export function ContractorRdr(props: ContractorRdrProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();

  return (
    <Panel borderType='flat'>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyFileViews' }}
        fetchFilters={{ companyId: props.company.companyId, type: 'RDR' }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'companyBranch.name',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'companyFileRdrData.communeName',
            label: t('commune'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
          {
            key: 'creationDate',
            label: t('added'),
            type: 'date',
          },
          {
            key: 'createdBy',
            label: t('introducedBy'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
          {
            renderer: (row) => (
              <EditModelButton
                id={row.id}
                entity={row}
                buttonLabel={t('edit')}
                dialogTitle={t('edit')}
                modelName={'companyFileViews'}
                fields={[{ label: t('comment'), type: 'text', field: 'comment' }]}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
          {
            renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </Panel>
  );
}
