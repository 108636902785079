import React from 'react';
import {useTranslation} from 'react-i18next';
import {DeleteOutline} from '@mui/icons-material';
import {AuthApiService} from '../../../../services/auth.api.service';
import {Button, Snackbar} from '../../../../libs/ui-components';

export interface DeleteUserButtonProps {
  id: string;
  onDeleted: () => Promise<void>;
}

export const DeleteUserButton = (props: DeleteUserButtonProps) => {
  const {t} = useTranslation();
  const [generalFailure, setGeneralFailure] = React.useState(false);

  async function handleDelete() {
    const result = await AuthApiService.deleteUser(props.id);
    if (result) {
      await props.onDeleted();
    } else {
      setGeneralFailure(true);
    }
  }

  return (
      <>
        <Button label={t('Common.delete')} startIcon={<DeleteOutline/>} color="warning" onClick={async () => handleDelete()}/>
        <Snackbar open={generalFailure} message={t('Common.error')} severity="error" onClose={() => setGeneralFailure(false)}/>
      </>
  );
};
