import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, Dialog, HStack, Tabs } from '@lib/ui-components';
import { ServiceSettlementImport } from './service-settlement-import';
import React from 'react';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ServiceSettlementExport } from './service-settlement-export';
import CloseIcon from '@mui/icons-material/Close';
import { History } from '@mui/icons-material';

export const ServicesSettlementExcelLogs = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useStateIfMounted(false);

  async function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <HStack align={'right'}>
      <Button label={t('exportAndImportLogs')} onClick={async () => setOpenDialog(true)} startIcon={<History />} />
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth='xl' fullWidth={true}>
        <DialogTitle>
          <IconButton
            data-test-id={'dialog-close-btn'}
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs
            panels={[
              { label: t('exportLogs'), component: <ServiceSettlementExport /> },
              { label: t('importLogs'), component: <ServiceSettlementImport /> },
            ]}
          />
        </DialogContent>
      </Dialog>
    </HStack>
  );
};
