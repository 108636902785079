import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { Tabs } from '@lib/ui-components';
import { ContractorAssignedProjects } from './contractor-assigned-projects';
import { ContractorGeneral } from './contractor-general';
import { ContractorLoginAccounts } from './contractor-login-accounts';
import { CompanyAddressInfo } from '../../shared/company-address-info';
import { ClientBDO } from '../../client-view/client-data/client-bdo';

export interface SubcontractorDataProps {
  company: CompanyViewModel;
}

export const ContractorData = (props: SubcontractorDataProps) => {
  const { t } = useTranslation();

  return (
    <>
      <CompanyAddressInfo company={props.company} />
      <Tabs
        marginTop='1rem'
        panels={[
          { label: t('assignedProjects'), component: <ContractorAssignedProjects company={props.company} /> },
          { label: t('general'), component: <ContractorGeneral companyId={props.company.companyId!} /> },
          { label: t('userAccounts'), component: <ContractorLoginAccounts company={props.company} /> },
          { label: t('bdo'), component: <ClientBDO company={props.company} companyRole={CompanyRole.SUBCONTRACTOR} /> },
        ]}
        borderType='none'
      />
    </>
  );
};
