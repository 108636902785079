import * as React from 'react';
import {CSSProperties} from 'react';
import styles from './styles.module.css';

export interface GridLabelProps {
  children?: React.ReactNode;
  gridAutoFlow?: string;
  gridTemplateColumns?: string;
  gap?: string;
  contentPaddingTop?: string;
  contentPaddingLeft?: string;
  contentPaddingRight?: string;
  contentPaddingBottom?: string;
  width?: string;
  height?: string;
  justifyContent?: string;
  placeItems?: string;
}

export const GridLabel = (props: GridLabelProps) => {
  function renderInsides() {
    const style: CSSProperties = {
      gap: props.gap !== undefined ? props.gap : '0.3rem',
      gridAutoFlow: props.gridAutoFlow !== undefined ? props.gridAutoFlow : 'row',
      gridTemplateColumns: props.gridTemplateColumns !== undefined ? props.gridTemplateColumns : '1fr 1fr',
      paddingTop: props.contentPaddingTop !== undefined ? props.contentPaddingTop : '0.3rem',
      paddingBottom: props.contentPaddingBottom !== undefined ? props.contentPaddingBottom : '0.3rem',
      paddingLeft: props.contentPaddingLeft !== undefined ? props.contentPaddingLeft : '0.3rem',
      paddingRight: props.contentPaddingRight !== undefined ? props.contentPaddingRight : '0.3rem',
      height: props.height ? props.height : '100%',
      width: props.width ? props.width : '100%',
      placeItems: props.placeItems ? props.placeItems : 'flex-start',
      justifyContent: props.justifyContent ? props.justifyContent : 'space-between'
    };

    return (
        <div className={styles['gridContainer']} style={style}>
          {props.children}
        </div>
    );
  }

  return renderInsides();
};
