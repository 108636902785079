import React from 'react';
import { useTranslation } from 'react-i18next';
import HeadquartersBranchesTab from './headquarters-branches/headquarters-branches-tab';
import ContactTab from './contact/contact-tab';
import UserAccountsTab from './user-accounts/user-accounts-tab';
import { Tabs } from '@lib/ui-components';
import ClientAgreementsTab from './agreements/client-agreements-tab';

export interface ClientTabProps {
  projectId: string;
}

export const ClientsTab = ({ projectId }: ClientTabProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Tabs
        marginTop='1rem'
        panels={[
          { label: t('Projects.ViewProject.Client.HeadquartersBranches'), component: <HeadquartersBranchesTab /> },
          { label: t('agreements'), component: <ClientAgreementsTab projectId={projectId} /> },
          { label: t('contact'), component: <ContactTab /> },
          { label: t('userAccounts'), component: <UserAccountsTab projectId={projectId} /> },
        ]}
        borderType='none'
      />
    </>
  );
};
