import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddModelButton, DataTable, DeleteModelButton, GetContentButton, Panel, Toolbar } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCompanyBranchView } from '../../../../../../../../data-source-hooks/company-branch.data-source';
import { QueryResultRenderer } from '../../../../../../../../libs/ui-components/components/query-result-renderer';

export interface CompanyBranchDecisionsOnCollectingProps {
  companyBranchId: string;
}

export function CompanyBranchDecisionsOnCollecting(props: CompanyBranchDecisionsOnCollectingProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const [branchQuery] = useCompanyBranchView(props.companyBranchId);

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        return (
          <Panel borderType='none'>
            <Toolbar
              right={[
                <AddModelButton
                  multipart={true}
                  buttonLabel={t('uploadFile')}
                  dialogTitle={t('uploadFile')}
                  modelName={'companyFileViews'}
                  fields={[
                    { label: t('expirationDate'), type: 'date', field: 'expirationDate', isVisible: (data) => data['forUnlimitedPeriod'] == false },
                    { label: t('forUnlimitedPeriod'), type: 'boolean', field: 'forUnlimitedPeriod' },
                    { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
                    { label: t('comment'), type: 'text', field: 'comment' },
                  ]}
                  hiddenValues={{
                    type: 'CONTRACTOR_DECISION_ON_COLLECTING',
                    companyId: companyBranchView.companyId,
                    companyBranchId: companyBranchView.branchId,
                  }}
                  onBeforeSave={(formData: any) => {
                    const decisionOnCollectingData: any = {};
                    decisionOnCollectingData.expirationDate = formData.expirationDate;
                    decisionOnCollectingData.forUnlimitedPeriod = formData.forUnlimitedPeriod;
                    if (decisionOnCollectingData.forUnlimitedPeriod === true) delete decisionOnCollectingData.expirationDate;

                    formData.decisionOnCollectingDataString = JSON.stringify(decisionOnCollectingData);
                    delete formData.expirationDate;
                    return formData;
                  }}
                  onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                />,
              ]}
            />
            <DataTable
              refreshKey={tableReloadKey}
              modelDef={{ modelName: 'companyFileViews' }}
              fetchFilters={{
                companyId: companyBranchView.companyId,
                companyBranchId: companyBranchView.branchId,
                type: 'CONTRACTOR_DECISION_ON_COLLECTING',
              }}
              columns={[
                {
                  key: 'fileName',
                  label: t('decisionsOnCollecting'),
                  type: 'text',
                },
                {
                  key: 'companyBranch.name',
                  label: t('Model.CompanyBranch.name'),
                  type: 'text',
                },
                {
                  key: 'companyBranch.registrationAddress.town',
                  label: t('locality'),
                  type: 'text',
                },
                {
                  key: 'companyBranch.registrationAddress.street',
                  label: t('street'),
                  type: 'text',
                },
                {
                  key: 'decisionOnCollectingData.expirationDate',
                  label: t('expirationDate'),
                  type: 'date',
                },
                {
                  key: 'decisionOnCollectingData.forUnlimitedPeriod',
                  label: t('forUnlimitedPeriod'),
                  type: 'boolean',
                  align: 'center',
                },
                {
                  key: 'creationDate',
                  label: t('uploadDate'),
                  type: 'date',
                },
                {
                  key: 'createdBy',
                  label: t('addedBy'),
                  type: 'text',
                },
                {
                  key: 'comment',
                  label: t('comment'),
                  type: 'text',
                },
              ]}
              rowOptions={[
                {
                  renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
                },
                {
                  renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
                },
              ]}
            />
          </Panel>
        );
      }}
    />
  );
}
