import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, DataTable, DeleteOnAggregateButton, Edit } from '@lib/ui-components';
import { ContactPersonTypeEnum } from '../../../../../libs/api-interface/models/company/contact-person-type.enum';
import * as Yup from 'yup';

export interface CompanyContactPersonsTableProps {
  companyId: string;
  branchId?: string;
  type: ContactPersonTypeEnum;
}

export const CompanyContactPersonsTable = (props: CompanyContactPersonsTableProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <DataTable
      refreshKey={tableReloadKey}
      modelDef={{ modelName: 'contactPersonViews' }}
      noFilters
      fetchFilters={{
        type: props.type,
        companyId: props.companyId,
        branchId: props.branchId,
      }}
      columns={[
        {
          key: 'firstName',
          label: t('firstName'),
          type: 'text',
        },
        {
          key: 'lastName',
          label: t('lastName'),
          type: 'text',
        },
        {
          key: 'email',
          label: t('email'),
          type: 'text',
        },
        {
          key: 'phone',
          label: t('phone'),
          type: 'text',
        },
        {
          key: 'mainContactPerson',
          label: t('mainContactPerson'),
          type: 'boolean',
        },
        {
          key: 'comment',
          label: t('comment'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => (
            <AggregateCommandButton
              aggregateName='CompanyAggregate'
              commandName='updateContactPerson'
              aggregateId={props.companyId}
              dialogTitle={t('edit')}
              buttonLabel={t('edit')}
              fields={[
                {
                  rowItems: [
                    { field: 'firstName', label: t('firstName'), type: 'text', defaultValue: row.firstName },
                    { field: 'lastName', label: t('lastName'), type: 'text', defaultValue: row.lastName },
                  ],
                },
                {
                  rowItems: [
                    { field: 'email', label: t('email'), type: 'text', defaultValue: row.email },
                    { field: 'phone', label: t('phone'), type: 'text', defaultValue: row.phone },
                  ],
                },
                {
                  label: t('mainContactPerson'),
                  type: 'boolean',
                  field: 'mainContactPerson',
                  defaultValue: row.mainContactPerson,
                },
                { field: 'comment', label: t('comment'), type: 'text', defaultValue: row.comment },
              ]}
              validationSchema={{
                email: Yup.string().when([], {
                  is: () => row.contactType === ContactPersonTypeEnum.ORDER,
                  then: () => Yup.string().email(t('requiredValidEmail')).required(t('fieldRequired')),
                }),
              }}
              hiddenValues={{
                contactPersonId: row.contactPersonId,
                branchId: row.branchId,
              }}
              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              startIcon={<Edit yellowIcon={true} />}
            />
          ),
        },
        {
          renderer: (row) => (
            <DeleteOnAggregateButton
              aggregateId={props.companyId}
              aggregateName='CompanyAggregate'
              commandName='removeContactPerson'
              payload={{
                contactPersonId: row.contactPersonId,
                branchId: row.branchId,
              }}
              onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
            />
          ),
        },
      ]}
    />
  );
};
