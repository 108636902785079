import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { CompaniesDataTable } from './companies.data-table';

export const CompaniesPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        panels={[
          { label: t('all'), component: <CompaniesDataTable /> },
          { label: t('Companies.clients'), component: <CompaniesDataTable roleClient={true} /> },
          { label: t('Companies.subcontractors'), component: <CompaniesDataTable roleSubcontractor={true} /> },
        ]}
      />
    </div>
  );
};
