import { Dialog as MuiDialog, DialogProps } from '@mui/material';

export const Dialog = (props: DialogProps) => {
  function onClose(event: any, reason: 'escapeKeyDown' | 'backdropClick') {
    if (reason !== 'backdropClick' && props.onClose !== undefined) {
      props.onClose(event, reason);
    }
  }

  const newProps = {
    ...props,
    onClose,
  };

  // https://medium.com/coding-at-dawn/how-to-pass-all-props-to-a-child-component-in-react-bded9e38bb62
  return <MuiDialog {...newProps}/>;
};
