import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectView } from '@lib/api-interface';
import { DataTable, Panel } from '@lib/ui-components';

export const ContractorQuantityReceived = () => {
  const { t } = useTranslation();

  return (
    <Panel borderType='raised'>
      <DataTable
        excel={true}
        refreshKey={1}
        modelDef={{ modelName: ProjectView.name }}
        fetchFilters={{}}
        columns={[
          {
            key: '',
            label: t('type'),
            type: 'text',
          },
          {
            key: '',
            label: t('clientName'),
            type: 'text',
          },
          {
            key: '',
            label: t('projectNum'),
            type: 'text',
          },
          {
            key: '',
            label: t('branch'),
            type: 'text',
          },
          {
            key: '',
            label: t('locality'),
            type: 'text',
          },
          {
            key: '',
            label: t('street'),
            type: 'text',
          },
          {
            key: '',
            label: t('wasteServiceCode'),
            type: 'text',
          },
          {
            key: '',
            label: t('wasteNameServiceDesc'),
            type: 'text',
          },
          {
            key: '',
            label: t('selectedForKpo'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: '',
            label: t('settlementType'),
            type: 'text',
          },
          {
            key: '',
            label: t('reportDate'),
            type: 'date',
          },
          {
            key: '',
            label: t('orderNum'),
            type: 'text',
          },
          {
            key: '',
            label: t('estimatedMass'),
            type: 'text',
          },
          {
            key: '',
            label: t('reportedWeight'),
            type: 'text',
          },
          {
            key: '',
            label: t('estimatedQuantities'),
            type: 'text',
          },
          {
            key: '',
            label: t('reportedQuantities'),
            type: 'text',
          },
          {
            key: '',
            label: t('transportPlan'),
            type: 'numeric',
          },
          {
            key: '',
            label: t('status'),
            type: 'text',
          },
          {
            key: '',
            label: t('modificationDate'),
            type: 'date',
          },
          {
            key: '',
            label: t('editedBy'),
            type: 'text',
          },
          {
            key: '',
            label: t('weightReceipt'),
            type: 'boolean',
            align: 'center',
          },
        ]}
      />
    </Panel>
  );
};
