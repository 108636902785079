import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@lib/ui-components';
import { BdoErrorsDataTable } from './bdo-errors.data-table';

export const BdoErrorsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header label={t('bdoErrors')} />
      <BdoErrorsDataTable />
    </>
  );
};
