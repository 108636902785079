import React from 'react';
import { TitlePositioner } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, CheckboxLabel, DataTable, Edit, EditModelButton, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useProjectContext } from '@context';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';

export default function ProjectSettingsTab() {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const projectView = useProjectContext();
  const queryClient = useQueryClient();
  const reloadData = () => queryClient.invalidateQueries(['projectViews', projectView.projectId!]);

  return (
    <>
      <Panel direction='row'>
        <Panel flex='1'>
          <TitlePositioner>
            <TextLabel label={t('Projects.ViewProject.Terms.Settings.mainSettings').toUpperCase()} />
          </TitlePositioner>
          <Panel borderType='raised'>
            <GridLabel>
              <GridLabel gridTemplateColumns='none'>
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.newServices')}
                  value={projectView.projectSettings?.newServicesDontRequireAdminAcceptance}
                  type='toggle'
                />
                <CheckboxLabel label={t('Projects.ViewProject.Terms.Settings.branchReports')} value={projectView.projectSettings?.branchCanGenerateReports} type='toggle' />
                <CheckboxLabel label={t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReports')} value={projectView.projectSettings?.clientAdminGenerateBdoKpoReports} type='toggle' />
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.clientUserGenerateBdoKpoReports')}
                  value={projectView.projectSettings?.clientUserGenerateBdoKpoReports}
                  type='toggle'
                />
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd')}
                  value={projectView.projectSettings?.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd}
                  type='toggle'
                />
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.clientAdminReports')}
                  value={projectView.projectSettings?.userAdminCanReportCollections}
                  type='toggle'
                />
                <CheckboxLabel label={t('Projects.ViewProject.Terms.Settings.assetsRequest')} value={projectView.projectSettings?.collectionRequestActive} type='toggle' />
                <CheckboxLabel label={t('organizationAsSide')} value={projectView.projectSettings?.organizationIsParticipating} type='toggle' />
                <CheckboxLabel label={t('bdoAndNotificationsDisabled')} value={projectView.projectSettings?.disableBdoAndNotifications} type='toggle' />
              </GridLabel>
              <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
                <AggregateCommandButton
                  aggregateId={projectView.projectId!}
                  aggregateName={'ProjectAggregate'}
                  commandName={'updateProjectSettings'}
                  buttonLabel=''
                  startIcon={<Edit />}
                  dialogTitle={t('Projects.ViewProject.Terms.Settings.mainSettings').toUpperCase()}
                  fields={[
                    {
                      field: 'newServicesDontRequireAdminAcceptance',
                      label: t('Projects.ViewProject.Terms.Settings.newServices'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.newServicesDontRequireAdminAcceptance,
                    },
                    {
                      field: 'branchCanGenerateReports',
                      label: t('Projects.ViewProject.Terms.Settings.branchReports'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.branchCanGenerateReports,
                    },
                    {
                      field: 'clientAdminGenerateBdoKpoReports',
                      label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReports'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.clientAdminGenerateBdoKpoReports,
                    },
                    {
                      field: 'clientUserGenerateBdoKpoReports',
                      label: t('Projects.ViewProject.Terms.Settings.clientUserGenerateBdoKpoReports'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.clientUserGenerateBdoKpoReports,
                    },
                    {
                      field: 'clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd',
                      label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd,
                    },
                    {
                      field: 'userAdminCanReportCollections',
                      label: t('Projects.ViewProject.Terms.Settings.clientAdminReports'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.userAdminCanReportCollections,
                    },
                    {
                      field: 'collectionRequestActive',
                      label: t('Projects.ViewProject.Terms.Settings.assetsRequest'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.collectionRequestActive,
                    },
                    {
                      field: 'organizationIsParticipating',
                      label: t('organizationAsSide'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.organizationIsParticipating,
                    },
                    {
                      field: 'disableBdoAndNotifications',
                      label: t('bdoAndNotificationsDisabled'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.disableBdoAndNotifications,
                    },
                  ]}
                  onBeforeSave={(formData) => {
                    return { ...projectView.projectSettings, ...formData };
                  }}
                  onSaved={async () => {
                    setTableReloadKey(tableReloadKey + 1);
                    await reloadData();
                  }}
                />
              </GridLabel>
            </GridLabel>
          </Panel>
        </Panel>
        <Panel flex='1'>
          <TitlePositioner>
            <TextLabel label={t('Projects.ViewProject.Terms.Settings.declaration').toUpperCase()} />
          </TitlePositioner>
          <Panel borderType='raised'>
            <GridLabel>
              <GridLabel gridTemplateColumns='none'>
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.ISAdmin')}
                  value={projectView.projectSettings?.acceptDeclarationChangeByOrganizationAdmin}
                  type='toggle'
                />
                <CheckboxLabel label={t('Projects.ViewProject.Terms.Settings.clientAdmin')} value={projectView.projectSettings?.acceptDeclarationChangeByUserAdmin} type='toggle' />
                <CheckboxLabel
                  label={t('Projects.ViewProject.Terms.Settings.clientBranch')}
                  value={projectView.projectSettings?.acceptDeclarationChangeByClientBranch}
                  type='toggle'
                />
                <CheckboxLabel label={t('regionalAdmin')} value={projectView.projectSettings?.acceptDeclarationChangeByRegionalAdmin} type='toggle' />
              </GridLabel>
              <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
                <AggregateCommandButton
                  aggregateId={projectView.projectId!}
                  aggregateName={'ProjectAggregate'}
                  commandName={'updateProjectSettings'}
                  buttonLabel=''
                  startIcon={<Edit />}
                  dialogTitle={t('Projects.ViewProject.Terms.Settings.declaration')}
                  fields={[
                    {
                      field: 'acceptDeclarationChangeByOrganizationAdmin',
                      label: t('Projects.ViewProject.Terms.Settings.ISAdmin'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.acceptDeclarationChangeByOrganizationAdmin,
                    },
                    {
                      field: 'acceptDeclarationChangeByUserAdmin',
                      label: t('Projects.ViewProject.Terms.Settings.clientAdmin'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.acceptDeclarationChangeByUserAdmin,
                    },
                    {
                      field: 'acceptDeclarationChangeByClientBranch',
                      label: t('Projects.ViewProject.Terms.Settings.clientBranch'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.acceptDeclarationChangeByClientBranch,
                    },
                    {
                      field: 'acceptDeclarationChangeByRegionalAdmin',
                      label: t('regionalAdmin'),
                      type: 'boolean',
                      defaultValue: projectView.projectSettings?.acceptDeclarationChangeByRegionalAdmin,
                    },
                  ]}
                  onBeforeSave={(formData) => {
                    return { ...projectView.projectSettings, ...formData };
                  }}
                  onSaved={async () => {
                    setTableReloadKey(tableReloadKey + 1);
                    await reloadData();
                  }}
                />
              </GridLabel>
            </GridLabel>
          </Panel>
        </Panel>
      </Panel>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectCompanyViews' }}
        fetchFilters={{ projectId: projectView.projectId, role: 'CLIENT' }}
        columns={[
          {
            key: 'companyName',
            label: t('clientName'),
            type: 'text',
          },
          {
            key: 'requestsForDpr',
            label: t('Projects.ViewProject.Terms.Settings.dpr'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settingsFromProject',
            label: t('Projects.ViewProject.Terms.Settings.mainSettings'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.organizationIsParticipating',
            label: t('organizationAsSide'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.newServicesDontRequireAdminAcceptance',
            label: t('Projects.ViewProject.Terms.Settings.newServices'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.branchCanGenerateReports',
            label: t('Projects.ViewProject.Terms.Settings.branchReports'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.clientAdminGenerateBdoKpoReports',
            label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReports'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.clientUserGenerateBdoKpoReports',
            label: t('Projects.ViewProject.Terms.Settings.clientUserGenerateBdoKpoReports'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd',
            label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.userAdminCanReportCollections',
            label: t('Projects.ViewProject.Terms.Settings.clientAdminReports'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.collectionRequestActive',
            label: t('Projects.ViewProject.Terms.Settings.assetsRequest'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.acceptDeclarationChangeByOrganizationAdmin',
            label: t('Projects.ViewProject.Terms.Settings.declarationISAdmin'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.acceptDeclarationChangeByUserAdmin',
            label: t('Projects.ViewProject.Terms.Settings.declarationClientAdmin'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.acceptDeclarationChangeByClientBranch',
            label: t('Projects.ViewProject.Terms.Settings.declarationClientBranch'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'settings.acceptDeclarationChangeByRegionalAdmin',
            label: t('Projects.ViewProject.Terms.Settings.declarationRegionalAdmin'),
            type: 'boolean',
            align: 'center',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={0}
                entity={''}
                modelName={''}
                dialogTitle={''}
                fields={[
                  {
                    field: 'requestsForDpr',
                    label: t('Projects.ViewProject.Terms.Settings.dpr'),
                    type: 'boolean',
                    defaultValue: row.requestsForDpr,
                  },
                  {
                    field: 'settingsFromProject',
                    label: t('Projects.ViewProject.Terms.Settings.mainSettings'),
                    type: 'boolean',
                    defaultValue: row.settingsFromProject,
                  },
                  {
                    field: 'organizationIsParticipating',
                    label: t('organizationAsSide'),
                    type: 'boolean',
                    defaultValue: row.settings?.organizationIsParticipating,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'newServicesDontRequireAdminAcceptance',
                    label: t('Projects.ViewProject.Terms.Settings.newServices'),
                    type: 'boolean',
                    defaultValue: row.settings?.newServicesDontRequireAdminAcceptance,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'branchCanGenerateReports',
                    label: t('Projects.ViewProject.Terms.Settings.branchReports'),
                    type: 'boolean',
                    defaultValue: row.settings?.branchCanGenerateReports,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'clientAdminGenerateBdoKpoReports',
                    label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReports'),
                    type: 'boolean',
                    defaultValue: row.settings?.clientAdminGenerateBdoKpoReports,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'clientUserGenerateBdoKpoReports',
                    label: t('Projects.ViewProject.Terms.Settings.clientUserGenerateBdoKpoReports'),
                    type: 'boolean',
                    defaultValue: row.settings?.clientUserGenerateBdoKpoReports,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd',
                    label: t('Projects.ViewProject.Terms.Settings.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd'),
                    type: 'boolean',
                    defaultValue: row.settings?.clientAdminGenerateBdoKpoReportsWithOnlyPlatformMpd,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'userAdminCanReportCollections',
                    label: t('Projects.ViewProject.Terms.Settings.clientAdminReports'),
                    type: 'boolean',
                    defaultValue: row.settings?.userAdminCanReportCollections,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'collectionRequestActive',
                    label: t('Projects.ViewProject.Terms.Settings.assetsRequest'),
                    type: 'boolean',
                    defaultValue: row.settings?.collectionRequestActive,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'acceptDeclarationChangeByOrganizationAdmin',
                    label: t('Projects.ViewProject.Terms.Settings.declarationISAdmin'),
                    type: 'boolean',
                    defaultValue: row.settings?.acceptDeclarationChangeByOrganizationAdmin,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'acceptDeclarationChangeByUserAdmin',
                    label: t('Projects.ViewProject.Terms.Settings.declarationClientAdmin'),
                    type: 'boolean',
                    defaultValue: row.settings?.acceptDeclarationChangeByUserAdmin,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'acceptDeclarationChangeByClientBranch',
                    label: t('Projects.ViewProject.Terms.Settings.declarationClientBranch'),
                    type: 'boolean',
                    defaultValue: row.settings?.acceptDeclarationChangeByClientBranch,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                  {
                    field: 'acceptDeclarationChangeByRegionalAdmin',
                    label: t('Projects.ViewProject.Terms.Settings.declarationRegionalAdmin'),
                    type: 'boolean',
                    defaultValue: row.settings?.acceptDeclarationChangeByRegionalAdmin,
                    isVisible: (data) => data.settingsFromProject === false,
                  },
                ]}
                validationSchema={{}}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                overrideSaveCall={async (payload: any) => {
                  try {
                    await AggregateCommandClientService.callCommand<any>('ProjectCompanyAggregate', 'setDprCompanySettings', row.projectCompanyId, {
                      requestsForDpr: payload.requestsForDpr,
                    });
                    if (payload.settingsFromProject === false) {
                      delete payload.requestsForDpr;
                      delete payload.settingsFromProject;
                      await AggregateCommandClientService.callCommand<any>('ProjectCompanyAggregate', 'setProjectSettings', row.projectCompanyId, payload);
                    } else {
                      await AggregateCommandClientService.callCommand<any>('ProjectCompanyAggregate', 'clearProjectSettings', row.projectCompanyId, {});
                    }
                    return new DataResultsDto(false, 200, undefined, undefined, undefined);
                  } catch (error: any) {
                    return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                  }
                }}
                yellowIcon
              />
            ),
          },
        ]}
      />
    </>
  );
}
