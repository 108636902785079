export enum PaymentDay {
  DAY_LAST = 'DAY_LAST',
  DAY_1 = 'DAY_1',
  DAY_2 = 'DAY_2',
  DAY_3 = 'DAY_3',
  DAY_4 = 'DAY_4',
  DAY_5 = 'DAY_5',
  DAY_6 = 'DAY_6',
  DAY_7 = 'DAY_7',
  DAY_8 = 'DAY_8',
  DAY_9 = 'DAY_9',
  DAY_10 = 'DAY_10',
  DAY_11 = 'DAY_11',
  DAY_12 = 'DAY_12',
  DAY_13 = 'DAY_13',
  DAY_14 = 'DAY_14',
  DAY_15 = 'DAY_15',
  DAY_16 = 'DAY_16',
  DAY_17 = 'DAY_17',
  DAY_18 = 'DAY_18',
  DAY_19 = 'DAY_19',
  DAY_20 = 'DAY_20',
  DAY_21 = 'DAY_21',
  DAY_22 = 'DAY_22',
  DAY_23 = 'DAY_23',
  DAY_24 = 'DAY_24',
  DAY_25 = 'DAY_25',
  DAY_26 = 'DAY_26',
  DAY_27 = 'DAY_27',
  DAY_28 = 'DAY_28',
}
