import React from 'react';
import { CompanyViewModel } from '@lib/api-interface';
import { CompanyBranchAccounts } from '../../../shared/company-branch-accounts';
import { RolesEnum } from '../../../../../../../domain/enums/roles.enum';

export interface ClientAdminsProps {
  company: CompanyViewModel;
}

export const ClientAdmins = (props: ClientAdminsProps) => {
  return <CompanyBranchAccounts companyId={props.company.companyId!} role={RolesEnum.CLIENT_ADMIN} hasProjects={true} />;
};
