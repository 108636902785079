import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyViewModel } from '@lib/api-interface';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';
import axios from 'axios';

export const useCompanyView = (companyId: string) => {
  const queryKey = ['companyViews', companyId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return ReactQueryHateoasClientService.findOneByAggregateId<CompanyViewModel>('companyViews', 'companyId', companyId);
    },
    {
      staleTime: 5000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};

export const getCompanyViewForContractor = async () => {
  return await axios.get(`${process.env['REACT_APP_API_URL']}/api/contractor/company`).then((res) => res.data);
};
