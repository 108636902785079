import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@lib/ui-components';

export interface BranchDataUserAccountsProps {
  branchId: string;
  projectId: string;
}

export const BranchDataUserAccounts = (props: BranchDataUserAccountsProps) => {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      fetchUrl={`api/projects/${props.projectId}/branches/${props.branchId}/accounts`}
      columns={[
        {
          key: 'login',
          label: t('login'),
          type: 'numeric',
        },
        {
          key: 'name',
          label: t('fullName'),
          type: 'text',
        },
        {
          key: 'tags.phone',
          label: t('phone'),
          type: 'text',
        },
        {
          key: 'email',
          label: t('email'),
          type: 'text',
        },
      ]}
    />
  );
};
