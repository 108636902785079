import axios, { AxiosResponse } from 'axios';
import { DataResultsDto } from './data-results.dto';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { DataResultsPagedDto, PageInfo } from './data-results-paged.dto';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import Big from 'big.js';

export interface HateoasRestApiRequestOptions {
  modelClass?: ClassConstructor<any>;
  filters?: any;
  contentType?: 'application/json' | 'multipart/form-data' | 'application/json-patch+json';
  search?: string;
  embeddedResultModelName?: string;
}

export class HateoasRestApiClientService {
  public static create<ModelType>(resourcePluralName: string, payload: Partial<ModelType>, options?: HateoasRestApiRequestOptions): Promise<DataResultsDto<ModelType>> {
    let body: Partial<ModelType> | FormData = payload;
    const contentType = options?.contentType || 'application/json';
    if (contentType === 'multipart/form-data') {
      body = this.toFormData(payload);
    }
    return axios({
      url: `${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data;
        delete data['_links'];
        return new DataResultsDto<ModelType>(false, undefined, undefined, undefined, options?.modelClass != null ? plainToInstance(options.modelClass, data) : data);
      })
      .catch((error) => {
        return new DataResultsDto<ModelType>(true, error.response.status, error.response?.data?.error, error.response?.data?.message, error.response?.data);
      });
  }

  public static update<ModelType>(resourcePluralName: string, id: number, payload: Partial<ModelType>, options?: HateoasRestApiRequestOptions): Promise<DataResultsDto<ModelType>> {
    const stringified = instanceToInstance(payload);
    this.mapBigToString(stringified);
    return axios
      .patch(`${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}/${id}`, stringified, {
        headers: {
          'Content-Type': options?.contentType || 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        const data = response.data;
        delete data['_links'];
        return new DataResultsDto<ModelType>(false, undefined, undefined, undefined, options?.modelClass != null ? plainToInstance(options.modelClass, data) : data);
      })
      .catch((error) => {
        return new DataResultsDto<ModelType>(true, error.response.status, error.response?.data, error.response?.data, undefined);
      });
  }

  private static mapBigToString(entity: any) {
    Object.keys(entity).forEach((key) => {
      if (entity[key] instanceof Big) {
        entity[key] = entity[key].toFixed();
      }
    });
  }

  private static toFormData(obj: any) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      formData.append(key, obj[key]);
    });
    return formData;
  }

  public static findAllPaged<ModelType>(
    resourcePluralName: string,
    page: number,
    size: 500,
    filters?: any,
    sort?: string,
    modelClass?: ClassConstructor<any>,
    search?: string,
    embeddedResultModelName?: string,
  ): Promise<DataResultsPagedDto<ModelType[]>> {
    if (sort === undefined) sort = 'id';
    let url = `${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}`;
    if (search) {
      url = `${url}/search/${search}`;
    }

    return axios
      .get(url, {
        params: {
          ...filters,
          sort,
          page,
          size,
        },
      })
      .then((response: AxiosResponse) => {
        const data: any[] = this.extractItemsFromEmbedded(response, embeddedResultModelName != null ? embeddedResultModelName : resourcePluralName, modelClass);
        let page: PageInfo;
        if (response.data['page']) {
          page = response.data['page'];
          page.hasNextPage = page.number + 1 < page.totalPages;
        } else {
          page = {
            number: 1,
            totalPages: 1,
            hasNextPage: false,
            size: data.length,
            totalElements: data.length,
          };
        }
        return new DataResultsPagedDto<ModelType[]>(false, undefined, undefined, undefined, data, page);
      })
      .catch((error) => {
        return new DataResultsPagedDto<ModelType[]>(true, error.response.status, error.response?.data?.error, error.response?.data?.message, error.response?.data, undefined);
      });
  }

  public static async findAll<ModelType>(resourcePluralName: string, filters?: any, sort?: string, options?: HateoasRestApiRequestOptions): Promise<DataResultsDto<ModelType[]>> {
    let currentPage = 0;
    const items: ModelType[] = [];
    let result;
    do {
      result = await this.findAllPaged<ModelType>(resourcePluralName, currentPage, 500, filters, sort, options?.modelClass, options?.search, options?.embeddedResultModelName);
      currentPage += 1;

      if (!result.failed && result.response !== undefined) {
        for (const item of result.response) {
          items.push(item);
        }
      }
    } while (!result.failed && (result.page?.hasNextPage === true || result.response?.length === 500));

    return new DataResultsDto<ModelType[]>(result.failed, result.errorStatusCode, result.error, result.errorMessage, items);
  }

  private static extractItemsFromEmbedded(response: AxiosResponse<any, any>, resourcePluralName: string, modelClass: ClassConstructor<any> | undefined): any[] {
    const embedded = response.data['_embedded'] ?? {};
    let data = embedded[resourcePluralName] ?? [];
    for (const item of data) {
      delete item['_links'];
    }
    if (modelClass != null) {
      data = plainToInstance(modelClass, data);
    }
    return data;
  }

  public static findOne<ModelType>(resourcePluralName: string, id?: string, options?: HateoasRestApiRequestOptions): Promise<DataResultsDto<ModelType>> {
    const params = options?.filters || {};
    let url = `${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}`;
    if (id) {
      url = `${url}/${id}`;
    }
    if (options?.search) {
      url = `${url}/search/${options.search}`;
    }
    return axios
      .get(url, { params })
      .then((response: AxiosResponse) => {
        let data = response.data;
        delete data['_links'];
        if (data['_embedded']) {
          data = { ...data, ...data['_embedded'] };
          delete data['_embedded'];
        }
        return new DataResultsDto<ModelType>(false, undefined, undefined, undefined, options?.modelClass != null ? plainToInstance(options?.modelClass, data) : data);
      })
      .catch((error) => {
        return new DataResultsDto<ModelType>(true, error.response.status, error.response?.data?.error, error.response?.data?.message, undefined);
      });
  }

  public static delete<ModelType>(resourcePluralName: string, id: string, modelClass?: ClassConstructor<any>): Promise<DataResultsDto<ModelType>> {
    return axios
      .delete(`${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}/${id}`)
      .then((response: AxiosResponse) => {
        const data = response.data;
        delete data['_links'];
        return new DataResultsDto<ModelType>(false, undefined, undefined, undefined, modelClass != null ? plainToInstance(modelClass, data) : data);
      })
      .catch((error) => {
        return new DataResultsDto<ModelType>(true, error.response.status, error.response?.data?.error, error.response?.data?.message, error.response);
      });
  }
}
