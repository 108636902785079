import React from 'react';
import { DataTable, Panel, TableFilterItem } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { ServicePriceBearer, ServiceType } from '@lib/api-interface';

interface AdditionalProps {
  companyId?: string;
  companyBranchId?: string;
  onDefaultFiltersUpdated?: (filters: Array<TableFilterItem>) => void;
  instanceFiltersRef: React.MutableRefObject<TableFilterItem[]>;
}

export const Additional = (props: AdditionalProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <DataTable
        refreshKey={1}
        fetchPostUrl={'/api/service/listByCompany'}
        fetchFilters={{
          subcontractorId: props.companyId ? props.companyId : props.companyBranchId,
          serviceType: ServiceType.ADDITIONAL,
          active: true,
          searchBySubcontractorCompanyBranch: !!props.companyBranchId,
        }}
        defaultTableFilters={props.instanceFiltersRef.current}
        onDefaultTableFiltersUpdated={(filters) => {
          if (props.onDefaultFiltersUpdated !== undefined) {
            props.onDefaultFiltersUpdated(filters);
          }
        }}
        columns={[
          { key: 'project.projectNumber', label: t('projectNumber'), type: 'text' },
          { key: 'ownerProjectBranch.companyName', label: t('companyName'), type: 'text' },
          { key: 'ownerProjectBranch.companyBranchName', label: t('branchName'), type: 'text' },
          { key: 'navResourceNumber', label: t('navResourceNumber'), type: 'text' },
          { key: 'navResourceName', label: t('navResourceName'), type: 'text' },
          { key: 'description', label: t('serviceDesc'), type: 'text' },
          {
            key: 'currentSettings.subcontractorCollectionPrice.stringValue',
            label: t('Projects.ViewProject.subcontractorCollectionPrice'),
            type: 'text',
            colorOnCondition: (row) => (row.currentSettings.subcontractorCollectionPrice.bearer === ServicePriceBearer.ORGANIZATION_COST ? '#EB5757' : '#0AAD50'),
          },
        ]}
      />
    </Panel>
  );
};
