export class JsonUtils {
  public static isJson(item: any): boolean {
    let value = typeof item !== 'string' ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return typeof value === 'object' && value !== null;
  }

  public static extractValue(item: any, key: string): any {
    if (typeof item === 'object') return item[key];
    if (JsonUtils.isJson(item)) {
      return JSON.parse(item)[key];
    }
    return undefined;
  }
}
