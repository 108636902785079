import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { DecisionsOnCollecting } from './decisions-on-collecting';
import { OtherDecisions } from './other-decisions';
import { CompanyViewModel } from '@lib/api-interface';

export interface DecisionsConclusionsProps {
  company: CompanyViewModel;
}

export function DecisionsConclusions(props: DecisionsConclusionsProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel header={t('decisions')} borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('decisionsOnCollecting'), t('otherDecisions')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>

      {activePage === 0 && <DecisionsOnCollecting company={props.company} />}
      {activePage === 1 && <OtherDecisions company={props.company} />}
    </Panel>
  );
}
