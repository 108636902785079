export type BreadcrumbDataFunction = (id: string) => Promise<string>;

export interface BreadcrumbDataQuery {
  query: string;
  fetchFunction: (id: string) => () => Promise<string>;
  mapToBreadcrumb: (data: any) => string;
}

const breadcrumbLabelProviders: Map<string, BreadcrumbDataQuery> = new Map<string, BreadcrumbDataQuery>();

export const registerBreadcrumbDataFunction = (breadcrumbCode: string, callback: BreadcrumbDataQuery) => {
  breadcrumbLabelProviders.set(breadcrumbCode, callback);
};

export const getBreadcrumbDataProvider = (breadcrumbCode: string): BreadcrumbDataQuery | undefined => {
  return breadcrumbLabelProviders.get(breadcrumbCode);
};
