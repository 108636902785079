import React from 'react';
import { useTranslation } from 'react-i18next';
import IsServicesTab from './is-services-tab';
import BdoApiTab from './bdo-api-tab';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';

export interface BdoTabProps {
  projectId: string;
}

export function BdoTab({ projectId }: BdoTabProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('isServices'), t('bdoApi')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 0 && <IsServicesTab />}
      {activePage === 1 && <BdoApiTab projectId={projectId} />}
    </Panel>
  );
}
