import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { ProjectBranchData } from './project-branch-data';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectBranchSettings, useProjectBranchView, useProjectView } from '@data-source-hooks';
import { FetchErrorIndicator } from '../../../../libs/ui-components/components/fetch-error-indicator';
import { Pickups } from '../../pickups/pickups';

import { ProjectSettings } from '@lib/api-interface';
import { InfrastructureServices } from './services/infrastructure-services';
import { BranchServices } from '../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/branch-services';
import { ProjectBranchContextProvider, ProjectBranchSettingsContextProvider, ProjectContextProvider } from '@context';
import { ProjectBranchHeader } from './project-branch-header';
import { ClientDocuments } from '../../../client-user/files/client-documents';
import { RolesEnum } from '../../../../domain/enums/roles.enum';
import { useUserState } from '../../../../state/UserState';
import { CommuneServiceDeclarations } from './services/commune-service-declarations';

export const ProjectBranch = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const pathParams = useParams();
  const projectBranchId = pathParams['projectBranchId']?.substring(1);
  const [projectBranchSettingsQuery] = useProjectBranchSettings(projectBranchId);
  const [projectBranchQuery] = useProjectBranchView(projectBranchId);
  const [projectQuery] = useProjectView(projectBranchQuery.data?.projectId);
  const projectBranchSettings: ProjectSettings = projectBranchSettingsQuery.data!;

  if (projectBranchQuery.isLoading || projectQuery.isLoading || projectBranchSettingsQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (projectBranchQuery.isError) {
    return <FetchErrorIndicator error={projectBranchQuery.error} />;
  }
  if (projectQuery.isError) {
    return <FetchErrorIndicator error={projectQuery.error} />;
  }
  if (projectBranchSettingsQuery.isError) {
    return <FetchErrorIndicator error={projectBranchSettingsQuery.error} />;
  }
  const projectView = projectQuery.data;
  const projectBranchView = projectBranchQuery.data;

  const tabs = [
    {
      label: t('Model.CompanyBranch.data'),
      component: <ProjectBranchData projectBranchView={projectBranchView} />,
    },
    {
      label: t('Projects.ViewProject.infrastructureServices'),
      component: <InfrastructureServices />,
    },
    {
      label: t('Projects.ViewProject.services'),
      component: <BranchServices projectId={projectBranchView.projectId} projectBranchId={projectBranchView.projectBranchId} />,
    },
    ...(!userState.roles.includes(RolesEnum.CLIENT_ADMIN) || projectBranchSettings.userAdminCanReportCollections
      ? [
          {
            label: t('pickups'),
            component: <Pickups projectBranchView={projectBranchView} />,
          },
        ]
      : []),
    {
      label: t('documents'),
      component: <ClientDocuments isClientAdmin={true} branchId={projectBranchView.branchId} />,
    },
    {
      label: t('communeDeclarations'),
      component: <CommuneServiceDeclarations projectId={projectView.projectId!} projectBranchId={projectBranchView.projectBranchId!} />,
    },
  ];

  return (
    <>
      <ProjectBranchHeader projectBranchView={projectBranchView} />
      <ProjectContextProvider value={projectView}>
        <ProjectBranchSettingsContextProvider value={projectBranchSettings}>
          <ProjectBranchContextProvider value={projectBranchView}>
            <Tabs marginTop='1rem' initialTabIndex={2} panels={tabs} borderType='none' />
          </ProjectBranchContextProvider>
        </ProjectBranchSettingsContextProvider>
      </ProjectContextProvider>
    </>
  );
};
