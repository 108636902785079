import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddModelButton, DataTable, DataTableColumn, HStack, RenderIf, ToggleButtons, Toolbar } from '@lib/ui-components';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';

export interface CompaniesDataTableProps {
  roleClient?: boolean;
  roleSubcontractor?: boolean;
}

export const CompaniesDataTable = (props: CompaniesDataTableProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  function isCorrespondenceAddressVisible(data: any): boolean {
    return data['correspondenceAddressEqRegistrationAddress'] !== true;
  }

  const fetchFilters: any = React.useMemo(() => {
    return {
      active: activePage === 0,
      roleClient: props.roleClient ? true : undefined,
      roleContractor: props.roleSubcontractor ? true : undefined,
    };
  }, [activePage, props.roleClient, props.roleSubcontractor]);
  const columns: DataTableColumn[] = React.useMemo(() => {
    const columns: DataTableColumn[] = [
      {
        key: 'name',
        label: t('companyName'),
        type: 'text',
        minWidth: '15rem',
      },
      {
        key: 'nip',
        label: t('nip'),
        type: 'text',
      },
      {
        key: 'buyerId',
        label: t('buyerNumber'),
        type: 'text',
      },
      {
        key: 'bdoIdentificationNumber',
        label: t('bdoIdentificationNumber'),
        type: 'text',
      },
      {
        key: 'roleClient',
        label: t('client'),
        type: 'boolean',
        align: 'center',
      },
      {
        key: 'roleContractor',
        label: t('subcontractor'),
        type: 'boolean',
        align: 'center',
      },
      {
        key: 'registrationAddress.town',
        label: t('locality'),
        type: 'text',
      },
      {
        key: 'registrationAddress.street',
        label: t('street'),
        type: 'text',
      },
      {
        key: 'registrationAddress.houseNum',
        label: t('houseNum'),
        type: 'text',
      },
      {
        key: 'registrationAddress.apartmentNum',
        label: t('apartmentNum'),
        type: 'text',
      },
      {
        key: 'registrationAddress.postalCode',
        label: t('postcode'),
        type: 'text',
      },
      {
        key: 'registrationAddress.province',
        label: t('province'),
        type: 'text',
      },
      {
        key: 'organizationContact',
        label: t('Model.Company.address.keeper'),
        type: 'text',
      },
      {
        key: 'organizationContactEmail',
        label: t('email'),
        type: 'text',
      },
    ];
    if (props.roleSubcontractor) {
      columns.push({
        key: 'bdoIdentificationNumber',
        label: t('bdo'),
        type: 'boolean',
        align: 'center',
      });
      columns.push({
        key: 'bdoNotApplicable',
        label: t('notApplicable'),
        type: 'boolean',
        align: 'center',
      });
      columns.push({
        key: 'collectionDecisionNotRequired',
        label: t('collectionDecisionNotRequired'),
        type: 'boolean',
        align: 'center',
      });
    }
    return columns;
  }, [props.roleSubcontractor]);

  return (
    <>
      <HStack align='center'>
        <ToggleButtons labels={[t('active'), t('notActive')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      <Toolbar
        right={[
          <RenderIf true={activePage === 0}>
            <AddModelButton
              buttonLabel={t('Companies.addNew')}
              dialogTitle={t('Companies.addNew')}
              modelName='companies'
              fields={[
                {
                  rowItems: [
                    { label: t('companyName'), type: 'text', field: 'name', width: '200%' },
                    { label: t('client'), type: 'boolean', field: 'roleClient' },
                    { label: t('subcontractor'), type: 'boolean', field: 'roleContractor' },
                  ],
                },
                {
                  rowItems: [
                    { label: t('nip'), type: 'text', field: 'nip' },
                    { label: t('krs'), type: 'text', field: 'krs' },
                    { label: t('buyerNumber'), type: 'text', field: 'buyerId' },
                  ],
                },
                {
                  label: t('bdoIdentificationNumber'),
                  type: 'table-select-by-api',
                  field: 'bdoIdentificationNumber',
                  tableSelectByApiParams: {
                    apiPostEndpoint: '/api/organization/bdo/search/company',
                    dialogTitle: t('bdoIdentificationNumber'),
                    displayFormat: '{registrationNumber}',
                    selectedValueField: 'registrationNumber',
                    columns: [
                      {
                        label: t('bdoIdentificationNumber'),
                        key: 'registrationNumber',
                        type: 'text',
                      },
                      { label: t('name'), key: 'name', type: 'text' },
                      { label: t('nip'), key: 'nip', type: 'text' },
                      { label: t('postcode'), key: 'postalCode', type: 'text' },
                      { label: t('addressInformation'), key: 'address', type: 'text' },
                    ],
                    otherValuesMap: {
                      companyId: 'bdoCompanyId',
                    },
                    queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
                  },
                },
                {
                  label: t('registrationAddress'),
                  type: 'header',
                  field: '-',
                  margin: '1.5rem 0 0 0',
                },
                {
                  rowItems: [
                    {
                      label: t('postcode'),
                      type: 'text',
                      field: 'registrationAddress.postalCode',
                      width: '15rem',
                    },
                    {
                      label: t('locality'),
                      type: 'text',
                      field: 'registrationAddress.town',
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('street'),
                      type: 'text',
                      field: 'registrationAddress.street',
                    },
                    {
                      label: t('houseNum'),
                      type: 'text',
                      field: 'registrationAddress.houseNum',
                      width: '20rem',
                    },
                    {
                      label: t('apartmentNum'),
                      type: 'text',
                      field: 'registrationAddress.apartmentNum',
                      width: '20rem',
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('country'),
                      type: 'text',
                      field: 'registrationAddress.country',
                      defaultValue: t('poland'),
                      width: '15rem',
                    },
                    {
                      label: t('commune'),
                      type: 'table-select',
                      field: 'registrationAddress.communeId',
                      tableSelectParams: {
                        modelName: 'communes',
                        dialogTitle: t('communes'),
                        displayFormat: '{name}',
                        columns: [
                          { key: 'name', label: t('commune'), type: 'text' },
                          { key: 'province', label: t('province'), type: 'text' },
                        ],
                        otherValuesMap: {
                          name: 'registrationAddress.commune',
                          province: 'registrationAddress.province',
                        },
                      },
                    },
                  ],
                },
                {
                  label: t('correspondenceAddress'),
                  type: 'header',
                  field: '-',
                  margin: '1.5rem 0 0 0',
                },
                {
                  label: t('correspondenceAddressEqRegistrationAddress'),
                  type: 'boolean',
                  field: 'correspondenceAddressEqRegistrationAddress',
                  margin: '0 0 1.5rem 0',
                  defaultValue: true,
                },
                {
                  rowItems: [
                    {
                      label: t('postcode'),
                      type: 'text',
                      field: 'correspondenceAddress.postalCode',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      width: '15rem',
                    },
                    {
                      label: t('locality'),
                      type: 'text',
                      field: 'correspondenceAddress.town',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                  ],
                },

                {
                  rowItems: [
                    {
                      label: t('street'),
                      type: 'text',
                      field: 'correspondenceAddress.street',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                    },
                    {
                      label: t('houseNum'),
                      type: 'text',
                      field: 'correspondenceAddress.houseNum',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      width: '20rem',
                    },
                    {
                      label: t('apartmentNum'),
                      type: 'text',
                      field: 'correspondenceAddress.apartmentNum',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      width: '20rem',
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('country'),
                      type: 'text',
                      field: 'correspondenceAddress.country',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      defaultValue: t('poland'),
                      width: '15rem',
                    },
                    {
                      label: t('commune'),
                      type: 'table-select',
                      field: 'correspondenceAddress.communeId',
                      isVisible: (data) => isCorrespondenceAddressVisible(data),
                      tableSelectParams: {
                        modelName: 'communes',
                        dialogTitle: t('communes'),
                        displayFormat: '{name}',
                        columns: [
                          { key: 'name', label: t('commune'), type: 'text' },
                          { key: 'province', label: t('province'), type: 'text' },
                        ],
                        otherValuesMap: {
                          name: 'correspondenceAddress.commune',
                          province: 'correspondenceAddress.province',
                        },
                      },
                    },
                  ],
                },
                {
                  rowItems: [
                    {
                      label: t('contractConclusionDate'),
                      type: 'date',
                      field: 'contractConclusionDate',
                    },
                  ],
                },
              ]}
              validationSchema={{
                name: Yup.string().required(t('requiredField')),
                nip: Yup.string().required(t('requiredField')).max(10, t('fieldToLong')),
                krs: Yup.string().matches(/^\d+$/, { message: t('krs.matches') }),

                registrationAddress: Yup.object({
                  street: Yup.string().required(t('requiredField')),
                  postalCode: Yup.string().required(t('requiredField')),
                  houseNum: Yup.string().required(t('requiredField')),
                  town: Yup.string().required(t('requiredField')),
                  communeId: Yup.string().required(t('requiredField')),
                }),
                correspondenceAddressEqRegistrationAddress: Yup.boolean(),
                correspondenceAddress: Yup.object().when('correspondenceAddressEqRegistrationAddress', {
                  is: (correspondenceAddressEqRegistrationAddress: boolean) => !correspondenceAddressEqRegistrationAddress,
                  then: Yup.object({
                    street: Yup.string().required(t('requiredField')),
                    postalCode: Yup.string().required(t('requiredField')),
                    houseNum: Yup.string().required(t('requiredField')),
                    town: Yup.string().required(t('requiredField')),
                    communeId: Yup.string().required(t('requiredField')),
                  }),
                }),
              }}
              hiddenValues={{ active: true }}
              onBeforeSave={(formData) => {
                const registrationCommuneIdParts = formData.registrationAddress.communeId.split('/');
                formData.registrationAddress!.communeId = registrationCommuneIdParts[registrationCommuneIdParts.length - 1];

                if (formData.correspondenceAddressEqRegistrationAddress === true) {
                  formData.correspondenceAddress = {} as any;
                  formData.correspondenceAddress!.street = formData.registrationAddress!.street;
                  formData.correspondenceAddress!.town = formData.registrationAddress!.town;

                  formData.correspondenceAddress!.commune = formData.registrationAddress!.commune;
                  formData.correspondenceAddress!.communeId = formData.registrationAddress!.communeId;
                  formData.correspondenceAddress!.province = formData.registrationAddress!.province;

                  formData.correspondenceAddress!.country = formData.registrationAddress!.country;

                  formData.correspondenceAddress!.postalCode = formData.registrationAddress!.postalCode;
                  formData.correspondenceAddress!.houseNum = formData.registrationAddress!.houseNum;
                  formData.correspondenceAddress!.apartmentNum = formData.registrationAddress!.apartmentNum;
                } else {
                  const correspondenceCommuneIdParts = formData.correspondenceAddress.communeId.split('/');
                  formData.correspondenceAddress!.communeId = correspondenceCommuneIdParts[correspondenceCommuneIdParts.length - 1];
                }
                delete formData.correspondenceAddressEqRegistrationAddress;
                return formData;
              }}
              overrideSaveCall={async (payload: any) => {
                try {
                  await AggregateCommandClientService.createAggregate<any>('CompanyAggregate', payload);
                  return new DataResultsDto(false, 200, undefined, undefined, undefined);
                } catch (error: any) {
                  return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                }
              }}
              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
            />
          </RenderIf>,
        ]}
      />
      <DataTable refreshKey={tableReloadKey} fetchUrl={'/api/company-views'} fetchFilters={fetchFilters} rowLink={{ linkPath: '', linkRowId: 'companyId' }} columns={columns} />
    </>
  );
};
