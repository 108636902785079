import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, GetContentButton, Panel } from '@lib/ui-components';
import { useWebSocketState } from '../../../../state/web-socket-state';
import { FileDownload } from '@mui/icons-material';

export const ServiceSettlementExport = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const webSocketState = useWebSocketState();

  React.useEffect(() => {
    setTableReloadKey((prevKey) => prevKey + 1);
  }, [webSocketState.settlementExportRefreshKey]);

  return (
    <Panel direction='column'>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'settlementExportLogViews',
        }}
        initialSort={{ columnIndex: 4, ascending: false }}
        columns={[
          {
            key: 'processId',
            label: t('exportId'),
            type: 'text',
          },
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'message',
            label: t('description'),
            type: 'text',
          },
          {
            key: 'filters',
            label: t('filters'),
            type: 'object',
          },
          {
            key: 'createdAt',
            label: t('addedDate'),
            type: 'date-time',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => row.fileId && <GetContentButton label={t('download')} icon={<FileDownload />} resourcePath={`api/settlement/export/${row.fileId}/content`} />,
          },
        ]}
      />
    </Panel>
  );
};
