import * as React from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.css';

export interface LeftMenuLinkProps {
  to: string;
  label: string;
  icon: JSX.Element;
}

export const LeftMenuLink = (props: LeftMenuLinkProps) => {
  function getActiveButton() {
    return (
      <Button data-test-id={`${props.label}-link`} className={styles['linkButton']} size='small' variant='contained' startIcon={props.icon}>
        <span className={styles['label']}>{props.label}</span>
      </Button>
    );
  }

  function getNormalButton() {
    return (
      <Button data-test-id={`${props.label}-link`} className={styles['linkButton']} size='small' startIcon={props.icon}>
        <span className={styles['label']}>{props.label}</span>
      </Button>
    );
  }

  return (
    <NavLink className={styles['link']} to={props.to}>
      {({ isActive }) => (isActive ? getActiveButton() : getNormalButton())}
    </NavLink>
  );
};
