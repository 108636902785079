import React from 'react';
import { CompanyViewModel } from '@lib/api-interface';
import { Panel } from '@lib/ui-components';
import { CompanyBranchAccounts } from '../../shared/company-branch-accounts';
import { RolesEnum } from '../../../../../../domain/enums/roles.enum';

export interface ContractorLoginAccountsProps {
  company: CompanyViewModel;
}

export const ContractorLoginAccounts = (props: ContractorLoginAccountsProps) => {
  return (
    <Panel borderType='flat'>
      <CompanyBranchAccounts companyId={props.company.companyId!} role={RolesEnum.CONTRACTOR} hasBranch={true} />
    </Panel>
  );
};
