import React from 'react';
import { Checkbox, FormControl, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import { DataSearchField } from '../data-table/table-search-field/data-search-field';
import { useStateIfMounted } from 'use-state-if-mounted';

export interface FormInputMultiDropdownOption {
  label: string;
  value: string;
}

export interface FormInputMultiDropdownProps extends FormInputProps {
  options: FormInputMultiDropdownOption[];
}

export const FormInputMultiDropdown: React.FC<FormInputMultiDropdownProps> = ({ name, control, label, options, variant, testId }) => {
  const [checked, setChecked] = React.useState<number[]>([]);
  const [filterText, setFilterText] = useStateIfMounted('');

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const filteredOptions = options.filter((option) => {
    return option.label.toLowerCase().startsWith(filterText.toLowerCase());
  });

  const getTestId = () => {
    return testId !== undefined ? `select-${testId}` : `select-${name}`;
  };

  const dropdownOptions = React.useMemo(
    () =>
      filteredOptions.map((option: FormInputMultiDropdownOption, index: number) => (
        <ListItemButton role={undefined} onClick={handleToggle(index)} dense key={index}>
          <ListItemIcon>
            <Checkbox edge='start' checked={checked.indexOf(index) !== -1} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText id={option.label} primary={option.label} />
        </ListItemButton>
      )),
    [filteredOptions],
  );

  return (
    <FormControl size={'small'} variant={variant != null ? variant : 'outlined'} style={{ marginTop: '0px', marginBottom: '32px', width: '100%' }}>
      <Controller
        name={name}
        control={control}
        render={() => (
          <>
            <DataSearchField onFiltersChange={(filter) => setFilterText(filter)} />
            <List data-test-id={getTestId()} sx={{ maxHeight: 400, overflow: 'auto' }}>
              {dropdownOptions}
            </List>
          </>
        )}
      />
    </FormControl>
  );
};
