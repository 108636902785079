import * as React from 'react';
import { CSSProperties } from 'react';
import styles from './styles.module.css';
import { useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';

export interface TextLabelProps {
  label?: string;
  direction?: 'row' | 'column';
  value?: string;
  labelColor?: string;
  valueColor?: string;
  labelFontSizeInRem?: number;
  valueFontSizeInRem?: number;
  valueFontFamily?: string;
  highlight?: boolean;
  mildHighlight?: boolean;
  noWrap?: boolean;
  gridColumn?: string;
  labelBold?: boolean;
  noValue?: boolean;
  dateFormat?: string;
}

function getSize(propsSize: number | undefined, defaultSize: number) {
  return propsSize ?? defaultSize;
}

export const TextLabel = (props: TextLabelProps) => {
  const theme = useTheme();

  const style: CSSProperties = {
    flexDirection: props.direction ?? 'column',
    gridColumn: props.gridColumn,
  };

  const labelStyle: CSSProperties = {
    fontSize: `${getSize(props.labelFontSizeInRem, 0.8)}rem`,
    color: props.labelColor ?? '#828282',
    fontWeight: props.labelBold ? 'bold' : 'normal',
  };

  let valFontSize = getSize(props.valueFontSizeInRem, 1.0);
  if (props.highlight) {
    valFontSize += 0.4;
  } else if (props.mildHighlight) {
    valFontSize += 0.1;
  }

  const valueStyle: CSSProperties = {
    fontSize: `${valFontSize}rem`,
    fontWeight: props.highlight || props.mildHighlight ? 'bold' : 'normal',
    color: props.valueColor ?? theme.palette.primary.main,
    whiteSpace: props.noWrap ? 'nowrap' : 'normal',
    fontFamily: props.valueFontFamily,
  };

  let displayValue = props.value;
  if (props.dateFormat !== undefined && props.value) {
    const date: Date = parseISO(props.value);
    if (date !== undefined) {
      displayValue = format(date, props.dateFormat);
    }
  }

  return (
    <div style={props.gridColumn ? { gridColumn: props.gridColumn } : { gridColumn: 'auto' }}>
      <div className={styles['container']} style={style}>
        <div className={styles['label']} style={labelStyle}>
          {props.label ? props.label : ''}
        </div>
        {!props.noValue && (
          <div className={styles['value']} style={valueStyle}>
            {displayValue}
          </div>
        )}
      </div>
    </div>
  );
};
