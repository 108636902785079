import { DialogContent, DialogTitle, FormHelperText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, GeneralForm, RenderIf, Snackbar } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axios from 'axios';
import { ServiceViewDetails } from '@lib/api-interface';
import { useStateIfMounted } from 'use-state-if-mounted';
import { JsonUtils } from '@lib/common-sdk';
import { getErrorMessage } from '../../../../../../../../../libs/ui-components/components/custom-post-error.handler';
import i18n from 'i18next';

interface ServiceToBranchesCopyDialogProps {
  serviceView: ServiceViewDetails;
  openDialog: boolean;
  handleCloseDialog?: React.MouseEventHandler<HTMLButtonElement>;
  onSaved: () => Promise<any>;
}

export const ServiceToBranchesCopyDialog = (props: ServiceToBranchesCopyDialogProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [failureMessage, setFailureMessage] = useStateIfMounted('');

  return (
    <>
      <Dialog open={props.openDialog} onClose={props.handleCloseDialog} maxWidth={false} fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          {t('copyServiceToAnotherBranches')}
          <IconButton
            aria-label='close'
            onClick={props.handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormHelperText style={{ fontSize: 15 }}>{t('copyServiceToAnotherBranchesDialogInfo')}</FormHelperText>
          <GeneralForm
            fields={[
              {
                label: t('branch'),
                type: 'table-multi-select',
                field: 'branchesIds',
                tableSelectByApiParams: {
                  apiPostEndpoint: '/api/project-company-branches/by-project',
                  apiPostParams: {
                    projectId: props.serviceView.project!.projectId,
                    projectBranchId: props.serviceView.projectBranchId,
                  },
                  displayFormat: '{companyBranchName}',
                  dialogTitle: t('selectBranch'),
                  selectedValueField: 'projectBranchId',
                  columns: [
                    {
                      key: 'companyName',
                      label: t('companyName'),
                      type: 'text',
                    },
                    {
                      key: 'companyBranchName',
                      label: t('Model.CompanyBranch.name'),
                      type: 'text',
                    },
                    {
                      key: 'nip',
                      label: t('nip'),
                      type: 'text',
                    },
                  ],
                },
              },
            ]}
            hideCancelButton={true}
            validationSchema={{
              branchesIds: Yup.array().min(1, t('fieldRequired')).required(t('fieldRequired')),
            }}
            onSaved={async (data) => {
              try {
                await axios.post('/api/usecase/service/copy-service-to-branches', {
                  serviceId: props.serviceView.serviceId,
                  projectBranchesIds: data.branchesIds,
                });
                props.onSaved();
              } catch (e: any) {
                const data = e.response?.data || '{ "code": "E00001"}';
                const code = JsonUtils.extractValue(data, 'code');
                const context = JsonUtils.extractValue(data, 'context');
                const errorText = getErrorMessage({ code, context }, translations) || t('Common.error');
                setFailureMessage(errorText);
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <RenderIf true={failureMessage !== undefined && failureMessage !== ''}>
        <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
      </RenderIf>
    </>
  );
};
