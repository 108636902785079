import React from 'react';

export function SettledIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 5.53896V5.99896C10.9994 7.07717 10.6503 8.12629 10.0047 8.98987C9.35908 9.85344 8.45164 10.4852 7.41768 10.7909C6.38372 11.0966 5.27863 11.0599 4.26724 10.6862C3.25584 10.3126 2.39233 9.62201 1.80548 8.71749C1.21863 7.81297 0.939896 6.74299 1.01084 5.66711C1.08178 4.59124 1.4986 3.56712 2.19914 2.74749C2.89968 1.92787 3.84639 1.35665 4.89809 1.11903C5.9498 0.881411 7.05013 0.990124 8.035 1.42896'
        stroke='#219653'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 2L6 7.005L4.5 5.505' stroke='#219653' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
