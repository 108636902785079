import axios, { AxiosResponse } from 'axios';
import { ClassConstructor } from 'class-transformer/types/interfaces';

export interface RestUsecaseClientServiceOptions {
  modelClass?: ClassConstructor<any>;
  contentType?: 'application/json' | 'multipart/form-data';
}

export class RestUsecaseClientService {
  public static post<CommandType, ResponseType>(groupName: string, useCaseName: string, payload: CommandType, options?: RestUsecaseClientServiceOptions): Promise<ResponseType> {
    let body: CommandType | FormData = payload;
    const contentType = options?.contentType || 'application/json';
    if (contentType === 'multipart/form-data') {
      body = this.toFormData(payload);
    }
    return axios({
      url: `${process.env['REACT_APP_USECASE_URL']}/${groupName}/${useCaseName}`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType,
      },
    }).then((response: AxiosResponse) => {
      if (response.status === 200) {
        return response.data?.result !== undefined ? response.data?.result : response.data;
      } else {
        throw new Error(response.data);
      }
    });
  }

  private static toFormData(obj: any) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      formData.append(key, obj[key]);
    });
    return formData;
  }
}
