import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, DataTable, DataTableColumn, Header, HStack, Panel } from '@lib/ui-components';
import React from 'react';
import { ReportExceedButton } from './report-exceed-button';
import { ServicePriceBearer, ServicePriceType, ServiceType } from '@lib/api-interface';
import { CollectionRequestStatus } from '../../../libs/api-interface/models/collection-request/collection-request-status.enum';
import { getWeekGapCustomEnumMapping } from './utils/get-week-gap-custom-enum-mapping';
import { useUserState } from '../../../state/UserState';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import { InfoRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface ScheduledServicePickupProps {
  projectId: string;
  branchId: string;
}

export const ScheduledServicePickup = (props: ScheduledServicePickupProps) => {
  const { t } = useTranslation();
  const userState = useUserState();
  const clientUser = userState.roles.includes(RolesEnum.CLIENT_USER);
  const clientUserSeesPrices = clientUser && userState.authorities.includes(AuthoritiesEnum.SEES_PRICES);
  const [upcomingTableReloadKey, setUpcomingTableReloadKey] = React.useState(0);
  const [onDemandTableReloadKey, setOnDemandTableReloadKey] = React.useState(0);
  const [inRealizationTableReloadKey, setInRealizationTableReloadKey] = React.useState(0);

  const commonColumns: DataTableColumn[] = [
    { key: 'collectionRequestView.wasteCode', label: t('wasteCode'), type: 'text' },
    { key: 'collectionRequestView.wasteName', label: t('wasteName'), type: 'text' },
    { key: 'collectionRequestView.wasteDescription', label: t('wasteDescription'), type: 'text' },
    {
      key: 'frequency',
      label: t('pickupFrequency'),
      type: 'numeric',
      joinedColumns: [
        {
          key: 'weekGap',
          label: '',
          type: 'enum',
          customValueMappings: getWeekGapCustomEnumMapping(),
        },
      ],
    },
    { key: 'collectionRequestView.collectionDate', label: t('pickupDate'), type: 'date' },
    { key: 'collectionRequestView.containers', label: t('receiptSizeUnit'), type: 'numeric' },
    { key: 'collectionRequestView.container', label: t('container'), type: 'text' },
    { key: 'collectionRequestView.exceedContainers', label: t('reportedExceed'), type: 'numeric' },
    { key: 'recipient', label: t('recipient'), type: 'text' },
  ];

  const priceColumns: DataTableColumn[] = [
    {
      key: 'projectBranchInfrastructureServiceView.currentSettings.clientLease',
      label: t('containerPrice'),
      type: 'numeric',
    },
    {
      key: 'collectionRequestView.clientTransportPrice.stringValue',
      label: t('clientTransport'),
      type: 'text',
    },
    {
      key: 'collectionRequestView.clientCollectionPrice.bearer',
      label: t('revenueOrIS'),
      type: 'enum',
      customValueMappings: [
        { value: ServicePriceBearer.ORGANIZATION_REVENUE, label: t('cost') },
        { value: ServicePriceBearer.ORGANIZATION_COST, label: t('revenue') },
      ],
    },
    {
      key: 'collectionRequestView.clientCollectionPrice.stringValue',
      label: t('clientCollection'),
      type: 'text',
    },
    {
      key: 'collectionRequestView.clientCollectionPrice.priceType',
      label: t('priceType'),
      type: 'enum',
      customValueMappings: [
        { value: ServicePriceType.UNIT, label: t('priceType.UNIT') },
        { value: ServicePriceType.M3, label: t('priceType.M3') },
        { value: ServicePriceType.MG, label: t('priceType.MG') },
      ],
    },
  ];

  const upcomingColumns: DataTableColumn[] = React.useMemo(() => {
    const columns = [...commonColumns];
    if (clientUserSeesPrices) {
      columns.push(...priceColumns);
    }
    return columns;
  }, [t, clientUser, clientUserSeesPrices]);

  const onDemandColumns: DataTableColumn[] = React.useMemo(() => {
    const columns = [
      { key: 'serviceView.projectWaste.wasteCode', label: t('wasteCode'), type: 'text' },
      { key: 'serviceView.projectWaste.wasteDescription', label: t('wasteName'), type: 'text' },
      { key: 'serviceView.projectWaste.projectWasteDescription', label: t('wasteDescription'), type: 'text' },
      { key: 'yearMonth', label: t('month'), type: 'date-year-month' },
      { key: 'serviceView.applicableCollectionInfrastructureCount', label: t('receiptSizeUnit'), type: 'numeric' },
      { key: 'serviceView.applicableInfrastructureService.name', label: t('container'), type: 'text' },
      { key: 'exceedContainers', label: t('reportedExceed'), type: 'numeric' },
      { key: 'serviceView.recipient', label: t('recipient'), type: 'text' },
    ] as DataTableColumn[];
    if (clientUserSeesPrices) {
      columns.push(
        ...priceColumns.map((column) => ({
          ...column,
          key: column.key.replace('collectionRequestView', 'serviceView'),
        })),
      );
    }
    return columns;
  }, [t, clientUserSeesPrices]);

  const inRealizationColumns: DataTableColumn[] = React.useMemo(() => {
    const columns = [...commonColumns];
    if (clientUser) {
      columns.push({ key: 'collectionRequestView.comment', label: t('comment'), type: 'text' });
    }
    if (clientUserSeesPrices) {
      columns.push(...priceColumns);
    }
    return columns;
  }, [t, clientUserSeesPrices]);

  return (
    <Panel borderType='none'>
      <Header label={t('upcomingPickupsDates')} />
      <DataTable
        refreshKey={upcomingTableReloadKey}
        fetchPostUrl={'api/client/collection-requests/closest'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: ServiceType.SCHEDULED,
          collectionRequestStatuses: [CollectionRequestStatus.SCHEDULED_FOR_KPO_CREATION, CollectionRequestStatus.SCHEDULED_FOR_NOTIFICATION, CollectionRequestStatus.SCHEDULED],
        }}
        initialSort={{ columnIndex: 4, ascending: true }}
        columns={upcomingColumns}
        rowOptions={[
          {
            renderer: (row) => (
              <ReportExceedButton
                collectionRequestId={row.collectionRequestView.collectionRequestId}
                container={row.collectionRequestView.container}
                exceedAmount={row.collectionRequestView.exceedContainers}
                afterSubmit={() => setUpcomingTableReloadKey(upcomingTableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
      <HStack>
        <Header label={t('onDemandPickups')} />
        <Tooltip title={t('onDemandPickupsDescription')} componentsProps={{ tooltip: { sx: { fontSize: '0.8rem' } } }}>
          <InfoRounded style={{ marginLeft: '0.5rem', cursor: 'help' }} fontSize='small' color='action' />
        </Tooltip>
      </HStack>
      <DataTable
        refreshKey={onDemandTableReloadKey}
        fetchPostUrl={'api/client/collection-requests/settlements/on-demand'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: ServiceType.SCHEDULED,
        }}
        initialSort={{ columnIndex: 3, ascending: true }}
        columns={onDemandColumns}
        rowOptions={[
          {
            renderer: (row) => (
              <ReportExceedButton
                settlementId={row.settlementId}
                container={row.serviceView.applicableInfrastructureService?.name}
                history={row.reportedExceeds}
                afterSubmit={() => setOnDemandTableReloadKey(onDemandTableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
      <Header label={t('pickupsInProgress')} />
      <DataTable
        refreshKey={inRealizationTableReloadKey}
        fetchPostUrl={'api/client/collection-requests'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: ServiceType.SCHEDULED,
          collectionRequestStatuses: [CollectionRequestStatus.IN_REALIZATION, CollectionRequestStatus.FEEDBACK_ALLOWED],
        }}
        initialSort={{ columnIndex: 4, ascending: true }}
        columns={inRealizationColumns}
        rowOptions={[
          {
            isVisible: (row) => row.collectionRequestView.status === CollectionRequestStatus.FEEDBACK_ALLOWED,
            renderer: (row) => (
              <AggregateCommandButton
                aggregateName='CollectionRequestAggregate'
                aggregateId={row.collectionRequestView.collectionRequestId}
                commandName='reportMissedPickup'
                buttonLabel={t('reportMissedPickup')}
                color={'warning'}
                variant={'contained'}
                forceConfirmation={true}
                onSaved={async () => setInRealizationTableReloadKey(inRealizationTableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
