import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPersonTypeEnum } from '@lib/api-interface';
import { DataTable, Panel } from '@lib/ui-components';
import { useProjectBranchContext } from '@context';

export const BranchDataContact = () => {
  const [tableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const projectBranchView = useProjectBranchContext();

  return (
    <Panel>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'contactPersonViews' }}
        noFilters
        fetchFilters={{
          type: ContactPersonTypeEnum.CONTACT,
          branchId: projectBranchView.branchId,
        }}
        columns={[
          {
            key: 'firstName',
            label: t('firstName'),
            type: 'numeric',
          },
          {
            key: 'lastName',
            label: t('lastName'),
            type: 'text',
          },
          {
            key: 'email',
            label: t('email'),
            type: 'text',
          },
          {
            key: 'phone',
            label: t('phone'),
            type: 'text',
          },
          {
            key: 'mainContactPerson',
            label: t('mainContactPerson'),
            type: 'boolean',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
        ]}
      />
    </Panel>
  );
};
