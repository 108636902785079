import React from 'react';

interface RenderIfTrueProps {
  children: React.ReactNode;
  true: boolean | undefined;
}

interface RenderIfFalseProps {
  children: React.ReactNode;
  false: boolean | undefined;
}

export const RenderIf = (props: RenderIfTrueProps | RenderIfFalseProps) => {
  return ('true' in props && props.true) || ('false' in props && props.false !== true) ? <>{props.children}</> : null;
};
