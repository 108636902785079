import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LeftMenuLayout } from '../../components/layouts/left-menu-layout/left-menu-layout';
import { CollectionRequests } from './collection-requests/collection-requests';
import { ContactForm } from './contact-form/contact-form';
import { ProjectBranches } from './projects/branches/project-branches';
import { ProjectBranch } from './projects/branches/project-branch';
import { Reports } from '../organization/reports/reports';
import { ClientData } from './client-data/client-data';
import { PickupsBranch } from './pickups/pickups-branch';
import { PickupsBranches } from './pickups/pickups-branches';
import { ServicesToAccept } from './services-to-accept/services-to-accept';
import { InstructionPDF } from '../public/poi-instruction';
import {
  Kpo,
} from '../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/kpo/kpo';

export const ClientAdminRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LeftMenuLayout />}>
        <Route index element={<Navigate to='/branches' />} />
        <Route path='services-to-accept' element={<ServicesToAccept />} />
        <Route path='branches'>
          <Route index element={<ProjectBranches />} />
          <Route path=':projectBranchId'>
            <Route index element={<ProjectBranch />} />
          </Route>
        </Route>
        <Route path='pickups'>
          <Route index element={<PickupsBranches />} />
          <Route path=':projectBranchId'>
            <Route index element={<PickupsBranch />} />
          </Route>
        </Route>
        <Route path='collection-requests'>
          <Route index element={<CollectionRequests />} />
        </Route>
        <Route path='reports' element={<Reports />} />
        <Route path="archive" element={<Kpo />} />
        <Route path='client-data' element={<ClientData />} />
        <Route path='contact-form' element={<ContactForm />} />
        <Route path="instruction" element={<InstructionPDF />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
