import { UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getServiceButtonConsts, invoicingMethodControls, navResourceControls, paymentMethodControls, priceFields, settlementTypeControls } from '../shared-components/shared';
import { additionalServiceValidationSchema } from './additional-service-validation';
import { useProjectBranchContext, useProjectBranchSettingsContext } from '@context';
import { ServiceButtonMode, ServiceButtonProps } from '../service-button-props';
import { PaymentMethod, ServiceType, SettlementType } from '@lib/api-interface';

export const AdditionalServiceButton = (props: ServiceButtonProps) => {
  const { t } = useTranslation();
  const projectBranch = useProjectBranchContext();
  const { label, useCaseName, startIcon } = getServiceButtonConsts(t, props.serviceButtonMode);
  const projectBranchSettings = useProjectBranchSettingsContext();

  const initialService = props.serviceButtonMode !== ServiceButtonMode.CREATE ? props?.service : undefined;
  const isCreationFromAdditionalTab = props.serviceButtonMode === ServiceButtonMode.CREATE && props?.isAdditionalTabSelected;
  const hiddenValues =
    props.serviceButtonMode === ServiceButtonMode.EDIT
      ? {
          serviceId: initialService!.serviceId,
        }
      : {
          projectBranchId: projectBranch.projectBranchId,
          companyBranchId: projectBranch.branchId,
          projectId: projectBranch.projectId,
          acceptanceState: 'INITIALIZED',
          serviceType: ServiceType.ADDITIONAL,
        };
  const priceInputs =
    props.serviceButtonMode === ServiceButtonMode.EDIT
      ? []
      : [
          ...priceFields('clientCollectionPrice', t, t('clientCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'UNIT',
            defaultBearer: 'ORGANIZATION_REVENUE',
            isVisible: (data) => !data.pix,
          }),
          ...priceFields('subcontractorCollectionPrice', t, t('subcontractorCollection'), {
            initialService: initialService,
            defaultPrice: undefined,
            defaultType: 'UNIT',
            defaultBearer: 'ORGANIZATION_COST',
          }),
        ];

  return (
    <UsecaseButton
      dataTestId={`service-button-${props.serviceButtonMode}`}
      maxWidth='lg'
      buttonLabel={label}
      dialogTitle={label}
      startIcon={startIcon}
      groupName='service'
      useCaseName={useCaseName}
      fields={[
        {
          rowItems: [
            navResourceControls(t, initialService?.navResourceId),
            {
              label: t('servicePixRelated'),
              type: 'boolean',
              field: 'pix',
              defaultValue: initialService?.currentSettings?.pix,
            },
          ],
        },
        {
          label: t('serviceDesc'),
          type: 'text',
          field: 'description',
          defaultValue: initialService?.description,
        },
        {
          rowItems: [
            {
              label: t('activationDate'),
              type: 'date',
              field: 'activationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.activationDate : undefined,
            },
            {
              label: t('deactivationDate'),
              type: 'date',
              field: 'deactivationDate',
              defaultValue: props.serviceButtonMode === ServiceButtonMode.EDIT ? initialService?.deactivationDate : undefined,
            },
          ],
        },
        ...priceInputs,
        {
          label: '',
          type: 'header',
          field: '',
        },
        {
          rowItems: [
            {
              label: t('settledSubcontractorName'),
              type: 'table-select',
              field: 'subcontractorId',
              defaultValue: initialService?.subcontractorId,
              tableSelectParams: {
                modelName: 'companyBranchViews',
                search: 'forSettledSubcontractor',
                dialogTitle: t('subcontractor'),
                displayFormat: '{name}',
                selectedValueField: 'branchId',
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('town'),
                    key: 'registrationAddress.town',
                    type: 'text',
                  },
                  {
                    label: t('nip'),
                    key: 'nip',
                    type: 'text',
                  },
                  {
                    label: t('bdoIdentificationNumber'),
                    key: 'bdoIdentificationNumber',
                    type: 'text',
                  },
                ],
              },
            },
            {
              label: t('reactionTime'),
              type: 'numeric',
              field: 'reactionTime',
              defaultValue: initialService?.reactionTime || 1,
            },
          ],
        },
        {
          label: '',
          type: 'header',
          field: '',
        },
        {
          rowItems: [settlementTypeControls(t, initialService?.settlementType || SettlementType.PER_MONTH)],
        },
        {
          rowItems: [
            paymentMethodControls(t, isCreationFromAdditionalTab ? PaymentMethod.TRANSFER : initialService?.paymentMethod),
            invoicingMethodControls(t, initialService?.invoicingMethod),
          ],
        },
        {
          rowItems: [
            {
              label: t('separatedInvoice'),
              type: 'boolean',
              field: 'separateInvoice',
              defaultValue: initialService?.separateInvoice,
            },
            {
              label: t('constantCost'),
              type: 'boolean',
              field: 'constantCost',
              defaultValue: initialService?.constantCost,
            },
            {
              label: t('organizationAsSide'),
              type: 'boolean',
              field: 'organizationAsSide',
              defaultValue:
                initialService?.currentSettings?.organizationAsSide === undefined
                  ? projectBranchSettings?.organizationIsParticipating
                  : initialService?.currentSettings?.organizationAsSide,
              isVisible: () => props.serviceButtonMode !== ServiceButtonMode.EDIT,
            },
          ],
        },
      ]}
      hiddenValues={hiddenValues}
      onSaved={async () => {
        if (props.onSaved) {
          props.onSaved();
        }
        if (props.serviceButtonMode !== ServiceButtonMode.EDIT) props.reloadServices();
      }}
      validationSchema={additionalServiceValidationSchema(t, props.serviceButtonMode === ServiceButtonMode.EDIT)}
    />
  );
};
