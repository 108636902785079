import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxLabel, CheckboxPanel, EnumLabel, Panel, Tabs, TextLabel } from '@lib/ui-components';
import { useParams } from 'react-router-dom';
import { ProjectSettings, ProjectTerms, ProjectType, ProjectView } from '@lib/api-interface';
import { BranchData } from './branch-data/branch-data';
import { InfrastructureServices } from './infrastructure-services/infrastructure-services';
import { BranchServices } from './branch-services/branch-services';
import { Kpo } from './kpo/kpo';
import { ProjectBranchContextProvider, ProjectBranchSettingsContextProvider, ProjectBranchTermsContextProvider, ProjectContextProvider } from '@context';
import { ProjectBranchViewModel } from '../../../../../../../libs/api-interface/models/project/project-branch-view.model';
import { useProjectBranchSettings, useProjectBranchTerms, useProjectBranchView, useProjectView } from '@data-source-hooks';
import { FetchErrorIndicator } from '../../../../../../../libs/ui-components/components/fetch-error-indicator';
import { DateTimeService } from '../../../../../../../services/date-time.service';

export const ProjectBranchView = () => {
  const { t } = useTranslation();
  const pathParams = useParams();

  const [projectQuery] = useProjectView(pathParams['projectId']?.substring(1));
  const [projectBranchQuery] = useProjectBranchView(pathParams['branchId']?.substring(1));
  const [projectBranchSettingsQuery] = useProjectBranchSettings(pathParams['branchId']?.substring(1));
  const [projectBranchTermsQuery] = useProjectBranchTerms(pathParams['branchId']?.substring(1));

  if (projectQuery.isLoading || projectBranchQuery.isLoading || projectBranchSettingsQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (projectQuery.isError) {
    return <FetchErrorIndicator error={projectQuery.error} />;
  }
  if (projectBranchQuery.isError) {
    return <FetchErrorIndicator error={projectBranchQuery.error} />;
  }
  if (projectBranchSettingsQuery.isError) {
    return <FetchErrorIndicator error={projectBranchSettingsQuery.error} />;
  }
  if (projectBranchTermsQuery.isError) {
    return <FetchErrorIndicator error={projectBranchTermsQuery.error} />;
  }
  const projectView: ProjectView = projectQuery.data!;
  const projectBranchView: ProjectBranchViewModel = projectBranchQuery.data!;
  const projectBranchSettings: ProjectSettings = projectBranchSettingsQuery.data!;
  const projectBranchTerms: ProjectTerms = projectBranchTermsQuery.data!;

  return (
    <ProjectContextProvider value={projectView}>
      <ProjectBranchContextProvider value={projectBranchView}>
        <ProjectBranchSettingsContextProvider value={projectBranchSettings}>
          <ProjectBranchTermsContextProvider value={projectBranchTerms}>
            <Panel borderType='raised' gap={'1rem'}>
              <Panel direction={'row'} justifyContent='space-between'>
                <Panel direction='row' gap='3rem'>
                  <TextLabel label={t('projectNumber').toUpperCase()} value={projectView.projectNumber} highlight={true} labelBold />
                  <EnumLabel
                    value={projectView.type}
                    enumOptions={[
                      { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
                      { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
                      { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
                    ]}
                  />
                  <TextLabel label={t('branch').toUpperCase()} value={projectBranchQuery.data?.companyBranchName} highlight={true} labelBold />
                </Panel>
                <CheckboxPanel justifyContent='flex-start' marginRight='4rem' marginTop='1rem'>
                  <CheckboxLabel label={t('branch')} value={true} />
                  <TextLabel value={projectBranchQuery.data?.companyName} />
                </CheckboxPanel>
              </Panel>

              <Panel direction={'row'} gap='8rem' contentMarginLeft='0.6rem'>
                <TextLabel label={t('firstName')} value={projectBranchView.contactPerson?.firstName} />
                <TextLabel label={t('lastName')} value={projectBranchView.contactPerson?.lastName} />
                <TextLabel label={t('phoneNumber')} value={projectBranchView.contactPerson?.phone} />
                <TextLabel label={t('email')} value={projectBranchView.contactPerson?.email} />
                <TextLabel
                  label={t('contractConclusionDate')}
                  value={projectBranchView.contractConclusionDate ? new Date(projectBranchView.contractConclusionDate).toISOString() : undefined}
                  dateFormat={DateTimeService.DEFAULT_FORMAT_DATE}
                />
              </Panel>
            </Panel>
            <Tabs
              marginTop='1rem'
              initialTabIndex={2}
              panels={[
                {
                  label: t('Model.CompanyBranch.data'),
                  component: <BranchData projectBranch={projectBranchQuery.data!} />,
                },
                {
                  label: t('Projects.ViewProject.infrastructureServices'),
                  component: <InfrastructureServices projectBranchSettings={projectBranchSettings} />,
                },
                {
                  label: t('Projects.ViewProject.services'),
                  component: (
                    <BranchServices projectId={projectView.projectId} projectBranchId={projectBranchView.projectBranchId} projectBranchDeleted={projectBranchView.deleted} />
                  ),
                },
                { label: t('kpo'), component: <Kpo /> },
              ]}
              borderType='none'
            />
          </ProjectBranchTermsContextProvider>
        </ProjectBranchSettingsContextProvider>
      </ProjectBranchContextProvider>
    </ProjectContextProvider>
  );
};
