import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat, FinancialReportType } from '@lib/api-interface';
import React, { useState } from 'react';
import { DataTable, RenderIf, Snackbar, Toolbar } from '@lib/ui-components';
import { CreateReportButton } from './create-report-button';
import { useUserState } from '../../../../state/UserState';
import { RolesEnum } from '../../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../../domain/enums/authorities.enum';
import { useWebSocketState } from '../../../../state/web-socket-state';
import { CreateClientAdminReportButton } from '../../../client-admin/reports/create-client-admin-report-button';
import { CreateSubcontractorReportButton } from '../../../contractor/reports/create-subcontractor-report-button';
import { ReportRowOptionsButtons } from '../report-row-options-buttons';

export const FinancialReports = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const webSocketState = useWebSocketState();
  const [tableRefreshKey, setTableRefreshKey] = useState(0);
  const [selectedReportType, setSelectedReportType] = useState(FinancialReportType.FINANCIAL_INCOME_AND_COSTS);
  const isOrganizationAdmin = userState.roles.includes(RolesEnum.ORGANIZATION) && userState.authorities.includes(AuthoritiesEnum.ORGANIZATION_ADMIN);
  const clientUser = userState.roles.includes(RolesEnum.CLIENT_USER);
  const clientAdmin = userState.roles.includes(RolesEnum.CLIENT_ADMIN);
  const contractor = userState.roles.includes(RolesEnum.CONTRACTOR);
  const [showReportGenerationStartedMessage, setShowReportGenerationStartedMessage] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTableRefreshKey((prevKey) => prevKey + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [webSocketState.reportGeneratedRefreshKey]);

  const createReportButton = React.useMemo(() => {
    const reportTypeWithAsyncGeneration = [FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS, FinancialReportType.FINANCIAL_INCOME_AND_COSTS].includes(selectedReportType);
    const onSaved = async () => {
      if (reportTypeWithAsyncGeneration) {
        setShowReportGenerationStartedMessage(true);
      }
      setTableRefreshKey((prevState) => prevState + 1);
    };

    if (clientAdmin || clientUser) {
      return <CreateClientAdminReportButton reportType={selectedReportType} clientUser={clientUser} onSaved={onSaved} />;
    } else if (contractor) {
      return <CreateSubcontractorReportButton reportType={selectedReportType} onSaved={onSaved} />;
    } else {
      return <CreateReportButton reportType={selectedReportType} onSaved={onSaved} />;
    }
  }, [selectedReportType, clientAdmin, clientUser, contractor]);

  const backgroundGenerationStartedMessage = React.useMemo(() => {
    return (
      <RenderIf true={showReportGenerationStartedMessage}>
        <Snackbar message={t('reportGenerationStarted')} severity='info' onClose={() => setShowReportGenerationStartedMessage(false)} />
      </RenderIf>
    );
  }, [showReportGenerationStartedMessage, t]);

  return (
    <>
      <Toolbar
        left={[
          <FormControl variant='standard' fullWidth>
            <InputLabel>{t('type')}</InputLabel>
            <Select defaultValue={selectedReportType} onChange={(event) => setSelectedReportType(event.target.value as FinancialReportType)}>
              {Object.values(FinancialReportType)
                .filter((val) => {
                  if (clientAdmin || clientUser || contractor) {
                    return val === FinancialReportType.FINANCIAL_INCOME_AND_COSTS;
                  }
                  return val !== FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS || isOrganizationAdmin;
                })
                .map((val) => (
                  <MenuItem key={`ReportType.${val}`} value={val}>
                    {t(`ReportType.${val}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>,
        ]}
        right={[createReportButton]}
      />

      <DataTable
        modelDef={{ modelName: 'reportViews' }}
        fetchFilters={{ type: selectedReportType }}
        columns={[
          { label: t('reportFile'), key: 'fileName', type: 'text' },
          { label: t('creationDate'), key: 'creationDate', type: 'date', dateFormat: dateTimeFormat },
        ]}
        initialSort={{ columnIndex: 1, ascending: false }}
        rowOptions={[
          {
            renderer: (row) => <ReportRowOptionsButtons {...row} />,
          },
        ]}
        refreshKey={tableRefreshKey}
      />
      {backgroundGenerationStartedMessage}
    </>
  );
};
