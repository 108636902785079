import { DataResultsErrorDto } from './data-results-error.dto';

export class DataResultsDto<ModelType> {
    failed: boolean;
    errorStatusCode: number | undefined;
    errorMessage: string | number | undefined;
    error: DataResultsErrorDto| any | undefined; // TODO: remove 'any'
    response: ModelType | undefined;

    constructor(failed: boolean, errorStatusCode: number | undefined, error: DataResultsErrorDto | any | undefined, errorMessage: string | number | undefined, response: ModelType | undefined) {
        this.failed = failed;
        this.errorStatusCode = errorStatusCode;
        this.errorMessage = errorMessage;
        this.error = error;
        this.response = response;
    }
}
