import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

export function Kpo() {
  const { t } = useTranslation();

  return (
    <Box display='flex' flexDirection='column' alignItems='center' marginTop={10} justifyContent='center'>
      <Typography variant='body1' align='center' gutterBottom>
        {t('kpoPoRedirectInfo')}
      </Typography>
      <Button variant='contained' color='primary' href='https://rsi.interserohpolska.pl' target='_blank'>
        {t('kpoPoRedirectButton')}
      </Button>
    </Box>
  );
}
