import React, { PropsWithChildren, useState } from 'react';
import { DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import { DataTable, DataTableColumn, Dialog, GeneralForm, GeneralFormItem, GeneralFormRowOfItems, RenderIf } from '@lib/ui-components';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../button/button';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useDebouncedCallback } from 'use-debounce';
import axios, { AxiosResponse } from 'axios';

export interface FormInputTableSelectByApiProps extends FormInputProps {
  initialEntity?: any;
  displayFormat: string; // ex '{firstName} {lastName}'
  dialogTitle: string;

  apiPostEndpoint: string;
  apiPostParams?: any;
  columns: DataTableColumn[];
  selectedValueField?: string; // _id by default
  onRowSelected?: (row: any) => void;
  getFormValues?: () => any;
  queryFields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  otherValuesMap?: any;
  autoSelectFirstOptionOnInitIfNoInitialEntity?: boolean;
  shouldAutoSelectFirst?: (apiParams: any) => boolean;
  shouldClearOnApiParams?: (apiParams: any) => boolean;
}

export const FormInputTableSelectByApi: React.FC<FormInputTableSelectByApiProps> = (props: PropsWithChildren<FormInputTableSelectByApiProps>) => {
  const [openDialog, setOpenDialog] = useStateIfMounted(false);
  const [displayedValue, setDisplayedValue] = useStateIfMounted('');
  const [selectedValue, setSelectedValue] = useStateIfMounted<string | null>('');
  const [queryFieldValues, setQueryFieldValues] = useStateIfMounted<any>({});
  const [queryFieldValuesHash, setQueryFieldValuesHash] = useStateIfMounted<string>(JSON.stringify({}));
  const debouncedQueryFieldValuesChange = useDebouncedCallback((data) => {
    if (JSON.stringify(data) !== queryFieldValuesHash) {
      setQueryFieldValuesHash(JSON.stringify(data));
      setQueryFieldValues(data);
      setTableReloadKey(tableReloadKey + 1);
    }
  }, 300);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const [cleared, setCleared] = useState(false);

  function computeApiParams() {
    const apiParams = props.apiPostParams != undefined ? { ...props.apiPostParams } : undefined;
    if (props.getFormValues != null && props.apiPostParams != null) {
      const formValues = props.getFormValues();
      Object.keys(props.apiPostParams).forEach((key) => {
        const paramVal = props.apiPostParams[key];
        if (typeof paramVal === 'string' && paramVal.startsWith('$')) {
          apiParams[key] = formValues[paramVal.substring(1)];
        } else {
          apiParams[key] = paramVal;
        }
      });
    }
    return { ...apiParams, ...queryFieldValues };
  }

  const apiParams = computeApiParams();

  const handleSelect = React.useCallback(
    async (row: any, onChange: (...event: any[]) => void, isSetValue?: boolean) => {
      const idFieldName = 'id';
      let selectedValue;
      if (props.selectedValueField != null) {
        selectedValue = row[props.selectedValueField];
      } else {
        selectedValue = row[idFieldName];
      }
      if (props.onRowSelected) {
        props.onRowSelected(row);
      }
      setDisplayedValue(formatDisplay(props.displayFormat, row));
      setSelectedValue(selectedValue);
      if (isSetValue === true) {
        onChange(props.name, selectedValue);
      } else {
        onChange(selectedValue);
      }
      setOpenDialog(false);
    },
    [props.displayFormat, setDisplayedValue, setOpenDialog, setSelectedValue],
  );

  React.useEffect(() => {
    if (props.initialEntity != null) {
      const entity = { ...props.initialEntity };
      if (props.selectedValueField !== undefined && props.selectedValueField !== props.name) {
        entity[props.selectedValueField] = entity[props.name];
      }

      const idFieldName = 'id';
      let selectedValue;
      if (props.selectedValueField != null) {
        selectedValue = entity[props.selectedValueField];
      } else {
        selectedValue = entity[idFieldName];
      }
      setDisplayedValue(formatDisplay(props.displayFormat, entity));
      setSelectedValue(selectedValue);
      props.setValue(props.name, selectedValue);
    }
  }, [props.initialEntity]);

  function fetchDataAndSelectFirst() {
    axios.post(props.apiPostEndpoint, apiParams).then((response: AxiosResponse) => {
      const items = response.data?.result !== undefined ? response.data?.result : response.data;
      if (items.length === 1) {
        handleSelect(items[0], props.setValue, true);
      }
    });
  }

  React.useEffect(() => {
    let clearedByApi = false;
    if (props.shouldClearOnApiParams && props.shouldClearOnApiParams(apiParams)) {
      clearedByApi = true;
      setDisplayedValue('');
      setSelectedValue(null);
      props.setValue(props.name, '');
      if (props.onRowSelected) {
        props.onRowSelected(null);
      }
      setCleared(true);
    }
    if (!clearedByApi && props.autoSelectFirstOptionOnInitIfNoInitialEntity === true && (props.initialEntity == null || cleared)) {
      if (props.shouldAutoSelectFirst) {
        if (props.shouldAutoSelectFirst(apiParams)) {
          fetchDataAndSelectFirst();
        }
      } else {
        fetchDataAndSelectFirst();
      }
    }
  }, [JSON.stringify(apiParams)]);

  function formatDisplay(text: string, args: any) {
    Object.keys(args).forEach((key) => {
      text = text.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
    });
    return text;
  }

  async function handleCloseDialog() {
    setOpenDialog(false);
  }

  async function handleInputClick(onChange: (...event: any[]) => void) {
    if (displayedValue !== '') {
      setDisplayedValue('');
      onChange('');
      setSelectedValue(null);
      if (props.onRowSelected) {
        props.onRowSelected(null);
      }
      setCleared(true);
    } else {
      setOpenDialog(true);
      setCleared(false);
    }
  }

  function processValueToDisplay(displayedValue: string, value: any) {
    if (props.resetField) {
      setDisplayedValue('');
      setSelectedValue(null);
      if (props.onRowSelected) {
        props.onRowSelected(null);
      }
      return displayedValue;
    }
    if (value === '' && selectedValue !== value && props.initialEntity === undefined) {
      setDisplayedValue('');
      setSelectedValue(null);
      if (props.onRowSelected) {
        props.onRowSelected(null);
      }
    } else if (value !== '' && selectedValue !== value) {
      setSelectedValue(value);
    }
    return displayedValue;
  }

  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <>
            <div data-test-id={`input-${props.name}-wrapper`}>
              <TextField
                inputProps={{ 'data-test-id': `input-${props.name}` }}
                helperText={error ? error.message : ' '}
                error={!!error}
                size='small'
                value={processValueToDisplay(displayedValue, value)}
                onChange={(event) => {
                  onChange(selectedValue);
                }}
                fullWidth
                label={props.label}
                variant={props.variant || 'outlined'}
                margin='normal'
                onClick={(event) => handleInputClick(onChange)}
                onKeyDown={(event) => event.preventDefault()}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton data-test-id={`input-${props.name}-clear-btn`} onClick={() => handleInputClick(onChange)}>
                        {displayedValue !== '' ? <ClearIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  caretColor: 'transparent',
                  cursor: 'pointer',
                }}
                disabled={props.isDeactivated}
              />
            </div>
            <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth='xl' fullWidth={true}>
              <DialogTitle>
                {props.dialogTitle}
                <IconButton
                  data-test-id={'dialog-close-btn'}
                  aria-label='close'
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <>
                  <RenderIf true={props.queryFields !== undefined}>
                    <GeneralForm key={1} fields={props.queryFields!} hideCancelButton={true} hideSubmitButton={true} onChanged={(data) => debouncedQueryFieldValuesChange(data)} />
                  </RenderIf>
                  <DataTable
                    refreshKey={tableReloadKey}
                    columns={props.columns}
                    fetchPostUrl={props.apiPostEndpoint}
                    fetchFilters={apiParams}
                    rowOptions={[
                      {
                        renderer: (row) => <Button label={t('Common.select')} color='primary' variant='outlined' onClick={async () => handleSelect(row, onChange)} />,
                      },
                    ]}
                  />
                </>
              </DialogContent>
            </Dialog>
          </>
        )}
      />
    </>
  );
};
