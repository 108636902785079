import React from 'react';
import { DataTable, HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import CollectionCalendar from '../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/collection-calendar-tab';

export const TodayPickups = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  const todayPickups = React.useMemo(() => {
    return (
      <DataTable
        refreshKey={1}
        fetchUrl={'api/contractor/today-pickups'}
        initialSort={{ columnIndex: 0, ascending: false }}
        columns={[
          { key: 'creationDate', label: t('reportDate'), type: 'date-time' },
          { key: 'clientName', label: t('companyName'), type: 'text' },
          { key: 'branchName', label: t('branchName'), type: 'text' },
          { key: 'branchRegistrationAddress.town', label: t('town'), type: 'text' },
          {
            key: 'branchRegistrationAddress.street',
            label: t('street'),
            type: 'text',
            joinedColumns: [{ key: 'branchRegistrationAddress.houseNum', label: t('houseNum'), type: 'text' }],
          },
          { key: 'wasteCode', label: t('wasteCode'), type: 'text' },
          { key: 'wasteName', label: t('wasteName'), type: 'text' },
          { key: 'wasteDescription', label: t('wasteDescription'), type: 'text' },
          { key: 'container', label: t('container'), type: 'text' },
          { key: 'containers', label: t('containerAmount'), type: 'numeric' },
          { key: 'comment', label: t('comment'), type: 'text' },
        ]}
      />
    );
  }, [activePage]);

  return (
    <Panel header={t('todayPickups')} borderType='none'>
      <HStack align='center'>
        <ToggleButtons labels={[t('todayPickups'), t('calendar')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>

      {activePage === 0 && todayPickups}
      {activePage === 1 && <CollectionCalendar forSubcontractor={true} />}
    </Panel>
  );
};
