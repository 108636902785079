import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';

export const FormInputPassword = ({ name, control, label, variant, isDeactivated }: FormInputProps) => {
  if (variant == null) {
    variant = 'outlined';
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div data-test-id={`input-${name}-wrapper`}>
          <TextField
            inputProps={{ 'data-test-id': `input-${name}` }}
            helperText={error ? error.message : ' '}
            size='small'
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant={variant}
            margin='normal'
            type='password'
            disabled={isDeactivated}
          />
        </div>
      )}
    />
  );
};
