import { TFunction } from 'react-i18next';
import { NavResourceAccountingGroup, NavResourceType, NavResourceUnit } from '../libs/api-interface/models/global-infrastructure/nav-resource.model';
import { GeneralFormSelectOption } from '../libs/ui-components';

export class InfrastructureCategorySelectOptionsFactory {
  static getTypeSelectOptions(t: TFunction<'common', undefined>): GeneralFormSelectOption[] {
    return [
      {
        label: t('Model.NavResource.type.HARDWARE'),
        value: NavResourceType.HARDWARE,
      },
      {
        label: t('Model.NavResource.type.EMPLOYEE'),
        value: NavResourceType.EMPLOYEE,
      },
    ];
  }

  static getUnitSelectOptions(t: TFunction<'common', undefined>): GeneralFormSelectOption[] {
    return [
      {
        label: t('Model.NavResource.unit.UNIT'),
        value: NavResourceUnit.UNIT,
      },
      {
        label: t('Model.NavResource.unit.MG'),
        value: NavResourceUnit.MG,
      },
      {
        label: t('Model.NavResource.unit.SERVICE'),
        value: NavResourceUnit.SERVICE,
      },
    ];
  }

  static getAccountingGroupSelectOptions(t: TFunction<'common', undefined>): GeneralFormSelectOption[] {
    return [
      {
        label: t('Model.NavResource.accountingGroup.CONSULTANCY'),
        value: NavResourceAccountingGroup.CONSULTANCY,
      },
      {
        label: t('Model.NavResource.accountingGroup.COMMODITY'),
        value: NavResourceAccountingGroup.COMMODITY,
      },
      {
        label: t('Model.NavResource.accountingGroup.SERVICES'),
        value: NavResourceAccountingGroup.SERVICES,
      },
      {
        label: t('Model.NavResource.accountingGroup.RSI'),
        value: NavResourceAccountingGroup.RSI,
      },
    ];
  }
}
