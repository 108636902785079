import { Expose } from 'class-transformer';

export const modelNameFromClassName = (className: string): string => {
  return className.endsWith('Model') ? className.substring(0, className.length - 5) : className;
};

export abstract class BaseModel {
  @Expose()
  id?: number;
}
