import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, DataTable, FormInputText, HStack, Panel, RenderIf, Snackbar } from '@lib/ui-components';
import { FormInputFile } from '../../../libs/ui-components/components/form/form-input-file';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { FormInputTextArea } from '../../../libs/ui-components/components/form/form-input-text-area';

interface IFormInput {
  phone: string;
  message: string;
  attachments: File[];
}

export const ContactForm = () => {
  const { t } = useTranslation();
  const [tableRefreshKey, setTableRefreshKey] = React.useState(0);
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const handleFileUpload = (file: File) => {
    setUploadedFiles([...uploadedFiles, file]);
    setTableRefreshKey(tableRefreshKey + 1);
  };

  const removeFile = (index: number) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(index, 1);
    setUploadedFiles(newFiles);
    setTableRefreshKey(tableRefreshKey + 1);
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(t('requiredField')),
    message: Yup.string().required(t('requiredField')),
  });

  const { handleSubmit, control, reset } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phone: '',
      message: '',
      attachments: [],
    },
  });

  const onSubmit = async (data: IFormInput) => {
    const formData = new FormData();
    formData.append('message', data.message);
    formData.append('phone', data.phone);
    uploadedFiles.forEach((file) => {
      formData.append('attachments', file);
    });

    await axios({
      method: 'post',
      data: formData,
      url: '/api/contact-form',
      responseType: 'json',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Unexpected response status');
        }
      })
      .catch((error) => {
        console.error('Error while sending contact form', error);
      });
    reset();
    setUploadedFiles([]);
    setTableRefreshKey(tableRefreshKey + 1);
    setShowSuccessMessage(true);
  };

  return (
    <Panel header={t('contactForm')} borderType='raised'>
      <FormInputText name={'phone'} control={control} label={t('phone')} />
      <FormInputTextArea name={'message'} control={control} label={t('messageContent')} rows={20} />
      <FormInputFile name={'attachments'} control={control} label={t('dropOrSelectAttachment')} onSelect={handleFileUpload} ignoreSelectedFileName={true} />
      <DataTable
        data={uploadedFiles}
        refreshKey={tableRefreshKey}
        noFilters={true}
        columns={[
          {
            key: 'name',
            label: t('file'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <Button label={t('remove')} startIcon={<DeleteIcon />} onClick={() => removeFile(row.__rowIdx)} />,
          },
        ]}
      />
      <HStack align={'right'}>
        <Button onClick={handleSubmit(onSubmit)} label={t('save')} color='primary' variant='contained' />
      </HStack>
      <RenderIf true={showSuccessMessage}>
        <Snackbar message={t('contactFormCreated')} severity={'success'} onClose={() => setShowSuccessMessage(false)} />
      </RenderIf>
    </Panel>
  );
};
