import * as React from 'react';
import {Property} from 'csstype';

type AlignType = 'left' | 'right' | 'center' | 'space-between';
type VAlignType = 'top' | 'center' | 'bottom';

export interface HStackProps {
    align?: AlignType;
    vAlign?: VAlignType;
    gap?: string;
    children?: React.ReactNode;
    inlineFlex?: boolean;
}

export const HStack = (props: HStackProps) => {
    function vAlignToCSS(align?: VAlignType): Property.AlignItems | undefined {
        switch (align) {
            case 'top':
                return 'start';
            case 'bottom':
                return 'end';
            case 'center':
                return 'center';
            default:
                return undefined;
        }
    }

    function valignToCSS(align?: AlignType): Property.JustifyContent | undefined {
        switch (align) {
            case 'left':
                return 'start';
            case 'right':
                return 'end';
            case 'center':
                return 'center';
            case 'space-between':
                return 'space-between';
            default:
                return undefined;
        }
    }

    const align: AlignType = props.align != null ? props.align : 'left';
    const vAlign: VAlignType = props.vAlign != null ? props.vAlign : 'center';

    return (
        <div
            style={{
                display: props.inlineFlex == null ? 'flex' : 'inline-flex',
                flexDirection: 'row',
                alignItems: vAlignToCSS(vAlign),
                justifyContent: valignToCSS(align),
                gap: props.gap,
                marginTop: '0.5rem',
            }}
        >
            {props.children}
        </div>
    );
};
