import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';
import { HelperText } from './helper-text';

interface FormInputTextProps extends FormInputProps {
  defaultValue?: string;
}

export const FormInputText = ({ name, control, label, variant, labelColor, isDeactivated, helperText, defaultValue }: FormInputTextProps) => {
  if (variant == null) {
    variant = 'outlined';
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      render={({ field, fieldState: { error } }) => (
        <div data-test-id={`input-${name}-wrapper`}>
          <TextField
            {...field}
            inputProps={{ 'data-test-id': `input-${name}` }}
            helperText={error ? error.message : helperText ? helperText : ' '}
            error={!!error}
            size='small'
            value={field.value}
            fullWidth
            label={label}
            variant={variant}
            margin='normal'
            sx={{ color: labelColor }}
            disabled={isDeactivated}
          />
          {helperText && error && <HelperText>{helperText}</HelperText>}
        </div>
      )}
    />
  );
};
