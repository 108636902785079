import styled from 'styled-components';

const Background = styled.div`
    width: 100vw;
    height: 100vw;
    border-radius: 50%;
    background-color: #002652;
    position: absolute;
    left: -50%;
    top: 50%;
    transform: translateY(-50%);
`;

export default Background;