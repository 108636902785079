import styled, { css } from 'styled-components';

interface TitlePositionerProps {
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
}

export const TitlePositioner = styled.div<TitlePositionerProps>`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0.9rem')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0.6rem')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '-1.2rem')};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
`;

export const StyledTableBorder = styled.div`
  border: 1px solid;
  border-radius: 10px;
  border-color: #183362;
  transition: 0.3s;

  :hover {
    border-color: #f2c94c;
  }
`;

interface TableContentProps {
  isDeactivated?: boolean;
}

export const TableAdaptiveArea = styled.div<TableContentProps>`
  ${({ isDeactivated }) =>
    isDeactivated &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

export const ActiveArea = styled.div`
  background-color: aqua;
  opacity: 0;
  pointer-events: revert;
`;

export const StyledServiceDivider = styled.div`
  margin-bottom: 1rem;
`;

export const LabelDiv = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.2rem;
  }

  & > * {
    margin-right: 0.3rem;
  }
`;

interface CollapsedAreaProps {
  collapse: boolean;
}

export const CollapsedArea = styled.div<CollapsedAreaProps>`
  display: ${({ collapse }) => (collapse ? 'none' : 'block')};
`;

interface StyledMarginDivProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const StyledMarginDiv = styled.div<StyledMarginDivProps>`
  margin-top: ${({ top }) => top};
  margin-right: ${({ right }) => right};
  margin-bottom: ${({ bottom }) => bottom};
  margin-left: ${({ left }) => left};
`;

export const StyledTopPanel = styled.div`
  padding: 1.5rem;
  min-width: 220px;
`;

export const StyledMaxContent = styled.div`
  max-width: max-content;
  margin-bottom: 1rem;
`;

export const StyledVerticalDivider = styled.div`
  border-left: 1px solid #e8e8e8;
`;

export const StyledIcon = styled.div`
  margin: auto;
  margin-top: 2rem;
`;

interface StyledDialogTitleProps {
  maxWidth?: string;
}

export const StyledDialogTitle = styled.div<StyledDialogTitleProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '340px')};
  text-align: center;
`;

export const ToolbarButtons = styled.div`
  & > * {
    margin: 0 1rem;
  }
`;
