import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { DataTable, Toolbar } from '@lib/ui-components';
import { CreateBdoReportButton } from './create-bdo-report-button';
import { BdoReportType } from '../../../../libs/api-interface/models/report/bdo-report-type.enum';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useWebSocketState } from '../../../../state/web-socket-state';
import { useUserState } from '../../../../state/UserState';
import { RolesEnum } from '../../../../domain/enums/roles.enum';
import { CreateClientAdminBdoReportButton } from '../../../client-admin/reports/create-client-admin-bdo-report-button';
import { dateTimeFormat } from '@lib/api-interface';
import { ReportRowOptionsButtons } from '../report-row-options-buttons';

export const BdoReports = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedReportType, setSelectedReportType] = useState(BdoReportType.KPO_REPORT);
  const webSocketState = useWebSocketState();
  const isClient = userState.roles.includes(RolesEnum.CLIENT_ADMIN) || userState.roles.includes(RolesEnum.CLIENT_USER);

  const createReportButton = React.useMemo(() => {
    if (isClient) {
      return <CreateClientAdminBdoReportButton reportType={selectedReportType} onSaved={async () => setRefreshKey((prevState) => prevState + 1)} />;
    }
    return <CreateBdoReportButton reportType={selectedReportType} onSaved={async () => setRefreshKey((prevState) => prevState + 1)} />;
  }, [selectedReportType]);

  React.useEffect(() => {
    setRefreshKey((prevState) => prevState + 1);
  }, [webSocketState.reportGeneratedRefreshKey, webSocketState.reportGenerationStartedRefreshKey]);

  return (
    <>
      <Toolbar
        left={[
          <FormControl variant='standard' fullWidth>
            <InputLabel>{t('type')}</InputLabel>
            <Select defaultValue={selectedReportType} onChange={(event) => setSelectedReportType(event.target.value as BdoReportType)}>
              {Object.values(BdoReportType)
                .filter((val) => {
                  if (isClient) {
                    return val === BdoReportType.KPO_REPORT;
                  }
                  return true;
                })
                .map((val) => (
                  <MenuItem key={`BdoReportType.${val}`} value={val}>
                    {t(`BdoReportType.${val}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>,
        ]}
        right={[createReportButton]}
      />

      <DataTable
        modelDef={{
          modelName: 'reportViews',
        }}
        fetchFilters={{ type: selectedReportType }}
        columns={[
          { label: t('reportFile'), key: 'fileName', type: 'text' },
          { label: t('creationDate'), key: 'creationDate', type: 'date', dateFormat: dateTimeFormat },
        ]}
        rowOptions={[
          {
            renderer: (row) => <ReportRowOptionsButtons {...row} />,
          },
        ]}
        initialSort={{ columnIndex: 1, ascending: false }}
        refreshKey={refreshKey}
      />
    </>
  );
};
