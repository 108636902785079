import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';

export interface FormInputDropdownOption {
  label: string;
  value: string;
}

export interface FormInputDropdownProps extends FormInputProps {
  options: FormInputDropdownOption[];
}

export const FormInputDropdown: React.FC<FormInputDropdownProps> = ({ name, control, label, options, variant, labelColor, isDeactivated, testId, onSelect }) => {
  const dropdownOptions = React.useMemo(
    () =>
      options.map((option: FormInputDropdownOption) => (
        <MenuItem key={option.value} value={option.value} data-test-id={`select-${name}-${option.value}`}>
          {option.label}
        </MenuItem>
      )),
    [options],
  );

  const getTestId = () => {
    return testId !== undefined ? `select-${testId}` : `select-${name}`;
  };

  return (
    <FormControl size={'small'} variant={variant != null ? variant : 'outlined'} style={{ marginTop: '0px', marginBottom: '32px', width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          onSelect && onSelect(value);
          return (
            <Select inputProps={{ 'data-test-id': getTestId() }} onChange={onChange} value={value} label={label} disabled={isDeactivated}>
              {dropdownOptions}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};
