export const RATE_PRECISION = 4;
export const RATE_MAX_VAL = 999999_9999;

export const MASS_PRECISION = 4;
export const MASS_MAX_VAL = 1000_000;
export const MASS_MIN_VAL = 0.0001;

export const QUANTITIES_PRECISION = 4;

export const VOLUME_PRECISION = 2;
export const VOLUME_MAX_VAL = 1000_00;
export const VOLUME_MIN_VAL = 0.01;

export const PLN_PRECISION = 2;
export const PLN_MAX_VAL = 999_999_999_999_99;
export const PICKUP_MASS_MAX_VAL = 24;

export const PICKUP_PRECISION = 2;
export const PAYMENT_PERIOD_PRECISION = 2;