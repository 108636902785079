import { DataTable, DataTableColumn, HStack, Panel, RenderIf, Snackbar, TableFilterItem, ToggleButtons, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ServicesToAccept = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [tableRefreshKey, setTableRefreshKey] = React.useState(0);
  const [successMessageShown, setSuccessMessageShown] = React.useState(false);
  const instanceFiltersRef: React.MutableRefObject<TableFilterItem[]> = React.useRef(new Array<TableFilterItem>());

  const columns = React.useMemo(() => {
    const columns: DataTableColumn[] = [
      { key: 'creationDate', label: t('reportDate'), type: 'date-time' },
      { key: 'service.projectBranch.companyBranchName', label: t('branch'), type: 'text' },
      { key: 'service.projectBranch.registrationAddress.town', label: t('branchCity'), type: 'text' },
      {
        key: 'service.projectWaste.wasteCode',
        label: t('wasteCode'),
        type: 'text',
        joinedColumns: [{ key: 'service.projectWaste.wasteDescription', label: '', type: 'text' }],
      },
      { key: 'service.collectionInfrastructureService.name', label: t('container'), type: 'text' },
      { key: 'service.collectionInfrastructureCount', label: t('containerAmount'), type: 'numeric' },
    ];
    if (activeIndex === 2) {
      columns.push({ key: 'active', label: t('serviceWaitingAcceptation'), type: 'boolean', align: 'center' });
    }
    return columns;
  }, [tableRefreshKey, t, activeIndex]);

  const fetchFilters = React.useMemo(() => {
    switch (activeIndex) {
      case 0:
        return { active: true };
      case 1:
        return { active: false };
      default:
        return {};
    }
  }, [activeIndex]);

  const successMessage = React.useMemo(() => {
    return (
      <RenderIf true={successMessageShown}>
        <Snackbar message={t('reportAccepted')} severity='success' onClose={() => setSuccessMessageShown(false)} />
      </RenderIf>
    );
  }, [successMessageShown]);

  return (
    <Panel header={t('servicesToAccept')} borderType='flat'>
      <HStack align='center'>
        <ToggleButtons
          labels={[t('open'), t('history'), t('all')]}
          activeIndex={activeIndex}
          onChange={(index) => {
            setActiveIndex(index);
            setTableRefreshKey(tableRefreshKey + 1);
          }}
        />
      </HStack>
      <DataTable
        excel={true}
        refreshKey={tableRefreshKey}
        fetchUrl={'api/services-to-accept'}
        fetchFilters={fetchFilters}
        defaultTableFilters={instanceFiltersRef.current}
        onDefaultTableFiltersUpdated={(filters) => {
          instanceFiltersRef.current = filters;
        }}
        columns={columns}
        initialSort={{ columnIndex: 0, ascending: false }}
        rowOptions={[
          {
            renderer: (row) => (
              <UsecaseButton
                useCaseName='AcceptServiceUseCase'
                groupName='service'
                hiddenValues={{ serviceId: row.serviceId }}
                variant={'contained'}
                color={'secondary'}
                buttonLabel={t('accept')}
                onSaved={async () => {
                  setTableRefreshKey(tableRefreshKey + 1);
                  setSuccessMessageShown(true);
                }}
                isDisabled={!row.active}
                forceConfirmation={true}
              />
            ),
          },
        ]}
      />
      {successMessage}
    </Panel>
  );
};
