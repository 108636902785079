import { useTranslation } from 'react-i18next';
import { Box, Button, FormHelperText, Paper, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthApiService } from '../../../services/auth.api.service';
import { FormInputText, Snackbar } from '../../../libs/ui-components';
import styled from 'styled-components';

interface IFormInput {
  login: string;
}

export const RestorePasswordPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [restorePasswordFailed, setRestorePasswordFailed] = React.useState(false);
  const [generalFailure, setGeneralFailure] = React.useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    login: Yup.string().required(t('LoginPage.login.required')),
  });

  const { handleSubmit, control } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    setRestorePasswordFailed(false);
    setGeneralFailure(false);
    try {
      const result = await AuthApiService.restorePassword({
        login: data.login,
      });
      if (result?.httpStatus) {
        setRestorePasswordFailed(true);
      } else {
        navigate('/login');
      }
    } catch (e) {
      setGeneralFailure(true);
    } finally {
      setLoading(false);
    }
  };

  const ResponsivePaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    height: '300px',
    width: '400px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    '@media (max-width:700px)': {
      top: '0',
      left: '0',
      transform: 'none',
      width: '100vw',
      height: '100vh',
      borderRadius: '0',
    },
  });

  return (
    <ResponsivePaper elevation={3}>
      <Typography component='h1' variant='h5' sx={{ marginBottom: '1rem' }}>
        {t('RestorePasswordPage.header')}
      </Typography>
      <Box
        component='form'
        noValidate
        sx={{ mt: 1, width: '330px' }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            handleSubmit(onSubmit)();
          }
        }}
      >
        <FormInputText name='login' control={control} label={t('login')} />

        <FormHelperText error={true} hidden={!restorePasswordFailed}>
          {t('RestorePasswordPage.restorePasswordFailed')}
        </FormHelperText>

        <LoadingButton onClick={handleSubmit(onSubmit)} variant={'contained'} fullWidth sx={{ mt: 3, mb: 2 }} loading={loading}>
          {t('RestorePasswordPage.button')}
        </LoadingButton>
        <NavLink to='/login'>
          <Button variant='text' size='small'>
            {t('RestorePassword.backToLogin')}
          </Button>
        </NavLink>
      </Box>
      <Snackbar open={generalFailure} message={t('Common.error')} severity='error' onClose={() => setGeneralFailure(false)} />
    </ResponsivePaper>
  );
};
