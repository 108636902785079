import * as React from 'react';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { Snackbar } from '../snackbar/snack-bar';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { RenderIf } from '../render-if';

type ButtonColor = 'success' | 'warning' | 'primary' | 'secondary' | undefined;

interface ButtonProps {
  label: string;
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  separated?: boolean;
  onLoaded: (file: any) => Promise<any> | void;
  confirmationMsg?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  tooltip?: string;
  register?: any;
  allowedExtensions?: string;
  dontChangeLabel?: boolean;
  uploadUrl?: string; // Todo
  disableErrorSnackbar?: boolean;
}

export const FileUploadButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [loading, setLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(props.label);
  const [failureMessage, setFailureMessage] = useStateIfMounted('');

  function mapToMUIColor(style: ButtonColor): 'success' | 'error' | 'primary' | 'secondary' | undefined {
    if (style === 'warning') {
      return 'error';
    }
    return style;
  }

  const handleFileUpload = async (file: File) => {
    if (!props.uploadUrl) {
      console.error('Upload URL not specified');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    await axios({
      method: 'post',
      data: formData,
      url: props.uploadUrl,
      responseType: 'json',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Unexpected response status');
        }
        const data = response.data;
        setLoading(false);
        props.onLoaded(data);
      })
      .catch((error) => {
        setLoading(false);
        setFailureMessage(errorKeys[error?.response?.data?.code] || t('settlementImportError'));
      });
  };

  const resetInput = (input: HTMLInputElement) => {
    if (input) {
      input.value = '';
    }
  };

  return (
    <>
      <LoadingButton
        variant={props.variant}
        color={mapToMUIColor(props.color)}
        sx={{ marginLeft: props.separated ? '3rem' : '0' }}
        loading={loading}
        size='small'
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        component='label'
      >
        {buttonLabel}

        <input
          type='file'
          hidden
          accept={props.allowedExtensions ? props.allowedExtensions : '*.*'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const input = e.target;
            const files = input.files;
            if (!files || files.length === 0) {
              return;
            }
            handleFileUpload(files[0]);
            resetInput(input);
            if (!props.dontChangeLabel) {
              setButtonLabel(files[0].name);
            }
          }}
        />
      </LoadingButton>
      <RenderIf false={props.disableErrorSnackbar}>
        <Snackbar message={failureMessage} severity="error" onClose={() => setFailureMessage('')} />
      </RenderIf>
    </>
  );
};
