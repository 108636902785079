import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Panel, RenderIf, Toolbar, UsecaseButton, YearFilterBar, YearFilterBarPropsValues } from '@lib/ui-components';
import { useParams } from 'react-router-dom';
import { getBdoRejectedCardsTableColumns } from './bdo-rejected-cards-table-columns';
import * as Yup from 'yup';
import { MASS_MAX_VAL, MASS_MIN_VAL, MASS_PRECISION } from '@lib/api-interface';
import axios from 'axios';

export const BdoRejectedCardsByProjectBranchPage = () => {
  const { t } = useTranslation();
  const pathParams = useParams();
  const projectId = pathParams['projectId']!.substring(1);
  const branchId = pathParams['branchId']!.substring(1);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [selectedYear, setSelectedYear] = React.useState('' + new Date().getFullYear());
  const [selectedKpoIds, setSelectedKpoIds] = React.useState(new Array<string>());
  const [localData, setLocalData] = React.useState<any[]>([]);

  function updateDataTable(filters: YearFilterBarPropsValues) {
    setSelectedYear(filters.date);
    setTableReloadKey(tableReloadKey + 1);
  }

  function updateSelectedKpos(rows: any[]) {
    const kpos = rows.map((item) => item.kpoId);
    setSelectedKpoIds(kpos);
  }

  const handleLocalDelete = (rowIds: string[]) => {
    setLocalData(prevData =>
      prevData.filter(row => !rowIds.includes(row.kpoId))
    );
    setTableReloadKey(tableReloadKey + 1);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/bdo/rejected-cards-by-branch/${projectId}/${branchId}/${selectedYear}`
        );
        setLocalData(response.data);
        setTableReloadKey(tableReloadKey + 1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedYear]);

  return (
    <Panel>
      <Panel borderType='raised'>
        <YearFilterBar onFilterChanged={(filters) => updateDataTable(filters)} initialYear={selectedYear} />
      </Panel>
      <Panel header={t('bdoRejectedCards')} borderType='raised'>
        <RenderIf true={selectedKpoIds.length > 0}>
          <Toolbar
            right={[
              <UsecaseButton
                groupName='bdo'
                useCaseName='WithdrawKpoCardUseCase'
                buttonLabel={t('withdraw')}
                dialogTitle={t('withdraw')}
                variant='contained'
                fields={[
                  {
                    label: t('reason'),
                    type: 'text',
                    field: 'reason',
                  },
                ]}
                validationSchema={{
                  reason: Yup.string().required(t('fieldRequired')),
                }}
                hiddenValues={{
                  kpoId: '-',
                  kpoIds: selectedKpoIds,
                }}
                onSaved={async () => {
                  handleLocalDelete(selectedKpoIds);
                }}
              />,
              <UsecaseButton
                groupName='bdo'
                useCaseName='ReviseKpoCardUseCase'
                buttonLabel={t('revise')}
                dialogTitle={t('revise')}
                variant='contained'
                fields={[
                  {
                    label: t('massMg'),
                    type: 'numeric',
                    field: 'mass',
                    numericPrecision: MASS_PRECISION,
                    numericMinValue: MASS_MIN_VAL,
                    numericMaxValue: MASS_MAX_VAL,
                  },
                  {
                    label: t('wasteCode'),
                    type: 'table-select-by-api',
                    field: 'wasteCodeId',
                    tableSelectByApiParams: {
                      apiPostEndpoint: '/api/organization/bdo/search/waste',
                      dialogTitle: t('wasteCode'),
                      displayFormat: '{code}',
                      selectedValueField: 'wasteCodeId',
                      columns: [
                        { label: t('wasteCode'), key: 'code', type: 'text' },
                        { label: t('wasteDescription'), key: 'description', type: 'text' },
                        {
                          label: t('Model.Waste.hazardous'),
                          key: 'hazardous',
                          type: 'boolean',
                        },
                      ],
                      queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
                      otherValuesMap: {
                        code: 'wasteCode',
                      },
                    },
                  },
                ]}
                validationSchema={{
                  mass: Yup.string().required(t('fieldRequired')),
                }}
                hiddenValues={{
                  kpoId: '-',
                  kpoIds: selectedKpoIds,
                }}
                onSaved={async () => {
                  handleLocalDelete(selectedKpoIds);
                }}
              />,
            ]}
          />
        </RenderIf>
        <DataTable
          data={localData}
          columns={getBdoRejectedCardsTableColumns(t)}
          selectable={true}
          selectableOptions={{
            comparatorField: 'cardNumber',
            onSelectedRowsChange: (rows) => updateSelectedKpos(rows),
          }}
          refreshKey={tableReloadKey}
          rowOptions={[
            {
              renderer: (row: any) => (
                <UsecaseButton
                  key={row.id}
                  groupName='bdo'
                  useCaseName='WithdrawKpoCardUseCase'
                  buttonLabel={t('withdraw')}
                  dialogTitle={t('withdraw')}
                  fields={[
                    {
                      label: t('reason'),
                      type: 'text',
                      field: 'reason',
                    },
                  ]}
                  validationSchema={{
                    reason: Yup.string().required(t('fieldRequired')),
                  }}
                  hiddenValues={{
                    kpoId: row.kpoId,
                  }}
                  onSaved={async () => {
                    handleLocalDelete([row.kpoId]);
                  }}
                />
              ),
            },
            {
              renderer: (row: any) => (
                <UsecaseButton
                  key={row.id}
                  groupName='bdo'
                  useCaseName='ReviseKpoCardUseCase'
                  buttonLabel={t('revise')}
                  dialogTitle={t('revise')}
                  fields={[
                    {
                      label: t('massMg'),
                      type: 'numeric',
                      field: 'mass',
                      numericPrecision: MASS_PRECISION,
                      numericMinValue: MASS_MIN_VAL,
                      numericMaxValue: MASS_MAX_VAL,
                    },
                    {
                      label: t('wasteCode'),
                      type: 'table-select-by-api',
                      field: 'wasteCodeId',
                      tableSelectByApiParams: {
                        apiPostEndpoint: '/api/organization/bdo/search/waste',
                        dialogTitle: t('wasteCode'),
                        displayFormat: '{code}',
                        selectedValueField: 'wasteCodeId',
                        columns: [
                          { label: t('wasteCode'), key: 'code', type: 'text' },
                          { label: t('wasteDescription'), key: 'description', type: 'text' },
                          {
                            label: t('Model.Waste.hazardous'),
                            key: 'hazardous',
                            type: 'boolean',
                          },
                        ],
                        queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
                        otherValuesMap: {
                          code: 'wasteCode',
                        },
                      },
                    },
                  ]}
                  validationSchema={{
                    mass: Yup.string().required(t('fieldRequired')),
                  }}
                  hiddenValues={{
                    kpoId: row.kpoId,
                  }}
                  onSaved={async () => {
                    handleLocalDelete([row.kpoId]);
                  }}
                />
              ),
            },
          ]}
        />
      </Panel>
    </Panel>
  );
};
