export interface PageInfo {
    size: number,
    totalElements: number,
    totalPages: number,
    number: number,
    hasNextPage: boolean
}

export class DataResultsPagedDto<ModelType> {
    failed: boolean;
    errorStatusCode: number | undefined;
    errorMessage: string | number | undefined;
    error: string | number | undefined;
    response: ModelType | undefined;
    page: PageInfo | undefined;

    constructor(failed: boolean, errorStatusCode: number | undefined, error: string | undefined, errorMessage: string | undefined, response: ModelType | undefined, page: PageInfo | undefined) {
        this.failed = failed;
        this.errorStatusCode = errorStatusCode;
        this.errorMessage = errorMessage;
        this.error = error;
        this.response = response;
        this.page = page;
    }
}
