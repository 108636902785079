import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxLabel,
  CheckboxPanel,
  DeleteOnAggregateButton,
  EditModelButton,
  GridLabel,
  HStack,
  Panel,
  RenderIf,
  Snackbar,
  TabPage,
  Tabs,
  TextLabel,
  ToggleButtons,
  UsecaseButton,
} from '@lib/ui-components';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyBranches } from './shared/company-branches/company-branches';
import { ClientInfrastructure } from './client-view/client-infrastructure';
import CompanyAgreements from './shared/company-agreements';
import { ClientData } from './client-view/client-data/client-data';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { ContractorData } from './contractor-view/contractor-data/contractor-data';
import { ContractorClientServices } from './contractor-view/contractor-client-services';
import { ContractorQuantityReceived } from './contractor-view/contractor-quantity-received';
import { ContractorDecisionsConclusions } from './contractor-view/contractor-decisions-conclusions/contractor-decisions-conclusions';
import { ContractorRdr } from './contractor-view/contractor-rdr';
import { ClientTabsWrapper } from './tabs-wrapper/client-tabs-wrapper';
import { ContractorTabsWrapper } from './tabs-wrapper/contractor-tabs-wrapper';
import { useQueryClient } from '@tanstack/react-query';
import { DataResultsDto, RestUsecaseClientService } from '@lib/common-sdk';
import { useCanDeleteCompany, useCompanyView } from '@data-source-hooks';
import { LoadingIndicator } from '../../../../libs/ui-components/components/loading-indicator';
import { FetchErrorIndicator } from '../../../../libs/ui-components/components/fetch-error-indicator';
import { DateTimeService } from '../../../../services/date-time.service';
import { useStateIfMounted } from 'use-state-if-mounted';

export const CompanyView = () => {
  const { t } = useTranslation();
  const pathParams = useParams();
  const [tabPages, setTabPages] = React.useState<TabPage[]>([]);
  const [activePage, setActivePage] = React.useState(0);
  const queryClient = useQueryClient();
  const [companyQuery] = useCompanyView(pathParams['companyId']!.substring(1));
  const [canDeleteCompanyQuery] = useCanDeleteCompany(pathParams['companyId']!.substring(1));
  const navigate = useNavigate();
  const [failureMessage, setFailureMessage] = useStateIfMounted('');

  function isOnlyClient(model: CompanyViewModel) {
    return model?.roleClient === true && model?.roleContractor === false;
  }

  function isOnlyContractor(model: CompanyViewModel) {
    return model?.roleClient === false && model?.roleContractor === true;
  }

  function isBothClientAndContractor(model: CompanyViewModel) {
    return model?.roleClient === true && model?.roleContractor === true;
  }

  React.useEffect(() => {
    if (companyQuery.isSuccess && canDeleteCompanyQuery.isSuccess) {
      const tabs: TabPage[] = [];
      if (isOnlyClient(companyQuery.data)) {
        tabs.push({
          label: t('Companies.CompanyView.companyClientInfo'),
          component: <ClientData company={companyQuery.data} />,
        });
        tabs.push({
          label: t('branches'),
          component: <CompanyBranches company={companyQuery.data} onCentralChanged={() => companyQuery.refetch()} />,
        });
        tabs.push({
          label: t('Companies.CompanyView.infrastructure'),
          component: <ClientInfrastructure />,
        });
        tabs.push({
          label: t('agreements'),
          component: <CompanyAgreements company={company} role={CompanyRole.CLIENT} />,
        });
      } else if (isOnlyContractor(companyQuery.data)) {
        tabs.push({
          label: t('Companies.CompanyView.companySubcontractorInfo'),
          component: <ContractorData company={companyQuery.data} />,
        });
        tabs.push({ label: t('clientsServices'), component: <ContractorClientServices company={companyQuery.data} /> });
        tabs.push({
          label: t('branches'),
          component: <CompanyBranches company={companyQuery.data} onCentralChanged={() => companyQuery.refetch()} />,
        });
        tabs.push({ label: t('quantityReceived'), component: <ContractorQuantityReceived /> });
        tabs.push({
          label: t('agreements'),
          component: <CompanyAgreements company={company} role={CompanyRole.SUBCONTRACTOR} />,
        });
        tabs.push({
          label: t('decisionsConclusions'),
          component: <ContractorDecisionsConclusions company={company} />,
        });
        tabs.push({ label: t('rdr'), component: <ContractorRdr company={company} /> });
      }
      setTabPages(tabs);
    }
  }, [companyQuery.data, companyQuery.isLoading, canDeleteCompanyQuery.isLoading, t]);

  if (companyQuery.isLoading || canDeleteCompanyQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (companyQuery.error) {
    return <FetchErrorIndicator error={companyQuery.error} />;
  }

  if (canDeleteCompanyQuery.error) {
    return <FetchErrorIndicator error={canDeleteCompanyQuery.error} />;
  }

  const company: CompanyViewModel = companyQuery.data!;

  return (
    <>
      <Panel
        borderType='raised'
        header={company.name}
        direction={'row'}
        rightItem={
          <HStack>
            <EditModelButton
              id={company.id ?? 0}
              entity={company}
              modelName={'companies'}
              dialogTitle={t('companyData').toUpperCase()}
              fields={[
                {
                  rowItems: [
                    { field: 'name', label: t('name'), type: 'text' },
                    { field: 'shortName', label: t('companyShortName'), type: 'text' },
                  ],
                },
                {
                  rowItems: [
                    { field: 'nip', label: t('nip'), type: 'text' },
                    { field: 'krs', label: t('krs'), type: 'text' },
                    { field: 'buyerId', label: t('buyerNumber'), type: 'text' },
                  ],
                },
                {
                  rowItems: [
                    { field: 'roleClient', label: t('client'), type: 'boolean' },
                    { field: 'roleContractor', label: t('subcontractor'), type: 'boolean' },
                  ],
                },
              ]}
              additionalFormButtons={[
                <UsecaseButton
                  useCaseName='UpdateCompanyArchivedStatusUseCase'
                  groupName='company'
                  hiddenValues={{ companyId: company.companyId, archived: company.active }}
                  onError={setFailureMessage}
                  onSaved={async () => await queryClient.invalidateQueries(['companyViews', company.companyId])}
                  forceConfirmation={true}
                  buttonLabel={t(company.active ? 'deactivate' : 'reactivate')}
                  color='warning'
                />,
              ]}
              hiddenValues={{ companyId: company.companyId }}
              overrideSaveCall={async (payload: any) => {
                try {
                  await RestUsecaseClientService.post('company', 'update-company-info', payload);
                  return new DataResultsDto(false, 200, undefined, undefined, undefined);
                } catch (error: any) {
                  return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                }
              }}
              onSaved={async () => {
                await queryClient.invalidateQueries(['companyViews', company.companyId]);
              }}
            />
            <RenderIf true={canDeleteCompanyQuery.data === true}>
              <DeleteOnAggregateButton
                aggregateId={company.companyId!}
                aggregateName='CompanyAggregate'
                commandName='deleteCompany'
                payload={{}}
                onDeleted={() => navigate('/companies')}
              />
            </RenderIf>
          </HStack>
        }
      >
        <GridLabel placeItems='end'>
          <GridLabel gridTemplateColumns='repeat(5, 1fr)'>
            <TextLabel label={t('companyShortName')} value={company.shortName} />
            <TextLabel label={t('nip')} value={company.nip} />
            <TextLabel label={t('krs')} value={company.krs} />
            <TextLabel label={t('bdo')} value={company.bdoIdentificationNumber} />
            <TextLabel label={t('buyerNumber')} value={company.buyerId} />
          </GridLabel>

          <Panel direction={'row'}>
            <CheckboxPanel>
              {/* <CheckboxLabel label={t('Model.Company.groupOfCompanies')} value={} /> */}
              <CheckboxLabel label={t('client')} value={company.roleClient} />
              <CheckboxLabel label={t('subcontractor')} value={company.roleContractor} />
            </CheckboxPanel>
          </Panel>
        </GridLabel>
      </Panel>
      {isBothClientAndContractor(company) && (
        <>
          <Panel contentMarginBottom='2rem' contentMarginTop='2rem'>
            <HStack align='center'>
              <ToggleButtons labels={[t('client'), t('subcontractor')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
            </HStack>
          </Panel>
          {activePage === 0 && (
            <ClientTabsWrapper
              company={company}
              onCentralBranchChanged={() => {
                companyQuery.refetch();
              }}
            />
          )}
          {activePage === 1 && (
            <ContractorTabsWrapper
              company={company}
              onCentralBranchChanged={() => {
                companyQuery.refetch();
              }}
            />
          )}
        </>
      )}
      <Tabs marginTop='1rem' panels={tabPages} borderType='none' />
      {company.lastModifiedBy != null && (
        <TextLabel value={`${t('lastModified')}: ${company.lastModifiedBy}  [${DateTimeService.isoStringDateToDateTime(company.lastModifiedDate)}]`} />
      )}
      <RenderIf true={failureMessage !== undefined && failureMessage !== ''}>
        <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
      </RenderIf>
    </>
  );
};
