import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddModelButton, DataTable, DeleteModelButton, EditModelButton, Header, Toolbar } from '@lib/ui-components';

export const SequencesPage = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const rightButtons: JSX.Element[] = [
    <AddModelButton
      modelName='sequenceMasks'
      buttonLabel={t('addSequence')}
      dialogTitle={t('addSequence')}
      fields={[
        { field: 'name', label: t('name'), type: 'text' },
        { field: 'mask', label: t('mask'), type: 'text', helperText: t('mask.help') },
      ]}
      validationSchema={{
        name: Yup.string().required(t('requiredField')),
        mask: Yup.string().required(t('requiredField')),
      }}
      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
    />,
  ];

  return (
    <>
      <Header label={t('sequences')} />
      <Toolbar right={rightButtons} />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'sequenceMasks' }}
        fetchFilters={{ archived: false }}
        columns={[
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'mask',
            label: t('mask'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                modelName='sequenceMasks'
                buttonLabel={t('edit')}
                dialogTitle={t('editSequence')}
                fields={[{ field: 'name', label: t('name'), type: 'text' }]}
                validationSchema={{
                  name: Yup.string().required(t('requiredField')),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                yellowIcon
              />
            ),
          },
          {
            isVisible: (row) => row['used'] === false,
            renderer: (row) => <DeleteModelButton id={row['id']} modelName='sequenceMasks' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </>
  );
};
