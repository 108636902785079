import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddModelButton, DataTable, DataTableRowOptionItem, DeleteModelButton, EditModelButton, Header, Toolbar } from '@lib/ui-components';
import * as Yup from 'yup';

export const BdoApiPage = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const rowOptions: DataTableRowOptionItem[] = [
    {
      renderer: (row) => (
        <EditModelButton
          id={row['id']}
          entity={row}
          modelName='organizationBdoApis'
          buttonLabel={t('edit')}
          dialogTitle={t('Button.OrganizationBdoApiModel.edit.dialog.title')}
          fields={[
            { field: 'clientId', label: t('Model.BdoKey.clientId'), type: 'text' },
            { field: 'clientSecret', label: t('Model.BdoKey.clientSecret'), type: 'text' },
          ]}
          validationSchema={{
            clientId: Yup.string().required(t('Model.BdoKey.clientId.required')),
            clientSecret: Yup.string().required(t('Model.BdoKey.clientSecret.required')),
          }}
          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          postErrorMappings={{
            '400': t('Error.OrganizationBdoApi.notValid'),
          }}
          yellowIcon
        />
      ),
    },
    {
      renderer: (row) => (
        <DeleteModelButton
          id={row['id']}
          modelName='organizationBdoApis'
          tooltip={t('GlobalSettings.bdoApi.tooltip.delete')}
          onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
        />
      ),
    },
  ];

  const rightButtons: JSX.Element[] = [
    <AddModelButton
      modelName='organizationBdoApis'
      buttonLabel={t('Button.OrganizationBdoApiModel.add')}
      dialogTitle={t('Button.OrganizationBdoApiModel.add.dialog.title')}
      fields={[
        { field: 'clientId', label: t('Model.BdoKey.clientId'), type: 'text' },
        { field: 'clientSecret', label: t('Model.BdoKey.clientSecret'), type: 'text' },
      ]}
      validationSchema={{
        clientId: Yup.string().required(t('Model.BdoKey.clientId.required')),
        clientSecret: Yup.string().required(t('Model.BdoKey.clientSecret.required')),
      }}
      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
      postErrorMappings={{
        '400': t('Error.OrganizationBdoApi.notValid'),
      }}
    />,
  ];

  return (
    <>
      <Header label={t('GlobalSettings.bdoApi.header')} />
      <Toolbar right={rightButtons} />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'organizationBdoApis' }}
        columns={[
          {
            key: 'clientId',
            label: t('Model.BdoKey.clientId'),
            type: 'text',
          },
          {
            key: 'clientSecret',
            label: t('Model.BdoKey.clientSecret'),
            type: 'text',
          },
        ]}
        rowOptions={rowOptions}
      />
    </>
  );
};
