import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditModelButton, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';
import { TitlePositioner } from '../../../../../components/Shared';
import { useQueryClient } from '@tanstack/react-query';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import * as Yup from 'yup';

export interface CompanyAddressInfoProps {
  company: CompanyViewModel | undefined;
}

function isCorrespondenceAddressVisible(data: any): boolean {
  return data['correspondenceAddressEqRegistrationAddress'] !== true;
}

export const CompanyAddressInfo = (props: CompanyAddressInfoProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Panel direction='row' borderType='raised'>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('Companies.CompanyView.CompanyInfo.companyDataHeader').toUpperCase()} />
        </TitlePositioner>
        <Panel borderType='flat'>
          <GridLabel gridTemplateColumns='8fr 1fr'>
            <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
              <TextLabel label={t('street')} value={props.company?.registrationAddress?.street} direction={'column'} />
              <TextLabel label={t('houseNum')} value={props.company?.registrationAddress?.houseNum} direction={'column'} />
              <TextLabel label={t('apartmentNum')} value={props.company?.registrationAddress?.apartmentNum} direction={'column'} />
              <TextLabel label={t('locality')} value={props.company?.registrationAddress?.town} direction={'column'} />
              <TextLabel label={t('postcode')} value={props.company?.registrationAddress?.postalCode} direction={'column'} />
              <TextLabel label={t('commune')} value={props.company?.registrationAddress?.commune} direction={'column'} />
              <TextLabel label={t('province')} value={props.company?.registrationAddress?.province} direction={'column'} />
              <TextLabel label={t('country')} value={props.company?.registrationAddress?.country} direction={'column'} />
            </GridLabel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
              <EditModelButton
                id={props.company!.id!}
                testId={'registration-address'}
                entity={props.company?.registrationAddress}
                modelName={'companies'}
                dialogTitle={t('Companies.CompanyView.CompanyInfo.companyDataHeader').toUpperCase()}
                fields={[
                  {
                    rowItems: [
                      { field: 'street', label: t('street'), type: 'text' },
                      { field: 'houseNum', label: t('houseNum'), type: 'text', width: '20rem' },
                      { field: 'apartmentNum', label: t('apartmentNum'), type: 'text', width: '20rem' },
                    ],
                  },
                  {
                    rowItems: [
                      { field: 'town', label: t('locality'), type: 'text', width: '15rem' },
                      { field: 'postalCode', label: t('postcode'), type: 'text', width: '21.5rem' },
                    ],
                  },
                  {
                    rowItems: [
                      {
                        label: t('commune'),
                        type: 'table-select',
                        field: 'communeId',
                        defaultValue: props.company?.registrationAddress?.communeId,
                        tableSelectParams: {
                          modelName: 'communes',
                          dialogTitle: t('communes'),
                          displayFormat: '{name}',
                          columns: [
                            { key: 'name', label: t('commune'), type: 'text' },
                            { key: 'province', label: t('province'), type: 'text' },
                          ],
                          otherValuesMap: {
                            name: 'commune',
                            province: 'province',
                          },
                        },
                      },
                      { field: 'country', label: t('country'), type: 'text' },
                    ],
                  },
                ]}
                validationSchema={{
                  street: Yup.string().required(t('requiredField')),
                  postalCode: Yup.string().required(t('requiredField')),
                  houseNum: Yup.string().required(t('requiredField')),
                  town: Yup.string().required(t('requiredField')),
                  communeId: Yup.string().required(t('requiredField')),
                }}
                onBeforeSave={(formData) => {
                  formData.companyId = props.company?.companyId;
                  formData.registrationAddress = true;
                  const communeIdParts = formData.communeId.split('/');
                  formData.communeId = communeIdParts[communeIdParts.length - 1];
                  return formData;
                }}
                overrideSaveCall={async (payload: any) => {
                  try {
                    await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'updateCompanyAddress', payload.companyId, payload);
                    return new DataResultsDto(false, 200, undefined, undefined, undefined);
                  } catch (error: any) {
                    return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                  }
                }}
                onSaved={async (result) => {
                  await queryClient.invalidateQueries(['companyViews', props.company!.companyId]);
                }}
              />
            </GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('correspondenceAddress').toUpperCase()} />
        </TitlePositioner>
        <Panel borderType='flat'>
          <GridLabel gridTemplateColumns='8fr 1fr'>
            <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
              <TextLabel label={t('street')} value={props.company?.correspondenceAddress?.street} direction={'column'} />
              <TextLabel label={t('houseNum')} value={props.company?.correspondenceAddress?.houseNum} direction={'column'} />
              <TextLabel label={t('apartmentNum')} value={props.company?.correspondenceAddress?.apartmentNum} direction={'column'} />
              <TextLabel label={t('locality')} value={props.company?.correspondenceAddress?.town} direction={'column'} />
              <TextLabel label={t('postcode')} value={props.company?.correspondenceAddress?.postalCode} direction={'column'} />
              <TextLabel label={t('commune')} value={props.company?.correspondenceAddress?.commune} direction={'column'} />
              <TextLabel label={t('province')} value={props.company?.correspondenceAddress?.province} direction={'column'} />
              <TextLabel label={t('country')} value={props.company?.correspondenceAddress?.country} direction={'column'} />
            </GridLabel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
              <EditModelButton
                id={props.company!.id!}
                testId={'correspondence-address'}
                entity={props.company?.correspondenceAddress}
                modelName={'companies'}
                dialogTitle={t('correspondenceAddress').toUpperCase()}
                fields={[
                  {
                    label: t('correspondenceAddressEqRegistrationAddress'),
                    type: 'boolean',
                    field: 'correspondenceAddressEqRegistrationAddress',
                    defaultValue: false,
                  },
                  {
                    rowItems: [
                      { field: 'street', label: t('street'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data) },
                      { field: 'houseNum', label: t('houseNum'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data), width: '20rem' },
                      { field: 'apartmentNum', label: t('apartmentNum'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data), width: '20rem' },
                    ],
                  },
                  {
                    rowItems: [
                      { field: 'town', label: t('locality'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data), width: '15rem' },
                      { field: 'postalCode', label: t('postcode'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data), width: '21.5rem' },
                    ],
                  },
                  {
                    rowItems: [
                      {
                        label: t('commune'),
                        type: 'table-select',
                        field: 'communeId',
                        defaultValue: props.company?.correspondenceAddress?.communeId,
                        isVisible: (data) => isCorrespondenceAddressVisible(data),
                        tableSelectParams: {
                          modelName: 'communes',
                          dialogTitle: t('communes'),
                          displayFormat: '{name}',
                          columns: [
                            { key: 'name', label: t('commune'), type: 'text' },
                            { key: 'province', label: t('province'), type: 'text' },
                          ],
                          otherValuesMap: {
                            name: 'commune',
                            province: 'province',
                          },
                        },
                      },
                      { field: 'country', label: t('country'), type: 'text', isVisible: (data) => isCorrespondenceAddressVisible(data) },
                    ],
                  },
                ]}
                validationSchema={{
                  street: Yup.string().required(t('requiredField')),
                  postalCode: Yup.string().required(t('requiredField')),
                  houseNum: Yup.string().required(t('requiredField')),
                  town: Yup.string().required(t('requiredField')),
                  communeId: Yup.string().required(t('requiredField')),
                }}
                onBeforeSave={(formData) => {
                  formData.companyId = props.company?.companyId;
                  formData.registrationAddress = false;
                  const communeIdParts = formData.communeId.split('/');
                  formData.communeId = communeIdParts[communeIdParts.length - 1];
                  if (formData.correspondenceAddressEqRegistrationAddress === true) {
                    formData.street = props.company!.registrationAddress!.street;
                    formData.town = props.company!.registrationAddress!.town;

                    formData.commune = props.company!.registrationAddress!.commune;
                    formData.communeId = props.company!.registrationAddress!.communeId;

                    formData.province = props.company!.registrationAddress!.province;
                    formData.country = props.company!.registrationAddress!.country;

                    formData.postalCode = props.company!.registrationAddress!.postalCode;
                    formData.houseNum = props.company!.registrationAddress!.houseNum;
                    formData.apartmentNum = props.company!.registrationAddress!.apartmentNum;
                  }
                  return formData;
                }}
                overrideSaveCall={async (payload: any) => {
                  try {
                    await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'updateCompanyAddress', payload.companyId, payload);
                    return new DataResultsDto(false, 200, undefined, undefined, undefined);
                  } catch (error: any) {
                    return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                  }
                }}
                onSaved={async (result) => {
                  await queryClient.invalidateQueries(['companyViews', props.company!.companyId]);
                }}
              />
            </GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
    </Panel>
  );
};
