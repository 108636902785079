import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePositioner } from '@shared/general';
import { AddIcon, AggregateCommandButton, DataTable, DeleteOnAggregateButton, Panel, TextLabel, Toolbar } from '@lib/ui-components';

export interface BdoApiTabProps {
  projectId: string;
}

export default function BdoApiTab({ projectId }: BdoApiTabProps) {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <>
      <Panel borderType='none' direction={'row'} justifyContent='space-between' gap={'3rem'}>
        <TitlePositioner>
          <TextLabel label={t('Projects.ViewProject.Bdo.api.ids')} />
        </TitlePositioner>
        <Toolbar
          right={[
            <AggregateCommandButton
              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              aggregateName='ProjectAggregate'
              aggregateId={projectId}
              commandName='addBdoKeyToProject'
              buttonLabel={t('add')}
              startIcon={<AddIcon />}
              fields={[
                {
                  label: t('bdoApiKey'),
                  field: 'companyBdoKeyId',
                  type: 'table-select-by-api',
                  tableSelectByApiParams: {
                    apiPostEndpoint: '/api/project/bdo/assignable-keys',
                    apiPostParams: { projectId: projectId },
                    columns: [
                      { label: t('companyName'), key: 'companyName', type: 'text' },
                      { label: t('clientId'), key: 'clientId', type: 'text' },
                      { label: t('description'), key: 'description', type: 'text' },
                      { label: t('forKpoInsert'), key: 'forKpoInsert', type: 'boolean' },
                      { label: t('forKpoUpdate'), key: 'forKpoUpdate', type: 'boolean' },
                      { label: t('forKeo'), key: 'forKeo', type: 'boolean' },
                    ],
                    dialogTitle: t('bdoApiKey'),
                    displayFormat: '{clientId}',
                    selectedValueField: 'companyBdoKeyId',
                  },
                },
              ]}
            />,
          ]}
        />
      </Panel>
      <DataTable
        refreshKey={tableReloadKey}
        fetchUrl={'/api/project-bdo-keys'}
        fetchFilters={{ projectId: projectId }}
        columns={[
          {
            key: 'companyName',
            label: t('Projects.ViewProject.Bdo.api.headquartersName'),
            type: 'text',
          },
          {
            key: 'nip',
            label: t('nip'),
            type: 'text',
          },
          {
            key: 'clientId',
            label: t('clientId'),
            type: 'text',
          },
          {
            key: 'clientSecret',
            label: t('clientSecret'),
            type: 'text',
          },
          {
            key: 'description',
            label: t('description'),
            type: 'text',
          },
          { key: 'forKpoInsert', label: t('forKpoInsert'), type: 'boolean' },
          { key: 'forKpoUpdate', label: t('forKpoUpdate'), type: 'boolean' },
          { key: 'forKeo', label: t('forKeo'), type: 'boolean' },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <DeleteOnAggregateButton
                aggregateName='ProjectAggregate'
                aggregateId={projectId}
                commandName='removeBdoKeyFromProject'
                payload={{
                  companyBdoKeyId: row.companyBdoKeyId,
                }}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </>
  );
}
