import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import axios from 'axios';
import { RenderIf } from '@lib/ui-components';
import { LoadingIndicator } from '../../../libs/ui-components/components/loading-indicator';
import { FormInputFile } from '../../../libs/ui-components/components/form/form-input-file';
import { useForm } from 'react-hook-form';
import { StyledDialogTitle } from '@shared/general';
import CloseIcon from '@mui/icons-material/Close';

interface IFormInput {
  file: File;
}

interface ExcelImportDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
  onError: (error: Error | unknown) => void;
}

export const ExcelImportDialog = (props: ExcelImportDialogProps) => {
  const { t } = useTranslation();
  const [file, setFile] = React.useState<File | null>(null);
  const [loaderShown, setLoaderShown] = React.useState(false);

  function onClose(success = false) {
    setFile(null);
    props.onClose(success);
  }

  const handleImport = async () => {
    setLoaderShown(true);
    if (!file) {
      setLoaderShown(false);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        data: formData,
        url: '/api/subcontractor/settlement/import',
        responseType: 'json',
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Unexpected response status');
          }
        })
        .catch((error) => {
          props.onError(error);
        });
      onClose(true);
    } catch (e) {
      props.onError(e);
    }
    setLoaderShown(false);
  };

  const { handleSubmit, control } = useForm<IFormInput>({
    defaultValues: {
      file: undefined,
    },
  });

  return (
    <>
      <Dialog open={props.open} onClose={() => onClose()} maxWidth={'md'} fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          <StyledDialogTitle>{t('uploadNewImportFile')}</StyledDialogTitle>
          <IconButton
            aria-label='close'
            data-test-id={'dialog-close-btn'}
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RenderIf true={loaderShown}>
            <LoadingIndicator />
          </RenderIf>
          <RenderIf false={loaderShown}>
            <FormInputFile name={'attachments'} control={control} label={t('dropOrSelectAttachment')} onSelect={(value) => setFile(value)} />
          </RenderIf>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImport} color='secondary' variant={'contained'}>
            {t('importFromExcel')}
          </Button>
        </DialogActions>
      </Dialog>
      {loaderShown}
    </>
  );
};
