import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { ClientData } from '../client-view/client-data/client-data';
import { CompanyBranches } from '../shared/company-branches/company-branches';
import { ClientInfrastructure } from '../client-view/client-infrastructure';
import CompanyAgreements from '../shared/company-agreements';
import { ClientFiles } from '../client-view/client-files/client-files';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';

export interface ClientTabsWrapperProps {
  company: CompanyViewModel;
  onCentralBranchChanged?: () => void;
}

export const ClientTabsWrapper = (props: ClientTabsWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        panels={[
          {
            label: t('Companies.CompanyView.companyClientInfo'),
            component: <ClientData company={props.company} />,
          },
          {
            label: t('branches'),
            component: (
              <CompanyBranches
                company={props.company}
                onCentralChanged={() => {
                  props?.onCentralBranchChanged && props.onCentralBranchChanged();
                }}
              />
            ),
          },
          { label: t('Companies.CompanyView.infrastructure'), component: <ClientInfrastructure /> },
          { label: t('agreements'), component: <CompanyAgreements company={props.company} role={CompanyRole.CLIENT} /> },
          { label: t('files'), component: <ClientFiles company={props.company} /> },
        ]}
      />
    </div>
  );
};
