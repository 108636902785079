import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import React from 'react';
import { Stack } from '@mui/material';
import { Panel, RenderIf, TextLabel } from '@lib/ui-components';
import { StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { ServiceBar } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import {
  priceItem,
  scheduleServiceBar,
} from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { ServiceStatusLabel } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-status-label';
import { ProjectBranchInfrastructureServiceViewModel } from '@lib/api-interface';

export interface ServiceTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
  showButtons?: boolean;
}

export const ServiceTable = (props: ServiceTableProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);

  const service = props.serviceViewDetails;
  const projectBranchInfrastructureServiceViewModel = new ProjectBranchInfrastructureServiceViewModel();
  Object.assign(projectBranchInfrastructureServiceViewModel, service.applicableCollectionInfrastructureService);

  return (
    <StyledTableBorder data-service-id={service.serviceId}>
      <Panel justifyContent='space-between' direction='row'>
        <ServiceStatusLabel active={service.active && service.acceptanceState !== 'INITIALIZED'} acceptanceState={service.acceptanceState} started={service.started} />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[
            { label: t('wasteCode') },
            { label: t('Model.Waste.type') },
            { label: t('ownDescriptionOfWaste') },
            { label: t('resourceName') },
            { label: t('number') },
            { label: t('collectionMethod') },
            { label: t('numberOfContainers') },
            { label: t('estimatedMass') },
            { label: t('activationDate') },
            { label: t('deactivationDate') },
          ]}
          items={[
            { value: service.projectWaste.wasteCode, options: { bold: true } },
            { value: service.projectWaste.wasteDescription, options: { bold: true } },
            { value: service.projectWaste.projectWasteDescription },
            {
              value: service.resourceInBulk ? t('Model.Infrastructure.InfrastructureCategory.IN_BULK') : service.projectInfrastructureService.name,
              options: { bold: true },
            },
            { value: service.resourceInBulk ? '-' : service.infrastructureCount, options: { bold: true } },
            { value: service.collectionInfrastructureService?.name, options: { bold: true } },
            { value: service.collectionInfrastructureCount, options: { bold: true } },
            { value: service.projectInfrastructureService?.estimatedMass, options: { bold: true } },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('settledSubcontractorName') },
            ...(service.serviceType === 'PER_REQUEST' ? [{ label: t('reactionTime') }] : []),
            { label: t('collectionPrice') },
            { label: t('transportPrice') },
          ]}
          items={[
            {
              value: service.currentSettings?.organizationAsSide ? 'Interzero Polska' : service.subcontractor,
              options: { bold: true },
            },
            ...(service.serviceType === 'PER_REQUEST' ? [{ value: service.reactionTime }] : []),
            service.currentSettings?.pix
              ? {
                  value: t('pix'),
                  options: { bold: true },
                }
              : priceItem(service.currentSettings?.clientCollectionPrice, {
                  priceTypeVisible: true,
                  translations,
                  reverseColors: true,
                }),
            priceItem(service.currentSettings?.clientTransportPrice, {
              priceTypeVisible: true,
              translations,
              reverseColors: true,
            }),
          ]}
        />
        {service.serviceType === 'SCHEDULED' && scheduleServiceBar(t, service, false)}
        <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6}>
          <RenderIf true={service.acceptanceState === 'ACCEPTED'}>
            <TextLabel value={`${t('acceptanceDate')}: ${service.acceptanceDate}`} />
          </RenderIf>
        </Stack>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
};
