import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { LoadingIndicator } from './loading-indicator';
import { FetchErrorIndicator } from './fetch-error-indicator';

export interface QueryResultRendererProps<T> {
  query: UseQueryResult<T>;
  render: (axiosResponse: T) => JSX.Element;
}

export const QueryResultRenderer = <T,>(props: QueryResultRendererProps<T>) => {
  const query = props.query;

  if (query.isLoading) {
    return <LoadingIndicator />;
  }

  if (query.error) {
    return <FetchErrorIndicator error={query.error} />;
  }

  if (typeof query.data === 'undefined' || query.data == null) {
    return null;
  }

  return props.render(query.data);
};
