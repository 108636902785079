export const extractValue = (data: any, key: string): any => {
    if (data == null) {
        return undefined;
    }
    if (!key.includes('.')) {
        return data[key];
    }
    const nestedKeys = key.split('.');
    let result = data;
    nestedKeys.forEach((nestedKey) => {
        if (result == null) {
            return;
        }
        result = result[nestedKey];
    });
    return result;
}
