import React from 'react';
import { CompanyViewModel } from '@lib/api-interface';
import { useTranslation } from 'react-i18next';
import { DataTable, DeleteModelButton, NavigateButton, Panel } from '@lib/ui-components';
import { useNavigate } from 'react-router-dom';

export function CompanyBranchKPO() {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Panel borderType='flat'>
      <DataTable
        refreshKey={1}
        modelDef={{ modelName: CompanyViewModel.name }}
        fetchFilters={{}}
        columns={[
          {
            key: '',
            label: t('year'),
            type: 'numeric',
          },
          {
            key: '',
            label: t('month'),
            type: 'text',
          },
          {
            key: '',
            label: t('modificationDate'),
            type: 'date',
          },
          {
            key: '',
            label: t('signedKpo'),
            type: 'text',
          },
          {
            key: '',
            label: t('uploadDate'),
            type: 'date',
          },
        ]}
        rowOptions={[
          { renderer: (row) => <NavigateButton label={t('uploadFile')} to={''} /> },

          {
            renderer: (row) => <DeleteModelButton id={row['_id']} modelName={CompanyViewModel.name} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </Panel>
  );
}
