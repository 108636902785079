import axios, { AxiosResponse } from 'axios';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { plainToInstance } from 'class-transformer';
import { PageInfo } from '@lib/common-sdk';
import { DataQueryPagedDto } from './data-query-paged.dto';

export interface ReactHateoasRestApiRequestOptions {
  modelClass?: ClassConstructor<any>;
  filters?: any;
  contentType?: 'application/json' | 'multipart/form-data';
  search?: string;
  embeddedResultModelName?: string;
}

export class ReactQueryHateoasClientService {
  public static findOne<ModelType>(resourcePluralName: string, id?: number, options?: ReactHateoasRestApiRequestOptions): Promise<ModelType> {
    const params = options?.filters || {};
    let url = `${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}`;
    if (id) {
      url = `${url}/${id}`;
    }
    if (options?.search) {
      url = `${url}/search/${options.search}`;
    }
    return axios.get(url, { params }).then((response: AxiosResponse) => {
      let data = response.data;
      delete data['_links'];
      if (data['_embedded']) {
        data = { ...data, ...data['_embedded'] };
        delete data['_embedded'];
      }
      return options?.modelClass != null ? plainToInstance(options?.modelClass, data) : data;
    });
  }

  public static findOneByAggregateId<ModelType>(resourcePluralName: string, idFieldName: string, id?: string, options?: ReactHateoasRestApiRequestOptions): Promise<ModelType> {
    if (id == undefined) throw new Error('undefined find by id');

    const filters: any = {};
    filters[idFieldName] = id;
    return this.findAll<ModelType>(resourcePluralName, filters, undefined, options).then((arrayResult) => {
      if (arrayResult.length > 0) return arrayResult[0];
      throw new Error('undefined find by id');
    });
  }

  public static findAllPaged<ModelType>(
    resourcePluralName: string,
    page: number,
    size: 500,
    filters?: any,
    sort?: string,
    modelClass?: ClassConstructor<any>,
    search?: string,
    embeddedResultModelName?: string,
  ): Promise<DataQueryPagedDto<ModelType[]>> {
    if (sort === undefined) sort = 'id';
    let url = `${process.env['REACT_APP_RESOURCES_URL']}/${resourcePluralName}`;
    if (search) {
      url = `${url}/search/${search}`;
    }

    return axios
      .get(url, {
        params: {
          ...filters,
          sort,
          page,
          size,
        },
      })
      .then((response: AxiosResponse) => {
        const data: any[] = this.extractItemsFromEmbedded(response, embeddedResultModelName != null ? embeddedResultModelName : resourcePluralName, modelClass);
        let page: PageInfo;
        if (response.data['page']) {
          page = response.data['page'];
          page.hasNextPage = page.number + 1 < page.totalPages;
        } else {
          page = {
            number: 1,
            totalPages: 1,
            hasNextPage: false,
            size: data.length,
            totalElements: data.length,
          };
        }
        return new DataQueryPagedDto<ModelType[]>(data, page);
      });
  }

  public static async findAll<ModelType>(resourcePluralName: string, filters?: any, sort?: string, options?: ReactHateoasRestApiRequestOptions): Promise<ModelType[]> {
    let currentPage = 0;
    const items: ModelType[] = [];
    let result;
    do {
      result = await this.findAllPaged<ModelType>(
        resourcePluralName,
        currentPage,
        500,
        filters,
        sort,
        options?.modelClass,
        options?.search,
      );
      currentPage += 1;

      if (result.response) {
        for (const item of result.response) {
          items.push(item);
        }
      }
    } while (result.page?.hasNextPage);

    return items;
  }

  private static extractItemsFromEmbedded(response: AxiosResponse<any, any>, resourcePluralName: string, modelClass: ClassConstructor<any> | undefined): any[] {
    const embedded = response.data['_embedded'] ?? {};
    let data = embedded[resourcePluralName] ?? [];
    for (const item of data) {
      delete item['_links'];
    }
    if (modelClass != null) {
      data = plainToInstance(modelClass, data);
    }
    return data;
  }
}
