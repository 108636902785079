import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@lib/ui-components';
import { CommuneServicesDataTable } from './commune-services.data-table';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';
import { CommuneModel } from '@lib/api-interface';
import { QueryResultRenderer } from '../../../../libs/ui-components/components/query-result-renderer';

export const CommuneServicesPage = () => {
  const { t } = useTranslation();
  const pathParams = useParams();

  const communeQuery = useQuery(['communes', pathParams['communeId']?.substring(1)], () =>
    ReactQueryHateoasClientService.findOne<CommuneModel>('communes', parseInt(pathParams['communeId']!, 10)),
  );

  return (
    <QueryResultRenderer
      query={communeQuery}
      render={(communeModel) => {
        return (
          <>
            <Header label={`${t('commune')}: ${communeModel.name}`} />
            <CommuneServicesDataTable communeId={communeModel.id!} />
          </>
        );
      }}
    />
  );
};
