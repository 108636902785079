import React from 'react';
import { useTranslation } from 'react-i18next';
import { HateoasRestApiClientService, JsonUtils } from '../../../../common-sdk';
import { Button } from '../../button/button';
import { Snackbar } from '../../snackbar/snack-bar';
import { useStateIfMounted } from 'use-state-if-mounted';
import { TrashcanIcon } from '../TrashcanIcon';
import i18n from 'i18next';
import { getErrorMessage } from '../../custom-post-error.handler';

export interface DeleteModelButtonProps {
  id: string;
  modelName: string;
  tooltip?: string;
  onDeleted: () => Promise<void>;
  variant?: 'text' | 'outlined' | 'contained';
  postErrorMappings?: Record<string, string>;
}

export const DeleteModelButton = (props: DeleteModelButtonProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const errorKeys = translations.error;

  async function handleDelete() {
    try {
      const results = await HateoasRestApiClientService.delete<any>(props.modelName, props.id);
      if (!results.failed) {
        await props.onDeleted();
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.errorStatusCode}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }

        const data = results.response?.data || '{ "code": "E00001"}';
        const code = JsonUtils.extractValue(data, 'code');
        const context = JsonUtils.extractValue(data, 'context');
        setFailureMessage(getErrorMessage({ code, context }, translations) || t('Common.error'));
      }
    } catch (e: any) {
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      const context = JsonUtils.extractValue(data, 'context');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(getErrorMessage({ code, context }, translations) || t('Common.error'));
    }
  }

  return (
    <>
      <Button
        dataTestId={`${props.modelName}-delete-btn`}
        label={t('Common.delete')}
        startIcon={<TrashcanIcon />}
        tooltip={props.tooltip}
        color='primary'
        forceConfirmation={true}
        variant={props.variant}
        onClick={async () => handleDelete()}
      />
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};
