import * as React from 'react';
import {CSSProperties} from 'react';
import styles from './styles.module.css';
import {useTheme} from '@mui/material';

export interface EnumLabelProps {
    direction?: 'row' | 'column';
    value?: string;
    enumOptions: { value: string; label: string }[];
    valueColor?: string;
    backgroundColor?: string;
    valueFontSize?: string;
}

export const EnumLabel = (props: EnumLabelProps) => {
    const theme = useTheme();

    const style: CSSProperties = {
        flexDirection: props.direction ?? 'column'
    };

    const valueStyle: CSSProperties = {
        fontSize: props.valueFontSize ?? '1rem',
        color: props.valueColor ?? theme.palette.primary.main
    };

    const labelStyle: CSSProperties = {
        fontSize: '0.8rem'
    };

    function getEnumLabel(): string {
        return props.enumOptions.find(item => item.value === props.value)?.label ?? '';
    }

    return (
        <div className={styles['container']} style={style}>
            <div style={labelStyle}>&nbsp;</div>
            <div
                className={styles['value-wrapper']}
                style={{
                    backgroundColor: props.backgroundColor ?? theme.palette.secondary.main
                }}
            >
                <div className={styles['value']} style={valueStyle}>
                    {getEnumLabel()}
                </div>
            </div>
        </div>
    );
};
