import { DataTable, GetContentButton, Panel } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const CommuneDeclarations = () => {
  const { t } = useTranslation();

  return (
    <Panel header={t('communeDeclarations')} borderType='raised'>
      <DataTable
        refreshKey={1}
        fetchUrl={'/api/client/commune-declarations'}
        initialSort={{ columnIndex: 2, ascending: false }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
          {
            key: 'uploadDate',
            label: t('added'),
            type: 'date',
            dateFormat: 'dd.MM.yyyy HH:mm:ss',
          },
        ]}
        rowOptions={[
          {
            renderer: (row: any) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/client/commune-declarations/${row.id}/content`} />,
          },
        ]}
      />
    </Panel>
  );
};
