import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AddModelButton, DataTable, GetContentButton, Panel, Toolbar } from '@lib/ui-components';
import * as Yup from 'yup';

export interface CommuneServiceDeclarationsProps {
  projectId: string;
  projectBranchId: string;
}

export const CommuneServiceDeclarations = (props: CommuneServiceDeclarationsProps) => {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const projectId = props.projectId;
  const branchId = props.projectBranchId;

  return (
    <Panel borderType="raised">
      <Toolbar
        right={[
          <AddModelButton
            multipart={true}
            buttonLabel={t('uploadFile')}
            dialogTitle={t('uploadFile')}
            modelName={'client-admin/communeDeclarationFileViews'}
            fields={[
              { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
              { label: t('comment'), type: 'text', field: 'comment' },
            ]}
            hiddenValues={{
              projectId: projectId,
              companyBranchId: branchId,
            }}
            validationSchema={{
              content: Yup.mixed().required(t('fieldRequired')),
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        fetchPostUrl={'/api/resource/client-admin/communeDeclarationFileViews/by-params'}
        fetchFilters={{
          projectId: projectId,
          companyBranchId: branchId,
        }}
        initialSort={{ columnIndex: 2, ascending: false }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
          {
            key: 'uploadDate',
            label: t('added'),
            type: 'date',
            dateFormat: 'dd.MM.yyyy HH:mm:ss',
          },
        ]}
        rowOptions={[
          {
            renderer: (row: any) => (
              <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />}
                                resourcePath={`api/resource/client-admin/communeDeclarationFileViews/${row.id}/content`} />
            ),
          },
        ]}
      />
    </Panel>
  );
}
