import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, DataTable, HStack, Panel, ToggleButtons } from '@lib/ui-components';

export const CollectionRequestsTab = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  function handleChangeActivePage(idx: number) {
    setActivePage(idx);
    setTableReloadKey(tableReloadKey + 1);
  }

  const fetchPostUrl = `/api/collection-request/list/${activePage === 0 ? 'active' : 'history'}`;

  return (
    <Panel borderType={'flat'}>
      <HStack align='center'>
        <ToggleButtons labels={[t('open'), t('history')]} activeIndex={activePage} onChange={handleChangeActivePage} />
      </HStack>
      <DataTable
        excel={true}
        refreshKey={tableReloadKey}
        fetchPostUrl={fetchPostUrl}
        initialSort={{ columnIndex: 0, ascending: false }}
        columns={[
          {
            key: 'creationDate',
            label: t('reportDate'),
            type: 'date',
            dateFormat: 'dd.MM.yyyy / HH:mm',
          },
          {
            key: 'collectionDate',
            label: t('pickupDate'),
            type: 'date',
          },
          {
            key: 'branchName',
            label: t('branch'),
            type: 'text',
          },
          {
            key: 'branchRegistrationAddress.town',
            label: t('branchCity'),
            type: 'text',
          },
          {
            key: 'wasteCode',
            label: t('wasteCode'),
            type: 'text',
          },
          {
            key: 'wasteName',
            label: t('wasteName'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'wasteDescription',
            label: t('wasteDescription'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('concerns'),
            type: 'enum',
            enumValues: [
              { value: 'PICKUP', label: t('collectionRequestType.PICKUP') },
              { value: 'SCHEDULE', label: t('collectionRequestType.SCHEDULE') },
              { value: 'NO_PICKUP', label: t('collectionRequestType.NO_PICKUP') },
              { value: 'EXCEED', label: t('collectionRequestType.EXCEED') },
            ],
            minWidth: '10rem',
          },
          {
            key: 'container',
            label: t('container'),
            type: 'text',
          },
          {
            key: 'containers',
            label: t('numberOfContainers'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            isVisible: () => activePage === 0,
            renderer: (row) => (
              <AggregateCommandButton
                aggregateName={'CollectionRequestAggregate'}
                aggregateId={row.collectionRequestId}
                commandName={'complete'}
                buttonLabel={t('done')}
                color='secondary'
                variant='contained'
                forceConfirmation={true}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
