import * as React from 'react';
import styles from './styles.module.css';
import { useUserState } from '../../../state/UserState';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthApiService } from '../../../services/auth.api.service';
import { WebSocketService } from '../../../services/web-socket.service';

export const AppBarOptions = () => {
  const userState = useUserState();
  const { t, i18n } = useTranslation();

  function handleLogout() {
    AuthApiService.logout().then((_) => {
      userState.logout();
    });
  }

  function changeLanguage(language: string) {
    document.documentElement.lang = language;
    i18n.changeLanguage(language);
  }

  return (
    <div className={styles['container']}>
      <WebSocketService />
      <div className={styles['languageButtonsContainer']}>
        <Tooltip title={t('AppBar.changeLanguage')}>
          <Button color='secondary' variant='contained' size='small' onClick={() => changeLanguage('pl')}>
            PL
          </Button>
        </Tooltip>
        <Tooltip title={t('AppBar.changeLanguage')}>
          <Button color='secondary' variant='contained' size='small' onClick={() => changeLanguage('en')}>
            EN
          </Button>
        </Tooltip>
      </div>
      <div> {userState.email} </div>
      <div>
        <Tooltip title={t('AppBar.logout')}>
          <IconButton color='secondary' onClick={() => handleLogout()}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
