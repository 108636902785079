import { Expose } from 'class-transformer';

export class ProjectWaste {
  @Expose()
  projectWasteId?: string;
  @Expose()
  bdoNum?: string;
  @Expose()
  projectWasteDescription?: string;
  @Expose()
  wasteCode?: string;
  @Expose()
  wasteDescription?: string;
}
