import * as React from 'react';
import { CSSProperties } from 'react';
import styles from './styles.module.css';

export interface CheckboxPanelProps {
  children?: React.ReactNode;
  justifyContent?: string;
  marginRight?: string;
  marginTop?: string;
}

export const CheckboxPanel = (props: CheckboxPanelProps) => {
  const style: CSSProperties = {
    justifyContent: props.justifyContent,
    marginRight: props.marginRight,
    marginTop: props.marginTop,
  };

  return (
    <div className={styles['container']} style={style}>
      {props.children}
    </div>
  );
};
