import { ServiceViewDetails } from '@lib/api-interface';

export enum ServiceButtonMode {
  CREATE = 'create',
  EDIT = 'edit',
  COPY = 'copy',
}

export type ServiceButtonProps =
  | {
      serviceButtonMode: ServiceButtonMode.CREATE;
      isAdditionalTabSelected?: boolean;
      onSaved?: () => void;
      reloadServices: () => void;
    }
  | {
      serviceButtonMode: ServiceButtonMode.EDIT;
      service: ServiceViewDetails;
      onSaved?: () => void;
      reloadServices: () => void;
    }
  | {
      serviceButtonMode: ServiceButtonMode.COPY;
      service: ServiceViewDetails;
      onSaved?: () => void;
      reloadServices: () => void;
    };
