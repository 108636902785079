import styled from 'styled-components';
import { AxiosError } from 'axios';
import ErrorIcon from '@mui/icons-material/Error';

const StyledErrorIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: red;
`;

export interface FetchErrorIndicatorProps {
  error: any;
}

export const FetchErrorIndicator = (props: FetchErrorIndicatorProps) => {
  function getErrorMessage() {
    // if not normal error and not axios error - display some string
    if (!(props.error instanceof AxiosError)) {
      // if normal error - display error message
      if (props.error instanceof Error) {
        return props.error.message;
      }

      return 'Error loading resource...';
    }

    const axiosError = props.error as AxiosError;
    const response = axiosError.response;
    const status = axiosError.status;
    if (response === undefined) {
      return 'No response from server, either server is down or no internet connection';
    }

    const responseData: any = response.data;

    // if there is error message - display error message
    if (responseData?.message != null) {
      // checks for undefined too
      return responseData?.message;
    }

    switch (response.status) {
      case 403:
        return 'Access denied';
      case 404:
        return 'Resource not found';
    }

    switch (status) {
      case '504':
        return 'Request timed out';
    }

    return 'Error loading resource...';
  }

  return (
    <StyledErrorIndicator>
      <ErrorIcon />
      <span>{getErrorMessage()}</span>
    </StyledErrorIndicator>
  );
};
