import { Expose } from 'class-transformer';
import { CompanyAddress } from '../company/company.model';
import { ProjectBranchContactPerson } from './project-branch-view.model';

// Mapped by ProjectCompanyBranchProjection.java
// TODO: Remove
export class ProjectCompanyBranchModel {
  @Expose()
  companyId?: string;
  @Expose()
  companyName?: string;
  @Expose()
  projectId?: string;
  @Expose()
  projectNumber?: string;
  @Expose()
  projectName?: string;
  @Expose()
  branchId?: string;
  @Expose()
  companyBranchId?: string;
  @Expose()
  companyBranchName?: string;
  @Expose()
  central?: boolean;
  @Expose()
  active?: boolean;
  @Expose()
  correspondenceAddress?: CompanyAddress;
  @Expose()
  registrationAddress?: CompanyAddress;
  @Expose()
  contactPerson?: ProjectBranchContactPerson; // main contact person from ContactPersons
  @Expose()
  contractConclusionDate?: Date;
}
