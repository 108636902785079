import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, Dialog, Panel, TextLabel } from '@lib/ui-components';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export interface ContactFormDetailsProps {
  name?: string;
  phone?: string;
  sendAt: string;
  message: string;
}

export const ContactFormDetails = (props: ContactFormDetailsProps) => {
  const { t } = useTranslation();
  const [openDialog, setDialog] = useStateIfMounted(false);

  async function handleCloseDialog() {
    setDialog(false);
  }

  const senderData = (): string => {
    return [props.name, props.phone].filter(Boolean).join(', ');
  };

  return (
    <>
      <Button label={t('details')} variant={'contained'} color={'secondary'} onClick={async () => setDialog(true)} />
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='md' fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto', fontSize: '1.7rem', color: '#183362' }}>
          {t('messageDetails')}
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          <Panel gap={'1rem'} direction={'column'} justifyContent={'center'} contentMarginBottom={'2rem'} contentMarginRight={'3rem'} contentMarginLeft={'3rem'}>
            <TextLabel value={senderData()} valueColor='#25282B' valueFontSizeInRem={1.1} />
            <TextLabel value={props.sendAt} valueColor='#A0A4A8' valueFontSizeInRem={0.9} dateFormat='dd.MM.yyyy HH:mm' />
            <div
              style={{
                fontSize: '1.1rem',
                color: '#25282B',
                whiteSpace: 'pre-wrap',
              }}
            >
              {props.message}
            </div>
          </Panel>
        </DialogContent>
      </Dialog>
    </>
  );
};
