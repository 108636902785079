import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton } from '@lib/ui-components';
import * as Yup from 'yup';
import { CompanyViewModel, ContactPersonTypeEnum } from '@lib/api-interface';

export interface AddCompanyContactButtonProps {
  company: CompanyViewModel;
  contactType: ContactPersonTypeEnum;
  branchId?: string;
  onSaved?: () => void;
}

export const AddCompanyContactButton = (props: AddCompanyContactButtonProps) => {
  const { t } = useTranslation();

  return (
    <AggregateCommandButton
      aggregateName='CompanyAggregate'
      aggregateId={props.company.companyId!}
      commandName='createContactPerson'
      buttonLabel={props.contactType === ContactPersonTypeEnum.ORGANIZATION ? t('addKeeper') : t('addContact')}
      dialogTitle={props.contactType === ContactPersonTypeEnum.ORGANIZATION ? t('addKeeper') : t('addContact')}
      fields={[
        {
          rowItems: [
            { field: 'firstName', label: t('firstName'), type: 'text' },
            { field: 'lastName', label: t('lastName'), type: 'text' },
          ],
        },
        {
          rowItems: [
            { field: 'email', label: t('email'), type: 'text' },
            { field: 'phone', label: t('phone'), type: 'text' },
          ],
        },
        { label: t('mainContactPerson'), type: 'boolean', field: 'mainContactPerson' },
        { label: t('comment'), type: 'text', field: 'comment' },
      ]}
      validationSchema={{
        firstName: Yup.string(),
        lastName: Yup.string(),
        email: Yup.string()
          .email(t('requiredValidEmail'))
          .when([], {
            is: () => props.contactType === ContactPersonTypeEnum.ORDER,
            then: () => Yup.string().email(t('requiredValidEmail')).required(t('fieldRequired')),
          }),
        phone: Yup.string(),
      }}
      onBeforeSave={(formData) => {
        formData.contactType = props.contactType;
        formData.branchId = props.branchId;
        return formData;
      }}
      onSaved={async () => {
        if (props.onSaved) props.onSaved();
      }}
    />
  );
};
