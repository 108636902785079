import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, FileUploadButton, HStack, ImportFromExcelIcon, Panel, Snackbar } from '@lib/ui-components';
import { useWebSocketState } from '../../../../state/web-socket-state';

export const ServiceSettlementImport = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [snackbarVisible, setSnackbarVisible] = React.useState(false);
  const webSocketState = useWebSocketState();

  React.useEffect(() => {
    setTableReloadKey((prevKey) => prevKey + 1);
  }, [webSocketState.settlementImportRefreshKey]);

  return (
    <Panel direction='column'>
      <HStack align={'right'}>
        <FileUploadButton
          uploadUrl='/api/settlement/import'
          onLoaded={async () => setSnackbarVisible(true)}
          label={t('uploadNewImportFile')}
          allowedExtensions='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          startIcon={<ImportFromExcelIcon />}
          dontChangeLabel={true}
        />
        {snackbarVisible && <Snackbar message={t('settlementImportStarted')} severity='info' onClose={() => setSnackbarVisible(false)} />}
      </HStack>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'settlementImportLogViews',
        }}
        initialSort={{ columnIndex: 4, ascending: false }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'message',
            label: t('description'),
            type: 'text',
          },
          {
            key: 'rowNum',
            label: t('rowNumber'),
            type: 'numeric',
          },
          {
            key: 'columnAddress',
            label: t('column'),
            type: 'text',
          },
          {
            key: 'createdAt',
            label: t('addedDate'),
            type: 'date-time',
          },
        ]}
      />
    </Panel>
  );
};
