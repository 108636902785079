import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddModelButton, DataTable, DeleteModelButton, EditModelButton, Header, Toolbar } from '../../../../libs/ui-components';
import * as Yup from 'yup';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../factories/nav-resource-select-options.factory';

export const NavResources = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const typeSelectOptions = InfrastructureCategorySelectOptionsFactory.getTypeSelectOptions(t);
  const unitSelectOptions = InfrastructureCategorySelectOptionsFactory.getUnitSelectOptions(t);
  const accountingGroupSelectOptions = InfrastructureCategorySelectOptionsFactory.getAccountingGroupSelectOptions(t);

  return (
    <div>
      <Header label={t('GlobalSettings.navResources')} />
      <Toolbar
        right={[
          <AddModelButton
            modelName='navResources'
            buttonLabel={t('Button.NavResourceModel.add')}
            dialogTitle={t('Button.NavResourceModel.add.dialog.title')}
            fields={[
              {
                rowItems: [
                  {
                    field: 'code',
                    label: t('Model.NavResource.code'),
                    type: 'text',
                  },
                  {
                    field: 'name',
                    label: t('name'),
                    type: 'text',
                  },
                ],
              },
              {
                field: 'type',
                label: t('type'),
                type: 'select',
                selectOptions: typeSelectOptions,
              },
              {
                rowItems: [
                  {
                    field: 'unit',
                    label: t('unitLong'),
                    type: 'select',
                    selectOptions: unitSelectOptions,
                  },
                  {
                    field: 'price',
                    label: t('Model.NavResource.price'),
                    type: 'numeric',
                    numericPrecision: 2,
                    numericMinValue: 0,
                    numericMaxValue: 999999999,
                  },
                ],
              },
              {
                field: 'accountingGroup',
                label: t('Model.NavResource.accountingGroup'),
                type: 'select',
                selectOptions: accountingGroupSelectOptions,
              },
            ]}
            validationSchema={{
              code: Yup.string().required(t('Model.NavResource.code.required')),
              name: Yup.string().required(t('Model.NavResource.name.required')),
              type: Yup.string().required(t('Model.NavResource.type.required')),
              unit: Yup.string().required(t('Model.NavResource.unit.required')),
              price: Yup.string().required(t('Model.NavResource.price.required')),
              accountingGroup: Yup.string().required(t('Model.NavResource.accountingGroup.required')),
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'navResources',
        }}
        columns={[
          {
            key: 'code',
            label: t('Model.NavResource.code'),
            type: 'text',
          },
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('type'),
            type: 'enum',
            enumValues: typeSelectOptions,
          },
          {
            key: 'unit',
            label: t('unitLong'),
            type: 'enum',
            enumValues: unitSelectOptions,
          },
          {
            key: 'price',
            label: t('Model.NavResource.price'),
            type: 'numeric',
            numericPrecision: 2,
          },
          {
            key: 'accountingGroup',
            label: t('Model.NavResource.accountingGroup'),
            type: 'enum',
            enumValues: accountingGroupSelectOptions,
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                modelName='navResources'
                buttonLabel={t('edit')}
                dialogTitle={t('Button.NavResourceModel.edit.dialog.title')}
                fields={[
                  {
                    rowItems: [
                      {
                        field: 'code',
                        label: t('Model.NavResource.code'),
                        type: 'text',
                      },
                      {
                        field: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                    ],
                  },
                  {
                    field: 'type',
                    label: t('type'),
                    type: 'select',
                    selectOptions: typeSelectOptions,
                  },
                  {
                    rowItems: [
                      {
                        field: 'unit',
                        label: t('unitLong'),
                        type: 'select',
                        selectOptions: unitSelectOptions,
                      },
                      {
                        field: 'price',
                        label: t('Model.NavResource.price'),
                        type: 'numeric',
                        numericPrecision: 2,
                        numericMinValue: 0,
                        numericMaxValue: 999999999,
                      },
                    ],
                  },
                  {
                    field: 'accountingGroup',
                    label: t('Model.NavResource.accountingGroup'),
                    type: 'select',
                    selectOptions: accountingGroupSelectOptions,
                  },
                ]}
                validationSchema={{
                  code: Yup.string().required(t('Model.NavResource.code.required')),
                  name: Yup.string().required(t('Model.NavResource.name.required')),
                  type: Yup.string().required(t('Model.NavResource.type.required')),
                  unit: Yup.string().required(t('Model.NavResource.unit.required')),
                  price: Yup.string().required(t('Model.NavResource.price.required')),
                  accountingGroup: Yup.string().required(t('Model.NavResource.accountingGroup.required')),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                yellowIcon
              />
            ),
          },
          {
            renderer: (row) => <DeleteModelButton id={row['id']} modelName='navResources' onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </div>
  );
};
