import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, RenderIf, TableFilterItem, ToggleButtons } from '@lib/ui-components';
import { CompanyBranchViewModel, CompanyViewModel, ServiceType } from '@lib/api-interface';
import { InfrastructureServicesDataTable } from '../../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/infrastructure-services/infrstructure-services-data-table';
import { ScheduledAndPerRequest } from './services/scheduled-and-per-request';
import { Additional } from './services/additional';

export interface ContractorClientServicesProps {
  company?: CompanyViewModel;
  companyBranch?: CompanyBranchViewModel;
}

export const ContractorClientServices = (props: ContractorClientServicesProps) => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = React.useState(1);
  const instanceFiltersRef: React.MutableRefObject<TableFilterItem[]> = React.useRef(new Array<TableFilterItem>());

  function updateFiltersIfChanged(filters: Array<TableFilterItem>) {
    instanceFiltersRef.current = filters;
  }

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('schedule'), t('onRequest'), t('additional'), t('infrastructure')]} activeIndex={selectedPage} onChange={(idx) => setSelectedPage(idx)} />
      </HStack>
      <RenderIf true={selectedPage === 0}>
        <ScheduledAndPerRequest
          companyId={props.company?.companyId}
          companyBranchId={props.companyBranch?.branchId}
          serviceType={ServiceType.SCHEDULED}
          instanceFiltersRef={instanceFiltersRef}
          onDefaultFiltersUpdated={(filters) => updateFiltersIfChanged(filters)}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 1}>
        <ScheduledAndPerRequest
          companyId={props.company?.companyId}
          companyBranchId={props.companyBranch?.branchId}
          serviceType={ServiceType.PER_REQUEST}
          instanceFiltersRef={instanceFiltersRef}
          onDefaultFiltersUpdated={(filters) => updateFiltersIfChanged(filters)}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 2}>
        <Additional
          companyId={props.company?.companyId}
          companyBranchId={props.companyBranch?.branchId}
          instanceFiltersRef={instanceFiltersRef}
          onDefaultFiltersUpdated={(filters) => updateFiltersIfChanged(filters)}
        />
      </RenderIf>
      <RenderIf true={selectedPage === 3}>
        <InfrastructureServicesDataTable
          refreshKey={0}
          fetchPostUrl={`/api/subcontractor-infrastructure-services/by-subcontractor/${props.company?.companyId ? props.company?.companyId : props.companyBranch?.branchId}`}
          forSubcontractor={true}
        />
      </RenderIf>
    </Panel>
  );
};
