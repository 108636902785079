import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { ContractorData } from '../contractor-view/contractor-data/contractor-data';
import { ContractorClientServices } from '../contractor-view/contractor-client-services';
import { ContractorQuantityReceived } from '../contractor-view/contractor-quantity-received';
import { ContractorDecisionsConclusions } from '../contractor-view/contractor-decisions-conclusions/contractor-decisions-conclusions';
import { ContractorRdr } from '../contractor-view/contractor-rdr';
import { CompanyBranches } from '../shared/company-branches/company-branches';
import CompanyAgreements from '../shared/company-agreements';
import { ClientFiles } from '../client-view/client-files/client-files';

export interface ContractorTabsWrapperProps {
  company: CompanyViewModel;
  onCentralBranchChanged?: () => void;
}

export const ContractorTabsWrapper = (props: ContractorTabsWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        panels={[
          {
            label: t('Companies.CompanyView.companySubcontractorInfo'),
            component: <ContractorData company={props.company} />,
          },
          { label: t('clientsServices'), component: <ContractorClientServices company={props.company} /> },
          {
            label: t('branches'),
            component: <CompanyBranches company={props.company} onCentralChanged={() => props?.onCentralBranchChanged && props.onCentralBranchChanged()} />,
          },
          { label: t('quantityReceived'), component: <ContractorQuantityReceived /> },
          {
            label: t('agreements'),
            component: <CompanyAgreements company={props.company} role={CompanyRole.SUBCONTRACTOR} />,
          },
          {
            label: t('decisionsConclusions'),
            component: <ContractorDecisionsConclusions company={props.company} />,
          },
          { label: t('rdr'), component: <ContractorRdr company={props.company} /> },
          { label: t('files'), component: <ClientFiles company={props.company} /> },
        ]}
      />
    </div>
  );
};
