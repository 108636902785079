import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { EditModelButton } from '../../action-buttons';
import { useTranslation } from 'react-i18next';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';

export interface CheckboxLabelProps {
  label: string;
  direction?: 'row' | 'column';
  value?: boolean;
  type?: 'checkbox' | 'toggle';
  labelColor?: string;
  labelFontSize?: string;
  valueColor?: string;
  valueSelectedColor?: string;
  width?: string;
  whiteSpace?: string;
  flexEnd?: boolean;
  marginTop?: string;
  editButton?: CheckboxLabelEditProps;
  editByAggregateButton?: CheckboxLabelEditByAggregateProps;
  gridColumn?: string;
}

export interface CheckboxLabelEditProps {
  id: number;
  entity: any;
  modelName: string;
  field: string;
  fieldLabel: string;
  onSaved?: (result: any) => Promise<any>;
}

export interface CheckboxLabelEditByAggregateProps {
  aggregateId: string;
  entity: any;
  aggregateName: string;
  commandName: string;
  field: string;
  fieldLabel: string;
  onSaved?: (result: any) => Promise<any>;
}

interface IconProps extends Pick<CheckboxLabelProps, 'value'> {
  getSelectedValueColor: () => string;
  getUnselectedValueColor: () => string;
}

const StyledContainer = styled.div<Pick<CheckboxLabelProps, 'direction' | 'width' | 'flexEnd' | 'marginTop' | 'gridColumn'>>`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  flex-direction: ${({ direction }) => (direction !== undefined ? direction : 'row')};
  width: ${({ width }) => (width ? width : '100%')};
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
  margin-top: ${({ marginTop }) => marginTop};
  grid-column: ${({ gridColumn }) => gridColumn};
`;

const StyledIcon = styled.div<IconProps>`
  color: ${({ value, getSelectedValueColor, getUnselectedValueColor }) => (value ? getSelectedValueColor() : getUnselectedValueColor())};
  margin-bottom: -0.3rem;
`;
const StyledLabel = styled.div<Pick<CheckboxLabelProps, 'labelFontSize' | 'labelColor' | 'whiteSpace'>>`
  font-size: ${({ labelFontSize }) => (labelFontSize !== undefined ? labelFontSize : '0.8rem')};
  color: ${({ labelColor }) => (labelColor !== undefined ? labelColor : '#828282')};
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : 'nowrap')};
`;

export const CheckboxLabel = (props: CheckboxLabelProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function getSelectedValueColor() {
    return props.valueSelectedColor !== undefined ? props.valueSelectedColor : theme.palette.primary.main;
  }

  function getUnselectedValueColor() {
    return props.valueColor !== undefined ? props.valueColor : '#828282';
  }

  function renderCheckbox() {
    if (props.type === undefined || props.type === 'checkbox') {
      return props.value === true ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    } else {
      return props.value === true ? <ToggleOnIcon color='secondary' fontSize='large' /> : <ToggleOffIcon fontSize='large' />;
    }
  }

  return (
    <StyledContainer direction={props.direction} width={props.width} flexEnd={props.flexEnd} marginTop={props.marginTop} gridColumn={props.gridColumn}>
      <StyledIcon getSelectedValueColor={getSelectedValueColor} getUnselectedValueColor={getUnselectedValueColor} value={props.value}>
        {renderCheckbox()}
      </StyledIcon>
      <StyledLabel labelFontSize={props.labelFontSize} labelColor={props.labelColor} whiteSpace={props.whiteSpace}>
        {props.label}
      </StyledLabel>

      {props.editButton && (
        <EditModelButton
          id={props.editButton.id}
          entity={props.editButton.entity}
          modelName={props.editButton.modelName}
          dialogTitle={t('edit')}
          fields={[{ field: props.editButton.field, label: props.editButton.fieldLabel, type: 'boolean' }]}
          onSaved={async (result) => {
            if (props.editButton?.onSaved) {
              props.editButton?.onSaved(result);
            }
          }}
        />
      )}

      {props.editByAggregateButton && (
        <EditModelButton
          id={0}
          entity={props.editByAggregateButton.entity}
          modelName='not-used'
          dialogTitle={t('edit')}
          fields={[{ field: props.editByAggregateButton.field, label: props.editByAggregateButton.fieldLabel, type: 'boolean' }]}
          overrideSaveCall={async (payload: any) => {
            try {
              await AggregateCommandClientService.callCommand<any>(
                props.editByAggregateButton!.aggregateName,
                props.editByAggregateButton!.commandName,
                props.editByAggregateButton!.aggregateId,
                payload,
              );
              return new DataResultsDto(false, 200, undefined, undefined, undefined);
            } catch (error: any) {
              return new DataResultsDto(true, error.status, undefined, undefined, undefined);
            }
          }}
          onSaved={async (result) => {
            if (props.editByAggregateButton?.onSaved) {
              props.editByAggregateButton?.onSaved(result);
            }
          }}
        />
      )}
    </StyledContainer>
  );
};
