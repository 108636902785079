export enum SubcontractorTypesEnum {
  TRANSPORTING = 'TRANSPORTING',
  RECEIVING = 'RECEIVING',
  TAKING_OVER = 'TAKING_OVER',
  OTHER = 'OTHER',
  RECEIVING_COMMUNAL_WASTE = 'RECEIVING_COMMUNAL_WASTE',
}

export function subcontractorTypesEnumToArray(obj: any): Array<SubcontractorTypesEnum> {
  const subcontractorTypes = [];
  if (obj?.TRANSPORTING) {
    subcontractorTypes.push(SubcontractorTypesEnum.TRANSPORTING);
  }
  if (obj?.RECEIVING) {
    subcontractorTypes.push(SubcontractorTypesEnum.RECEIVING);
  }
  if (obj?.TAKING_OVER) {
    subcontractorTypes.push(SubcontractorTypesEnum.TAKING_OVER);
  }
  if (obj?.OTHER) {
    subcontractorTypes.push(SubcontractorTypesEnum.OTHER);
  }
  if (obj?.RECEIVING_COMMUNAL_WASTE) {
    subcontractorTypes.push(SubcontractorTypesEnum.RECEIVING_COMMUNAL_WASTE);
  }
  return subcontractorTypes;
}

export function subcontractorTypesEnumToObject(arr: Array<SubcontractorTypesEnum> | undefined): any {
  const result: any = {};
  if (arr == null) {
    return result;
  }
  arr.forEach((value) => {
    result[value.valueOf()] = true;
  });
  return result;
}
