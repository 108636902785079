import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export function additionalServiceValidationSchema(t: TFunction<'common', undefined>, editing?: boolean) {
  type TF = Yup.TestFunction<string | undefined, AnyObject>;

  function isEmpty(value?: string) {
    return value == null || value.trim().length === 0;
  }

  const deactivationDateAfterActivationDate: TF = function (value) {
    const { activationDate } = this.parent;

    if (isEmpty(value)) {
      return true;
    }
    if (isEmpty(activationDate)) {
      return true;
    }
    return value! >= activationDate;
  };

  let creatingValidation = {};
  if (!editing) {
    creatingValidation = {
      clientCollectionPrice: Yup.object().when('pix', {
        is: (pix: boolean | undefined) => pix === false || pix === undefined,
        then: Yup.object({
          price: Yup.string().required(t('fieldRequired')),
        }),
      }),
      subcontractorCollectionPrice: Yup.object({
        price: Yup.string().required(t('fieldRequired')),
      }),
    };
  }

  return {
    ...creatingValidation,
    navResourceId: Yup.string().required(t('fieldRequired')),
    activationDate: Yup.string().required(t('fieldRequired')),
    deactivationDate: Yup.string().test('is-deactivation-date-after-activation-date', t('deactivationDateAfterActivationDateRequired'), deactivationDateAfterActivationDate),
    subcontractorId: Yup.string().required(t('fieldRequired')),
  };
}
