import { SendMailIcon } from '../../libs/ui-components/components/action-buttons/SendMailIcon';
import { UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ResendActivationMailButtonProps {
  userId: string;
  userIsActive: boolean;
  onSaved: () => Promise<any>;
}

export const ResendActivationMailButton = (props: ResendActivationMailButtonProps) => {
  const { t } = useTranslation();

  return (
    <UsecaseButton
      forceConfirmation={true}
      onBeforeSave={(data) => {
        data.userId = props.userId;
        if (props.userIsActive) {
          throw { response: { status: 'activatingAlreadyActiveUserError' } };
        }
        return data;
      }}
      postErrorMappings={{
        activatingAlreadyActiveUserError: t('resendActivationMailToActiveUserError'),
      }}
      onSaved={props.onSaved}
      useCaseName={'ResendActivationMailUseCase'}
      groupName={'account'}
      tooltip={t('resendActivationMailToUser')}
      icon={<SendMailIcon />}
    />
  );
};
