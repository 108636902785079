import * as React from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { Panel, PanelBorderType } from '../panel/panel';

export interface TabPage {
  label: string;
  component: JSX.Element;
}

export interface TabsProps {
  panels: TabPage[];
  marginTop?: string;
  borderType?: PanelBorderType;
  initialTabIndex?: number;
}

// navigation tab: https://stackoverflow.com/a/71075773/777344
export const Tabs = (props: TabsProps) => {
  const [selectedTab, setSelectedTab] = React.useState(props.initialTabIndex || 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabs = props.panels.map((panel, index) => {
    return <MuiTab key={index} label={panel.label} data-test-id={`${panel.label}-tab`} />;
  });

  if (props.panels.length === 0) {
    return null;
  }

  const borderType = props.borderType != null ? props.borderType : 'raised';

  return (
    <div style={{ marginTop: props.marginTop }}>
      <MuiTabs value={selectedTab} onChange={handleChange} variant='scrollable' scrollButtons='auto'>
        {tabs}
      </MuiTabs>
      <Panel borderType={borderType} contentMarginLeft={borderType === 'none' ? '0' : undefined} contentMarginRight={borderType === 'none' ? '0' : undefined}>
        {props.panels[selectedTab].component}
      </Panel>
    </div>
  );
};
