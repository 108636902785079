import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LeftMenuLayout } from '../../components/layouts/left-menu-layout/left-menu-layout';
import { GlobalSettings } from './global-settings/global-settings';
import { CompaniesPage } from './companies/companies.page';
import { CompanyView } from './companies/company-view/company-view';
import { Projects } from './projects/projects';
import { Registers } from './registers/registers';
import { ViewProject } from './projects/view-project/view-project';
import { CompanyBranchView } from './companies/company-view/shared/company-branches/company-branch-view/company-branch-view';
import { ProjectBranchView } from './projects/view-project/clients-tab/headquarters-branches/project-branch-view/project-branch-view';
import { MonthEndClosing } from './month-end-closing/month-end-closing';
import { CollectionRequests } from './collection-requests/collection-requests';
import { DeclarationChangesDetails } from './collection-requests/declaration-changes/declaration-changes-details';
import { CommunesPage } from './commune/communes.page';
import { CommuneServicesPage } from './commune/commune-services/commune-services.page';
import { BdoErrorsPage } from './bdo-errors/bdo-errors.page';
import { Reports } from './reports/reports';
import { BdoRejectedCardsPage } from './bdo-rejected-cards/bdo-rejected-cards.page';
import { BdoRejectedCardsByProjectPage } from './bdo-rejected-cards/bdo-rejected-cards-by-project.page';
import { BdoRejectedCardsByProjectBranchPage } from './bdo-rejected-cards/bdo-rejected-cards-by-project-branch.page';
import CommuneServiceDeclarations from './projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/commune-tab/commune-service-declarations';
import { MapsContainer } from './maps/maps-container';

// OPTIONAL TODO: https://stackoverflow.com/a/71075773/777344
export const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LeftMenuLayout />}>
        <Route index element={<Navigate to='/projects' />} />
        <Route path='projects'>
          <Route index element={<Projects />} />
          <Route path=':projectId'>
            <Route index element={<ViewProject />} />
            <Route path=':branchId'>
              <Route index element={<ProjectBranchView />} />
              <Route path='commune-declarations' element={<CommuneServiceDeclarations />} />
            </Route>
          </Route>
        </Route>
        <Route path='companies'>
          <Route index element={<CompaniesPage />} />
          <Route path=':companyId'>
            <Route index element={<CompanyView />} />
            <Route path=':branchId' element={<CompanyBranchView />} />
          </Route>
        </Route>
        <Route path='maps'>
          <Route index element={<MapsContainer />} />
        </Route>
        <Route path='collection-requests'>
          <Route index element={<CollectionRequests />} />
          <Route path=':collectionRequestId' element={<DeclarationChangesDetails />} />
        </Route>
        <Route path='month-end-closing' element={<MonthEndClosing />} />
        <Route path='global-settings' element={<GlobalSettings />} />
        <Route path='registers' element={<Registers />} />
        <Route path='communes'>
          <Route index element={<CommunesPage />} />
          <Route path=':communeId' element={<CommuneServicesPage />} />
        </Route>
        <Route path='bdo-errors' element={<BdoErrorsPage />} />
        <Route path='bdo-rejected-cards'>
          <Route index element={<BdoRejectedCardsPage />} />
          <Route path=':projectId' element={<BdoRejectedCardsByProjectPage />} />
          <Route path=':projectId/:branchId' element={<BdoRejectedCardsByProjectBranchPage />} />
        </Route>
        <Route path='reports' element={<Reports />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
