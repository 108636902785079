import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';
import { CompanyAddress, ProjectView } from '@lib/api-interface';

export enum CompanyRole {
  CLIENT = 'CLIENT',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
}

export class ProjectBranchContactPerson {
  @Expose()
  contactPersonId?: string;
  @Expose()
  firstName?: string;
  @Expose()
  lastName?: string;
  @Expose()
  email?: string;
  @Expose()
  phone?: string;
  @Expose()
  comment?: string;
}

export class ProjectBranchViewModel extends BaseModel {
  @Expose()
  projectBranchId?: string; // Aggregate id
  @Expose()
  projectId?: string; // Aggregate id
  @Expose()
  projectCompanyId?: string; // Aggregate id
  @Expose()
  companyId?: string; // Aggregate id
  @Expose()
  branchId?: string; // aggregate - nested - id (nested in company aggregate)
  @Expose()
  branchNestedNumber?: number;

  @Expose()
  companyName?: string;

  @Expose()
  companyBranchName?: string;

  @Expose()
  central?: boolean;

  @Expose()
  nip?: string;

  @Expose()
  correspondenceAddress?: CompanyAddress;

  @Expose()
  registrationAddress?: CompanyAddress;

  @Expose()
  role?: CompanyRole;

  @Expose()
  contactPerson?: ProjectBranchContactPerson; // may be undefined

  @Expose()
  projectName?: string;
  @Expose()
  projectNumber?: string;
  @Expose()
  project?: ProjectView;
  @Expose()
  deleted?: boolean;
  @Expose()
  contractConclusionDate?: Date;
}
