import { GeneralForm, HStack } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';

interface YearFilterBarProps {
  onFilterChanged: (filters: YearFilterBarPropsValues) => void;
  initialYear: string;
  maxYear?: Date;
  minYear?: Date;
}

export interface YearFilterBarPropsValues {
  date: string;
}

export const YearFilterBar = (props: YearFilterBarProps) => {
  const { t } = useTranslation();

  return (
    <HStack align='left'>
      <GeneralForm
        fields={[
          {
            rowItems: [
              {
                field: 'date',
                type: 'date-year',
                label: t('year'),
                clearableDate: false,
                defaultValue: props.initialYear,
                maxDate: props.maxYear,
                minDate: props.minYear,
              },
            ],
          },
        ]}
        variant='standard'
        hideSubmitButton={true}
        hideCancelButton={true}
        onChanged={(data) => {
          props.onFilterChanged(data);
        }}
      />
    </HStack>
  );
};
