import React, { useCallback, useMemo, useState } from 'react';
import { ApiCallButton, Button, DataTable, Dialog, HStack } from '@lib/ui-components';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { HateoasRestApiClientService } from '@lib/common-sdk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryResultRenderer } from '../../../../libs/ui-components/components/query-result-renderer';

interface AccountAssignedProject {
  accountId: string;
  projectId: string;
}

export interface AssignProjectsButtonProps {
  accountId: string;
  onSaved: (rows: any[]) => void;
  projectSingleSelect?: boolean;
}

export const AssignProjectsButton = ({ accountId, onSaved, projectSingleSelect }: AssignProjectsButtonProps) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} label={t('assignProjects')} />
      <Dialog open={openDialog} maxWidth={false} fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AssignProjectsButtonDialogContentWithDataFetcher
            accountId={accountId}
            onSaved={(rows) => {
              handleCloseDialog();
              onSaved(rows);
            }}
            projectSingleSelect={projectSingleSelect}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AssignProjectsButtonDialogContentWithDataFetcher = ({ accountId, onSaved, projectSingleSelect }: AssignProjectsButtonProps) => {
  const queryKey = ['accountAssignedProjects', 'byAccountId', accountId];
  const query = useQuery(queryKey, () =>
    HateoasRestApiClientService.findAll<AccountAssignedProject>('accountAssignedProjects', { accountId }, undefined, {
      search: 'byAccountId',
    }),
  );

  return (
    <QueryResultRenderer
      query={query}
      render={(result) => {
        return <AssignProjectsButtonDialogContent accountId={accountId} projects={result.response!} onSaved={onSaved} projectSingleSelect={projectSingleSelect} />;
      }}
    />
  );
};

export interface AssignProjectsButtonDialogProps {
  accountId: string;
  onSaved: (rows: any[]) => void;
  projects: AccountAssignedProject[];
  projectSingleSelect?: boolean;
}

const AssignProjectsButtonDialogContent = ({ accountId, onSaved, projects, projectSingleSelect }: AssignProjectsButtonDialogProps) => {
  const queryKey = ['accountAssignedProjects', 'byAccountId', accountId];
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState(new Array<any>());
  const [refreshKey, setRefreshKey] = useState(0);
  const queryClient = useQueryClient();

  // Optimize the setSelectedRows function using useCallback
  const handleSelectedRowsChange = useCallback(
    (newSelectedRows: any[]) => {
      function areArraysEqual(arr1: any[], arr2: any[]): boolean {
        if (arr1.length !== arr2.length) {
          return false;
        }
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i].projectId !== arr2[i].projectId) {
            return false;
          }
        }
        return true;
      }

      if (!areArraysEqual(selectedRows, newSelectedRows)) {
        setSelectedRows(newSelectedRows);
      }
    },
    [], // No dependencies since setSelectedRows is constant
  );

  const dataTable = useMemo(
    () => (
      <DataTable
        columns={[
          { key: 'projectNumber', label: t('projectNumber'), type: 'text' },
          { key: 'name', label: t('name'), type: 'text' },
        ]}
        refreshKey={refreshKey}
        modelDef={{ modelName: 'projectViews' }}
        selectable={true}
        selectableOptions={{
          onSelectedRowsChange: handleSelectedRowsChange,
          initialSelections: projects,
          comparatorField: 'projectId',
          singleSelect: projectSingleSelect,
        }}
        contentMaxHeightAsAlmostOneScreen={true}
      />
    ),
    [refreshKey, projects],
  );

  return (
    <>
      {dataTable}
      <HStack align={'right'}>
        <ApiCallButton
          variant={'contained'}
          color={'secondary'}
          url={'/api/account/assign-projects'}
          payload={{ accountId, projectIds: selectedRows.map((row) => row.projectId) }}
          onCompleted={async () => {
            onSaved(selectedRows);
            await queryClient.invalidateQueries(queryKey);
            setRefreshKey((prevState) => prevState + 1);
          }}
          buttonLabel={t('assignProjects')}
        />
      </HStack>
    </>
  );
};
