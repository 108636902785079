import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';

export enum ServicePriceType {
  M3 = 'M3',
  UNIT = 'UNIT',
  MG = 'MG',
}

export enum Currency {
  PLN = 'PLN',
  EUR = 'EUR',
}

export enum ServicePriceBearer {
  ORGANIZATION_COST = 'ORGANIZATION_COST',
  ORGANIZATION_REVENUE = 'ORGANIZATION_REVENUE',
}

export class ServicePrice {
  @Expose()
  @IsNotEmpty()
  price?: number;

  @Expose()
  @IsNotEmpty()
  priceType?: ServicePriceType;

  @Expose()
  @IsNotEmpty()
  currency?: Currency;

  @Expose()
  @IsNotEmpty()
  bearer?: ServicePriceBearer;

  @Expose()
  navResourceId?: string;
}
