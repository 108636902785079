import React from 'react';
import { Button } from '../../button/button';
import { To, useNavigate } from 'react-router-dom';

export interface NavigateButtonProps {
  label: string;
  to?: To; // if undefined - navigate back in history
  tooltip?: string;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: JSX.Element;
}

export const NavigateButton = (props: NavigateButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      label={props.label}
      startIcon={props.icon}
      tooltip={props.tooltip}
      variant={props.variant}
      onClick={async () => {
        if (props.to) {
          navigate(props.to);
        } else {
          navigate(-1);
        }
      }}
    />
  );
};
