import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DataTable, HStack, TextLabel, ToggleButtons } from '@lib/ui-components';
import { ContactFormDetails } from './contact-form-details';
import { handleDeactivateContactForm } from './contact-form-deactivation-handler';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface ContactFormsProps {
  isForClientAdmin?: boolean;
}

export const ContactForms = ({ isForClientAdmin = false }: ContactFormsProps) => {
  const { t } = useTranslation();
  const baseGetUrl = isForClientAdmin ? '/api/client-admin/contact-forms' : '/api/contact-forms';
  const [activePage, setActivePage] = React.useState(0);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [dateFilter, setDateFilter] = React.useState(new Date());

  const changeMonthFilter = (monthToAdd: number) => {
    const shiftedDate = new Date(dateFilter);
    shiftedDate.setMonth(shiftedDate.getMonth() + monthToAdd);
    setDateFilter(shiftedDate);
    setTableReloadKey(tableReloadKey + 1);
  };

  function formatDateToYearMonth(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
  }

  return (
    <>
      <HStack align='center'>
        <ToggleButtons labels={[t('open'), t('history')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 1 && (
        <HStack align='left' vAlign='bottom' gap='3rem'>
          <TextLabel label={t('selectedPeriod')} value={dateFilter.toISOString()} dateFormat={'MM.yyyy'} direction={'row'} />
          <HStack align='left' vAlign='bottom' gap='0.3rem'>
            <Button label={t('previousMonth')} color='primary' variant='text' startIcon={<ArrowBackIos color={'secondary'} />} onClick={() => changeMonthFilter(-1)} />
            <Button label={t('nextMonth')} color='primary' variant='text' endIcon={<ArrowForwardIos color={'secondary'} />} onClick={() => changeMonthFilter(1)} />
          </HStack>
        </HStack>
      )}
      <DataTable
        excel={true}
        refreshKey={tableReloadKey}
        fetchUrl={`${baseGetUrl}${activePage === 1 ? '/history' : ''}`}
        fetchFilters={activePage === 1 ? { yearMonth: formatDateToYearMonth(dateFilter) } : {}}
        initialSort={{ columnIndex: 3, ascending: false }}
        columns={[
          {
            key: 'senderLogin',
            label: t('senderLogin'),
            type: 'text',
          },
          {
            key: 'companyName',
            label: t('companyName'),
            type: 'text',
          },
          {
            key: 'companyBranchName',
            label: t('companyBranch'),
            type: 'text',
          },
          {
            key: 'creationDate',
            label: t('dateSent'),
            type: 'date-time',
          },
          {
            key: 'message',
            label: t('message'),
            type: 'text',
            minWidth: '15rem',
            singleLine: {
              maxCellWidthInRem: 50,
            },
          },
          {
            key: 'phone',
            label: t('phoneNumber'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <ContactFormDetails name={row.senderName} phone={row.phone} sendAt={row.creationDate} message={row.message} />,
          },
          {
            renderer: (row) => (
              <Button
                label={t('done')}
                variant={'contained'}
                color={'secondary'}
                onClick={() => {
                  handleDeactivateContactForm(row.id).then(() => setTableReloadKey(tableReloadKey + 1));
                }}
              />
            ),
            isVisible: () => activePage === 0,
          },
        ]}
      />
    </>
  );
};
