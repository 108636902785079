import React, { useState } from 'react';
import { TitlePositioner } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { AddIcon, AggregateCommandButton, CheckboxLabel, DataTable, Edit, GridLabel, Panel, TextLabel, Toolbar, UsecaseDeleteButton } from '@lib/ui-components';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { useQueryClient } from '@tanstack/react-query';

export interface ClientBDOProps {
  company: CompanyViewModel;
  companyRole: CompanyRole;
}

export function ClientBDO({ company, companyRole }: ClientBDOProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [tableReloadKey, setTableReloadKey] = useState(1);

  async function invalidateCompany() {
    await queryClient.invalidateQueries(['companyViews', company.companyId]);
  }

  return (
    <Panel>
      <Panel direction='row' borderType='raised'>
        <Panel flex='1'>
          <TitlePositioner>
            <TextLabel label={t('bdo')} />
          </TitlePositioner>
          <Panel borderType='flat'>
            <GridLabel>
              <GridLabel>
                <TextLabel label={t('Model.CompanyBranch.bdoNumber')} value={company.bdoIdentificationNumber} gridColumn='1/3' />
              </GridLabel>
              <GridLabel>
                <CheckboxLabel label={t('Model.Company.VerifiedBdo')} type='toggle' value={company.verifiedBdo} />
                <GridLabel gridTemplateColumns='none' justifyContent='end'>
                  <AggregateCommandButton
                    aggregateId={company.companyId!}
                    aggregateName='CompanyAggregate'
                    commandName='updateCompanyBdo'
                    startIcon={<Edit />}
                    dialogTitle={t('bdoSettings').toUpperCase()}
                    fields={[
                      {
                        label: t('bdoIdentificationNumber'),
                        type: 'table-select-by-api',
                        field: 'bdoIdentificationNumber',
                        tableSelectByApiParams: {
                          apiPostEndpoint: '/api/organization/bdo/search/company',
                          dialogTitle: t('bdoIdentificationNumber'),
                          displayFormat: '{registrationNumber}',
                          selectedValueField: 'registrationNumber',
                          initialEntity: company,
                          columns: [
                            {
                              label: t('bdoIdentificationNumber'),
                              key: 'registrationNumber',
                              type: 'text',
                            },
                            { label: t('name'), key: 'name', type: 'text' },
                            { label: t('nip'), key: 'nip', type: 'text' },
                            { label: t('postcode'), key: 'postalCode', type: 'text' },
                            { label: t('addressInformation'), key: 'address', type: 'text' },
                          ],
                          otherValuesMap: {
                            companyId: 'bdoCompanyId',
                          },
                          queryFields: [
                            {
                              label: t('bdoSearch'),
                              type: 'text',
                              field: 'query',
                            },
                          ],
                        },
                      },
                      {
                        field: 'verifiedBdo',
                        label: t('Model.Company.VerifiedBdo'),
                        type: 'boolean',
                        defaultValue: company.verifiedBdo,
                      },
                    ]}
                    onSaved={() => invalidateCompany()}
                    hiddenValues={{
                      companyId: company.companyId,
                      bdoCompanyId: company.bdoCompanyId,
                    }}
                  />
                </GridLabel>
              </GridLabel>
            </GridLabel>
          </Panel>
        </Panel>
      </Panel>
      <Toolbar
        right={[
          <AggregateCommandButton
            aggregateName='CompanyAggregate'
            aggregateId={company.companyId!}
            commandName='addCompanyBdoKey'
            buttonLabel={t('add')}
            startIcon={<AddIcon />}
            fields={[
              { field: 'clientId', label: t('clientId'), type: 'text' },
              { field: 'clientSecret', label: t('clientSecret'), type: 'text' },
              { field: 'description', label: t('description'), type: 'text' },
              { field: 'forKpoInsert', label: t('forKpoInsert'), type: 'boolean' },
              { field: 'forKpoUpdate', label: t('forKpoUpdate'), type: 'boolean' },
              { field: 'forKeo', label: t('forKeo'), type: 'boolean' },
            ]}
            hiddenValues={{ companyRole: companyRole }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        modelDef={{ modelName: 'companyBdoKeyViews' }}
        fetchFilters={{
          companyId: company.companyId,
          companyRole: companyRole,
        }}
        columns={[
          { key: 'clientId', label: t('clientId'), type: 'text' },
          {
            key: 'clientSecret',
            label: t('clientSecret'),
            type: 'text',
          },
          {
            key: 'description',
            label: t('description'),
            type: 'text',
          },
          { key: 'forKpoInsert', label: t('forKpoInsert'), type: 'boolean' },
          { key: 'forKpoUpdate', label: t('forKpoUpdate'), type: 'boolean' },
          { key: 'forKeo', label: t('forKeo'), type: 'boolean' },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <AggregateCommandButton
                aggregateName='CompanyAggregate'
                aggregateId={company.companyId!}
                commandName='updateCompanyBdoKey'
                startIcon={<Edit yellowIcon={true} />}
                buttonLabel={t('edit')}
                dialogTitle={t('bdoSettings').toUpperCase()}
                fields={[
                  {
                    field: 'description',
                    label: t('description'),
                    type: 'text',
                    defaultValue: row.description,
                  },
                  {
                    field: 'forKpoInsert',
                    label: t('forKpoInsert'),
                    type: 'boolean',
                    defaultValue: row.forKpoInsert,
                  },
                  {
                    field: 'forKpoUpdate',
                    label: t('forKpoUpdate'),
                    type: 'boolean',
                    defaultValue: row.forKpoUpdate,
                  },
                  { field: 'forKeo', label: t('forKeo'), type: 'boolean', defaultValue: row.forKeo },
                ]}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                hiddenValues={{ keyUuid: row.companyBdoKeyId }}
              />
            ),
          },
          {
            renderer: (row) => (
              <UsecaseDeleteButton
                useCaseName='RemoveCompanyBdoKeyUseCase'
                groupName='company'
                payload={{
                  companyId: company.companyId!,
                  uuid: row.companyBdoKeyId,
                }}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
        refreshKey={tableReloadKey}
      />
    </Panel>
  );
}
