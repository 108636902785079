import * as React from 'react';
import { HStack } from '../h-stack/h-stack';
import { Button } from '../button/button';

export interface ToggleButtonsProps {
  labels: string[];
  activeIndex?: number;
  onChange?: (index: number) => void;
  withUseEffect?: boolean;
}

export const ToggleButtons = (props: ToggleButtonsProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(props.activeIndex != null ? props.activeIndex : 0);

  props.withUseEffect &&
    React.useEffect(() => {
      if (props.activeIndex != null) {
        setSelectedIndex(props.activeIndex);
      }
    }, [props.activeIndex]);

  async function handleButtonClick(index: number) {
    setSelectedIndex(index);
    props.onChange?.(index);
  }

  const buttons = props.labels.map((label, index) => {
    return <Button key={index} label={label} onClick={() => handleButtonClick(index)} variant='contained' color={selectedIndex === index ? 'secondary' : 'info'} />;
  });
  return <HStack gap='0.3rem'>{buttons}</HStack>;
};
