import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '../../../../../../libs/ui-components';
import { InfrastructureTab } from './infrastructure-tab/infrastructure-tab';
import { WasteTab } from './waste-tab/waste-tab';
import { ProjectView } from '../../../../../../libs/api-interface';

export interface DictionaryTabProps {
  project?: ProjectView;
}

export const DictionaryTab = (props: DictionaryTabProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('Projects.ViewProject.Dictionary.wasteTab'), t('infrastructure')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 0 && <WasteTab />}
      {activePage === 1 && <InfrastructureTab />}
    </Panel>
  );
};
