import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { AllUserAccounts } from './all-user-accounts';
import { ProjectAccounts } from './project-accounts';

interface UserAccountsTabProps {
  projectId: string;
}

export default function UserAccountsTab({ projectId }: UserAccountsTabProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('all'), t('regionalAdmins')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 0 && <AllUserAccounts />}
      {activePage === 1 && <ProjectAccounts projectId={projectId} />}
    </Panel>
  );
}
