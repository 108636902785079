import React from 'react';
import { CompanyViewModel } from '@lib/api-interface';
import { RolesEnum } from '../../../../../../../domain/enums/roles.enum';
import { CompanyBranchAccounts } from '../../../shared/company-branch-accounts';

export interface ClientUsersProps {
  company: CompanyViewModel;
}

export const ClientUsers = (props: ClientUsersProps) => {
  return (
    <>
      <CompanyBranchAccounts companyId={props.company.companyId!} role={RolesEnum.CLIENT_USER} hasBranch={true} hasProjects={true} />
    </>
  );
};
