import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import { FormInputDatePlain } from './form-input-date-plain';

export interface FormInputDateProps extends FormInputProps {
  dateVariant?: 'date' | 'year-month' | 'year';
  clearableDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const FormInputDate = ({ name, control, label, variant, dateVariant, isDeactivated, clearableDate, minDate, maxDate }: FormInputDateProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <FormInputDatePlain
          label={label}
          variant={variant}
          dateVariant={dateVariant}
          error={error}
          onChange={onChange}
          defaultValue={value}
          isDeactivated={isDeactivated}
          clearableDate={clearableDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    />
  );
};
