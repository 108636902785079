import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { CompanyAddress } from '@lib/api-interface';
import { TitlePositioner } from '@shared/general';

export interface BranchAddressInfoProps {
  registrationAddress?: CompanyAddress;
  correspondenceAddress?: CompanyAddress;
}

export const BranchAddressInfo = (props: BranchAddressInfoProps) => {
  const { t } = useTranslation();

  return (
    <Panel direction='row' borderType='raised'>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('registrationAddress').toUpperCase()} />
        </TitlePositioner>
        <Panel borderType='flat'>
          <GridLabel gridTemplateColumns='8fr 1fr'>
            <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
              <TextLabel label={t('street')} value={props.registrationAddress?.street} direction={'column'} />
              <TextLabel label={t('houseNum')} value={props.registrationAddress?.houseNum} direction={'column'} />
              <TextLabel label={t('apartmentNum')} value={props.registrationAddress?.apartmentNum} direction={'column'} />
              <TextLabel label={t('locality')} value={props.registrationAddress?.town} direction={'column'} />
              <TextLabel label={t('postcode')} value={props.registrationAddress?.postalCode} direction={'column'} />
              <TextLabel label={t('commune')} value={props.registrationAddress?.commune} direction={'column'} />
              <TextLabel label={t('province')} value={props.registrationAddress?.province} direction={'column'} />
              <TextLabel label={t('country')} value={props.registrationAddress?.country} direction={'column'} />
            </GridLabel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'></GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
      <Panel flex='1'>
        <TitlePositioner>
          <TextLabel label={t('Companies.CompanyView.CompanyInfo.correspondenceDataHeader').toUpperCase()} />
        </TitlePositioner>
        <Panel borderType='flat'>
          <GridLabel gridTemplateColumns='8fr 1fr'>
            <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
              <TextLabel label={t('street')} value={props.correspondenceAddress?.street} direction={'column'} />
              <TextLabel label={t('houseNum')} value={props.correspondenceAddress?.houseNum} direction={'column'} />
              <TextLabel label={t('apartmentNum')} value={props.correspondenceAddress?.apartmentNum} direction={'column'} />
              <TextLabel label={t('locality')} value={props.correspondenceAddress?.town} direction={'column'} />
              <TextLabel label={t('postcode')} value={props.correspondenceAddress?.postalCode} direction={'column'} />
              <TextLabel label={t('commune')} value={props.correspondenceAddress?.commune} direction={'column'} />
              <TextLabel label={t('province')} value={props.correspondenceAddress?.province} direction={'column'} />
              <TextLabel label={t('country')} value={props.correspondenceAddress?.country} direction={'column'} />
            </GridLabel>
            <GridLabel gridTemplateColumns='none' justifyContent='flex-end'></GridLabel>
          </GridLabel>
        </Panel>
      </Panel>
    </Panel>
  );
};
