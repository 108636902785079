import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, Paper, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthApiService } from '../../../services/auth.api.service';
import { FormInputPassword, Snackbar } from '../../../libs/ui-components';
import styled from 'styled-components';

interface IFormInput {
  password: string;
}

export const ActivatePage = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [activationFailed, setActivationFailed] = React.useState(false);
  const [generalFailure, setGeneralFailure] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .required(t('ActivatePage.passwordRequired'))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('ActivatePage.passwordRequirements')),
    confirmPassword: Yup.string()
    .required(t('ActivatePage.confirmPasswordRequired'))
    .oneOf([Yup.ref('password'), null], t('ActivatePage.confirmPasswordMatch'))
  });

  const {handleSubmit, control} = useForm<IFormInput>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    setActivationFailed(false);
    setGeneralFailure(false);
    try {
      const result = await AuthApiService.activateUser({
        password: data.password,
        jwt: searchParams.get('jwt')!
      });
      if (result?.httpStatus) {
        setActivationFailed(true);
      } else {
        navigate('/login');
      }
    } catch (e) {
      setGeneralFailure(true);
    } finally {
      setLoading(false);
    }
  };

  const ResponsivePaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    height: '400px',
    width: '400px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    '@media (max-width:700px)': {
      top: '0',
      left: '0',
      transform: 'none',
      width: '100vw',
      height: '100vh',
      borderRadius: '0',
    },
  });

  return (
    <ResponsivePaper elevation={3}>
        <Typography component="h1" variant="h5" sx={{marginBottom: '1rem'}}>
          {t('ActivatePage.header')}
        </Typography>
        <Box
            component="form"
            noValidate
            sx={{mt: 1, width: '330px'}}
            onKeyPress={(e: any) => {
              handleSubmit(onSubmit)();
            }}
        >
          <FormInputPassword name="password" control={control} label={t('ActivatePage.password')}/>
          <FormInputPassword name="confirmPassword" control={control} label={t('ActivatePage.confirmPassword')}/>

          <FormHelperText error={true} hidden={!activationFailed}>
            {t('ActivatePage.activationFailed')}
          </FormHelperText>

          <LoadingButton onClick={handleSubmit(onSubmit)} variant={'contained'} fullWidth sx={{mt: 3, mb: 2}} loading={loading}>
            {t('ActivatePage.button')}
          </LoadingButton>
        </Box>
        <Snackbar open={generalFailure} message={t('Common.error')} severity="error" onClose={() => setGeneralFailure(false)}/>
    </ResponsivePaper>
  );
};
