import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, Snackbar, TrashcanIcon } from '@lib/ui-components';
import React from 'react';
import axios from 'axios';

export interface UsecaseDeleteButtonProps {
  groupName?: string;
  useCaseName: string;
  payload: any;
  tooltip?: string;
  onDeleted: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  postErrorMappings?: Record<string, string>;
}

export const UsecaseDeleteButton = (props: UsecaseDeleteButtonProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [failureMessage, setFailureMessage] = useStateIfMounted('');

  function convertToKebabCaseRemoveUseCaseIfExists(input: string): string {
    let convertedString = input
      .replace(/([a-z])([A-Z])/g, '$1-$2') // Convert camelCase to kebab-case
      .replace(/([A-Z])([A-Z])/g, '$1-$2') // Convert acronyms to kebab-case
      .toLowerCase(); // Convert all characters to lowercase

    if (convertedString.endsWith('-use-case')) {
      convertedString = convertedString.slice(0, convertedString.length - '-use-case'.length);
    }

    return convertedString;
  }

  async function handleDelete() {
    try {
      const results = await axios.request({
        method: 'POST',
        url: `${process.env['REACT_APP_USECASE_URL']}${props.groupName ? `/${props.groupName}` : ''}/${convertToKebabCaseRemoveUseCaseIfExists(props.useCaseName)}`,
        data: { ...props.payload },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (results.status < 400) {
        props.onDeleted();
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.status}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }
        setFailureMessage(errorKeys[JSON.parse(results.data)?.code] || t('Common.error'));
      }
    } catch (e: any) {
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = typeof data === 'string' ? JSON.parse(data)?.code : data.code;
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(errorKeys[code] || t('Common.error'));
    }
  }

  return (
    <>
      <Button
        label={t('Common.delete')}
        startIcon={<TrashcanIcon />}
        tooltip={props.tooltip}
        color='primary'
        forceConfirmation={true}
        variant={props.variant}
        onClick={async () => handleDelete()}
      />
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};
