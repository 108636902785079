import { GeneralForm, HStack } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

interface YearMonthFilterBarProps {
  onFilterChanged: (filters: YearMonthFilterBarValues) => void;
  initialMonthYear: string;
  maxYearMonth?: Date;
  minYearMonth?: Date;
  showSearch?: boolean;
}

export interface YearMonthFilterBarValues {
  date: string;
  filter: string;
}

export const YearMonthFilterBar = (props: YearMonthFilterBarProps) => {
  const { t } = useTranslation();

  const debouncedValueChange = useDebouncedCallback((value) => {
    props.onFilterChanged(value);
  }, 300);

  return (
    <HStack align='left'>
      <GeneralForm
        fields={[
          {
            rowItems: [
              {
                field: 'date',
                type: 'date-year-month',
                label: t('month'),
                clearableDate: false,
                defaultValue: props.initialMonthYear,
                maxDate: props.maxYearMonth,
                minDate: props.minYearMonth,
              },
              { field: 'filter', type: 'text', label: t('search'), isVisible: () => props.showSearch === true },
            ],
          },
        ]}
        variant='standard'
        hideSubmitButton={true}
        hideCancelButton={true}
        onChanged={(data) => {
          if (props.showSearch == true) {
            debouncedValueChange(data);
          } else {
            props.onFilterChanged(data);
          }
        }}
      />
    </HStack>
  );
};
