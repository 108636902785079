import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { CompanyBranchCommercialDoc } from './company-branch-commercial-doc';
import { CompanyBranchOtherFiles } from './company-branch-other-files';
import { useCompanyBranchContext } from '@context';

export const CompanyBranchFiles = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const branchView = useCompanyBranchContext();

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('commercialDocument'), t('otherFiles')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>

      {activePage === 0 && <CompanyBranchCommercialDoc branchView={branchView} />}
      {activePage === 1 && <CompanyBranchOtherFiles branchView={branchView} />}
    </Panel>
  );
};
