import { ServicePriceBearer } from '@lib/api-interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  DataTableCustomValueMapping,
  HStack,
  Panel,
  RenderIf,
  Snackbar,
  TableFilterItem,
  ToggleButtons,
  UsecaseButton,
  YearMonthFilterBar,
  YearMonthFilterBarValues,
} from '@lib/ui-components';
import { Add } from '@mui/icons-material';
import { Checkbox, InputLabel, TextField } from '@mui/material';
import { priceTypeEnumValues } from '../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { StyledBooleanTrueSign } from '../../../libs/ui-components/components/data-table/StyledBooleanTrueSign';
import { StyledBooleanFalseSign } from '../../../libs/ui-components/components/data-table/StyledBooleanFalseSign';
import { useUserState } from '../../../state/UserState';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import { GreenRedDotIndicator } from '../../../libs/ui-components/components/indicator/green-red-dot-indicator';
import axios from 'axios';

export interface InvoicesProps {
  bearer: ServicePriceBearer;
  onDefaultFiltersUpdated?: (filters: Array<TableFilterItem>) => void;
  instanceFiltersRef: React.MutableRefObject<TableFilterItem[]>;
}

export const Invoices = ({ bearer, onDefaultFiltersUpdated, instanceFiltersRef }: InvoicesProps) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [branches, setBranches] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState(new Array<any>());
  const [batchInvoiceNumber, setBatchInvoiceNumber] = useState('');
  const [batchInvoiceComment, setBatchInvoiceComment] = useState('');
  const { t } = useTranslation();
  const owners = ['CLIENT', 'SUBCONTRACTOR'];
  const userState = useUserState();
  const canAddInvoiceNumber = userState.authorities.includes(AuthoritiesEnum.ORGANIZATION_ADMIN) || userState.authorities.includes(AuthoritiesEnum.PROJECT_INVOICE);
  const [showBatchUpdateCalledMEssage, setShowBatchUpdateCalledMessage] = useState(false);
  const [hasPendingUpdateCommand, setHasPendingUpdateCommand] = useState(null);

  const now = new Date();
  const month = now.getMonth() + 1;
  const yearMonthNow = `${now.getFullYear()}-${month > 9 ? month : '0' + month}`;
  const savedFilters = sessionStorage.getItem('invoicesFilters');
  const initialFilters = savedFilters
    ? JSON.parse(savedFilters)
    : {
        date: yearMonthNow,
        filter: '',
      };
  const [activeFilters, setActiveFilters] = React.useState<YearMonthFilterBarValues>(initialFilters);

  function handleAggregationChange(value: boolean) {
    setBranches(value);
    setRefreshKey(refreshKey + 1);
  }

  function handlePageChange(index: number) {
    setActivePage(index);
    setRefreshKey(refreshKey + 1);
  }

  async function handleBatchChangeSuccess() {
    setBatchInvoiceNumber('');
    setBatchInvoiceComment('');
    setRefreshKey(refreshKey + 1);
    setShowBatchUpdateCalledMessage(true);
  }

  function saveActiveFilters(filters: YearMonthFilterBarValues) {
    sessionStorage.setItem('invoicesFilters', JSON.stringify(filters));
  }

  const customValueMappings: DataTableCustomValueMapping[] = [
    { value: true, label: <StyledBooleanTrueSign />, stringLabel: t('true') },
    { value: false, label: <StyledBooleanFalseSign />, stringLabel: t('false') },
    { value: null, label: '-', excludeFromFilter: true, stringLabel: '' },
    { value: undefined, label: '-', excludeFromFilter: true, stringLabel: '' },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/invoice/hasPendingUpdateNumberAndCommentCommand');
        setHasPendingUpdateCommand(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [refreshKey]);

  const batchUpdateFields = React.useMemo(() => {
    if (selectedInvoices.length === 0) return null;

    const commonFields = (
      <>
        <TextField
          inputProps={{ 'data-test-id': 'batch-invoice-comment-text-field' }}
          key='batch-invoice-comment-text-field'
          variant='standard'
          label={t('comment')}
          onChange={(event) => setBatchInvoiceComment(event.target.value)}
        />
        <UsecaseButton
          dataTestId='add-invoice-number-and-comment-button'
          groupName='invoice'
          useCaseName={canAddInvoiceNumber ? 'add-invoice-number-and-comment' : 'add-invoice-comment'}
          buttonLabel={t('assign')}
          variant='outlined'
          hiddenValues={{
            invoiceIds: selectedInvoices.flatMap((invoice) => invoice.invoiceIds),
            invoiceNumber: canAddInvoiceNumber ? batchInvoiceNumber : undefined,
            comment: batchInvoiceComment,
          }}
          isDisabled={hasPendingUpdateCommand !== null && hasPendingUpdateCommand}
          onSaved={handleBatchChangeSuccess}
        />
        <GreenRedDotIndicator showRedLight={hasPendingUpdateCommand !== null && hasPendingUpdateCommand} />
      </>
    );

    return (
      <HStack align='left' gap='1em'>
        {canAddInvoiceNumber && (
          <TextField
            inputProps={{ 'data-test-id': 'batch-invoice-number-text-field' }}
            key='batch-invoice-number-text-field'
            variant='standard'
            label={t('invoiceNumber')}
            onChange={(event) => setBatchInvoiceNumber(event.target.value)}
          />
        )}
        {commonFields}
      </HStack>
    );
  }, [selectedInvoices, batchInvoiceNumber, batchInvoiceComment, canAddInvoiceNumber, hasPendingUpdateCommand]);

  const dataTable = React.useMemo(() => {
    return (
      <DataTable
        selectable={true}
        selectableOptions={{
          onSelectedRowsChange: setSelectedInvoices,
          comparatorField: 'invoiceIds',
        }}
        refreshKey={refreshKey}
        modelDef={{
          modelName: branches ? 'invoiceDbViews' : 'invoiceAggregatedDbViews',
        }}
        fetchFilters={{
          priceBearer: bearer,
          invoiceOwner: owners[activePage],
          yearMonth: activeFilters.date,
        }}
        defaultTableFilters={instanceFiltersRef.current}
        onDefaultTableFiltersUpdated={(filters) => {
          if (onDefaultFiltersUpdated !== undefined) {
            onDefaultFiltersUpdated(filters);
          }
        }}
        excel={true}
        columns={[
          { key: 'yearMonth', label: t('month'), type: 'date-year-month', excludeFromFilter: true },
          { key: 'companyName', label: t('companyName'), type: 'text' },
          { key: 'branchName', label: t('branchName'), type: 'text' },
          { key: 'projectName', label: t('projectName'), type: 'text' },
          { key: 'projectNumber', label: t('projectNumber'), type: 'text' },
          { key: 'branchAddress.town', label: t('branchCity'), type: 'text' },
          { key: 'branchAddress.street', label: t('branchStreet'), type: 'text' },
          { key: 'resource', label: t('wasteCode'), type: 'text' },
          { key: 'resourceDescription', label: t('wasteDescription'), type: 'text' }, //
          {
            key: 'invoiceBearer',
            label: t('concerns'),
            type: 'enum',
            joinedColumns: [{ key: 'implementationDate', label: '', type: 'date' }],
            enumValues: [
              { value: 'CLIENT_TRANSPORT', label: t('clientTransport') },
              { value: 'SUBCONTRACTOR_TRANSPORT', label: t('subcontractorTransport') },
              { value: 'CLIENT_COLLECTION', label: t('clientCollection') },
              { value: 'SUBCONTRACTOR_COLLECTION', label: t('subcontractorCollection') },
              { value: 'CLIENT', label: t('clientLease') },
              { value: 'SUBCONTRACTOR', label: t('subcontractorLease') },
            ],
          },
          { key: 'servicePrice.stringValue', label: t('pricePerUnit'), type: 'text' },
          { key: 'totalReportedValue', label: t('declaredSum'), type: 'numeric', numericPrecision: 4 },
          {
            key: 'totalPricePLN',
            label: `${t('totalPrice')} (PLN)`,
            type: 'numeric',
            numericPrecision: 2,
            bold: true,
          },
          {
            key: 'totalPriceEUR',
            label: `${t('totalPrice')} (EUR)`,
            type: 'numeric',
            numericPrecision: 2,
            bold: true,
          },
          { key: 'priceType', label: t('priceType'), type: 'enum', enumValues: priceTypeEnumValues(t) },
          {
            key: 'einvoiceAgreed',
            label: t('acceptElectronicInvoices'),
            type: 'enum',
            customValueMappings: customValueMappings,
          },
          {
            key: 'organizationAsSide',
            label: t('organizationAsSideShortened'),
            type: 'enum',
            customValueMappings: customValueMappings,
          },
          { key: branches ? 'invoiceNumber' : 'invoiceNumbers', label: t('invoiceNumber'), type: 'text' },
          { key: branches ? 'comment' : 'comments', label: t('comment'), type: 'text' },
        ]}
        rowOptions={[
          {
            isVisible: () => canAddInvoiceNumber,
            renderer: (row: any) => (
              <UsecaseButton
                dataTestId={`add-invoice-number-${row.invoiceId}`}
                onSaved={async () => setRefreshKey(refreshKey + 1)}
                groupName='invoice'
                useCaseName='add-invoice-number'
                startIcon={<Add />}
                buttonLabel={t('invoiceNumber')}
                fields={[
                  {
                    field: 'invoiceNumber',
                    label: t('invoiceNumber'),
                    type: 'text',
                    defaultValue: row.invoiceNumber,
                  },
                ]}
                hiddenValues={{ invoiceIds: row.invoiceIds }}
              />
            ),
          },
          {
            renderer: (row: any) => (
              <UsecaseButton
                dataTestId={`add-invoice-comment-${row.invoiceId}`}
                onSaved={async () => setRefreshKey(refreshKey + 1)}
                groupName='invoice'
                useCaseName='add-invoice-comment'
                startIcon={<Add />}
                buttonLabel={t('comment')}
                fields={[
                  {
                    field: 'comment',
                    label: t('comment'),
                    type: 'text',
                    defaultValue: row.comment,
                  },
                ]}
                hiddenValues={{ invoiceIds: row.invoiceIds }}
              />
            ),
          },
        ]}
      />
    );
  }, [refreshKey, bearer]);

  return (
    <>
      <Panel>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '33%' }}>
            <YearMonthFilterBar
              onFilterChanged={(filters) => {
                setActiveFilters(filters);
                saveActiveFilters(filters);
                setRefreshKey(refreshKey + 1);
              }}
              initialMonthYear={activeFilters.date}
            />
          </div>
          <div style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
            <ToggleButtons labels={[t('client'), t('subcontractor')]} activeIndex={activePage} onChange={(index) => handlePageChange(index)} />
          </div>
          <div style={{ width: '33%', display: 'flex', justifyContent: 'right' }}>
            <HStack>
              <InputLabel>{t('branches')}</InputLabel>
              <Checkbox checked={branches} onChange={(event, checked) => handleAggregationChange(checked)} />
            </HStack>
          </div>
        </div>
      </Panel>
      <Panel>{batchUpdateFields}</Panel>
      {dataTable}
      <RenderIf true={showBatchUpdateCalledMEssage}>
        <Snackbar message={t('addInvoiceNumberAndCommentProcessStarted')} severity={'info'} onClose={() => setShowBatchUpdateCalledMessage(false)} />
      </RenderIf>
    </>
  );
};
