import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { dateTimeFormat } from '@lib/api-interface';
import { DataTable, GetContentButton, Toolbar } from '@lib/ui-components';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CreateReportButton } from '../financial/create-report-button';
import { FileDownload } from '@mui/icons-material';
import { MonthClosingReportType } from '../../../../libs/api-interface/models/report/month-closing-report-type.enum';
import { useWebSocketState } from '../../../../state/web-socket-state';

export const MonthEndClosingReports = () => {
  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(0);
  const webSocketState = useWebSocketState();
  const [selectedReportType, setSelectedReportType] = useState(MonthClosingReportType.MONTH_CLOSING_MISSING_INVOICES);

  React.useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, [webSocketState.reportGeneratedRefreshKey]);

  return (
    <>
      <Toolbar
        left={[
          <FormControl variant='standard' fullWidth>
            <InputLabel>{t('type')}</InputLabel>
            <Select defaultValue={selectedReportType} onChange={(event) => setSelectedReportType(event.target.value as MonthClosingReportType)}>
              {Object.values(MonthClosingReportType).map((val) => (
                <MenuItem key={`ReportType.${val}`} value={val}>
                  {t(`ReportType.${val}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>,
        ]}
        right={[<CreateReportButton reportType={selectedReportType} onSaved={async () => setRefreshKey((prevState) => prevState + 1)} />]}
      />

      <DataTable
        modelDef={{
          modelName: 'reportViews',
        }}
        fetchFilters={{ type: selectedReportType }}
        columns={[
          { label: t('reportFile'), key: 'fileName', type: 'text' },
          { label: t('creationDate'), key: 'creationDate', type: 'date', dateFormat: dateTimeFormat },
        ]}
        initialSort={{ columnIndex: 1, ascending: false }}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('download')} icon={<FileDownload />} resourcePath={`api/resource/reports/${row.id}/content`} />,
          },
        ]}
        refreshKey={refreshKey}
      />
    </>
  );
};
