import {
  AddIcon,
  AggregateCommandButton,
  Button,
  ButtonProps,
  DataTableColumnEnumValue,
  Edit,
  GeneralFormItem,
  GeneralFormRowOfItems,
  GeneralFormSelectOption,
  RenderIf,
} from '@lib/ui-components';
import { alpha, FormControlLabel, Stack, Switch } from '@mui/material';
import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';
import {
  InvoicingMethod,
  MASS_PRECISION,
  PaymentMethod,
  PaymentPeriod,
  PLN_PRECISION,
  ServicePrice,
  ServicePriceBearer,
  ServiceType,
  SettlementType,
  SettlementUnit,
} from '@lib/api-interface';
import { ServiceBar, ServiceBarItem } from './service-bar';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../../../../../../factories/infrastructure-category-select-options.factory';
import { ServiceButtonMode } from '../service-button-props';
import { ContentCopy } from '@mui/icons-material';
import { ProjectBranchInfrastructureServiceViewModel } from '../../../../../../../../../libs/api-interface/models/project/project-branch-infrastructure-service-view.model';
import i18n from 'i18next';

export const YellowSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#F2C94C',
    '&:hover': {
      backgroundColor: alpha('#F2C94C', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#F2C94C',
  },
}));

export const GreyButton = ({ label, onClick, startIcon, endIcon }: ButtonProps) => {
  return <Button variant={'contained'} color={'info'} label={label} onClick={onClick} startIcon={startIcon} endIcon={endIcon} />;
};

export function activeCompareFn(item1: any, item2: any) {
  if (item1.active === item2.active) return 0;
  return item1.active ? -1 : 1;
}

export function isDynamicScheduleType(service: any): boolean {
  return !service.onDemand && service.scheduleType === 'DYNAMIC';
}

export function isStaticScheduleType(service: any): boolean {
  return !service.onDemand && service.scheduleType === 'STATIC';
}

export function isTrue(value: ((data: any) => boolean) | boolean | undefined, data: any): boolean | undefined {
  return !(typeof value === 'boolean') ? value?.(data) : value;
}

export interface ServiceButtonConsts {
  label: string;
  useCaseName: string;
  startIcon: JSX.Element;
}

type TranslationKeys =
  | 'schedule'
  | 'scheduleType'
  | 'onDemand'
  | 'frequency'
  | 'calendar'
  | 'calendar.FIRST_OCCURRENCE'
  | 'calendar.SECOND_OCCURRENCE'
  | 'calendar.THIRD_OCCURRENCE'
  | 'calendar.FOURTH_OCCURRENCE'
  | 'calendar.FIFTH_OCCURRENCE'
  | 'scheduleType.STATIC'
  | 'scheduleType.DYNAMIC'
  | 'frequency.ONE_WEEK'
  | 'frequency.TWO_WEEKS'
  | 'frequency.THREE_WEEKS'
  | 'frequency.FOUR_WEEKS'
  | 'frequency.FIVE_WEEKS'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export function getServiceButtonConsts(t: TFunction<'common', undefined>, mode: ServiceButtonMode): ServiceButtonConsts {
  let label: string, useCaseName: string, startIcon: JSX.Element;
  switch (mode) {
    case ServiceButtonMode.CREATE:
      label = t('addNewService');
      useCaseName = 'create-service';
      startIcon = <AddIcon />;
      break;
    case ServiceButtonMode.EDIT:
      label = t('edit');
      useCaseName = 'edit-service';
      startIcon = <Edit />;
      break;
    case ServiceButtonMode.COPY:
      label = t('copy');
      useCaseName = 'create-service';
      startIcon = <ContentCopy />;
      break;
  }
  return { label, useCaseName, startIcon };
}

export function scheduleServiceBar(t: TFunction<'common', undefined>, service: any, showFormControlLabel = true) {
  function mapWeekGapToFrequencyLabel(weekGap: any): string {
    const translations: any = i18n.getDataByLanguage(i18n.language);
    if (weekGap == 1) return translations.common['frequency.ONE_WEEK'];
    if (weekGap == 2) return translations.common['frequency.TWO_WEEKS'];
    if (weekGap == 3) return translations.common['frequency.THREE_WEEKS'];
    if (weekGap == 4) return translations.common['frequency.FOUR_WEEKS'];
    if (weekGap == 5) return translations.common['frequency.FIVE_WEEKS'];
    return 'UNKNOWN';
  }

  return (
    <>
      <RenderIf true={showFormControlLabel}>
        <FormControlLabel
          control={
            <YellowSwitch
              checked={isDynamicScheduleType(service)}
              onClick={() => {
                return null;
              }}
            />
          }
          label={t('dynamicCalendar')}
          labelPlacement={'start'}
        />
        <FormControlLabel
          control={
            <YellowSwitch
              checked={isStaticScheduleType(service)}
              onClick={() => {
                return null;
              }}
            />
          }
          label={t('staticCalendar')}
          labelPlacement={'start'}
        />
        <FormControlLabel label={t('onDemand')} labelPlacement={'start'} control={<YellowSwitch checked={service.onDemand} />} />
      </RenderIf>
      <ServiceBar
        isVisible={isDynamicScheduleType(service)}
        headers={[
          { label: t('frequency') },
          { label: t('serviceStartDate') },
          { label: t('monday') },
          { label: t('tuesday') },
          { label: t('wednesday') },
          { label: t('thursday') },
          { label: t('friday') },
          { label: t('saturday') },
          { label: t('sunday') },
        ]}
        items={[
          { value: mapWeekGapToFrequencyLabel(service.dynamicCalendarSchedule?.weekGap) },
          { value: service.activeFromDate || service.activationDate },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.monday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.tuesday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.wednesday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.thursday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.friday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.saturday, type: 'boolean' },
          { value: service.dynamicCalendarSchedule?.weeklySchedule?.sunday, type: 'boolean' },
        ]}
      />

      <ServiceBar
        isVisible={isStaticScheduleType(service)}
        headers={[{ label: t('serviceStartDate'), options: { colSpan: 9 } }]}
        items={[
          [
            {
              value: service.activeFromDate || service.activationDate,
              options: { colSpan: 9 },
            },
          ],
          [{ value: t('billingPeriodOccurrences') }, ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => ({ value: t(day as TranslationKeys) }))],
          ...['first', 'second', 'third', 'fourth', 'fifth'].map(
            (occurrence) =>
              [
                { value: t(`calendar.${occurrence.toUpperCase()}_OCCURRENCE` as TranslationKeys) },
                ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => ({
                  value: service.staticCalendarSchedule?.[day]?.[occurrence],
                  type: 'boolean',
                })),
              ] as ServiceBarItem[],
          ),
        ]}
      />
    </>
  );
}

export interface PriceItemOptions {
  priceTypeVisible?: boolean;
  translations?: any;
  reverseColors?: boolean;
  replaceActualPriceWithZero?: boolean;
}

export function priceItem(item?: ServicePrice, options?: PriceItemOptions): ServiceBarItem {
  if (item == null) {
    return {
      value: '-',
    };
  }
  if (options?.replaceActualPriceWithZero) {
    item.price = 0;
  }
  let value = `${item.price?.toFixed(PLN_PRECISION)} ${item.currency}`;
  if (options?.priceTypeVisible) {
    const priceTypeTranslation = options?.translations ? options.translations.common[`priceType.${item.priceType}`] : item.priceType;
    value = `${value} / ${priceTypeTranslation}`;
  }
  let color: 'green' | 'red';
  if (item.bearer === ServicePriceBearer.ORGANIZATION_COST) {
    color = options?.reverseColors ? 'green' : 'red';
  } else {
    color = options?.reverseColors ? 'red' : 'green';
  }
  return {
    value,
    options: { color: color },
  };
}

export async function reloadService(queryClient: QueryClient, serviceId: number) {
  await queryClient.invalidateQueries(['ServiceViewDetails', serviceId]);
}

type TF = Yup.TestFunction<string | undefined, AnyObject>;

function isEmpty(value?: string) {
  return value == null || value.trim().length === 0;
}

const isNavResourceRequired: TF = function (value) {
  const { bearer } = this.parent;
  if (bearer === 'ORGANIZATION_REVENUE') {
    return !isEmpty(value);
  }
  return true;
};

export function settlementNavResourceValidation(t: TFunction<'common', undefined>) {
  const clientTransportPriceValidation = Yup.object({
    navResourceId: Yup.string().test('is-clientTransport-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
  });
  const subcontractorTransportPriceValidation = Yup.object({
    navResourceId: Yup.string().test('is-subcontractorTransport-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
  });
  const clientCollectionPriceValidation = Yup.object({
    navResourceId: Yup.string().test('is-clientCollection-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
  });
  const subcontractorCollectionPriceValidation = Yup.object({
    navResourceId: Yup.string().test('is-subcontractorCollection-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
  });

  const validationSchema = {
    clientTransportPrice: clientTransportPriceValidation,
    subcontractorTransportPrice: subcontractorTransportPriceValidation,
    clientCollectionPrice: clientCollectionPriceValidation,
    subcontractorCollectionPrice: subcontractorCollectionPriceValidation,
  };

  return validationSchema;
}

export function priceFieldsValidation(t: TFunction<'common', undefined>, serviceType: ServiceType) {
  const requiredPriceValidation = Yup.object({
    price: Yup.string().required(t('fieldRequired')),
  });
  const clientCollectionPriceValidation = Yup.object().when('pix', {
    is: (pix: boolean | undefined) => pix === false || pix === undefined,
    then: Yup.object({
      price: Yup.string().required(t('fieldRequired')),
      navResourceId: Yup.string().test('is-clientCollection-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
    }),
  });
  const subcontractorCollectionPriceValidation = Yup.object({
    price: Yup.string().required(t('fieldRequired')),
    navResourceId: Yup.string().test('is-subcontractorCollection-navResourceId-required', t('fieldRequired'), isNavResourceRequired),
  });

  let validationSchema;

  switch (serviceType) {
    case ServiceType.SCHEDULED:
    case ServiceType.PER_REQUEST:
      validationSchema = {
        clientCollectionPrice: clientCollectionPriceValidation,
        subcontractorCollectionPrice: subcontractorCollectionPriceValidation,
      };
      break;
    case ServiceType.ADDITIONAL:
      validationSchema = {
        clientCollectionPrice: requiredPriceValidation,
        subcontractorCollectionPrice: requiredPriceValidation,
      };
      break;
    case ServiceType.COMMUNE:
      validationSchema = {
        communePrice: requiredPriceValidation,
        declaredPrice: Yup.string().required(t('fieldRequired')),
      };
  }

  return validationSchema;
}

export function serviceValidationSchema(t: TFunction<'common', undefined>, editing: boolean, serviceType: ServiceType) {
  function isPositive(value?: string) {
    if (isEmpty(value)) {
      return true;
    }
    const num = parseInt(value!, 10);
    return num > 0;
  }

  const isProjectInfrastructureServiceRequired: TF = function (value) {
    const { resourceInBulk } = this.parent;
    if (resourceInBulk === false && isEmpty(value)) {
      return false;
    }
    return true;
  };
  const isCollectionInfrastructureServiceRequired: TF = function (value) {
    const { resourceInBulk, resourceInBulkNoPackage } = this.parent;
    if (resourceInBulkNoPackage === true) {
      return true;
    }
    if (resourceInBulk === true && isEmpty(value)) {
      return false;
    }
    return true;
  };
  const projectInfrastructureServiceIdPresent: TF = function (value) {
    const { projectInfrastructureServiceId, resourceInBulk } = this.parent;

    if (resourceInBulk === true) {
      return true;
    }
    if (!isEmpty(projectInfrastructureServiceId) && isEmpty(value)) {
      return false;
    }
    return true;
  };
  const isNoPackageMassPresent: TF = function (value) {
    const { resourceInBulkNoPackage } = this.parent;
    if (resourceInBulkNoPackage === true && isEmpty(value)) {
      return false;
    }
    return true;
  };
  const collectionInfrastructureServiceIdPresent: TF = function (value) {
    const { collectionInfrastructureServiceId } = this.parent;

    if (!isEmpty(collectionInfrastructureServiceId) && isEmpty(value)) {
      return false;
    }
    return true;
  };
  const afterActivationDate: TF = function (value) {
    const { activationDate } = this.parent;

    if (isEmpty(value)) {
      return true;
    }
    if (isEmpty(activationDate)) {
      return true;
    }
    return value! >= activationDate;
  };

  let creatingValidation = {};
  if (!editing) {
    creatingValidation = {
      ...priceFieldsValidation(t, serviceType),
    };
  }

  return {
    ...creatingValidation,
    pix: Yup.boolean(),
    projectWasteId: Yup.string().required(t('fieldRequired')),
    projectInfrastructureServiceId: Yup.string().test('is-projectInfrastructureServiceId-needed', t('fieldRequired'), isProjectInfrastructureServiceRequired),
    infrastructureCount: Yup.string()
      .test('is-infrastructureCount-needed', t('fieldRequired'), projectInfrastructureServiceIdPresent)
      .test('is-infrastructureCount-positive', t('positiveNumberRequired'), isPositive),
    infrastructureOutputMass: Yup.string().test('is-infrastructureOutputMass-needed', t('fieldRequired'), projectInfrastructureServiceIdPresent),
    noPackageMass: Yup.string().test('is-noPackageMass-needed', t('fieldRequired'), isNoPackageMassPresent),
    collectionInfrastructureServiceId: Yup.string().test('is-collectionInfrastructureServiceId-needed', t('fieldRequired'), isCollectionInfrastructureServiceRequired),
    collectionInfrastructureCount: Yup.string()
      .test('is-collectionInfrastructureCount-needed', t('fieldRequired'), collectionInfrastructureServiceIdPresent)
      .test('is-collectionInfrastructureCount-positive', t('positiveNumberRequired'), isPositive),
    collectionInfrastructureOutputMass: Yup.string().test('is-collectionInfrastructureOutputMass-needed', t('fieldRequired'), collectionInfrastructureServiceIdPresent),
    activationDate: Yup.string().required(t('fieldRequired')),
    activeFromDate: Yup.string().test('is-active-from-date-after-activation-date', t('activeFromDateBeforeActivationDateRequired'), afterActivationDate),
    deactivationDate: Yup.string().test('is-deactivation-date-after-activation-date', t('deactivationDateAfterActivationDateRequired'), afterActivationDate),
    subcontractorId: Yup.string().required(t('fieldRequired')),
    transporterId: Yup.string().required(t('fieldRequired')),
    transporterKpoId: Yup.string().required(t('fieldRequired')),
    receiverKpoId: Yup.string().required(t('fieldRequired')),
    reactionTime: Yup.string()
      .test('is-reaction-time-positive', t('positiveNumberRequired'), isPositive)
      .max(5, t('fieldToLong'))
      .test('is-reaction-time-required', t('fieldRequired'), (value) => (serviceType === ServiceType.SCHEDULED ? true : !isEmpty(value))),
    apiLinkEmail: Yup.string().test('multiple-emails', t('requiredValidEmails'), (value) => {
      if (!value) return true;
      const emails = value.split(',').map((email) => email.trim());
      return emails.every((email) => Yup.string().email().isValidSync(email));
    }),
  };
}

export function priceTypeEnumValues(t: TFunction<'common', undefined>): DataTableColumnEnumValue[] {
  return [
    { value: 'MG', label: t('priceType.MG') },
    { value: 'M3', label: t('priceType.M3') },
    { value: 'UNIT', label: t('priceType.UNIT') },
  ];
}

export function priceBearerEnumValues(t: TFunction<'common', undefined>): DataTableColumnEnumValue[] {
  return [
    { label: t('isCost'), value: 'ORGANIZATION_COST' },
    { label: t('isRevenue'), value: 'ORGANIZATION_REVENUE' },
  ];
}

export function paymentPeriodEnumValues(t: TFunction<'common'>): DataTableColumnEnumValue[] {
  return [
    { value: 'MONTH', label: t('paymentPeriod.MONTH') },
    { value: 'TWO_MONTHS', label: t('paymentPeriod.TWO_MONTHS') },
    { value: 'QUARTER', label: t('paymentPeriod.QUARTER') },
  ];
}

export function paymentDayEnumValues(t: TFunction<'common', undefined>): DataTableColumnEnumValue[] {
  const days: DataTableColumnEnumValue[] = [{ label: t('paymentDay.DAY_LAST'), value: 'DAY_LAST' }];
  for (let i = 1; i <= 28; i++) {
    days.push({
      label: i.toString(),
      value: `DAY_${i.toString()}`,
    });
  }
  return days;
}

export function paymentDueEnumValues(t: TFunction<'common'>, paymentPeriod?: PaymentPeriod): DataTableColumnEnumValue[] {
  switch (paymentPeriod) {
    case PaymentPeriod.MONTH:
      return [
        { value: 'FIRST_MONTH', label: t('paymentDue.FIRST_MONTH') },
        { value: 'AFTER_BILLING_PERIOD', label: t('paymentDue.AFTER_BILLING_PERIOD') },
      ];
    case PaymentPeriod.TWO_MONTHS:
      return [
        { value: 'FIRST_MONTH', label: t('paymentDue.FIRST_MONTH') },
        { value: 'BILLING_PERIOD_LAST_MONTH', label: t('paymentDue.BILLING_PERIOD_LAST_MONTH') },
        { value: 'AFTER_BILLING_PERIOD', label: t('paymentDue.AFTER_BILLING_PERIOD') },
      ];
    case PaymentPeriod.QUARTER:
      return [
        { value: 'FIRST_MONTH', label: t('paymentDue.FIRST_MONTH') },
        { value: 'SECOND_MONTH', label: t('paymentDue.SECOND_MONTH') },
        { value: 'BILLING_PERIOD_LAST_MONTH', label: t('paymentDue.BILLING_PERIOD_LAST_MONTH') },
        { value: 'AFTER_BILLING_PERIOD', label: t('paymentDue.AFTER_BILLING_PERIOD') },
      ];
    default:
      return [
        { value: 'FIRST_MONTH', label: t('paymentDue.FIRST_MONTH') },
        { value: 'SECOND_MONTH', label: t('paymentDue.SECOND_MONTH') },
        { value: 'THIRD_MONTH', label: t('paymentDue.THIRD_MONTH') },
        { value: 'BILLING_PERIOD_LAST_MONTH', label: t('paymentDue.BILLING_PERIOD_LAST_MONTH') },
        { value: 'AFTER_BILLING_PERIOD', label: t('paymentDue.AFTER_BILLING_PERIOD') },
        { value: 'NOT_APPLICABLE', label: t('paymentDue.NOT_APPLICABLE') },
      ];
  }
}

export interface InfrastructureServiceFieldsOptions {
  service?: any;
  projectBranchId: string;
  collectionMethod?: boolean;
  resource?: boolean;
  label: string;
  idField: string;
  countField: string;
  hideCountField?: boolean;
  massField: string;
  isCommune: boolean;
  isVisible?: (data: any) => boolean;
}

export function infrastructureServiceFields(t: TFunction<'common', undefined>, options: InfrastructureServiceFieldsOptions): GeneralFormItem[] {
  let massInitialEntity = undefined;
  if (options.service) {
    massInitialEntity = {
      [options.massField]: options.service?.[options.massField],
    };
  }
  return [
    {
      label: options.label,
      type: 'table-select',
      field: options.idField,
      defaultValue: options.service?.[options.idField],
      tableSelectParams: {
        selectedValueField: 'projectBranchInfrastructureServiceId',
        modelName: 'projectBranchInfrastructureServiceViews',
        modelClass: ProjectBranchInfrastructureServiceViewModel,
        displayFormat: '{name}',
        dialogTitle: options.label,
        fetchFilters: {
          projectBranchId: options.projectBranchId,
          collectionMethod: options.collectionMethod,
          resource: options.resource,
          deleted: false,
          commune: options.isCommune,
        },
        columns: [
          {
            key: 'category',
            label: t('category'),
            type: 'enum',
            enumValues: InfrastructureCategorySelectOptionsFactory.getSelectOptions(t),
          },
          {
            key: 'name',
            label: t('resourceName'),
            type: 'text',
          },
          {
            key: 'projectInfrastructureDescription',
            label: t('projectInfrastructureDescription'),
            type: 'text',
          },
          {
            key: 'estimatedMass',
            label: t('estimatedMass'),
            type: 'text',
          },
          {
            key: 'owner',
            label: t('owner'),
            type: 'enum',
            enumValues: [
              { value: 'CLIENT', label: t('client') },
              { value: 'SUBCONTRACTOR', label: t('subcontractor') },
              { value: 'ORGANIZATION', label: t('is') },
            ],
          },
          {
            key: 'subcontractorName',
            label: t('subcontractorsName'),
            type: 'text',
          },
          {
            key: 'fixedAsset',
            label: t('fixedAsset'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'shared',
            label: t('shared'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'currentSettings.infrastructureCount',
            label: t('number'),
            type: 'numeric',
          },
          {
            key: 'currentSettings.clientLease',
            label: t('leasePriceClient'),
            type: 'text',
          },
          {
            key: 'currentSettings.subcontractorLease',
            label: t('leasePriceSubcontractor'),
            type: 'text',
          },
          {
            key: 'navResourceNumber',
            label: t('navResourceNumber'),
            type: 'text',
          },
          {
            key: 'navResourceName',
            label: t('navResourceName'),
            type: 'text',
          },
          {
            key: 'commune',
            label: t('commune'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'activationDate',
            label: t('activationDate'),
            type: 'date',
          },
          {
            key: 'deactivationDate',
            label: t('deactivationDate'),
            type: 'date',
          },
        ],
      },
      isVisible: options.isVisible,
    },
    {
      label: t('number'),
      field: options.countField,
      type: 'numeric',
      defaultValue: options.hideCountField === true ? '1' : options?.service?.[options.countField],
      isVisible: (data) => {
        return options.hideCountField !== true && ((options.isVisible != null && options.isVisible(data)) || options.isVisible == null);
      },
    },
    {
      label: t('massMg'),
      type: 'table-select-by-api',
      field: options.massField,
      defaultValue: options?.service?.[options.massField],
      tableSelectByApiParams: {
        apiPostParams: {
          projectBranchInfrastructureServiceId: `$${options.idField}`,
        },
        initialEntity: massInitialEntity,
        autoSelectFirstOptionOnInitIfNoInitialEntity: true,
        shouldAutoSelectFirst: (apiParams: any) => apiParams.projectBranchInfrastructureServiceId.length > 0,
        shouldClearOnApiParams: (apiParams: any) => apiParams.projectBranchInfrastructureServiceId.length === 0,
        dialogTitle: t('massMg'),
        apiPostEndpoint: '/api/usecase/project/get-project-infrastructure-available-masses',
        displayFormat: '{mass}',
        selectedValueField: 'mass',
        columns: [
          {
            key: 'mass',
            label: t('mass'),
            type: 'numeric',
            numericPrecision: MASS_PRECISION,
          },
        ],
      },
      isVisible: options.isVisible,
    },
  ];
}

export interface PriceFieldsOptions {
  overrideTypeSelectOptions?: GeneralFormSelectOption[];
  defaultPrice?: number;
  defaultType?: 'M3' | 'UNIT' | 'MG';
  defaultBearer?: 'ORGANIZATION_COST' | 'ORGANIZATION_REVENUE';
  fixedBearer?: ((data: any) => boolean) | boolean;
  fixedPriceType?: ((data: any) => boolean) | boolean;
  bearerVisible?: ((data: any) => boolean) | boolean;
  priceTypeVisible?: ((data: any) => boolean) | boolean;
  initialService?: any;
  navResourceVisible?: ((data: any) => boolean) | boolean;
  isVisible?: (data: any) => boolean;
  type?: ServiceType;
}

export function priceInputFields(field: string, t: TFunction<'common', undefined>, options?: PriceFieldsOptions): GeneralFormItem[] {
  let typeSelectOptions: GeneralFormSelectOption[] = [
    { label: t('priceType.M3'), value: 'M3' },
    { label: t('priceType.UNIT'), value: 'UNIT' },
    { label: t('priceType.MG'), value: 'MG' },
  ];
  if (options?.overrideTypeSelectOptions != null) {
    typeSelectOptions = options.overrideTypeSelectOptions;
  }
  let defaultPriceField = options?.initialService?.currentSettings;
  if (defaultPriceField != null) {
    defaultPriceField = defaultPriceField[field];
  }
  return [
    {
      label: t('price'),
      type: 'numeric',
      numericPrecision: 2,
      field: `${field}.price`,
      defaultValue: options?.defaultPrice == null ? defaultPriceField?.price : options?.defaultPrice,
      isVisible: options?.isVisible,
    },
    {
      label: t('type'),
      type: 'select',
      defaultValue: options?.defaultType || defaultPriceField?.priceType,
      selectOptions: typeSelectOptions,
      field: `${field}.priceType`,
      isDeactivated: options?.fixedPriceType,
      isVisible: options?.isVisible || options?.priceTypeVisible,
    },
    {
      label: t('currency'),
      type: 'select',
      selectOptions: [
        { label: 'PLN', value: 'PLN' },
        { label: 'EUR', value: 'EUR' },
      ],
      field: `${field}.currency`,
      defaultValue: defaultPriceField?.currency,
      isVisible: options?.isVisible,
    },
    {
      label: t('revenueOrIS'),
      type: 'select',
      selectOptions: [
        { label: t('isCost'), value: 'ORGANIZATION_COST' },
        { label: t('isRevenue'), value: 'ORGANIZATION_REVENUE' },
      ],
      field: `${field}.bearer`,
      defaultValue: options?.defaultBearer || defaultPriceField?.bearer,
      isDeactivated: options?.fixedBearer,
      isVisible: options?.isVisible || options?.bearerVisible,
    },
    {
      label: t('navResource'),
      type: 'table-select',
      field: `${field}.navResourceId`,
      defaultValue: defaultPriceField?.navResourceId || defaultPriceField?.navResource?.id,
      isVisible: (data) => (options?.isVisible?.(data) !== false && isTrue(options?.navResourceVisible, data)) || false,
      tableSelectParams: {
        modelName: 'navResources',
        selectedValueField: 'id',
        displayFormat: options?.type === 'SCHEDULED' || options?.type === 'PER_REQUEST' ? '{code}' : '{name}',
        columns: [
          { label: t('navResourceName'), type: 'text', key: 'name' },
          { label: t('navResourceNumber'), type: 'text', key: 'code' },
        ],
        dialogTitle: t('navResource'),
      },
    },
  ];
}

export function priceFields(field: string, t: TFunction<'common', undefined>, headerLabel: string, options?: PriceFieldsOptions): (GeneralFormItem | GeneralFormRowOfItems)[] {
  return [
    {
      label: headerLabel,
      type: 'header',
      field: '',
      margin: '2rem 0 -1rem 0',
      isVisible: options?.isVisible,
    },
    {
      rowItems: priceInputFields(field, t, options),
      isVisible: options?.isVisible,
    },
  ];
}

export function variableAnnotationsFields(service: any, t: TFunction<'common', undefined>, reloadServices: () => void) {
  const AddVariableAnnotationButton = () => {
    return (
      <AggregateCommandButton
        aggregateName={'ServiceAggregate'}
        aggregateId={service.serviceId}
        commandName={'addVariableAnnotation'}
        variant={'contained'}
        color={'info'}
        buttonLabel={t('add')}
        startIcon={<AddIcon />}
        dialogTitle={''}
        fields={[{ label: t('variableAnnotation'), field: 'value', type: 'text' }]}
        onSaved={async () => reloadServices()}
      />
    );
  };
  if (service.variableAnnotations != null && service.variableAnnotations.length > 0) {
    return service.variableAnnotations.map((variableAnnotation: any, idx: number) => {
      return [
        { value: variableAnnotation.value },
        {
          value: (
            <>
              <Stack direction={'row'} spacing={2}>
                {idx === service.variableAnnotations.length - 1 && <AddVariableAnnotationButton />}
                <AggregateCommandButton
                  aggregateName={'ServiceAggregate'}
                  aggregateId={service.serviceId}
                  commandName={'deleteVariableAnnotation'}
                  variant={'contained'}
                  color={'info'}
                  buttonLabel={t('Common.delete')}
                  hiddenValues={{
                    uuid: variableAnnotation.uuid,
                  }}
                  forceConfirmation={true}
                  onSaved={async () => reloadServices()}
                />
              </Stack>
            </>
          ),
        },
      ];
    });
  } else {
    return [
      {},
      {
        value: <AddVariableAnnotationButton />,
      },
    ];
  }
}

export function requestEmailsFields(service: any, t: TFunction<'common', undefined>, reloadServices: () => void) {
  const AddRequestEmailButton = () => {
    return (
      <AggregateCommandButton
        aggregateName={'ServiceAggregate'}
        aggregateId={service.serviceId}
        commandName={'addRequestEmail'}
        variant={'contained'}
        color={'info'}
        buttonLabel={t('add')}
        startIcon={<AddIcon />}
        dialogTitle={''}
        fields={[
          {
            label: t('emailForApplication'),
            field: 'email',
            type: 'table-select',
            tableSelectParams: {
              selectedValueField: 'email',
              modelName: 'contactPersonViews',
              dialogTitle: t('emailForApplication'),
              displayFormat: '{email}',
              columns: [{ key: 'email', label: t('email'), type: 'text' }],
              fetchFilters: {
                branchId: service.subcontractorId,
                type: 'ORDER',
              },
            },
          },
        ]}
        onSaved={async () => reloadServices()}
      />
    );
  };
  if (service.requestEmails != null && service.requestEmails.length > 0) {
    return service.requestEmails.map((requestEmail: string, idx: number) => {
      return [
        { value: requestEmail },
        {
          value: (
            <>
              <Stack direction={'row'} spacing={2}>
                {idx === service.requestEmails.length - 1 && <AddRequestEmailButton />}
                <AggregateCommandButton
                  aggregateName={'ServiceAggregate'}
                  aggregateId={service.serviceId}
                  commandName={'deleteRequestEmail'}
                  variant={'contained'}
                  color={'info'}
                  buttonLabel={t('Common.delete')}
                  hiddenValues={{
                    email: requestEmail,
                  }}
                  forceConfirmation={true}
                  onSaved={async () => reloadServices()}
                />
              </Stack>
            </>
          ),
        },
      ];
    });
  } else {
    return [
      {},
      {
        value: <AddRequestEmailButton />,
      },
    ];
  }
}

export function variableAnnotationsFieldsReloadById(service: any, t: TFunction<'common', undefined>, queryClient: QueryClient) {
  const AddVariableAnnotationButton = () => {
    return (
      <AggregateCommandButton
        aggregateName={'ServiceAggregate'}
        aggregateId={service.serviceId}
        commandName={'addVariableAnnotation'}
        variant={'contained'}
        color={'info'}
        buttonLabel={t('add')}
        startIcon={<AddIcon />}
        dialogTitle={''}
        fields={[{ label: t('variableAnnotation'), field: 'value', type: 'text' }]}
        onSaved={async () => await reloadService(queryClient, service.id)}
      />
    );
  };
  if (service.variableAnnotations != null && service.variableAnnotations.length > 0) {
    return service.variableAnnotations.map((variableAnnotation: any, idx: number) => {
      return [
        { value: variableAnnotation.value },
        {
          value: (
            <>
              <Stack direction={'row'} spacing={2}>
                {idx === service.variableAnnotations.length - 1 && <AddVariableAnnotationButton />}
                <AggregateCommandButton
                  aggregateName={'ServiceAggregate'}
                  aggregateId={service.serviceId}
                  commandName={'deleteVariableAnnotation'}
                  variant={'contained'}
                  color={'info'}
                  buttonLabel={t('Common.delete')}
                  hiddenValues={{
                    uuid: variableAnnotation.uuid,
                  }}
                  forceConfirmation={true}
                  onSaved={async () => await reloadService(queryClient, service.id)}
                />
              </Stack>
            </>
          ),
        },
      ];
    });
  } else {
    return [
      {},
      {
        value: <AddVariableAnnotationButton />,
      },
    ];
  }
}

export function requestEmailsFieldsReloadById(service: any, t: TFunction<'common', undefined>, queryClient: QueryClient) {
  const AddRequestEmailButton = () => {
    return (
      <AggregateCommandButton
        aggregateName={'ServiceAggregate'}
        aggregateId={service.serviceId}
        commandName={'addRequestEmail'}
        variant={'contained'}
        color={'info'}
        buttonLabel={t('add')}
        startIcon={<AddIcon />}
        dialogTitle={''}
        fields={[
          {
            label: t('emailForApplication'),
            field: 'email',
            type: 'table-select',
            tableSelectParams: {
              selectedValueField: 'email',
              modelName: 'contactPersonViews',
              dialogTitle: t('emailForApplication'),
              displayFormat: '{email}',
              columns: [{ key: 'email', label: t('email'), type: 'text' }],
              fetchFilters: {
                companyId: service.subcontractorCompanyId,
              },
            },
          },
        ]}
        onSaved={async () => await reloadService(queryClient, service.id)}
      />
    );
  };
  if (service.requestEmails != null && service.requestEmails.length > 0) {
    return service.requestEmails.map((requestEmail: string, idx: number) => {
      return [
        { value: requestEmail },
        {
          value: (
            <>
              <Stack direction={'row'} spacing={2}>
                {idx === service.requestEmails.length - 1 && <AddRequestEmailButton />}
                <AggregateCommandButton
                  aggregateName={'ServiceAggregate'}
                  aggregateId={service.serviceId}
                  commandName={'deleteRequestEmail'}
                  variant={'contained'}
                  color={'info'}
                  buttonLabel={t('Common.delete')}
                  hiddenValues={{
                    email: requestEmail,
                  }}
                  forceConfirmation={true}
                  onSaved={async () => await reloadService(queryClient, service.id)}
                />
              </Stack>
            </>
          ),
        },
      ];
    });
  } else {
    return [
      {},
      {
        value: <AddRequestEmailButton />,
      },
    ];
  }
}

export function activeFromControls(t: TFunction<'common', undefined>, defaultValue?: any, onDemand?: (data: any) => boolean): GeneralFormItem {
  return {
    label: t('serviceStartDate'),
    type: 'date',
    field: 'activeFromDate',
    defaultValue,
    isVisible: onDemand,
  };
}

export function bdoStatusControls(
  t: TFunction<'common', undefined>,
  canBePlanned: boolean,
  defaultValue?: 'CARD_ACCEPTED' | 'CARD_PLANNED' | 'LINK_TO_SUBCONTRACTOR' | 'LINK_TO_SUBCONTRACTOR_API',
): GeneralFormItem {
  const selectOptions: GeneralFormSelectOption[] = [
    { value: 'CARD_ACCEPTED', label: t('bdoStatus.CARD_ACCEPTED') },
    { value: 'LINK_TO_SUBCONTRACTOR', label: t('bdoStatus.LINK_TO_SUBCONTRACTOR') },
    { value: 'LINK_TO_SUBCONTRACTOR_API', label: t('bdoStatus.LINK_TO_SUBCONTRACTOR_API') },
  ];
  if (canBePlanned) {
    selectOptions.splice(1, 0, { value: 'CARD_PLANNED', label: t('bdoStatus.CARD_PLANNED') });
  }
  return {
    label: t('bdoStatus'),
    type: 'select',
    field: 'bdoStatus',
    defaultValue,
    selectOptions,
  };
}

export function invoicingMethodControls(t: TFunction<'common', undefined>, defaultValue?: InvoicingMethod): GeneralFormItem {
  return {
    label: t('invoicingMethod'),
    type: 'select',
    field: 'invoicingMethod',
    testId: 'popup-invoicingMethod',
    defaultValue,
    selectOptions: [
      { value: InvoicingMethod.PER_MONTH, label: t('invoicingMethod.PER_MONTH') },
      { value: InvoicingMethod.PER_COLLECTION, label: t('invoicingMethod.PER_COLLECTION') },
    ],
  };
}

export function paymentMethodControls(t: TFunction<'common', undefined>, defaultValue?: PaymentMethod): GeneralFormItem {
  return {
    label: t('paymentMethodCode'),
    type: 'select',
    field: 'paymentMethod',
    testId: 'popup-paymentMethod',
    defaultValue,
    selectOptions: [
      { value: PaymentMethod.PREPAYMENT, label: t('paymentMethodCode.PREPAYMENT') },
      { value: PaymentMethod.TRANSFER, label: t('paymentMethodCode.TRANSFER') },
    ],
  };
}

export function settlementTypeControls(t: TFunction<'common', undefined>, defaultValue?: SettlementType): GeneralFormItem {
  return {
    label: t('settlementType'),
    type: 'select',
    field: 'settlementType',
    testId: 'popup-settlementType',
    defaultValue,
    selectOptions: [
      { value: SettlementType.PER_MONTH, label: t('settlementType.PER_MONTH') },
      { value: SettlementType.PER_COLLECTION, label: t('settlementType.PER_COLLECTION') },
    ],
  };
}

export function settlementUnitControls(t: TFunction<'common', undefined>, defaultValue?: SettlementUnit): GeneralFormItem {
  return {
    label: t('settlementUnit'),
    type: 'select',
    field: 'settlementUnit',
    testId: 'popup-settlementUnit',
    defaultValue,
    selectOptions: [
      { value: SettlementUnit.MG, label: t('settlementUnit.MG') },
      { value: SettlementUnit.UNIT, label: t('settlementUnit.UNIT') },
      { value: SettlementUnit.M3, label: t('settlementUnit.M3') },
    ],
  };
}

export function navResourceControls(t: TFunction<'common', undefined>, defaultValue?: string): GeneralFormItem {
  return {
    label: t('navResource'),
    type: 'table-select',
    field: 'navResourceId',
    defaultValue: defaultValue,
    tableSelectParams: {
      modelName: 'navResources',
      selectedValueField: 'id',
      displayFormat: '{name}',
      columns: [
        { label: t('navResourceName'), type: 'text', key: 'name' },
        { label: t('navResourceNumber'), type: 'text', key: 'code' },
      ],
      dialogTitle: t('navResource'),
    },
  };
}

function generateStaticScheduleRowItems(t: TFunction, occurrence: string, service?: any): GeneralFormItem[] {
  const days: TranslationKeys[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  return days.map((day) => ({
    label: t(day),
    type: 'boolean',
    field: `staticCalendarSchedule.${day}.${occurrence}`,
    defaultValue: service?.staticCalendarSchedule?.[day]?.[occurrence],
    isVisible: isStaticScheduleType,
  }));
}

export function scheduleTypeControls(t: TFunction, service?: any, hasOnDemand?: (data: any) => boolean): (GeneralFormItem | GeneralFormRowOfItems)[] {
  const occurrences = ['first', 'second', 'third', 'fourth', 'fifth'];

  return [
    {
      rowItems: [
        { label: t('schedule'), type: 'header', field: '' },
        {
          label: t('onDemand'),
          type: 'boolean',
          field: 'onDemand',
          defaultValue: service?.onDemand,
          isVisible: hasOnDemand,
        },
      ],
    },
    {
      rowItems: [
        {
          label: t('scheduleType'),
          type: 'select',
          field: 'scheduleType',
          isVisible: (data) => data.onDemand === false,
          defaultValue: service?.scheduleType || 'DYNAMIC',
          selectOptions: [
            { value: 'STATIC', label: t('scheduleType.STATIC') },
            { value: 'DYNAMIC', label: t('scheduleType.DYNAMIC') },
          ],
        },
        activeFromControls(t, service?.activeFromDate, (data) => data.onDemand === false),
      ],
    },
    {
      label: t('frequency'),
      type: 'select',
      field: 'dynamicCalendarSchedule.weekGap',
      defaultValue: service?.dynamicCalendarSchedule?.weekGap,
      selectOptions: [
        { value: '1', label: t('frequency.ONE_WEEK') },
        { value: '2', label: t('frequency.TWO_WEEKS') },
        { value: '3', label: t('frequency.THREE_WEEKS') },
        { value: '4', label: t('frequency.FOUR_WEEKS') },
        { value: '5', label: t('frequency.FIVE_WEEKS') },
      ],
      isVisible: isDynamicScheduleType,
    },
    { label: t('calendar'), type: 'header', field: '', isVisible: isDynamicScheduleType },
    {
      rowItems: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => ({
        label: t(day as TranslationKeys),
        type: 'boolean',
        field: `dynamicCalendarSchedule.weeklySchedule.${day}`,
        defaultValue: service?.dynamicCalendarSchedule?.weeklySchedule?.[day],
        isVisible: isDynamicScheduleType,
      })),
    },
    ...occurrences.flatMap(
      (occurrence) =>
        [
          {
            label: t(`calendar.${occurrence.toUpperCase()}_OCCURRENCE` as TranslationKeys),
            type: 'header',
            field: '',
            isVisible: isStaticScheduleType,
          },
          { rowItems: generateStaticScheduleRowItems(t, occurrence, service) },
        ] as GeneralFormItem[],
    ),
  ];
}
