import { Button, DataTable, Dialog, FormInputText, RenderIf, TextLabel } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

interface IFormInput {
  exceedAmount: number;
}

interface ReportedExceed {
  uploadedAt: Date;
  exceedContainers: number;
}

interface ReportExceedProps {
  collectionRequestId?: string;
  settlementId?: string;
  container: string;
  exceedAmount?: number;
  afterSubmit?: () => void;
  disabled?: boolean;
  history?: ReportedExceed[];
}

export const ReportExceedButton = (props: ReportExceedProps) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const { handleSubmit, control, setValue } = useForm<IFormInput>({
    defaultValues: {
      exceedAmount: props.exceedAmount,
    },
    resolver: yupResolver(
      Yup.object().shape({
        exceedAmount: Yup.number().required(t('fieldRequired')).typeError(t('numberRequired')).min(0, t('notNegativeNumberRequired')),
      }),
    ),
  });

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const onSubmit = async (data: IFormInput) => {
    await axios({
      method: 'post',
      data: {
        collectionRequestId: props.collectionRequestId,
        exceedAmount: data.exceedAmount,
        settlementId: props.settlementId,
      },
      url: props.collectionRequestId ? '/api/usecase/collection-request/report-collection-request-exceed' : '/api/usecase/settlement/report-on-demand-service-exceed',
      responseType: 'json',
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Unexpected response status');
        }
        props.afterSubmit && props.afterSubmit();
      })
      .catch((error) => {
        console.error('Error while sending contact form', error);
      });
    setOpenDialog(false);
  };

  return (
    <>
      <Button label={t('reportExceed')} variant='contained' color={'secondary'} isDisabled={props.disabled} onClick={() => setOpenDialog(true)} />
      <Dialog open={openDialog} maxWidth='md' fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          {t('reportingExceed')}
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextLabel label={t('container')} value={props.container} />
          <div style={{ marginTop: '1rem' }} />
          <FormInputText
            name='exceedAmount'
            control={control}
            label={t('exceedAmount')}
            setValue={setValue}
            helperText={props.settlementId ? t('exceedWillBeSummedWarning') : ''}
          />
          <RenderIf true={!!props.settlementId}>
            <DataTable
              refreshKey={1}
              data={props.history || []}
              noFilters={true}
              initialSort={{ columnIndex: 0, ascending: false }}
              columns={[
                {
                  key: 'uploadedAt',
                  label: t('reportDate'),
                  type: 'date-time',
                },
                {
                  key: 'exceedContainers',
                  label: t('exceedAmount'),
                  type: 'numeric',
                },
              ]}
            />
          </RenderIf>
        </DialogContent>
        <DialogActions>
          <Button label={t('save')} color='primary' variant='contained' onClick={handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
    </>
  );
};
