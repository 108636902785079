import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataTable, GetContentButton } from '@lib/ui-components';
import { useProjectContext } from '@context';

export default function SubcontractorsAgreementsTab() {
  const { t } = useTranslation();
  const projectView = useProjectContext();
  const noticePeriodOptions = [
    {
      label: t('month'),
      value: 'MONTH',
    },
    {
      label: t('twoMonths'),
      value: 'TWO_MONTHS',
    },
    {
      label: t('threeMonths'),
      value: 'THREE_MONTHS',
    },
    {
      label: t('sixMonths'),
      value: 'SIX_MONTHS',
    },
    {
      label: t('year'),
      value: 'YEAR',
    },
  ];

  return (
    <DataTable
      excel={true}
      refreshKey={1}
      fetchPostUrl='/api/project/subcontractor-agreements'
      fetchFilters={{ projectId: projectView.projectId }}
      columns={[
        {
          key: 'fileName',
          label: t('file'),
          type: 'text',
        },
        {
          key: 'companyBranch.name',
          label: t('Model.CompanyBranch.name'),
          type: 'text',
        },
        {
          key: 'companyBranch.registrationAddress.town',
          label: t('locality'),
          type: 'text',
        },
        {
          key: 'companyBranch.registrationAddress.street',
          label: t('street'),
          type: 'text',
        },
        {
          key: 'agreementData.activeFrom',
          label: t('validFrom'),
          type: 'date',
        },
        {
          key: 'agreementData.forUnlimitedPeriod',
          label: t('forUnlimitedPeriod'),
          type: 'boolean',
          align: 'center',
        },
        {
          key: 'agreementData.activeTo',
          label: t('validUntil'),
          type: 'date',
        },
        {
          key: 'agreementData.noticePeriod',
          label: t('noticePeriod'),
          type: 'enum',
          enumValues: noticePeriodOptions,
        },
        {
          key: 'comment',
          label: t('comment'),
          type: 'text',
        },
        {
          key: 'creationDate',
          label: t('added'),
          type: 'date',
        },
        {
          key: 'createdBy',
          label: t('introducedBy'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
        },
      ]}
    />
  );
}
