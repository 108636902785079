import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledLoadingIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const LoadingIndicator = () => {
  const { t } = useTranslation();
  const message = t('Common.loading');
  // const [message, setMessage] = useState(t('Common.loading'));

  // useEffect(() => {
  //   const timeout1 = setTimeout(() => setMessage('Loading... this takes unusually long time'), 10000);
  //
  //   return () => {
  //     clearTimeout(timeout1);
  //   };
  // }, []);

  return (
    <StyledLoadingIndicator>
      <CircularProgress /> <span>{message}</span>{' '}
    </StyledLoadingIndicator>
  );
};
