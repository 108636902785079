import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ProjectTerms } from '@lib/api-interface';
import { RestUsecaseClientService } from '@lib/common-sdk';

export const useProjectBranchTerms = (projectBranchId: string | undefined) => {
  const queryKey = ['projectBranchTerms', projectBranchId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return RestUsecaseClientService.post<any, ProjectTerms>('project', 'get-project-terms-for-branch', { projectBranchId: projectBranchId });
    },
    {
      staleTime: 5000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};
