import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientAssignedProjects } from './client-assigned-projects';
import { ClientUserAccounts } from './client-user-accounts/client-user-accounts';
import { ClientBDO } from './client-bdo';
import { Tabs } from '@lib/ui-components';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { CompanyAddressInfo } from '../../shared/company-address-info';

export interface CompanyInfoProps {
  company: CompanyViewModel;
}

export const ClientData = (props: CompanyInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <CompanyAddressInfo company={props.company} />
      <Tabs
        marginTop='1rem'
        panels={[
          { label: t('assignedProjects'), component: <ClientAssignedProjects company={props.company} /> },
          { label: t('userAccounts'), component: <ClientUserAccounts company={props.company} /> },
          { label: t('bdo'), component: <ClientBDO company={props.company} companyRole={CompanyRole.CLIENT} /> },
        ]}
        borderType='none'
      />
    </>
  );
};
