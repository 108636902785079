export function LargeAddIcon() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40.0003 73.3332C58.4098 73.3332 73.3337 58.4093 73.3337 39.9998C73.3337 21.5903 58.4098 6.6665 40.0003 6.6665C21.5908 6.6665 6.66699 21.5903 6.66699 39.9998C6.66699 58.4093 21.5908 73.3332 40.0003 73.3332Z'
        stroke='#F2C94C'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M40 26.6665V53.3332' stroke='#F2C94C' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M26.667 40H53.3337' stroke='#F2C94C' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
