import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, RenderIf, TextLabel, Toolbar } from '@lib/ui-components';
import { StyledServiceDivider } from '@shared/general';
import { activeCompareFn } from './shared-components/shared';
import { useProjectBranchContext } from '@context';
import { ServiceType, ServiceViewDetails } from '@lib/api-interface';
import { FetchErrorIndicator } from '../../../../../../../../libs/ui-components/components/fetch-error-indicator';
import { useServiceDetailsViews } from '../../../../../../../../data-source-hooks/service-details-view.data-source';
import { ServiceTableProps } from './shared-components/service-table';
import { ServiceButton } from './shared-components/service-button';
import { ServiceButtonMode } from './service-button-props';
import { AdditionalServiceButton } from './additional-tab/additional-service-button';
import { CommuneServiceButton } from './commune-tab/commune-service-button';
import { RolesEnum } from '../../../../../../../../domain/enums/roles.enum';
import { useUserState } from '../../../../../../../../state/UserState';

export interface NewServiceButtonProps {
  onSaved: () => void;
}

export interface ServiceTabProps {
  serviceType: ServiceType;
  active: boolean;
  serviceTable: (props: ServiceTableProps) => JSX.Element;
  tableFilters: any;
  subcontractorId?: string;
  hideButtons?: boolean;
}

function getServiceUrl(userRoles: string[], subcontractorId?: string): string {
  let baseEndpoint = '/api/service';
  if (userRoles.findIndex((value) => value === RolesEnum.CLIENT_ADMIN) >= 0) {
    baseEndpoint = '/api/client-admin/services';
  }

  let endpoint = '/list';
  if (subcontractorId) {
    endpoint = '/listByCompany';
  }
  return baseEndpoint + endpoint;
}

export const ServiceTab = ({ serviceType, active, serviceTable, tableFilters, subcontractorId, hideButtons }: ServiceTabProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const projectBranch = !subcontractorId ? useProjectBranchContext() : undefined;
  const ServiceTable = serviceTable;
  const userState = useUserState();

  // by default use the '/api/service/' endpoint, if the user is a client admin, then use the '/api/client-admin/services/' endpoint
  // if subcontractorId is not null, then use the '.../listByCompany' endpoint, otherwise use '.../list'
  // listByCompany endpoint returns data only for the subcontractorId, while list endpoint returns data for the projectBranchId
  const rolesArray = [...userState.roles];
  const url = getServiceUrl(rolesArray, subcontractorId);
  const [serviceDetailsViewsQuery, reloadServiceDetails] = useServiceDetailsViews(
    url,
    subcontractorId ? 'subcontractorId' : 'projectBranchId',
    subcontractorId ?? projectBranch?.projectBranchId ?? '',
    serviceType,
    active,
    tableFilters,
  );
  const clientAdmin = userState.roles.findIndex((value) => value === RolesEnum.CLIENT_ADMIN) >= 0 ?? false;
  const showButtons = hideButtons ? !hideButtons : !subcontractorId && !clientAdmin;

  const NewServiceButton = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: NewServiceButtonProps) => {
      switch (serviceType) {
        case ServiceType.SCHEDULED:
          return <ServiceButton serviceButtonMode={ServiceButtonMode.CREATE} type={ServiceType.SCHEDULED} onSaved={props.onSaved} reloadServices={reloadServiceDetails} />;
        case ServiceType.PER_REQUEST:
          return <ServiceButton serviceButtonMode={ServiceButtonMode.CREATE} type={ServiceType.PER_REQUEST} onSaved={props.onSaved} reloadServices={reloadServiceDetails} />;
        case ServiceType.ADDITIONAL:
          return (
            <AdditionalServiceButton serviceButtonMode={ServiceButtonMode.CREATE} isAdditionalTabSelected={true} onSaved={props.onSaved} reloadServices={reloadServiceDetails} />
          );
        case ServiceType.COMMUNE:
          return <CommuneServiceButton serviceButtonMode={ServiceButtonMode.CREATE} onSaved={props.onSaved} reloadServices={reloadServiceDetails} />;
        default:
          return <></>;
      }
    };
  }, [serviceType]);

  if (serviceDetailsViewsQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (serviceDetailsViewsQuery.isError) {
    return <FetchErrorIndicator error={serviceDetailsViewsQuery.error} />;
  }

  return (
    <>
      <Panel contentMarginBottom='2rem' contentMarginRight='1rem'>
        <Toolbar
          right={[<TextLabel label={t('all')} value={serviceDetailsViewsQuery.data!.length.toString() || '0'} labelColor='#828282' valueColor='#EB5757' direction='row' />]}
        />
      </Panel>
      <RenderIf true={showButtons}>
        <Toolbar right={[<NewServiceButton onSaved={() => setTableReloadKey(tableReloadKey + 1)} />]} />
      </RenderIf>
      {serviceDetailsViewsQuery.data!.sort(activeCompareFn).map((service: ServiceViewDetails, index: number) => (
        <StyledServiceDivider key={index}>
          <ServiceTable serviceViewDetails={service} reloadServices={reloadServiceDetails} showButtons={showButtons} />
        </StyledServiceDivider>
      ))}
    </>
  );
};
