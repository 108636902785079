import axios, { AxiosResponse } from 'axios';

export class AggregateCommandClientService {
  public static callCommand<CommandType>(aggregateName: string, commandName: string, uuid: string, payload: CommandType): Promise<void> {
    return axios({
      url: `${process.env['REACT_APP_AGGREGATE_URL']}/${aggregateName}/${commandName}/${uuid}`,
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response: AxiosResponse) => {
      if (response.status === 200) {
        return;
      } else {
        throw new Error(response.data);
      }
    });
  }

  public static createAggregate<CommandType>(aggregateName: string, payload: CommandType): Promise<string> {
    return axios({
      url: `${process.env['REACT_APP_AGGREGATE_URL']}/${aggregateName}`,
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response: AxiosResponse) => {
      if (response.status === 200) {
        return response.data.uuid;
      } else {
        throw new Error(response.data);
      }
    });
  }
}
