import { Button } from '@lib/ui-components';
import React from 'react';
import axios from 'axios';

export interface GetContentButtonProps {
  label: string;
  resourcePath: string;
  tooltip?: string;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: JSX.Element;
  method?: 'get' | 'post';
  data?: any;
  isDisabled?: boolean;
}

export const GetContentButton = (props: GetContentButtonProps) => {
  return (
    <Button
      label={props.label}
      startIcon={props.icon}
      tooltip={props.tooltip}
      variant={props.variant}
      isDisabled={props.isDisabled}
      onClick={async () => {
        axios({
          method: props.method || 'get',
          data: props.data,
          url: props.resourcePath,
          responseType: 'blob', // Set the response type to 'blob' to handle binary data
        }).then((response) => {
          const contentDispositionHeader = response.headers['content-disposition'];
          let fileName = '';

          if (contentDispositionHeader) {
            const fileNameMatch = contentDispositionHeader.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = decodeURIComponent(fileNameMatch[1]);
            }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }}
    />
  );
};
