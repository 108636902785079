import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditModelButton, Panel, Toolbar } from '@lib/ui-components';

export function PricesTab() {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();

  return (
    <Panel borderType='raised' contentMarginTop='2rem' contentMarginBottom='2rem' contentMarginRight='2rem'>
      <Toolbar
        right={[
          <EditModelButton
            id={0}
            entity={''}
            modelName={''}
            buttonLabel={t('Projects.ViewProject.pricesTab.button')}
            dialogTitle={t('Projects.ViewProject.pricesTab.button')}
            fields={[
              {
                field: '',
                label: t('priceChangeDate'),
                type: 'date-year-month',
              },
              {
                field: '',
                label: t('selectServiceType'),
                type: 'text',
              },
              {
                rowItems: [
                  {
                    field: '',
                    label: t('selectWasteCode'),
                    type: 'long-numeric',
                  },
                  {
                    field: '',
                    label: t('selectInfrastructureResource'),
                    type: 'text',
                  },
                ],
              },

              {
                rowItems: [
                  {
                    field: '',
                    label: t('selectHeadquarters') + '*',
                    type: 'text',
                  },
                  {
                    field: '',
                    label: t('selectSubcontractor') + '*',
                    type: 'text',
                  },
                ],
              },
              {
                field: '',
                label: t('changePrices') + '*',
                type: 'text',
              },
              {
                rowItems: [
                  {
                    field: '',
                    label: t('oldPriceReceival'),
                    type: 'long-numeric',
                  },
                  {
                    field: '',
                    label: t('newPriceReceival'),
                    type: 'long-numeric',
                  },
                  {
                    field: '',
                    label: t('revenueOrIS'),
                    type: 'text',
                  },
                ],
              },
              {
                rowItems: [
                  {
                    field: '',
                    label: t('oldPriceTransport'),
                    type: 'long-numeric',
                  },
                  {
                    field: '',
                    label: t('newPriceTransport'),
                    type: 'long-numeric',
                  },
                  {
                    field: '',
                    label: t('revenueOrIS'),
                    type: 'text',
                  },
                ],
              },
              {
                field: '',
                label: t('organizationAsSide'),
                type: 'boolean',
              },
            ]}
            validationSchema={{}}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
    </Panel>
  );
}
