import { Icon, Tooltip } from '@mui/material';
import { ActiveIcon, DeactivatedIcon, ForApprovalIcon } from '@shared/service-table';
import { LabelDiv } from '@shared/general';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ServiceStatusLabelProps {
  active: boolean;
  started: boolean;
  acceptanceState: string;
}

export const ServiceStatusLabel = (props: ServiceStatusLabelProps) => {
  const { t } = useTranslation();

  return (
    <LabelDiv>
      {props.acceptanceState === 'INITIALIZED' && (
        <>
          <Tooltip title={t('Projects.ViewProject.serviceForApproval')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <ForApprovalIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.serviceForApproval')}
        </>
      )}
      {props.acceptanceState === 'SEND_TO_ACCEPTANCE' && (
        <>
          <Tooltip title={t('Projects.ViewProject.serviceWaitingForApproval')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <ForApprovalIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.serviceWaitingForApproval')}
        </>
      )}
      {props.acceptanceState === 'REJECTED' && (
        <>
          <Tooltip title={t('Projects.ViewProject.serviceRejected')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <DeactivatedIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.serviceRejected')}
        </>
      )}
      {props.active && props.acceptanceState === 'ACCEPTED' && (
        <>
          <Tooltip title={t('Projects.ViewProject.serviceActivated')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <ActiveIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.serviceActivated')}
        </>
      )}
      {!props.active && props.started && props.acceptanceState === 'ACCEPTED' && (
        <>
          <Tooltip title={t('Projects.ViewProject.serviceDeactivated')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <DeactivatedIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.serviceDeactivated')}
        </>
      )}
      {!props.active && !props.started && props.acceptanceState === 'ACCEPTED' && (
        <>
          <Tooltip title={t('Projects.ViewProject.acceptedNotActiveTooltip')}>
            <Icon sx={{ lineHeight: '0.9rem' }}>
              <DeactivatedIcon />
            </Icon>
          </Tooltip>
          {t('Projects.ViewProject.acceptedNotActive')}
        </>
      )}
    </LabelDiv>
  );
};
