import { format, parseISO } from 'date-fns';

export class DateTimeService {
  static readonly DEFAULT_FORMAT_DATE = 'dd.MM.yyyy';
  static readonly DEFAULT_FORMAT_DATETIME = 'd.M.yyyy / HH:mm';

  public static isoStringDateToDateTime(isoDate?: string) {
    if (isoDate == null) return '';
    const date: Date = parseISO(isoDate);
    return format(date, DateTimeService.DEFAULT_FORMAT_DATETIME);
  }
}
