import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RestUsecaseClientService } from '@lib/common-sdk';

export const useCanDeleteCompany = (companyId: string) => {
  const queryKey = ['can-delete-company', companyId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return RestUsecaseClientService.post<any, boolean>('company', 'can-delete-company', { companyId: companyId });
    },
    {
      staleTime: 2000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};
