import { CompanyRole, FinancialReportPeriod, FinancialReportType, ServicePriceBearer } from '@lib/api-interface';
import { AddIcon, GeneralFormItem, GeneralFormRowOfItems, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MonthClosingReportType } from '../../../../libs/api-interface/models/report/month-closing-report-type.enum';
import { RolesEnum } from '../../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../../domain/enums/authorities.enum';
import { useUserState } from '../../../../state/UserState';

export interface CreateReportButtonProps {
  reportType: FinancialReportType | MonthClosingReportType;
  onSaved: () => Promise<any>;
}

export const CreateReportButton = ({ reportType, onSaved }: CreateReportButtonProps) => {
  const userState = useUserState();
  const { t } = useTranslation();
  const reportTypeSpecificFilters: (GeneralFormItem | GeneralFormRowOfItems)[] = [];
  const [reportOwner, setReportOwner] = React.useState(CompanyRole.CLIENT);
  const isOrganizationUser = userState.roles.findIndex((value) => value === RolesEnum.ORGANIZATION) >= 0;
  const isOrganizationAdmin = isOrganizationUser && userState.authorities.findIndex((value) => value === AuthoritiesEnum.ORGANIZATION_ADMIN) >= 0;
  const validationSchema = {
    allProjects: Yup.boolean(),
    projectId: Yup.string().when('allProjects', {
      is: false,
      then: Yup.string().required(t('fieldRequired')),
    }),
  };

  switch (reportType) {
    case MonthClosingReportType.MONTH_CLOSING_MISSING_INVOICES:
      reportTypeSpecificFilters.push(
        {
          field: 'reportPeriod',
          label: t('reportPeriod'),
          type: 'select',
          selectOptions: Object.values(FinancialReportPeriod).map((val) => {
            return { label: t(`financialReportPeriod.${val}`), value: val };
          }),
        },
        {
          rowItems: [
            {
              field: 'start',
              label: t('month'),
              type: 'date-year-month',
            },
            {
              field: 'end',
              label: t('month'),
              type: 'date-year-month',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
          ],
        },
        {
          rowItems: [
            {
              field: 'priceBearer',
              label: '',
              type: 'select',
              selectOptions: [
                { label: t('incomeInvoices'), value: ServicePriceBearer.ORGANIZATION_REVENUE },
                { label: t('costInvoices'), value: ServicePriceBearer.ORGANIZATION_COST },
              ],
            },
            {
              field: 'invoiceOwner',
              label: '',
              type: 'select',
              selectOptions: [
                { label: t('client'), value: CompanyRole.CLIENT },
                { label: t('subcontractor'), value: CompanyRole.SUBCONTRACTOR },
              ],
              onSelect: (value) => {
                setReportOwner(value as CompanyRole);
              },
            },
          ],
        },
        {
          rowItems: [
            {
              field: 'companyId',
              label: t('company'),
              type: 'table-select',
              tableSelectParams: {
                modelName: 'companyViews',
                dialogTitle: t('companyName'),
                displayFormat: '{name}',
                selectedValueField: 'companyId',
                fetchFilters: reportOwner === CompanyRole.CLIENT ? { roleClient: true } : { roleContractor: true },
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('client'),
                    key: 'roleClient',
                    type: 'boolean',
                  },
                  {
                    label: t('subcontractor'),
                    key: 'roleContractor',
                    type: 'boolean',
                  },
                ],
              },
            },
            {
              field: 'branchId',
              label: t('branch'),
              type: 'table-select',
              isDeactivated: (data) => !data.companyId,
              tableSelectParams: {
                modelName: 'companyBranchViews',
                dialogTitle: t('branchName'),
                displayFormat: '{name}',
                selectedValueField: 'branchId',
                fetchFilters:
                  reportOwner === CompanyRole.CLIENT
                    ? {
                        roleClient: true,
                        companyId: '$companyId',
                      }
                    : { roleContractor: true, companyId: '$companyId' },
                columns: [
                  {
                    label: t('name'),
                    key: 'name',
                    type: 'text',
                  },
                  {
                    label: t('client'),
                    key: 'roleClient',
                    type: 'boolean',
                  },
                  {
                    label: t('subcontractor'),
                    key: 'roleContractor',
                    type: 'boolean',
                  },
                ],
              },
            },
          ],
        },
      );
      Object.assign(validationSchema, {
        projectId: Yup.string().notRequired(),
        reportPeriod: Yup.string().required(t('fieldRequired')),
        start: Yup.string().required(t('fieldRequired')),
      });
      break;
    case FinancialReportType.FINANCIAL_INCOME_AND_COSTS:
      reportTypeSpecificFilters.push(
        {
          field: 'reportPeriod',
          label: t('reportPeriod'),
          type: 'select',
          selectOptions: Object.values(FinancialReportPeriod).map((val) => {
            return { label: t(`financialReportPeriod.${val}`), value: val };
          }),
        },
        {
          rowItems: [
            {
              field: 'start',
              label: t('month'),
              type: 'date-year-month',
            },
            {
              field: 'end',
              label: t('month'),
              type: 'date-year-month',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
            {
              field: 'sumMonths',
              label: t('sumMonths'),
              type: 'boolean',
              isVisible: (data) => data.reportPeriod === FinancialReportPeriod.CUSTOM,
            },
          ],
        },
      );
      Object.assign(validationSchema, {
        reportPeriod: Yup.string().required(t('fieldRequired')),
        start: Yup.string().required(t('fieldRequired')),
      });
      break;
    case FinancialReportType.FINANCIAL_ANNUAL:
    case FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS:
      reportTypeSpecificFilters.push({
        field: 'year',
        label: t('year'),
        type: 'date-year',
        defaultValue: new Date().getFullYear().toString(),
      });
      if (reportType === FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS) {
        Object.assign(validationSchema, {
          projectId: Yup.string().notRequired(),
        });
      }
      Object.assign(validationSchema, {
        year: Yup.string().required(t('fieldRequired')),
      });
      break;
    case FinancialReportType.FINANCIAL_MONTHLY:
      reportTypeSpecificFilters.push({
        field: 'yearMonth',
        label: t('month'),
        type: 'date-year-month',
      });
      Object.assign(validationSchema, {
        yearMonth: Yup.string().required(t('fieldRequired')),
      });
      break;
  }

  if (reportType !== FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS && reportType !== MonthClosingReportType.MONTH_CLOSING_MISSING_INVOICES) {
    reportTypeSpecificFilters.push(
      {
        rowItems: [
          {
            field: 'projectId',
            label: t('project'),
            type: 'table-select',
            isDeactivated: (data) => data.allProjects,
            tableSelectParams: {
              modelName: 'projectViews',
              dialogTitle: t('project'),
              displayFormat: '{name}',
              selectedValueField: 'projectId',
              columns: [
                {
                  label: t('name'),
                  key: 'name',
                  type: 'text',
                },
                {
                  label: t('projectNumber'),
                  key: 'projectNumber',
                  type: 'text',
                },
              ],
            },
          },
          {
            field: 'companyId',
            label: t('company'),
            type: 'table-select',
            isDeactivated: (data) => data.allProjects,
            tableSelectParams: {
              modelName: 'companyViews',
              search: 'byProjectId',
              dialogTitle: t('project'),
              displayFormat: '{name}',
              selectedValueField: 'companyId',
              fetchFilters: {
                projectId: '$projectId',
              },
              columns: [
                {
                  label: t('companyName'),
                  key: 'name',
                  type: 'text',
                },
              ],
            },
          },
          {
            field: 'branchId',
            label: t('branch'),
            type: 'table-select',
            isDeactivated: (data) => data.allProjects,
            tableSelectParams: {
              modelName: 'companyBranchViews',
              search: 'byProjectIdAndCompanyId',
              dialogTitle: t('branch'),
              displayFormat: '{name}',
              selectedValueField: 'branchId',
              fetchFilters: {
                projectId: '$projectId',
                companyId: '$companyId',
              },
              columns: [
                {
                  key: 'branchNestedNumber',
                  label: t('branchNestedNumber'),
                  type: 'numeric',
                  align: 'right',
                },
                {
                  key: 'name',
                  label: t('branchName'),
                  type: 'text',
                },
                {
                  key: 'central',
                  label: t('headquarter'),
                  type: 'boolean',
                  align: 'center',
                },
                {
                  key: 'registrationAddress.town',
                  label: t('locality'),
                  type: 'text',
                },
                {
                  key: 'registrationAddress.street',
                  label: t('street'),
                  type: 'text',
                },
                {
                  key: 'registrationAddress.houseNum',
                  label: t('houseNum'),
                  type: 'text',
                },
                {
                  key: 'registrationAddress.apartmentNum',
                  label: t('apartmentNum'),
                  type: 'text',
                },
                {
                  key: 'registrationAddress.postalCode',
                  label: t('postcode'),
                  type: 'text',
                },
                {
                  key: 'registrationAddress.commune',
                  label: t('commune'),
                  type: 'text',
                },
              ],
            },
          },
        ],
      },
      {
        rowItems: [
          {
            field: 'allProjects',
            label: t('allProjects'),
            type: 'boolean',
            isVisible: () => reportType === FinancialReportType.FINANCIAL_INCOME_AND_COSTS && isOrganizationAdmin,
          },
          {
            field: 'forClient',
            label: t('reportForClient'),
            type: 'boolean',
          },
          {
            field: 'organizationAsSide',
            label: t('organizationAsSide'),
            type: 'boolean',
            isVisible: isOrganizationUser,
          },
        ],
      },
    );
  }

  return (
    <UsecaseButton
      variant='contained'
      startIcon={<AddIcon />}
      onSaved={onSaved}
      groupName='reports'
      useCaseName='generate-report'
      buttonLabel={t('generateReport')}
      fields={[...reportTypeSpecificFilters]}
      onBeforeSave={(data) => {
        data.forClient ? (data.issuer = 'CLIENT') : (data.issuer = 'ORGANIZATION');
        return data;
      }}
      hiddenValues={{ reportType }}
      validationSchema={validationSchema}
    />
  );
};
