import { Button, Panel, RenderIf } from '@lib/ui-components';
import React from 'react';
import { CollapsedArea, LabelDiv, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import { useQueryClient } from '@tanstack/react-query';
import i18n from 'i18next';
import { ServiceBar } from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import { Grid } from '@mui/material';
import {
  priceItem,
  variableAnnotationsFieldsReloadById,
} from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { CanceledIcon, ForApprovalIcon, InvoicedIcon, SettledIcon, UnsettledIcon } from './icons';
import { SettlementDetailsView } from '@lib/api-interface';
import { ServiceComplaintsSettlementTableFooter } from './service-complaints-settlement-table-footer';

export interface ServiceComplaintsSettlementTableProps {
  settlement: SettlementDetailsView;
  reloadSettlements: () => void;
  showButtons?: boolean;
}

export function ServiceComplaintsSettlementTable({ settlement, reloadSettlements, showButtons=true }: ServiceComplaintsSettlementTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);
  const queryClient = useQueryClient();

  const regex = /(\d{4})-(\d{2})/g;
  const match = regex.exec(settlement.yearMonth!) || [null, null, null];
  const monthLabel = translations.common[`shortMonth.${match[2]}`];
  const dateLabel = `${monthLabel} ${match[1]}`;

  function getNavResourceNumber() {
    if (settlement.serviceView.serviceType === 'ADDITIONAL') {
      return settlement.serviceView.navResourceNumber;
    }

    let result = settlement.clientCollectionNavResourceNumber != null ? settlement.clientCollectionNavResourceNumber : '';
    result +=
      settlement.subcontractorCollectionNavResourceNumber != null
        ? result !== ''
          ? ', ' + settlement.subcontractorCollectionNavResourceNumber
          : settlement.subcontractorCollectionNavResourceNumber
        : '';
    return result;
  }

  function getNavResourceName() {
    if (settlement.serviceView.serviceType === 'ADDITIONAL') {
      return settlement.serviceView.navResourceName;
    }
    let result = settlement.clientCollectionNavResourceName != null ? settlement.clientCollectionNavResourceName : '';
    result +=
      settlement.subcontractorCollectionNavResourceName != null
        ? result !== ''
          ? ', ' + settlement.subcontractorCollectionNavResourceName
          : settlement.subcontractorCollectionNavResourceName
        : '';
    return result;
  }

  return (
    <StyledTableBorder>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <LabelDiv>
          {settlement.settlementStatus === 'SETTLED' && <SettledIcon />}
          {settlement.settlementStatus === 'UNSETTLED' && <UnsettledIcon />}
          {settlement.settlementStatus === 'TO_BE_ACCEPTED' && <ForApprovalIcon />}
          {settlement.settlementStatus === 'INVOICED' && <InvoicedIcon />}
          {settlement.settlementStatus === 'CANCELLED' && <CanceledIcon />}
          {dateLabel}
        </LabelDiv>
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={settlement.settlementStatus === 'CANCELLED'}>
        <ServiceBar
          headers={[
            { label: t('projectNumber') },
            { label: t('clientName') },
            { label: t('branch') },
            { label: t('locality') },
            { label: t('street') },
            { label: t('activeBdo') },
            { label: t('bdoStatus') },
            { label: t('kpoNumber') },
            { label: t('serviceType') },
          ]}
          items={[
            { value: settlement.complaintSettlementView?.projectNumber, options: { bold: true } },
            { value: settlement.complaintSettlementView?.companyName },
            { value: settlement.complaintSettlementView?.branchName },
            { value: settlement.complaintSettlementView?.registrationAddress?.town },
            { value: settlement.complaintSettlementView?.registrationAddress?.street },
            { value: settlement.serviceView?.project.terms?.general?.bdo, type: 'boolean' },
            { value: settlement.serviceView !== undefined ? translations.common[`bdoStatus.${settlement.serviceView.bdoStatus}`] : '' },
            { value: settlement.kpoNumber },
            { value: t('complaint') },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('wasteCode') },
            { label: t('pix') },
            { label: t('wasteName') },
            { label: t('ownDescriptionOfWaste') },
            { label: t('resourceName') },
            { label: t('number') },
            { label: t('collectionMethod') },
            { label: t('containerAmount') },
            { label: t('forUtilization') },
            { label: t('reportDate') },
            { label: t('implementationDate') },
          ]}
          items={[
            { value: settlement.serviceView?.projectWaste?.wasteCode, options: { bold: true } },
            { value: settlement.serviceView?.currentSettings?.pix, type: 'boolean' },
            { value: settlement.serviceView?.projectWaste?.wasteDescription, options: { bold: true } },
            { value: settlement.serviceView?.projectWaste?.projectWasteDescription },
            {
              value: settlement.serviceView?.resourceInBulk ? t('Model.Infrastructure.InfrastructureCategory.IN_BULK') : settlement.serviceView?.projectInfrastructureService?.name,
              options: { bold: true },
            },
            {
              value: settlement.serviceView?.resourceInBulk ? '-' : settlement.serviceView?.infrastructureCount,
              options: { bold: true },
            },
            { value: settlement.serviceView?.collectionInfrastructureService?.name, options: { bold: true } },
            { value: settlement.serviceView?.collectionInfrastructureCount, options: { bold: true } },
            { value: settlement.serviceView?.utilization, type: 'boolean' },
            { value: settlement.reportDate },
            { value: settlement.serviceView?.settlementType === 'PER_MONTH' ? '-' : settlement.implementationDate },
          ]}
        />
        <CollapsedArea collapse={expandCollapseSwitch}>
          <ServiceBar
            headers={[{ label: t('settledSubcontractorName') }, { label: t('settledTransportEntity') }, { label: t('transporterKPO') }, { label: t('receiverKPO') }]}
            items={[
              { value: settlement.serviceView?.subcontractor },
              { value: settlement.serviceView?.transporter },
              { value: settlement.serviceView?.transporterKpo },
              { value: settlement.serviceView?.receiverKpo },
            ]}
          />
          <ServiceBar
            headers={[
              { label: t('settlementType') },
              { label: t('Projects.ViewProject.clientCollectionPrice') },
              { label: t('Projects.ViewProject.subcontractorCollectionPrice') },
              { label: t('Projects.ViewProject.clientTransportPrice') },
              { label: t('Projects.ViewProject.subcontractorTransportPrice') },
              { label: t('logisticMinimum') },
              { label: t('logisticMinimumSurcharge') },
              { label: t('paymentMethodCode') },
              { label: t('separatedInvoice') },
              { label: t('invoicingMethod') },
              { label: t('settlementUnit') },
              { label: t('constantCost') },
              { label: t('organizationAsSide') },
              { label: t('plImport') },
              { label: t('plExport') },
            ]}
            items={[
              { value: translations.common[`settlementType.${settlement.serviceView?.settlementType}`] },
              priceItem(settlement.complaintData?.priceInfos?.CLIENT_COLLECTION, {
                priceTypeVisible: true,
                translations,
              }),
              priceItem(settlement.complaintData?.priceInfos?.SUBCONTRACTOR_COLLECTION, {
                priceTypeVisible: true,
                translations,
              }),
              priceItem(settlement.complaintData?.priceInfos?.CLIENT_TRANSPORT, {
                priceTypeVisible: true,
                translations,
              }),
              priceItem(settlement.complaintData?.priceInfos?.SUBCONTRACTOR_TRANSPORT, {
                priceTypeVisible: true,
                translations,
              }),
              { value: settlement.serviceView?.logisticMinimum },
              { value: settlement.serviceView?.logisticMinimumSurcharge },
              { value: translations.common[`paymentMethodCode.${settlement.serviceView?.paymentMethod}`] },
              { value: settlement.serviceView?.separateInvoice, type: 'boolean' },
              { value: translations.common[`invoicingMethod.${settlement.serviceView?.invoicingMethod}`] },
              { value: translations.common[`settlementUnit.${settlement.serviceView?.settlementUnit}`] },
              { value: settlement.serviceView?.constantCost, type: 'boolean' },
              { value: settlement.serviceView?.currentSettings.organizationAsSide, type: 'boolean' },
              { value: settlement.serviceView?.plImport, type: 'boolean' },
              { value: settlement.serviceView?.plExport, type: 'boolean' },
            ]}
          />
          <Grid container>
            <Grid item xs={6}>
              <ServiceBar
                headers={[{ label: t('navResourceNumber') }, { label: t('navResourceName') }]}
                items={[{ value: getNavResourceNumber() }, { value: getNavResourceName() }]}
              />
            </Grid>
            <Grid item xs={6}>
              <ServiceBar headers={[{ label: t('variableAnnotation') }, {}]} items={variableAnnotationsFieldsReloadById(settlement, t, queryClient)} />
            </Grid>
          </Grid>
          <ServiceBar
            headers={[
              { label: t('estimatedMass') },
              { label: t('reportedWeightExceed') },
              { label: t('reportedWeight') },
              { label: t('receivedMass') },
              { label: t('estimatedQuantities') },
              { label: t('reportedQuantitiesExceed') },
              { label: t('reportedQuantities') },
              { label: t('quantityReceived') },
              { label: t('transportPlan') },
              { label: t('transportCompleted') },
            ]}
            items={[
              { value: '-' },
              { value: '-' },
              { value: '-' },
              {
                value: settlement.complaintData?.mass,
                // options: { color: settlement.complaintSettlementView.massDiscrepancyInRange ? 'green' : 'red' },
              },
              { value: '-' },
              { value: '-' },
              { value: '-' },
              { value: settlement.complaintData?.quantities },
              { value: '-' },
              { value: '-' },
            ]}
          />
        </CollapsedArea>
        <RenderIf true={showButtons}>
          <ServiceComplaintsSettlementTableFooter settlement={settlement} t={t} reloadSettlements={reloadSettlements} />
        </RenderIf>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}
