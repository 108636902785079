import React, { useEffect } from 'react';

export const InstructionPDF: React.FC = () => {
  useEffect(() => {
    const pdfUrl = '/Instrukcja_POI_2.0.pdf';

    window.open(pdfUrl, '_blank');

    window.location.href = '/';
  }, []);

  return null;
};