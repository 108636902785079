import React from 'react';

export interface GreenRedDotIndicatorProps {
  showRedLight: boolean;
}

export const GreenRedDotIndicator = ({ showRedLight }: GreenRedDotIndicatorProps) => {
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: showRedLight ? '#EB5757' : '#0AAD50',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        border: '2px solid white',
      }}
    />
  );
};
