import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ProjectView } from '@lib/api-interface';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';

export const useProjectView = (projectId: string | undefined) => {
  const queryKey = ['projectViews', projectId];
  const queryClient = useQueryClient();
  const query = useQuery(
    queryKey,
    (context) => {
      return ReactQueryHateoasClientService.findOneByAggregateId<ProjectView>('projectViews', 'projectId', projectId);
    },
    {
      staleTime: 3000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};
