import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@lib/ui-components';

export const DeclarationChanges = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyViews' }}
        columns={[
          {
            key: 'subcontractor',
            label: t('subcontractor'),
            type: 'text',
            minWidth: '12rem',
          },
          {
            key: 'branch',
            label: t('branch'),
            type: 'text',
          },
          {
            key: 'pickupDate',
            label: t('pickupDate'),
            type: 'date',
          },
          {
            key: 'reasonForChange',
            label: t('reasonForChange'),
            type: 'text',
            minWidth: '17rem',
          },
          {
            key: 'previousValue',
            label: t('previousValue'),
            type: 'text',
          },
          {
            key: 'previousValueMg',
            label: t('previousValueMg'),
            type: 'numeric',
          },
          {
            key: 'previousNumberOfTransports',
            label: t('previousNumberOfTransports'),
            type: 'numeric',
          },
          {
            key: 'newValueMg',
            label: t('newValueMg'),
            type: 'numeric',
          },
          {
            key: 'newNumberOfTransports',
            label: t('newNumberOfTransports'),
            type: 'numeric',
          },
          {
            key: 'status',
            label: t('status'),
            type: 'text',
          },
          {
            key: 'author',
            label: t('author'),
            type: 'text',
          },
        ]}
      />
    </>
  );
};
