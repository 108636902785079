import React from 'react';
import { TitlePositioner } from '../../../../../../components/Shared';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, CheckboxLabel, DataTable, Edit, EditModelButton, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { useProjectContext } from '@context';
import { useQueryClient } from '@tanstack/react-query';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { YellowIcon } from '../../../../../../libs/ui-components/components/action-buttons/YellowIcon';

type TFB = Yup.TestFunction<boolean | undefined, AnyObject>;

const isWithdrawKplCardFromPlatformAutomaticallyMutuallyExclusive: TFB = function (value) {
  const { withdrawKpoCardAutomatically } = this.parent;
  if (withdrawKpoCardAutomatically == true && value == true) {
    return false;
  }
  return true;
};

const isWithdrawKpoCardAutomaticallyMutuallyExclusive: TFB = function (value) {
  const { withdrawKpoCardFromPlatformAutomatically } = this.parent;
  if (withdrawKpoCardFromPlatformAutomatically == true && value == true) {
    return false;
  }
  return true;
};

export default function IsServicesTab() {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const projectView = useProjectContext();
  const queryClient = useQueryClient();
  const reloadData = () => queryClient.invalidateQueries(['projectViews', projectView.projectId!]);

  return (
    <>
      <TitlePositioner>
        <TextLabel label={t('Projects.ViewProject.Bdo.services.mainSettings')} />
      </TitlePositioner>
      <Panel borderType='raised'>
        <GridLabel>
          <GridLabel>
            <Panel flex='1'>
              <CheckboxLabel label={t('Projects.ViewProject.Bdo.services.KeoBdo')} value={projectView.projectBdoSettings?.keoTurnedOnInBdo} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Bdo.services.keoPlatform')} value={projectView.projectBdoSettings?.keoOnlyFromPlatform} type='toggle' />
            </Panel>
            <Panel flex='1'>
              <CheckboxLabel label={t('Projects.ViewProject.Bdo.services.BdoRecord')} value={projectView.projectBdoSettings?.bdoRegisterForCurrentYearOnly} type='toggle' />
              <CheckboxLabel label={t('Projects.ViewProject.Bdo.services.autoKPO')} value={projectView.projectBdoSettings?.withdrawKpoCardAutomatically} type='toggle' />
              <CheckboxLabel label={t('withdrawKpoCardFromPlatformAutomatically')} value={projectView.projectBdoSettings?.withdrawKpoCardFromPlatformAutomatically} type='toggle' />
            </Panel>
          </GridLabel>
          <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
            <AggregateCommandButton
              aggregateId={projectView.projectId!}
              aggregateName={'ProjectAggregate'}
              commandName={'updateProjectBdoSettings'}
              buttonLabel=''
              startIcon={<Edit />}
              dialogTitle={t('Projects.ViewProject.Terms.Settings.mainSettings').toUpperCase()}
              fields={[
                {
                  field: 'keoTurnedOnInBdo',
                  label: t('Projects.ViewProject.Bdo.services.KeoBdo'),
                  type: 'boolean',
                  defaultValue: projectView.projectBdoSettings?.keoTurnedOnInBdo,
                },
                {
                  field: 'keoOnlyFromPlatform',
                  label: t('Projects.ViewProject.Bdo.services.keoPlatform'),
                  type: 'boolean',
                  defaultValue: projectView.projectBdoSettings?.keoOnlyFromPlatform,
                },
                {
                  field: 'bdoRegisterForCurrentYearOnly',
                  label: t('Projects.ViewProject.Bdo.services.BdoRecord'),
                  type: 'boolean',
                  defaultValue: projectView.projectBdoSettings?.bdoRegisterForCurrentYearOnly,
                },
                {
                  field: 'withdrawKpoCardAutomatically',
                  label: t('Projects.ViewProject.Bdo.services.autoKPO'),
                  type: 'boolean',
                  defaultValue: projectView.projectBdoSettings?.withdrawKpoCardAutomatically,
                },
                {
                  field: 'withdrawKpoCardFromPlatformAutomatically',
                  label: t('withdrawKpoCardFromPlatformAutomatically'),
                  type: 'boolean',
                  defaultValue: projectView.projectBdoSettings?.withdrawKpoCardFromPlatformAutomatically,
                },
              ]}
              validationSchema={{
                withdrawKpoCardAutomatically: Yup.boolean().test(
                  'is-withdrawKpoCardAutomatically-mutually-exclusive',
                  t('mutually-exclusive'),
                  isWithdrawKpoCardAutomaticallyMutuallyExclusive,
                ),
                withdrawKpoCardFromPlatformAutomatically: Yup.boolean().test(
                  'is-withdrawKpoCardFromPlatformAutomatically-mutually-exclusive',
                  t('mutually-exclusive'),
                  isWithdrawKplCardFromPlatformAutomaticallyMutuallyExclusive,
                ),
              }}
              onBeforeSave={(formData) => {
                return { ...projectView.projectBdoSettings, ...formData };
              }}
              onSaved={async () => {
                setTableReloadKey(tableReloadKey + 1);
                await reloadData();
              }}
            />
          </GridLabel>
        </GridLabel>
      </Panel>
      <TitlePositioner>
        <TextLabel label={t('Projects.ViewProject.Bdo.services.headquartersSettings')} />
      </TitlePositioner>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectCompanyViews' }}
        fetchFilters={{ projectId: projectView.projectId, role: 'CLIENT', deleted: false }}
        columns={[
          {
            key: 'companyName',
            label: t('clientName'),
            type: 'text',
          },
          {
            key: 'bdoFunctionalityEnabled',
            label: t('activeBdo'),
            type: 'boolean',
          },
          {
            key: 'bdoSettingsFromProject',
            label: t('Projects.ViewProject.Bdo.services.tabMainSettings'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoSettings.keoTurnedOnInBdo',
            label: t('Projects.ViewProject.Bdo.services.KeoBdo'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoSettings.keoOnlyFromPlatform',
            label: t('Projects.ViewProject.Bdo.services.keoPlatform'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoSettings.bdoRegisterForCurrentYearOnly',
            label: t('Projects.ViewProject.Bdo.services.BdoRecord'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoSettings.withdrawKpoCardAutomatically',
            label: t('Projects.ViewProject.Bdo.services.autoKPO'),
            type: 'boolean',
            align: 'center',
          },
          {
            key: 'bdoSettings.withdrawKpoCardFromPlatformAutomatically',
            label: t('withdrawKpoCardFromPlatformAutomatically'),
            type: 'boolean',
            align: 'center',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={0}
                entity={''}
                modelName={''}
                dialogTitle={t('edit')}
                fields={[
                  {
                    field: 'bdoSettingsFromProject',
                    label: t('Projects.ViewProject.Bdo.services.tabMainSettings'),
                    type: 'boolean',
                    defaultValue: row.bdoSettingsFromProject,
                  },
                  {
                    field: 'keoOnlyFromPlatform',
                    label: t('Projects.ViewProject.Bdo.services.keoPlatform'),
                    type: 'boolean',
                    defaultValue: row.bdoSettings?.keoOnlyFromPlatform,
                    isVisible: (data) => data.bdoSettingsFromProject === false,
                  },
                  {
                    field: 'keoTurnedOnInBdo',
                    label: t('Projects.ViewProject.Bdo.services.KeoBdo'),
                    type: 'boolean',
                    defaultValue: row.bdoSettings?.keoTurnedOnInBdo,
                    isVisible: (data) => data.bdoSettingsFromProject === false,
                  },
                  {
                    field: 'bdoRegisterForCurrentYearOnly',
                    label: t('Projects.ViewProject.Bdo.services.BdoRecord'),
                    type: 'boolean',
                    defaultValue: row.bdoSettings?.bdoRegisterForCurrentYearOnly,
                    isVisible: (data) => data.bdoSettingsFromProject === false,
                  },
                  {
                    field: 'withdrawKpoCardAutomatically',
                    label: t('Projects.ViewProject.Bdo.services.autoKPO'),
                    type: 'boolean',
                    defaultValue: row.bdoSettings?.withdrawKpoCardAutomatically,
                    isVisible: (data) => data.bdoSettingsFromProject === false,
                  },
                  {
                    field: 'withdrawKpoCardFromPlatformAutomatically',
                    label: t('withdrawKpoCardFromPlatformAutomatically'),
                    type: 'boolean',
                    defaultValue: row.bdoSettings?.withdrawKpoCardAutomatically,
                    isVisible: (data) => data.bdoSettingsFromProject === false,
                  },
                ]}
                validationSchema={{
                  withdrawKpoCardAutomatically: Yup.boolean().test(
                    'is-withdrawKpoCardAutomatically-mutually-exclusive',
                    t('mutually-exclusive'),
                    isWithdrawKpoCardAutomaticallyMutuallyExclusive,
                  ),
                  withdrawKpoCardFromPlatformAutomatically: Yup.boolean().test(
                    'is-withdrawKpoCardFromPlatformAutomatically-mutually-exclusive',
                    t('mutually-exclusive'),
                    isWithdrawKplCardFromPlatformAutomaticallyMutuallyExclusive,
                  ),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                overrideSaveCall={async (payload: any) => {
                  try {
                    if (payload.bdoSettingsFromProject === false) {
                      delete payload.bdoSettingsFromProject;
                      await AggregateCommandClientService.callCommand<any>('ProjectCompanyAggregate', 'setProjectBdoSettings', row.projectCompanyId, payload);
                    } else {
                      await AggregateCommandClientService.callCommand<any>('ProjectCompanyAggregate', 'clearProjectBdoSettings', row.projectCompanyId, {});
                    }
                    return new DataResultsDto(false, 200, undefined, undefined, undefined);
                  } catch (error: any) {
                    return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                  }
                }}
                yellowIcon
              />
            ),
          },
          {
            renderer: (row) => (
              <AggregateCommandButton
                aggregateName='ProjectCompanyAggregate'
                aggregateId={row.projectCompanyId}
                commandName='setBdoFunctionality'
                buttonLabel={t('activeBdo')}
                fields={[
                  {
                    field: 'bdoFunctionalityEnabled',
                    label: t('activeBdo'),
                    type: 'boolean',
                    defaultValue: row.bdoFunctionalityEnabled,
                  },
                ]}
                startIcon={
                  <YellowIcon>
                    <PowerSettingsNewIcon />
                  </YellowIcon>
                }
                dialogIcon={
                  <YellowIcon sizePx={80}>
                    <PowerSettingsNewIcon />
                  </YellowIcon>
                }
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </>
  );
}
