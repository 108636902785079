import { useTranslation } from 'react-i18next';
import React from 'react';
import { DataTable } from '@lib/ui-components';
import { ContactPersonTypeEnum } from '@lib/api-interface';

interface CompanyOrganizationUsersProps {
  companyId: string;
}

export const CompanyOrganizationUsers = (props: CompanyOrganizationUsersProps) => {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      modelDef={{ modelName: 'contactPersonViews' }}
      noFilters={true}
      fetchFilters={{
        type: ContactPersonTypeEnum.ORGANIZATION,
        companyId: props.companyId,
      }}
      columns={[
        {
          key: 'firstName',
          label: t('firstName'),
          type: 'text',
        },
        {
          key: 'lastName',
          label: t('lastName'),
          type: 'text',
        },
        {
          key: 'phone',
          label: t('phone'),
          type: 'text',
        },
        {
          key: 'email',
          label: t('email'),
          type: 'text',
        },
      ]}
    />
  );
};
