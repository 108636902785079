import * as React from 'react';
import { Alert, Portal, Snackbar as MatSnackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

export interface SnackbarProps {
  message: string;
  severity?: AlertColor;
  open?: boolean;
  onClose: () => void;
  autoHideDuration?: number;
  onlyManuallyClosed?: boolean;
}

export const Snackbar: (props: SnackbarProps) => JSX.Element = (props: SnackbarProps) => {
  return (
    <Portal>
      <MatSnackbar
        open={props.open != null ? props.open : props.message !== ''}
        autoHideDuration={props.autoHideDuration ? props.autoHideDuration : props.onlyManuallyClosed ? null : 5000}
        TransitionProps={{ onExited: () => props.onClose() }}
        onClose={() => props.onClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => props.onClose()} severity={props.severity} variant='filled' sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </MatSnackbar>
    </Portal>
  );
};
