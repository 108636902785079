import L from 'leaflet';

export enum MarkerIconColor {
  BLACK = 'black',
  BLUE = 'blue',
  GOLD = 'gold',
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  VIOLET = 'violet',
  YELLOW = 'yellow',
}

export function getMarkerIcon(color: MarkerIconColor = MarkerIconColor.BLUE) {
  return new L.Icon({
    iconUrl: `/map-markers/marker-icon-2x-${color}.png`,
    shadowUrl: '/map-markers/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
}
