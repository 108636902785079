import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, GetContentButton } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ProjectBranchDataAgreementsProps {
  companyId: string;
  projectId: string;
}

export const CompanyAgreements = (props: ProjectBranchDataAgreementsProps) => {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      fetchPostUrl={'/api/resource/companyFileViews/by-params'}
      fetchFilters={{
        companyId: props.companyId,
        projectId: props.projectId,
        type: 'AGREEMENT',
      }}
      noFilters={true}
      initialSort={{ columnIndex: 2, ascending: false }}
      columns={[
        {
          key: 'fileName',
          label: t('file'),
          type: 'text',
        },
        {
          key: 'comment',
          label: t('comment'),
          type: 'text',
        },
        {
          key: 'uploadDate',
          label: t('added'),
          type: 'date-time',
        },
        {
          key: 'uploadBy',
          label: t('introducedBy'),
          type: 'text',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
        },
      ]}
    />
  );
};
