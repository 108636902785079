import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, HStack, ToggleButtons } from '@lib/ui-components';
import { EuroDaily } from './euro-daily/euro-daily';
import { EuroMonthly } from './euro-monthly/euro-monthly';
import { PixRates } from './pix/pix-rates';

export const ExchangeRates = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <>
      <Header label={t('GlobalSettings.exchangeRates')} />
      <HStack align='center'>
        <ToggleButtons
          labels={[t('GlobalSettings.exchangeRates.euroDaily'), t('GlobalSettings.exchangeRates.euroMonthly'), t('pix')]}
          activeIndex={activePage}
          onChange={(index) => setActivePage(index)}
        />
      </HStack>
      {activePage === 0 && <EuroDaily />}
      {activePage === 1 && <EuroMonthly />}
      {activePage === 2 && <PixRates />}
    </>
  );
};
