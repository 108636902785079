import React from 'react';
import { TitlePositioner } from '../../../../../../../../components/Shared';
import { useTranslation } from 'react-i18next';
import { CheckboxLabel, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { useProjectBranchContext } from '@context';
import { useCompanyBranchView } from '@data-source-hooks';
import { QueryResultRenderer } from '../../../../../../../../libs/ui-components/components/query-result-renderer';

export default function BranchDataBdo() {
  const { t } = useTranslation();
  const projectBranch = useProjectBranchContext();
  const [companyBranchQuery] = useCompanyBranchView(projectBranch.branchId!);

  return (
    <QueryResultRenderer
      query={companyBranchQuery}
      render={(companyBranchView) => {
        return (
          <Panel direction='row'>
            <Panel flex='1'>
              <TitlePositioner>
                <TextLabel label={t('bdo')} />
              </TitlePositioner>
              <Panel borderType='raised'>
                <GridLabel>
                  <GridLabel>
                    <TextLabel label={t('Model.Company.mpdNumber')} value={companyBranchView.bdoIdentificationNumber} gridColumn='1/4' />
                    <CheckboxLabel label={t('Model.Company.VerifiedBdo')} type='toggle' value={companyBranchView.verifiedBdo} />
                  </GridLabel>
                </GridLabel>
              </Panel>
            </Panel>
          </Panel>
        );
      }}
    />
  );
}
