import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationUser } from './organization-user/organization-user';
import { useUserState } from '../../../state/UserState';
import { TabPage, Tabs } from '@lib/ui-components';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';

export const Registers = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const panelsArray: TabPage[] = [];

  if (userState.authorities.findIndex((value) => value === AuthoritiesEnum.MANAGES_USERS || value === AuthoritiesEnum.ORGANIZATION_ADMIN) >= 0) {
    panelsArray.push({ label: t('organizationUsers'), component: <OrganizationUser /> });
  }

  return (
    <div>
      <Tabs panels={panelsArray} />
    </div>
  );
};
