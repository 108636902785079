import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePositioner } from '../../../../../../components/Shared';
import { CompanyViewModel } from '@lib/api-interface';
import { DataTable, Panel, TextLabel } from '@lib/ui-components';

export default function ContactTab() {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [activePage, setActivePage] = React.useState(0);
  const { t } = useTranslation();

  return (
    <Panel borderType='raised'>
      <TitlePositioner>
        <TextLabel label={t('mainContactPerson').toUpperCase()} />
      </TitlePositioner>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: CompanyViewModel.name }}
        noFilters
        columns={[
          {
            key: '',
            label: t('firstName'),
            type: 'text',
          },
          {
            key: '',
            label: t('lastName'),
            type: 'text',
          },
          {
            key: '',
            label: t('email'),
            type: 'text',
          },
          {
            key: '',
            label: t('phoneNumber'),
            type: 'numeric',
          },
          {
            key: '',
            label: t('comment'),
            type: 'text',
          },
        ]}
      />
      <TitlePositioner>
        <TextLabel label={t('otherContactPersons').toUpperCase()} />
      </TitlePositioner>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: CompanyViewModel.name }}
        noFilters
        columns={[
          {
            key: '',
            label: t('firstName'),
            type: 'text',
          },
          {
            key: '',
            label: t('lastName'),
            type: 'text',
          },
          {
            key: '',
            label: t('email'),
            type: 'text',
          },
          {
            key: '',
            label: t('phoneNumber'),
            type: 'numeric',
          },
          {
            key: '',
            label: t('comment'),
            type: 'text',
          },
        ]}
      />
    </Panel>
  );
}
