import React from 'react';
import styles from './styles.module.css';
import { Outlet } from 'react-router-dom';
import logo from './logo.svg';
import { LeftMenuItemsSelector } from '../../left-menu-items-selector/left-menu-items-selector';
import { AppBar } from '../../app-bar/app-bar';
import { Collapse, Slide } from '@mui/material';
import styled from 'styled-components';
import buildInfo from './../../../build.json';
import { Helmet } from 'react-helmet';

const CollapseDiv = styled.div`
  position: fixed;
  z-index: 100;
`;

export const LeftMenuLayout = () => {
  const [checked, setChecked] = React.useState(false);

  const handleOpen = () => {
    setChecked(true);
  };

  const handleClose = () => {
    setChecked(false);
  };

  return (
    <>
      <Helmet>
        <title>Platforma Odpadowa Interzero</title>
      </Helmet>
      <div className={styles['container']}>
        <CollapseDiv onMouseOver={handleOpen} onMouseLeave={handleClose}>
          <Collapse orientation='horizontal' collapsedSize={51} in={checked}>
            <div className={styles['left']}>
              <Slide direction='right' in={checked}>
                <img src={logo} className={styles['logo']} alt='logo' />
              </Slide>

              <LeftMenuItemsSelector />
              <div className={styles['versionContainer']}>
                <div className={styles['version']}>Build: {buildInfo.buildVersion}</div>
              </div>
            </div>
          </Collapse>
        </CollapseDiv>

        <div className={styles['right']}>
          <AppBar />
          <Outlet />
        </div>
      </div>
    </>
  );
};
