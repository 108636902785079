import { FormInputProps, Panel } from '@lib/ui-components';
import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';
import Dropzone from 'react-dropzone';

export interface FormInputFileProps extends FormInputProps {
  ignoreSelectedFileName?: boolean;
}

export const FormInputFile: React.FC<FormInputFileProps> = ({ name, control, label, variant, setValue, isDeactivated, onSelect, ignoreSelectedFileName }) => {
  const [selectedFileName, setSelectedFileName] = React.useState<string | undefined>(undefined);

  return (
    <FormControl size={'small'} variant={variant != null ? variant : 'outlined'} style={{ marginTop: '0px', marginBottom: '32px', width: '100%' }}>
      <Controller
        name={name}
        control={control}
        render={() => (
          <Dropzone
            onDrop={(acceptedFiles) => {
              setValue && setValue(name, acceptedFiles[0]);
              !ignoreSelectedFileName && setSelectedFileName(acceptedFiles[0].name);
              onSelect && onSelect(acceptedFiles[0]);
            }}
            maxFiles={1}
            disabled={isDeactivated}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input data-test-id={`file-input-${name}`} {...getInputProps()} />
                  {selectedFileName == null && (
                    <Panel borderType='flat'>
                      <div
                        style={{
                          display: 'block',
                          fontFamily: 'Montserrat,"Helvetica Neue",Arial,sans-serif',
                          color: '#00000099',
                          fontWeight: 400,
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          padding: 0,
                          marginBottom: '8px',
                        }}
                      >
                        {label}
                      </div>
                    </Panel>
                  )}
                  {selectedFileName != null && (
                    <Panel borderType='flat'>
                      <div
                        style={{
                          display: 'block',
                          fontFamily: 'Montserrat,"Helvetica Neue",Arial,sans-serif',
                          color: '#00000099',
                          fontWeight: 400,
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          padding: 0,
                          marginBottom: '8px',
                        }}
                      >
                        {selectedFileName}
                      </div>
                    </Panel>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        )}
      />
    </FormControl>
  );
};
