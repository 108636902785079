import { InfrastructureServices } from '../../client-admin/projects/branches/services/infrastructure-services';
import { ProjectBranchContextProvider } from '@context';
import React from 'react';
import { getProjectBranchViewForClient } from '@data-source-hooks';
import { useTranslation } from 'react-i18next';

export const Infrastructure = () => {
  const { t } = useTranslation();
  const [projectBranchView, setProjectBranchView] = React.useState(null);
  React.useEffect(() => {
    const fetchProjectBranchView = async () => {
      try {
        const projectBranchView = await getProjectBranchViewForClient();
        setProjectBranchView(projectBranchView);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchProjectBranchView();
  }, []);

  if (projectBranchView === null) {
    return <div>{t('Common.loading')}</div>;
  }

  return (
    <ProjectBranchContextProvider value={projectBranchView}>
      <InfrastructureServices withMainTableTitle={true} />
    </ProjectBranchContextProvider>
  );
};
