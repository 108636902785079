import { Expose } from 'class-transformer';

export enum ScheduleType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export class WeeklySchedule {
  @Expose()
  monday?: boolean;
  @Expose()
  tuesday?: boolean;
  @Expose()
  wednesday?: boolean;
  @Expose()
  thursday?: boolean;
  @Expose()
  friday?: boolean;
  @Expose()
  saturday?: boolean;
  @Expose()
  sunday?: boolean;
  @Expose()
  frequency?: number;
}

export class WeeklyOccurrenceStaticSchedule {
  @Expose()
  first?: boolean;
  @Expose()
  second?: boolean;
  @Expose()
  third?: boolean;
  @Expose()
  fourth?: boolean;
  @Expose()
  fifth?: boolean;
}

export class StaticCalendarSchedule {
  @Expose()
  monday?: WeeklyOccurrenceStaticSchedule;
  @Expose()
  tuesday?: WeeklyOccurrenceStaticSchedule;
  @Expose()
  wednesday?: WeeklyOccurrenceStaticSchedule;
  @Expose()
  thursday?: WeeklyOccurrenceStaticSchedule;
  @Expose()
  friday?: WeeklyOccurrenceStaticSchedule;
  @Expose()
  saturday?: WeeklyOccurrenceStaticSchedule;
}

export class DynamicCalendarSchedule {
  @Expose()
  weekGap?: number;
  @Expose()
  weeklySchedule?: WeeklySchedule;
}
