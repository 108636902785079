import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AddModelButton, DataTable, DeleteModelButton, GetContentButton, Panel, Toolbar } from '@lib/ui-components';
import { CompanyBranchViewModel } from '@lib/api-interface';

export interface ClientOtherFilesProps {
  branchView: CompanyBranchViewModel;
}

export function CompanyBranchOtherFiles(props: ClientOtherFilesProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();

  return (
    <Panel borderType='none'>
      <Toolbar
        right={[
          <AddModelButton
            multipart={true}
            buttonLabel={t('uploadFile')}
            dialogTitle={t('uploadFile')}
            modelName={'companyFileViews'}
            fields={[
              { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
              { label: t('comment'), type: 'text', field: 'comment' },
              { label: t('availableToBranch'), type: 'boolean', field: 'availableToBranch' },
            ]}
            hiddenValues={{
              type: 'OTHER',
              companyId: props.branchView.companyId,
              companyBranchId: props.branchView.branchId!,
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyFileViews' }}
        fetchFilters={{ companyBranchId: props.branchView.branchId!, type: 'OTHER' }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'companyBranch.name',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
          {
            key: 'availableToBranch',
            label: t('availableToBranch'),
            type: 'boolean',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
          {
            renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </Panel>
  );
}
