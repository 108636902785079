import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';

export interface FormInputChipsProps extends FormInputProps {
  precision?: number;
  min?: number;
  max?: number;
  initialValue?: string[];
  placeholderLabel: string;
}

function valueAsNumber(value: string) {
  return Number(value.replace(',', '.'));
}

function transformToNumberString(x: any, decimalPlaces: number) {
  const val = x
    .toString()
    .replace(/\./g, ',') // replace . with ,
    .replace(/[,](?=.*[,])/g, '') // remove all , but last
    .replace(/(?!^)-/g, ''); // remove all - but first

  if (decimalPlaces !== undefined) {
    const valueParts = val.split(',');

    if (valueParts[0] === '') {
      valueParts[0] = '0';
    } else if (valueParts[0] === '-') {
      valueParts[0] = '-0';
    } else {
      valueParts[0] = `${parseInt(valueParts[0], 10)}`;
    }

    if (decimalPlaces === 0) {
      return valueParts[0];
    }

    if (valueParts.length === 1) {
      return valueParts[0] + '.' + '0'.repeat(decimalPlaces);
    }

    const lengthOfDecimalPoints = valueParts[1].length;
    if (lengthOfDecimalPoints < decimalPlaces) {
      return valueParts[0] + '.' + valueParts[1] + '0'.repeat(decimalPlaces - lengthOfDecimalPoints);
    } else if (lengthOfDecimalPoints === decimalPlaces) {
      return valueParts[0] + '.' + valueParts[1];
    } else {
      return valueParts[0] + '.' + valueParts[1].substr(0, decimalPlaces);
    }
  }

  return val;
}

export const FormInputChips = (props: FormInputChipsProps) => {
  const [valueArray, setValueArray] = React.useState<string[]>(props.initialValue ?? []);

  const precision = props.precision != null ? props.precision : 0;

  function formatValue(value: string): string | undefined {
    if (value !== null && value !== undefined && `${value}`.trim() !== '') {
      let transformedValue = value;
      if (props.precision != null) {
        const val = value.replace(/[^\d,.-]/g, '');
        if (val === '') return undefined;
        transformedValue = transformToNumberString(val, props.precision);
      }

      if (props.max || props.min) {
        const valAsNumber = valueAsNumber(transformedValue);
        if (props.max != null) {
          if (valAsNumber > props.max) {
            transformedValue = transformToNumberString(props.max, precision);
          }
        }

        if (props.min != null) {
          if (valAsNumber < props.min) {
            transformedValue = transformToNumberString(props.min, precision);
          }
        }
      }
      return transformedValue;
    } else {
      return undefined;
    }
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          value={valueArray}
          onChange={(event, newInputValue) => {
            event.preventDefault();
            event.stopPropagation();
            if (Array.isArray(newInputValue)) {
              const newValArray = newInputValue as string[];
              if (newValArray.length < valueArray.length) {
                setValueArray(newValArray);
                onChange(newValArray);
              } else {
                const val = formatValue(newValArray[newInputValue.length - 1]);
                if (val !== undefined) {
                  if (valueArray.indexOf(val) === -1) {
                    valueArray.push(val);
                    setValueArray(valueArray);
                    onChange(valueArray);
                  }
                }
              }
            }
          }}
          renderTags={(acValue: any[], getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes) =>
            acValue.map((option: any, index: any) => {
              return <Chip key={index} data-test-id={`chip-${('' + option).replaceAll(' ', '-')}`} variant='outlined' label={option} {...getTagProps({ index })} />;
            })
          }
          renderInput={(params: any) => (
            <TextField
              inputProps={{ 'data-test-id': `input-${props.name}` }}
              variant={props.variant != null ? props.variant : 'outlined'}
              {...params}
              label={props.label}
              fullWidth
              margin='normal'
              size='small'
              helperText={error ? error.message : ' '}
              error={!!error}
              placeholder={props.placeholderLabel}
              disabled={props.isDeactivated}
            />
          )}
        />
      )}
    />
  );
};
