import React from 'react';
import { DataModelClientService, HateoasRestApiClientService } from '../../../common-sdk';
import { ClassConstructor } from 'class-transformer/types/interfaces';

export enum ModelProviderState {
  NO_ID,
  LOADING,
  LOADED,
  FAILED,
}

export interface HateoasModelProviderOptions<Type> {
  classConstructor?: ClassConstructor<Type>;
  filters?: any;
  reloadKey?: number;
}

export const useModelProvider = <ModelType>(classConstructor: ClassConstructor<any>, id?: string) => {
  const [data, setData] = React.useState<ModelType | undefined>(undefined);
  const [state, setState] = React.useState<ModelProviderState>(id === undefined ? ModelProviderState.NO_ID : ModelProviderState.LOADING);
  React.useEffect(() => {
    if (id !== undefined) {
      if (id.startsWith('!')) {
        // eslint-disable-next-line
        id = id.substring(1);
      }
      setState(ModelProviderState.LOADING);
      DataModelClientService.findOne<ModelType>(classConstructor.name, id, classConstructor)
        .then((results) => {
          if (!results.failed) {
            setData(results.response);
            setState(ModelProviderState.LOADED);
          } else {
            setState(ModelProviderState.FAILED);
          }
        })
        .catch((error) => {
          setState(ModelProviderState.FAILED);
        });
    } else {
      if (state !== ModelProviderState.NO_ID) {
        setState(ModelProviderState.NO_ID);
        setData(undefined);
      }
    }
  }, [classConstructor, id]);
  return { data, state };
};

export const useHateoasModelProvider = <ModelType>(resourcePluralName: string, id?: string, options?: HateoasModelProviderOptions<ModelType>) => {
  const [data, setData] = React.useState<ModelType | undefined>(undefined);
  const [state, setState] = React.useState<ModelProviderState>(id === undefined ? ModelProviderState.NO_ID : ModelProviderState.LOADING);

  React.useEffect(() => {
    if (id !== undefined) {
      if (id.startsWith('!')) {
        // eslint-disable-next-line
        id = id.substring(1);
      }
      setState(ModelProviderState.LOADING);
      HateoasRestApiClientService.findOne<ModelType>(resourcePluralName, id, options)
        .then((results) => {
          if (!results.failed) {
            setData(results.response);
            setState(ModelProviderState.LOADED);
          } else {
            setState(ModelProviderState.FAILED);
          }
        })
        .catch((error) => {
          setState(ModelProviderState.FAILED);
        });
    } else {
      if (state !== ModelProviderState.NO_ID) {
        setState(ModelProviderState.NO_ID);
        setData(undefined);
      }
    }
  }, [resourcePluralName, id, options?.reloadKey]);
  return { data, state };
};
