import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddModelButton, DataTable, DeleteModelButton, GetContentButton, Panel, Toolbar } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCompanyBranchView } from '../../../../../../../../data-source-hooks/company-branch.data-source';
import { QueryResultRenderer } from '../../../../../../../../libs/ui-components/components/query-result-renderer';

export interface CompanyBranchOtherDecisionsProps {
  companyBranchId: string;
}

export function CompanyBranchOtherDecisions(props: CompanyBranchOtherDecisionsProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const [branchQuery] = useCompanyBranchView(props.companyBranchId);

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        return (
          <Panel borderType='none'>
            <Toolbar
              right={[
                <AddModelButton
                  multipart={true}
                  buttonLabel={t('uploadFile')}
                  dialogTitle={t('uploadFile')}
                  modelName={'companyFileViews'}
                  fields={[
                    { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
                    { label: t('comment'), type: 'text', field: 'comment' },
                  ]}
                  hiddenValues={{
                    type: 'CONTRACTOR_OTHER',
                    companyId: companyBranchView.companyId,
                    companyBranchId: companyBranchView.branchId,
                  }}
                  onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                />,
              ]}
            />
            <DataTable
              refreshKey={tableReloadKey}
              modelDef={{ modelName: 'companyFileViews' }}
              fetchFilters={{
                companyId: companyBranchView.companyId,
                companyBranchId: companyBranchView.branchId,
                type: 'CONTRACTOR_OTHER',
              }}
              columns={[
                {
                  key: 'fileName',
                  label: t('otherDecisions'),
                  type: 'text',
                },
                {
                  key: 'creationDate',
                  label: t('uploadDate'),
                  type: 'date',
                },
                {
                  key: 'createdBy',
                  label: t('addedBy'),
                  type: 'text',
                },
                {
                  key: 'comment',
                  label: t('comment'),
                  type: 'text',
                },
              ]}
              rowOptions={[
                {
                  renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
                },
                {
                  renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
                },
              ]}
            />
          </Panel>
        );
      }}
    />
  );
}
