import { AuthoritiesEnum } from '../enums/authorities.enum';

export class UserAuthoritiesService {
  static extractAuthorities(authorities: any): Array<AuthoritiesEnum> {
    const result: Array<AuthoritiesEnum> = [];
    if (authorities?.organizationAdmin) result.push(AuthoritiesEnum.ORGANIZATION_ADMIN);
    if (authorities?.managesUsers) result.push(AuthoritiesEnum.MANAGES_USERS);
    if (authorities?.assignsProjectNumber) result.push(AuthoritiesEnum.ASSIGNS_PROJECT_NUMBER);
    if (authorities?.viewsUnassignedProjects) result.push(AuthoritiesEnum.VIEWS_UNASSIGNED_PROJECTS);
    if (authorities?.projectBdo) result.push(AuthoritiesEnum.PROJECT_BDO);
    if (authorities?.projectSales) result.push(AuthoritiesEnum.PROJECT_SALES);
    if (authorities?.projectInvoice) result.push(AuthoritiesEnum.PROJECT_INVOICE);
    if (authorities?.projectLeadPerson) result.push(AuthoritiesEnum.PROJECT_LEAD);
    if (authorities?.seesPrices) result.push(AuthoritiesEnum.SEES_PRICES);
    if (authorities?.decisionChangeAcceptanceNotRequired) result.push(AuthoritiesEnum.DECISION_CHANGE_ACCEPTANCE_NOT_REQUIRED);
    return result;
  }
}
