import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../factories/infrastructure-category-select-options.factory';
import { MASS_MAX_VAL, MASS_PRECISION, PLN_MAX_VAL, PLN_PRECISION, VOLUME_MAX_VAL, VOLUME_PRECISION } from '@lib/api-interface';
import { AddModelButton, DataTable, DeleteModelButton, EditModelButton, Header, Toolbar } from '@lib/ui-components';

export const FixedAssets = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const infrastructureCategorySelectOptions = InfrastructureCategorySelectOptionsFactory.getSelectOptions(t);

  return (
    <>
      <Header label={t('GlobalSettings.fixed-assets.header')} />
      <Toolbar
        right={[
          <AddModelButton
            modelName='organizationInfrastructures'
            buttonLabel={t('Button.OrganizationInfrastructureModel.add')}
            dialogTitle={t('Button.OrganizationInfrastructureModel.add.dialog.title')}
            fields={[
              {
                field: 'infrastructure',
                label: t('infrastructure'),
                type: 'table-select',
                tableSelectParams: {
                  modelName: 'infrastructures',
                  displayFormat: '{name}',
                  dialogTitle: t('infrastructure'),
                  columns: [
                    { key: 'name', label: t('resourceName'), type: 'text' },
                    { key: 'category', label: t('Model.Infrastructure.category'), type: 'enum', enumValues: InfrastructureCategorySelectOptionsFactory.getSelectOptions(t) },
                    { key: 'mass', label: t('estimatedMassMg'), type: 'numeric' },
                    { key: 'volume', label: t('volumeM3'), type: 'numeric' },
                  ],
                },
              },
              { field: 'name', label: t('resourceName'), type: 'text' },
              {
                field: 'serialNumber',
                label: t('Model.OrganizationInfrastructure.serialNumber'),
                type: 'text',
              },
              {
                rowItems: [
                  {
                    field: 'volume',
                    label: t('volumeM3'),
                    type: 'numeric',
                    numericPrecision: VOLUME_PRECISION,
                    numericMinValue: 0,
                    numericMaxValue: VOLUME_MAX_VAL,
                  },
                  {
                    field: 'mass',
                    label: t('estimatedMassMg'),
                    type: 'numeric',
                    numericPrecision: MASS_PRECISION,
                    numericMinValue: 0,
                    numericMaxValue: MASS_MAX_VAL,
                  },
                ],
              },
              {
                field: 'deprecation',
                label: t('Model.OrganizationInfrastructure.deprecation'),
                type: 'boolean',
              },
              {
                rowItems: [
                  {
                    field: 'deprecationStartDate',
                    label: t('Model.OrganizationInfrastructure.deprecationStartDate'),
                    type: 'date',
                  },
                  {
                    field: 'deprecationMonths',
                    label: t('Model.OrganizationInfrastructure.deprecationMonths'),
                    type: 'numeric',
                    numericPrecision: 0,
                    numericMinValue: 1,
                    numericMaxValue: 999,
                  },
                ],
              },
              {
                rowItems: [
                  {
                    field: 'deprecationAmount',
                    label: t('Model.OrganizationInfrastructure.deprecationAmount'),
                    type: 'numeric',
                    numericPrecision: PLN_PRECISION,
                    numericMinValue: 0,
                    numericMaxValue: PLN_MAX_VAL,
                  },
                  {
                    field: 'deprecationRate',
                    label: t('Model.OrganizationInfrastructure.deprecationRate'),
                    type: 'numeric',
                    numericPrecision: 0,
                    numericMinValue: 0,
                    numericMaxValue: 100,
                  },
                ],
              },
            ]}
            validationSchema={{
              name: Yup.string().required(t('Model.Infrastructure.name.required')),
              serialNumber: Yup.string().required(t('Model.OrganizationInfrastructure.serialNumber.required')),
            }}
            postErrorMappings={{ '500': t('resourceExists') }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'organizationInfrastructures',
        }}
        columns={[
          {
            key: 'category',
            label: t('Model.Infrastructure.category'),
            type: 'enum',
            enumValues: infrastructureCategorySelectOptions,
          },
          {
            key: 'name',
            label: t('resourceName'),
            type: 'text',
          },
          {
            key: 'serialNumber',
            label: t('Model.OrganizationInfrastructure.serialNumber'),
            type: 'text',
          },
          {
            key: 'volume',
            label: t('volumeM3'),
            type: 'numeric',
            numericPrecision: VOLUME_PRECISION,
          },
          {
            key: 'mass',
            label: t('estimatedMassMg'),
            type: 'numeric',
            numericPrecision: MASS_PRECISION,
          },
          {
            key: 'deprecation',
            label: t('Model.OrganizationInfrastructure.deprecation'),
            type: 'boolean',
          },
          {
            key: 'deprecationMonths',
            label: t('Model.OrganizationInfrastructure.deprecationMonths'),
            type: 'numeric',
          },
          {
            key: 'deprecationAmount',
            label: t('Model.OrganizationInfrastructure.deprecationAmount'),
            type: 'numeric',
            numericPrecision: PLN_PRECISION,
          },
          {
            key: 'deprecationStartDate',
            label: t('Model.OrganizationInfrastructure.deprecationStartDate'),
            type: 'date',
          },
          {
            key: 'deprecationRate',
            label: t('Model.OrganizationInfrastructure.deprecationRate'),
            type: 'numeric',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                modelName='organizationInfrastructures'
                buttonLabel={t('edit')}
                dialogTitle={t('Button.InfrastructureModel.edit.dialog.title')}
                fields={[
                  {
                    field: 'category',
                    label: t('Model.Infrastructure.category'),
                    type: 'select',
                    selectOptions: infrastructureCategorySelectOptions,
                  },
                  {
                    field: 'name',
                    label: t('resourceName'),
                    type: 'text',
                  },
                  {
                    field: 'serialNumber',
                    label: t('Model.OrganizationInfrastructure.serialNumber'),
                    type: 'text',
                  },
                  {
                    rowItems: [
                      {
                        field: 'volume',
                        label: t('volumeM3'),
                        type: 'numeric',
                        numericPrecision: VOLUME_PRECISION,
                        numericMinValue: 0,
                        numericMaxValue: VOLUME_MAX_VAL,
                      },
                      {
                        field: 'mass',
                        label: t('estimatedMassMg'),
                        type: 'numeric',
                        numericPrecision: MASS_PRECISION,
                        numericMinValue: 0,
                        numericMaxValue: MASS_MAX_VAL,
                      },
                    ],
                  },
                  {
                    field: 'deprecation',
                    label: t('Model.OrganizationInfrastructure.deprecation'),
                    type: 'boolean',
                  },
                  {
                    rowItems: [
                      {
                        field: 'deprecationStartDate',
                        label: t('Model.OrganizationInfrastructure.deprecationStartDate'),
                        type: 'date',
                      },
                      {
                        field: 'deprecationMonths',
                        label: t('Model.OrganizationInfrastructure.deprecationMonths'),
                        type: 'numeric',
                        numericPrecision: 0,
                        numericMinValue: 1,
                        numericMaxValue: 999,
                      },
                    ],
                  },
                  {
                    rowItems: [
                      {
                        field: 'deprecationAmount',
                        label: t('Model.OrganizationInfrastructure.deprecationAmount'),
                        type: 'numeric',
                        numericPrecision: PLN_PRECISION,
                        numericMinValue: 0,
                        numericMaxValue: PLN_MAX_VAL,
                      },
                      {
                        field: 'deprecationRate',
                        label: t('Model.OrganizationInfrastructure.deprecationRate'),
                        type: 'numeric',
                        numericPrecision: 0,
                        numericMinValue: 0,
                        numericMaxValue: 100,
                      },
                    ],
                  },
                ]}
                validationSchema={{
                  category: Yup.string().required(t('Model.Infrastructure.category.required')),
                  name: Yup.string().required(t('Model.Infrastructure.name.required')),
                  serialNumber: Yup.string().required(t('Model.OrganizationInfrastructure.serialNumber.required')),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                postErrorMappings={{ '500': t('resourceExists') }}
                yellowIcon
              />
            ),
          },
          {
            renderer: (row) => (
              <DeleteModelButton
                id={row['id']}
                modelName='organizationInfrastructures'
                tooltip={t('GlobalSettings.infrastructure.tooltip.delete')}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </>
  );
};
