import { hookstate, useHookstate } from '@hookstate/core';

const webSocketState = hookstate({
  settlementExportRefreshKey: 0,
  settlementImportRefreshKey: 0,
  reportGeneratedRefreshKey: 0,
  reportGenerationStartedRefreshKey: 0,
  branchesImportRefreshKey: 0,
  updateCompanyMpdBdoRefreshKey: 0,
  receivedQuantitiesImportRefreshKey: 0,
});

export function useWebSocketState() {
  const state = useHookstate(webSocketState);
  return {
    get settlementExportRefreshKey() {
      return state.settlementExportRefreshKey.get();
    },
    setSettlementExportRefreshKey() {
      state.settlementExportRefreshKey.set((prevKey) => prevKey + 1);
    },
    get settlementImportRefreshKey() {
      return state.settlementImportRefreshKey.get();
    },
    setSettlementImportRefreshKey() {
      state.settlementImportRefreshKey.set((prevKey) => prevKey + 1);
    },
    get branchesImportRefreshKey() {
      return state.branchesImportRefreshKey.get();
    },
    setBranchesImportedRefreshKey() {
      state.branchesImportRefreshKey.set((prevKey) => prevKey + 1);
    },
    get reportGeneratedRefreshKey() {
      return state.reportGeneratedRefreshKey.get();
    },
    setReportGeneratedRefreshKey() {
      state.reportGeneratedRefreshKey.set((prevKey) => prevKey + 1);
    },
    get reportGenerationStartedRefreshKey() {
      return state.reportGenerationStartedRefreshKey.get();
    },
    setReportGenerationStartedRefreshKey() {
      state.reportGenerationStartedRefreshKey.set((prevKey) => prevKey + 1);
    },
    get updateCompanyMpdBdoRefreshKey() {
      return state.updateCompanyMpdBdoRefreshKey.get();
    },
    setUpdateCompanyMpdBdoRefreshKey() {
      state.updateCompanyMpdBdoRefreshKey.set((prevKey) => prevKey + 1);
    },
    get receivedQuantitiesImportRefreshKey() {
      return state.receivedQuantitiesImportRefreshKey.get();
    },
    setRefreshReceivedQuantitiesImport() {
      state.receivedQuantitiesImportRefreshKey.set((prevKey) => prevKey + 1);
    },
  };
}
