import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataResultsDto, HateoasRestApiClientService, JsonUtils } from '@lib/common-sdk';
import { Button, ButtonColor, Dialog, GeneralForm, GeneralFormItem, GeneralFormRowOfItems, Snackbar } from '../../index';
import CloseIcon from '@mui/icons-material/Close';
import { useStateIfMounted } from 'use-state-if-mounted';
import { LargeAddIcon } from '../LargeAddIcon';
import { AddIcon } from '../AddIcon';
import { Breakpoint } from '@mui/system';
import i18n from 'i18next';
import { getErrorMessage } from '../../custom-post-error.handler';

export interface AddModelButtonProps {
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  onBeforeSave?: (formData: any) => any;
  onSaved: (result: any) => Promise<any>;
  overrideSaveCall?: (payload: any) => Promise<DataResultsDto<any>>;
  modelName: string;
  validationSchema?: any;
  buttonLabel: string;
  dialogTitle: string;
  fields: (GeneralFormItem | GeneralFormRowOfItems)[];
  hiddenValues?: any;
  bottomMessage?: string;
  postErrorMappings?: Record<string, string>;
  multipart?: boolean;
  startIcon?: JSX.Element;
  dialogIcon?: JSX.Element;
  maxWidth?: Breakpoint | false;
}

export const AddModelButton = (props: AddModelButtonProps) => {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);

  async function handleSaveDialog(formData: any) {
    let results: DataResultsDto<any>;
    try {
      if (props.onBeforeSave) {
        formData = props.onBeforeSave(formData);
      }
      if (props.overrideSaveCall) {
        results = await props.overrideSaveCall({ ...props.hiddenValues, ...formData });
      } else {
        results = await HateoasRestApiClientService.create<any>(
          props.modelName,
          { ...props.hiddenValues, ...formData },
          {
            contentType: props?.multipart ? 'multipart/form-data' : 'application/json',
          },
        );
      }
      if (!results.failed) {
        setDialog(false);
        setTimeout(() => props.onSaved(results), 0);
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.errorStatusCode}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }
        setFailureMessage(getErrorMessage(results.response, translations) || t('Common.error'));
      }
    } catch (e: any) {
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      const context = JsonUtils.extractValue(data, 'context');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(getErrorMessage({ code, context }, translations) || t('Common.error'));
    }
  }

  async function handleCloseDialog() {
    setDialog(false);
  }

  return (
    <>
      <Button
        dataTestId={`${props.modelName}-add-btn`}
        label={props.buttonLabel}
        onClick={async () => setDialog(true)}
        variant={props.variant}
        color={props.color}
        startIcon={props.startIcon ? props.startIcon : <AddIcon />}
      />
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth={props.maxWidth === undefined ? 'md' : props.maxWidth} fullWidth={true}>
        <div style={{ margin: 'auto', marginTop: '2rem' }}>{props.dialogIcon ? props.dialogIcon : <LargeAddIcon />}</div>
        <DialogTitle sx={{ margin: 'auto', fontSize: '1.7rem', color: '#183362' }}>
          {props.dialogTitle}
          <IconButton
            aria-label='close'
            data-test-id={'dialog-close-btn'}
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GeneralForm
            fields={props.fields}
            validationSchema={props.validationSchema}
            onSaved={async (data) => handleSaveDialog(data)}
            onCanceled={async () => handleCloseDialog()}
            hideCancelButton={false}
            hiddenValues={props.hiddenValues}
            variant='standard'
          />
          {props.bottomMessage && (
            <DialogTitle
              sx={{
                margin: 'auto',
                fontSize: '1rem',
                maxWidth: '30rem',
                textAlign: 'center',
              }}
            >
              {props.bottomMessage}
            </DialogTitle>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};
