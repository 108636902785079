import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from './form-input-props';
import { useStateIfMounted } from 'use-state-if-mounted';
import { longNumericToString, valueToLongNumeric } from '../../../common-sdk';

export interface FormInputLongNumericProps extends FormInputProps {
  precision?: number;
  min?: number;
  max?: number;
  initialValue?: string | number | undefined;
}

export const FormInputLongNumeric = (props: FormInputLongNumericProps) => {
  const precision = props.precision != null ? props.precision : 0;
  const [displayed, setDisplayed] = useStateIfMounted<string | number | undefined>(longNumericToString(props.initialValue, precision));

  function formatValue(value: string) {
    if (value !== null && value !== undefined && `${value}`.trim() !== '') {
      const val = value.replace(/[^\d,.-]/g, '');
      if (val !== '') {
        let longNumeric = valueToLongNumeric(val, precision);
        if (longNumeric !== undefined) {
          if (props.max != null || props.min != null) {
            if (props.max != null) {
              if (longNumeric > props.max) {
                longNumeric = props.max;
              }
            }
            if (props.min != null) {
              if (longNumeric < props.min) {
                longNumeric = props.min;
              }
            }
          }

          return longNumericToString(longNumeric, precision);
        }
      }
    }
    return '';
  }

  function handleChange(value: any, onChange: (...event: any[]) => void): void {
    const toDisplay = formatValue(value.toString());
    setDisplayed(toDisplay);
    onChange(toDisplay);
    if (toDisplay !== '') {
      props.setValue(props.name, toDisplay, precision);
    } else {
      props.setValue(props.name, '');
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>, value: any, onChange: (...event: any[]) => void) {
    if (event.key === 'Enter') {
      const toDisplay = formatValue(value.toString());
      setDisplayed(toDisplay);
      onChange(toDisplay);
      if (toDisplay !== '') {
        props.setValue(props.name, toDisplay, precision);
      } else {
        props.setValue(props.name, '');
      }
    }
  }

  function processValueToDisplay(displayed: string | number | undefined, value: any) {
    if (value === '' && displayed !== value) {
      setDisplayed('');
    }
    return displayed;
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div data-test-id={`input-${props.name}-wrapper`}>
          <TextField
            inputProps={{ 'data-test-id': `input-${props.name}` }}
            helperText={error ? error.message : ' '}
            size='small'
            error={!!error}
            value={processValueToDisplay(displayed, value)}
            fullWidth
            label={props.label}
            variant={props.variant != null ? props.variant : 'outlined'}
            margin='normal'
            onBlur={(event) => handleChange(displayed, onChange)}
            onChange={(event) => {
              setDisplayed(event.target.value);
              onChange(event.target.value);
            }}
            onKeyPress={(event) => handleKeyDown(event, displayed, onChange)}
            disabled={props.isDeactivated}
          />
        </div>
      )}
    />
  );
};
