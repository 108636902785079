import { DataTableColumn } from '@lib/ui-components';
import { TFunction } from 'react-i18next';
import { MASS_PRECISION } from '@lib/api-interface';

export const getBdoRejectedCardsTableColumns = (t: TFunction<'common'>): DataTableColumn[] => {
  return [
    { key: 'senderName', label: t('senderName.break'), type: 'text' },
    { key: 'senderEupName', label: t('branchName.break'), type: 'text' },
    { key: 'cardNumber', label: t('kpoNumber'), type: 'text', minWidth: '15rem' },
    { key: 'receiverName', label: t('Model.CompanyBranch.collecting'), type: 'text' },
    { key: 'wasteCode', label: t('wasteCode'), type: 'text' },
    { key: 'initialMass', label: t('initialMass.break'), type: 'numeric', numericPrecision: MASS_PRECISION },
    { key: 'wasteMass', label: t('correctedMass.break'), type: 'numeric', numericPrecision: MASS_PRECISION },
    { key: 'vehicleRegNumber', label: t('licensePlates.break'), type: 'text' },
    { key: 'remarks', label: t('remarks'), type: 'text' },
    { key: 'senderFirstNameAndLastName', label: t('senderFirstNameAndLastName'), type: 'text' },
    {
      key: 'realTransportTime',
      label: t('realTransportTime'),
      type: 'date',
      dateFormat: 'd.M.yyyy / HH:mm',
      minWidth: '10rem',
    },
    {
      key: 'cardRejectionTime',
      label: t('cardRejectionTime'),
      type: 'date',
      dateFormat: 'd.M.yyyy / HH:mm',
      minWidth: '10rem',
    },
    {
      key: 'cardRejectionTime',
      label: t('cardRejectionTime'),
      type: 'date',
      dateFormat: 'd.M.yyyy / HH:mm',
      minWidth: '10rem',
    },
    { key: 'receiverFirstAndLastName', label: t('receiverFirstAndLastName'), type: 'text' },
    { key: 'receiverEupNumber', label: t('receiverEupNumber'), type: 'text' },
    { key: 'receiverEupName', label: t('receiverEupName'), type: 'text' },
    { key: 'carrierName', label: t('carrierName'), type: 'text' },
    { key: 'carrierIdentificationNumber', label: t('carrierIdentificationNumber'), type: 'text' },
  ];
};
