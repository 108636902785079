import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ProjectSettings } from '@lib/api-interface';
import { RestUsecaseClientService } from '@lib/common-sdk';
import axios from 'axios';

export const useProjectBranchSettings = (projectBranchId: string | undefined) => {
  const queryKey = ['projectBranchSettings', projectBranchId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return RestUsecaseClientService.post<any, ProjectSettings>('project', 'get-project-settings-for-branch', { projectBranchId: projectBranchId });
    },
    {
      staleTime: 5000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};

export const getProjectBranchSettingsForClient = async () => {
  return await axios.get(`${process.env['REACT_APP_API_URL']}/api/project-branch-view/settings`).then((res) => res.data);
};
