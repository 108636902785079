import React from 'react';
import { Panel } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';
import { CompanyAssignedProjects } from '../../shared/company-assigned-projects';

export interface ClientAssignedProjectsProps {
  company: CompanyViewModel;
}

export const ClientAssignedProjects = (props: ClientAssignedProjectsProps) => {
  return (
    <Panel borderType='raised'>
      <CompanyAssignedProjects company={props.company} />
    </Panel>
  );
};
