import React from 'react';
import { Box, Button, FormHelperText, Grid, Paper, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ErrorResult } from '../../../domain/vo/ErrorResult';
import { AuthApiService } from '../../../services/auth.api.service';
import { useUserState } from '../../../state/UserState';
import { FormInputCheckbox, FormInputPassword, FormInputText, Snackbar } from '../../../libs/ui-components';
import FormLogo from './poi_logo_n.svg';
import styled from 'styled-components';

interface IFormInput {
  login: string;
  password: string;
  rememberMe: boolean;
}

const defaultValues = {
  login: '',
  password: '',
  rememberMe: false,
};

export const LoginPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [generalFailure, setGeneralFailure] = React.useState(false);
  const userState = useUserState();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    login: Yup.string().required(t('LoginPage.login.required')),
    password: Yup.string().required(t('LoginPage.password.required')),
    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  });

  const { handleSubmit, control, setValue } = useForm<IFormInput>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data: IFormInput) => {
    setLoading(true);
    setLoginFailed(false);
    setGeneralFailure(false);
    AuthApiService.login({
      login: data.login,
      password: data.password,
      rememberMe: data.rememberMe,
    })
      .then((result) => {
        setLoading(false);
        if ('token' in result) {
          userState.setUser(result.token, result.refreshToken);
          navigate('/');
        } else {
          setLoginFailed(true);
          setTimeout(() => setLoginFailed(false), 1000 * 2);
        }
      })
      .catch((error: ErrorResult) => {
        setLoading(false);
        setGeneralFailure(true);
      });
  };

  const ResponsivePaper = styled(Paper)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1rem',
    maxWidth: '800px',
    width: '800px',
    height: '800px',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:700px)': {
      top: '0',
      left: '0',
      transform: 'none',
      width: '100vw',
      height: '100vh',
      borderRadius: '0',
    },
  });

  const ResponsiveGrid = styled(Grid)({
    '@media (max-width:700px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  });

  const ResponsiveLogo = styled('img')({
    width: '90%',
    marginBottom: '3rem',
    alignSelf: 'flex-start',
    '@media (max-width:600px)': {
      width: '50%',
      alignSelf: 'center',
    },
  });

  return (
    <ResponsiveGrid item container height="100vh" alignItems="center" justifyContent="center">
      <ResponsivePaper elevation={3}>
        <Grid>
          <ResponsiveLogo
            src={FormLogo}
            alt="Logo Interzero"
            style={{
              width: '90%',
              marginBottom: '3rem',
              alignSelf: 'flex-start',
            }}
          />
          <Typography component="h1" variant="h5" sx={{ marginBottom: '1rem' }}>
            {t('LoginPage.login-header')}
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: '330px' }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                handleSubmit(onSubmit)();
              }
            }}
          >
            <FormInputText name="login" control={control} label={t('login')} variant={'standard'} />
            <FormInputPassword name="password" control={control} label={t('LoginPage.password')} variant={'standard'} />
            <FormInputCheckbox name="rememberMe" control={control} setValue={setValue}
                               label={t('LoginPage.rememberMe')} />

            <FormHelperText error={true} hidden={!loginFailed}>
              {t('LoginPage.loginFailed')}
            </FormHelperText>
            <Grid container spacing={2} alignItems="center" style={{ display: 'flex' }}>
              <Grid item style={{ flex: 1 }}>
                <LoadingButton data-test-id={'login-btn'} onClick={handleSubmit(onSubmit)} variant={'contained'}
                               sx={{ width: '100%', height: '100%' }} loading={loading}>
                  {t('LoginPage.login-btn')}
                </LoadingButton>
              </Grid>
              <Grid item style={{ flex: 1.1 }}>
                <NavLink to="/restore-password">
                  <Button data-test-id={'restore-password-btn'} variant="text"
                          style={{ width: '100%', height: '100%' }}>
                    {t('LoginPage.restorePassword')}
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Snackbar open={generalFailure} message={t('Common.error')} severity="error"
                  onClose={() => setGeneralFailure(false)} />
      </ResponsivePaper>
    </ResponsiveGrid>
  );
};
