export const valueToLongNumeric = (value: string | number | undefined | null, precision: number | undefined): number | undefined => {
    if (value === undefined) return undefined;
    if (value === null) {
        value = 0;
    }
    const stringValue = `${value}`.replaceAll('.', ',');
    const parts = stringValue.split(',');
    let longNumeric = parseInt(parts[0], 10);
    if (precision !== undefined && precision !== 0) {
        for (let i = 0; i < precision; i += 1) {
            longNumeric *= 10;
        }

        if (parts.length === 2) {
            if (parts[1].length < precision) {
                parts[1] = parts[1].padEnd(precision, '0');
            } else if (parts[1].length > precision) {
                let amount = parseInt(parts[1].substring(0, precision + 1), 10);
                amount += 5;
                parts[1] = `${amount}`.substring(0, precision);
            }
            const fraction = parseInt(parts[1], 10);
            longNumeric += fraction;
        }
    }
    return longNumeric;
};

export const longNumericToString = (value: string | number | undefined | null, precision: number | undefined, delimiter = '.'): string => {
    if (value === undefined) return '';
    if (value === null) return '';
    if (value === '') return '';
    let stringValue = `${value}`;

    if (precision === undefined || precision === 0) {
        return stringValue;
    }

    if (stringValue.length <= precision) {
        const expectedDigits = precision + 1; // 0.000 for 3 precision
        stringValue = stringValue.padStart(expectedDigits, '0');
        const part1 = stringValue.substring(0, 1);
        const part2 = stringValue.substring(1);
        return `${part1}${delimiter}${part2}`;
    }

    const delta = stringValue.length - precision;
    const part1 = stringValue.substring(0, delta);
    const part2 = stringValue.substring(delta);
    return `${part1}${delimiter}${part2}`;
};
