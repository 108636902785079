import { BranchAddressInfo } from '../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-data/branch-address-info';
import React from 'react';
import { Tabs } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { ProjectBranchViewModel } from '@lib/api-interface';
import { ProjectBranchDataUsers } from './project-branch-data-users';

interface ProjectBranchDataProps {
  projectBranchView?: ProjectBranchViewModel;
}

export const ProjectBranchData = (props: ProjectBranchDataProps) => {
  const { t } = useTranslation();
  const projectBranchView = props.projectBranchView!;

  return (
    <>
      <BranchAddressInfo correspondenceAddress={projectBranchView.correspondenceAddress} registrationAddress={projectBranchView.registrationAddress} />
      <Tabs
        marginTop='1rem'
        panels={[
          {
            label: t('userAccounts'),
            component: <ProjectBranchDataUsers branchId={projectBranchView.branchId!} projectId={projectBranchView.projectId!} />,
          },
        ]}
        borderType='raised'
      />
    </>
  );
};
