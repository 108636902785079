import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, GetContentButton, Panel } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CompanyViewModel } from '@lib/api-interface';

export interface DecisionsOnCollectingProps {
  company: CompanyViewModel;
}

export function DecisionsOnCollecting(props: DecisionsOnCollectingProps) {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      modelDef={{ modelName: 'companyFileViews' }}
      fetchFilters={{ companyId: props.company.companyId, type: 'CONTRACTOR_DECISION_ON_COLLECTING' }}
      columns={[
        {
          key: 'fileName',
          label: t('decisionsOnCollecting'),
          type: 'text',
        },
        {
          key: 'comment',
          label: t('comment'),
          type: 'text',
        },
        {
          key: 'creationDate',
          label: t('uploadDate'),
          type: 'date',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
        },
      ]}
    />
  );
}
