import {IsNotEmpty} from 'class-validator';
import {Expose} from 'class-transformer';
import {BaseModel} from '../../../common-sdk';

export class OrganizationBdoApiModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  clientId: string | undefined;

  @IsNotEmpty()
  @Expose()
  clientSecret: string | undefined;
}
