import {IsNotEmpty} from 'class-validator';
import {Expose} from 'class-transformer';
import {BaseModel} from '../../../common-sdk';

export class WasteModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  code: string | undefined;

  @IsNotEmpty()
  @Expose()
  description: string | undefined;

  @Expose()
  bdoWasteId: number | undefined;

  @IsNotEmpty()
  @Expose()
  hazardous: boolean | undefined = false;
}
