export interface PageInfo {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
  hasNextPage: boolean;
}

export class DataQueryPagedDto<ModelType> {
  response: ModelType | undefined;
  page: PageInfo | undefined;

  constructor(response: ModelType | undefined, page: PageInfo | undefined) {
    this.response = response;
    this.page = page;
  }
}
