import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LeftMenuLayout } from '../../components/layouts/left-menu-layout/left-menu-layout';
import { OrganizationUsers } from './organization-users/organization-users';
import { Aggregates } from './aggregates/aggregates';

export const TechnicalAdminRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LeftMenuLayout />}>
        <Route index element={<Navigate to='/organization-users' />} />
        <Route path='organization-users' element={<OrganizationUsers />} />
        <Route path='aggregates' element={<Aggregates />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
