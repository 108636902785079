import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLabel } from '@lib/ui-components';
import { TitlePositioner } from '@shared/general';
import { CompanyContactPersonsTable } from '../../../../company-contact-persons-table';
import { ContactPersonTypeEnum } from '../../../../../../../../../libs/api-interface/models/company/contact-person-type.enum';
import { useCompanyBranchContext } from '../../../../../../../../../contexts/company-branch.context';

export const CompanyBranchContactPersons = () => {
  const { t } = useTranslation();
  const companyBranch = useCompanyBranchContext();

  return (
    <>
      <TitlePositioner>
        <TextLabel label={t('contactPersons')} />
      </TitlePositioner>
      <CompanyContactPersonsTable companyId={companyBranch.companyId!} branchId={companyBranch.branchId} type={ContactPersonTypeEnum.CONTACT} />
    </>
  );
};
