import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyViewModel, ProjectView } from '@lib/api-interface';
import { DataTable, EditModelButton, Panel } from '@lib/ui-components';

export const ClientInfrastructure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <Panel borderType='flat'>
      <DataTable
        refreshKey={1}
        modelDef={{ modelName: ProjectView.name }}
        columns={[
          {
            key: '',
            label: t('category'),
            type: 'text',
          },
          {
            key: '',
            label: t('resourceName'),
            type: 'text',
          },
          {
            key: '',
            label: t('estimatedMassMg'),
            type: 'text',
          },
          {
            key: '',
            label: t('owner'),
            type: 'text',
          },
          {
            key: '',
            label: t('projectNum'),
            type: 'numeric',
          },
          {
            key: '',
            label: t('projectName'),
            type: 'text',
          },
          {
            key: '',
            label: t('number'),
            type: 'numeric',
            linkPath: '/', // TODO
          },
          {
            key: '',
            label: t('comment'),
            type: 'text',
          },
          {
            key: 'creationDate',
            label: t('creationDate'),
            type: 'date',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                modelName={CompanyViewModel.name}
                dialogTitle={t('edit')}
                fields={[
                  {
                    rowItems: [
                      {
                        field: '',
                        label: t('category'),
                        type: 'select',
                        selectOptions: [],
                      },
                      { field: '', label: t('resourceName'), type: 'text' },
                      { field: '', label: t('estimatedMassMg'), type: 'text' },
                    ],
                  },
                  {
                    rowItems: [
                      {
                        field: '',
                        label: t('owner'),
                        type: 'select',
                        selectOptions: [],
                      },
                      {
                        field: '',
                        label: t('projectNum'),
                        type: 'select',
                        selectOptions: [],
                      },
                      { field: '', label: t('projectName'), type: 'text' },
                    ],
                  },
                  { field: '', label: t('comment'), type: 'text' },
                ]}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                yellowIcon
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
