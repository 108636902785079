import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataTable, DataTableColumn, GetContentButton, Panel, TextLabel } from '@lib/ui-components';
import { CompanyRole, CompanyViewModel } from '@lib/api-interface';
import { DateTimeService } from '../../../services/date-time.service';

export interface CompanyAgreementsProps {
  company: CompanyViewModel;
  addNewDocumentButtonHidden?: boolean;
}

export default function Agreements(props: CompanyAgreementsProps) {
  const { t } = useTranslation();

  const subcontractorDataTableColumns: DataTableColumn[] = [
    {
      key: 'fileName',
      label: t('file'),
      type: 'text',
    },
    {
      key: 'comment',
      label: t('comment'),
      type: 'text',
    },
    {
      key: 'uploadDate',
      label: t('added'),
      type: 'date',
    },
    {
      key: 'uploadBy',
      label: t('introducedBy'),
      type: 'text',
    },
  ];

  const dataTable = React.useMemo(() => {
    return (
      <DataTable
        refreshKey={1}
        fetchFilters={{ companyId: props.company.companyId, type: 'AGREEMENT', role: CompanyRole.SUBCONTRACTOR }}
        fetchPostUrl={'/api/resource/companyFileViews/by-params'}
        columns={subcontractorDataTableColumns}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
        ]}
      />
    );
  }, [props.company.companyId, t]);

  return (
    <Panel header={t('agreements')} borderType='flat'>
      {dataTable}
      {props.company.lastModifiedSubcontractorAgreementsBy != null && (
        <TextLabel
          value={`${t('lastModifiedFiles')}: ${props.company.lastModifiedSubcontractorAgreementsBy}  [${DateTimeService.isoStringDateToDateTime(
            props.company.lastModifiedSubcontractorAgreementsDate,
          )}]`}
        />
      )}
    </Panel>
  );
}
