import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterItem, Tabs } from '@lib/ui-components';
import { ServicesSettlement } from './services-settlement/services-settlement';
import { Invoices } from './invoices';
import { ServicePriceBearer } from '@lib/api-interface';

export const MonthEndClosing = () => {
  const { t } = useTranslation();
  // using Ref instead of useState because update of Ref is not triggering repaint of children, when tabs are switched, previous filter values are passed in ref object
  const instanceFiltersRef: React.MutableRefObject<TableFilterItem[]> = React.useRef(new Array<TableFilterItem>());

  function updateFiltersIfChanged(filters: Array<TableFilterItem>) {
    instanceFiltersRef.current = filters;
  }

  return (
    <>
      <Tabs
        panels={[
          { label: t('servicesSettlement'), component: <ServicesSettlement /> },
          {
            label: t('incomeInvoices'),
            component: (
              <Invoices
                key={'Invoices' + new Date().getTime()}
                instanceFiltersRef={instanceFiltersRef}
                bearer={ServicePriceBearer.ORGANIZATION_REVENUE}
                onDefaultFiltersUpdated={(filters) => updateFiltersIfChanged(filters)}
              />
            ),
          },
          {
            label: t('costInvoices'),
            component: (
              <Invoices
                key={'Invoices' + new Date().getTime()}
                instanceFiltersRef={instanceFiltersRef}
                bearer={ServicePriceBearer.ORGANIZATION_COST}
                onDefaultFiltersUpdated={(filters) => updateFiltersIfChanged(filters)}
              />
            ),
          },
        ]}
      />
    </>
  );
};
