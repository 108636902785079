import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@lib/ui-components';
import { CompanyViewModel, ProjectType } from '@lib/api-interface';

export interface CompanyAssignedProjectsProps {
  company: CompanyViewModel;
}

export const CompanyAssignedProjects = (props: CompanyAssignedProjectsProps) => {
  const { t } = useTranslation();

  return (
    <DataTable
      refreshKey={1}
      fetchUrl={'/api/project-companies'}
      fetchFilters={{ companyId: props.company?.companyId, deleted: false }}
      columns={[
        {
          key: 'project.projectNumber',
          label: t('projectNum'),
          type: 'text-chip',
        },
        {
          key: 'project.name',
          label: t('projectName'),
          type: 'text',
          linkPath: '/projects/',
          linkRowId: 'project.projectId',
        },
        {
          key: 'project.sellerPerson',
          label: t('salesperson'),
          type: 'text',
        },
        {
          key: 'project.projectLeadPerson',
          label: t('projectLeadPerson'),
          type: 'text',
        },
        {
          key: 'project.invoicePerson',
          label: t('invoicingPerson'),
          type: 'text',
        },
        {
          key: 'project.managesBdoPerson',
          label: t('managingBdo'),
          type: 'text',
        },
        {
          key: 'project.type',
          label: t('projectType'),
          type: 'enum',
          enumValues: [
            { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
            { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
            { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
          ],
        },
        {
          key: 'project.creationDate',
          label: t('creationDate'),
          type: 'date',
        },
      ]}
    />
  );
};
