import * as React from 'react';
import {useTheme} from '@mui/material';
import styles from './styles.module.css';

export interface HeaderProps {
    label: string;
}

export const Header = (props: HeaderProps) => {
    const theme = useTheme();

    return (
        <div
            className={styles['header']}
            style={{
                color: theme.palette.primary.main
            }}
        >
            {props.label}
        </div>
    );
};
