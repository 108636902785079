export const numericToStringFormatter = (x: any, decimalPlaces: number): string => {
  const val = x
    .toString()
    .replace(/\./g, ',') // replace . with ,
    .replace(/[,](?=.*[,])/g, '') // remove all , but last
    .replace(/(?!^)-/g, ''); // remove all - but first

  if (decimalPlaces !== undefined) {
    const valueParts = val.split(',');

    if (valueParts[0] === '') {
      valueParts[0] = '0';
    } else if (valueParts[0] === '-') {
      valueParts[0] = '-0';
    } else {
      valueParts[0] = `${parseInt(valueParts[0], 10)}`;
    }

    if (decimalPlaces === 0) {
      return valueParts[0];
    }

    if (valueParts.length === 1) {
      return valueParts[0] + '.' + '0'.repeat(decimalPlaces);
    }

    const lengthOfDecimalPoints = valueParts[1].length;
    if (lengthOfDecimalPoints < decimalPlaces) {
      return valueParts[0] + '.' + valueParts[1] + '0'.repeat(decimalPlaces - lengthOfDecimalPoints);
    } else if (lengthOfDecimalPoints === decimalPlaces) {
      return valueParts[0] + '.' + valueParts[1];
    } else {
      return valueParts[0] + '.' + valueParts[1].substr(0, decimalPlaces);
    }
  }

  return val;
};
