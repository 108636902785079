import { ServiceType, ServiceViewDetails } from '@lib/api-interface';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

export const useServiceDetailsViews = (url: string, key: string, id: string, serviceType: ServiceType, active: boolean, filters: any) => {
  const filterValues = Object.values(filters);
  const queryKey = ['ServiceViewDetails', id, serviceType, active, ...filterValues];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    () => {
      return axios.post(url, { [key]: id, serviceType, active, ...filters }).then((response: AxiosResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.data);
        }
      });
    },
    {
      staleTime: 2000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};