import { hookstate, ImmutableArray, useHookstate } from '@hookstate/core';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthApiService } from '../services/auth.api.service';
import { LoginResultVO } from '../domain/vo/LoginResultVO';

const initialLocation = window.location.pathname;

interface UserInteface {
  token: string;
  refreshToken: string;
  uuid: string;
  userId: string;
  email: string;
  roles: string[];
  authorities: string[];
}

const userState = hookstate<UserInteface>({
  token: '',
  refreshToken: '',

  uuid: '',
  userId: '',
  email: '',
  roles: [],
  authorities: [],
});

export function getInitialLocation() {
  return initialLocation;
}

async function refreshAccessToken(): Promise<LoginResultVO> {
  delete axios.defaults.headers.common['Authorization']; // remove Authorization for refresh-token-generation
  return await AuthApiService.tryRefreshToken();
}

let isRefreshing = false;
let refreshQueue: ((token: string) => void)[] = [];

// Function to add new subscribers waiting for the token refresh
function subscribeTokenRefresh(cb: (token: string) => void) {
  refreshQueue.push(cb);
}

// Function to handle the token refresh
async function onTokenRefreshed(token: string) {
  isRefreshing = false;
  refreshQueue.forEach((cb) => cb(token));
  refreshQueue = [];
}

export interface UserState {
  readonly isPresent: boolean;
  readonly canAskForRefreshToken: boolean;
  readonly token: string;
  readonly refreshToken: string;
  readonly uuid: string;
  readonly userId: string;
  readonly email: string;
  readonly roles: ImmutableArray<string>;
  readonly authorities: ImmutableArray<string>;
  setUser: (token: string, refreshToken: string) => void;
  logout: () => void;
}

export function useUserState(): UserState {
  const state = useHookstate(userState);

  // Axios interceptor to handle JWT refresh tokens
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const originalRequest = error.config;
      const apiUrl = process.env['REACT_APP_API_URL'];
      const isUnauthorized = error.response?.status === 401;

      if (isUnauthorized && originalRequest.url !== apiUrl + '/api/auth/refresh-token') {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshTokenResponse = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${refreshTokenResponse.token}`;
            const decoded: any = jwt_decode(refreshTokenResponse.token);
            state.uuid.set(decoded.uuid);
            state.userId.set(decoded.id);
            state.email.set(decoded.email);
            state.roles.set(decoded.roles);
            state.authorities.set(decoded.authorities);
            state.refreshToken.set(refreshTokenResponse.refreshToken);
            state.token.set(refreshTokenResponse.token);
            await onTokenRefreshed(refreshTokenResponse.token);
            originalRequest.headers['Authorization'] = `Bearer ${refreshTokenResponse.token}`;
            return await axios(originalRequest);
          } catch (refreshError: any) {
            isRefreshing = false;
            // Handle the error (note this can be error from original call, not from refresh
            if (refreshError?.isAxiosError) {
              const axiosError = refreshError as AxiosError;
              if (axiosError.response?.status === 401) {
                // log the user out
                window.location.reload();
              }
            }
            throw refreshError;
          }
        }

        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      }
      return Promise.reject(error);
    },
  );

  return {
    get isPresent() {
      return state.token.get().length !== 0;
    },
    get canAskForRefreshToken() {
      return state.refreshToken.get() !== '-';
    },
    get token() {
      return state.token.get();
    },
    get refreshToken() {
      return state.refreshToken.get();
    },
    get uuid() {
      return state.uuid.get();
    },
    get userId() {
      return state.userId.get();
    },
    get email() {
      return state.email.get();
    },
    get roles() {
      return state.roles.get();
    },
    get authorities() {
      return state.authorities.get();
    },
    setUser(token: string, refreshToken: string) {
      if (token.length === 0) {
        state.uuid.set('');
        state.userId.set('');
        state.email.set('');
        state.roles.set([]);
        state.authorities.set([]);

        state.refreshToken.set(refreshToken);
        state.token.set('');

        axios.defaults.headers.common = {};
      } else {
        const decoded: any = jwt_decode(token);
        state.uuid.set(decoded.uuid);
        state.userId.set(decoded.id);
        state.email.set(decoded.email);
        state.roles.set(decoded.roles);
        state.authorities.set(decoded.authorities);

        state.refreshToken.set(refreshToken);
        state.token.set(token);

        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      }
    },
    logout() {
      state.uuid.set('');
      state.userId.set('');
      state.email.set('');
      state.roles.set([]);
      state.authorities.set([]);

      state.refreshToken.set('');
      state.token.set('');

      axios.defaults.headers.common = {};
      localStorage.removeItem('jwtFingerprint');
      localStorage.removeItem('RefreshToken');
      sessionStorage.removeItem('jwtFingerprint');
      sessionStorage.removeItem('RefreshToken');
    },
  };
}
