import { ErrorResult } from '../domain/vo/ErrorResult';
import { LoginCommand } from '../domain/commands/LoginCommand';
import { LoginResultVO } from '../domain/vo/LoginResultVO';
import { ApiClientService } from './api-client.service';
import { CreateUserCommand } from '../domain/commands/CreateUserCommand';
import { UserVO } from '../domain/vo/UserVO';
import { ActivateUserCommand } from '../domain/commands/activate-user.command';
import { DataResultsDto, HateoasRestApiClientService } from '@lib/common-sdk';
import { ResetPasswordCommand, RestorePasswordCommand } from '@lib/api-interface';
import { UpdateUserCommand } from '../domain/commands/UpdateUserCommand';
import axios, { AxiosResponse } from 'axios';

export class AuthApiService {
  public static login(command: LoginCommand): Promise<LoginResultVO | ErrorResult> {
    return ApiClientService.post<LoginCommand, LoginResultVO>('/api/auth/login', command, process.env['REACT_APP_API_URL']);
  }

  public static tryRefreshToken(): Promise<LoginResultVO> {
    const apiUrl = process.env['REACT_APP_API_URL'];
    return axios.post(`${apiUrl}/api/auth/refresh-token`, {}).then((response: AxiosResponse) => {
      return response.data;
    });
  }

  public static logout(): Promise<any | ErrorResult> {
    return ApiClientService.post<any, any>('/api/auth/logout', {}, process.env['REACT_APP_API_URL']);
  }

  static async createUser(command: CreateUserCommand): Promise<ErrorResult | UserVO> {
    return ApiClientService.post<CreateUserCommand, UserVO>('/api/account/create', command, process.env['REACT_APP_API_URL']);
  }

  static async updateUser(command: UpdateUserCommand): Promise<ErrorResult | any> {
    return ApiClientService.post<UpdateUserCommand, UserVO>('/api/account/update', command, process.env['REACT_APP_API_URL']);
  }

  static async deleteUser(userUuid: string): Promise<DataResultsDto<UserVO>> {
    return HateoasRestApiClientService.delete('accounts', userUuid);
  }

  static async activateUser(command: ActivateUserCommand): Promise<null | ErrorResult> {
    return ApiClientService.post('/api/account/activate', command, process.env['REACT_APP_API_URL']);
  }

  static async restorePassword(command: RestorePasswordCommand): Promise<null | ErrorResult> {
    return ApiClientService.post('/api/account/restore-password', command);
  }

  static async resetPassword(command: ResetPasswordCommand): Promise<null | ErrorResult> {
    return ApiClientService.post('/api/account/reset-password', command);
  }
}
