import * as React from 'react';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStateIfMounted } from 'use-state-if-mounted';

export interface SmartDataSearchFieldProps {
  onFiltersChange: (filter: string) => void;
}

export const DataSearchField: (props: SmartDataSearchFieldProps) => JSX.Element = (props: SmartDataSearchFieldProps) => {
  const [filterText, setFilterText] = useStateIfMounted('');
  const { t } = useTranslation();

  const debouncedFilterText = useDebouncedCallback((value) => {
    props.onFiltersChange(value);
  }, 200);

  return (
    <FormControl sx={{ m: 1, width: '25ch' }} variant='standard'>
      <InputLabel>{t('SmartDataTable.search')}</InputLabel>
      <Input
        inputProps={{ 'data-test-id': 'data-table-search' }}
        type='text'
        value={filterText}
        onChange={(event) => {
          setFilterText(event.target.value);
          debouncedFilterText(event.target.value);
        }}
        endAdornment={
          <InputAdornment position='end'>
            {filterText.length > 0 ? (
              <IconButton
                data-test-id='data-table-search-clear-btn'
                onClick={() => {
                  setFilterText('');
                  props.onFiltersChange('');
                }}
                edge='end'
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              false
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
