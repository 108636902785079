import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddModelButton,
  AggregateCommandButton,
  Button,
  DataTable,
  DeleteModelButton,
  DeleteOnAggregateButton,
  EditModelButton,
  GetContentButton,
  Panel,
  RenderIf,
  Toolbar,
} from '@lib/ui-components';
import { useCompanyBranchView } from '@data-source-hooks';
import { QueryResultRenderer } from '../../../../../../../libs/ui-components/components/query-result-renderer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RenderIfAsync } from '../../../../../../../libs/ui-components/components/render-if-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface CompanyBranchRdrProps {
  companyBranchId: string;
}

export function CompanyBranchRdr(props: CompanyBranchRdrProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  const [branchQuery] = useCompanyBranchView(props.companyBranchId);
  const [selectedCommune, setSelectedCommune] = React.useState<any>(undefined);

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        return (
          <>
            <RenderIf true={selectedCommune === undefined}>
              <>
                <Panel borderType='flat'>
                  <Toolbar
                    right={[
                      <AggregateCommandButton
                        aggregateName='CompanyAggregate'
                        aggregateId={companyBranchView.companyId!}
                        commandName='addRdrCommune'
                        buttonLabel={t('addNewCommune')}
                        dialogTitle={t('addNewCommune')}
                        fields={[
                          {
                            label: t('commune'),
                            type: 'table-select-by-api',
                            field: 'communeId',
                            tableSelectByApiParams: {
                              apiPostEndpoint: '/api/company/rdr/assignable-communes',
                              apiPostParams: { branchId: companyBranchView.branchId },
                              dialogTitle: t('communes'),
                              displayFormat: '{name}',
                              columns: [
                                { key: 'name', label: t('commune'), type: 'text' },
                                { key: 'province', label: t('province'), type: 'text' },
                              ],
                            },
                          },
                        ]}
                        hiddenValues={{ branchId: companyBranchView.branchId }}
                        onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                      />,
                    ]}
                  />
                  <DataTable
                    refreshKey={tableReloadKey}
                    fetchUrl={'/api/company-branches/rdr-communes'}
                    fetchFilters={{ branchId: companyBranchView.branchId }}
                    onRowClicked={(row) => setSelectedCommune(row)}
                    columns={[
                      {
                        key: 'communeName',
                        label: t('commune'),
                        type: 'text',
                      },
                      {
                        key: 'communeProvince',
                        label: t('province'),
                        type: 'text',
                      },
                    ]}
                    rowOptions={[
                      {
                        renderer: (row) => (
                          <DeleteOnAggregateButton
                            aggregateName='CompanyAggregate'
                            aggregateId={companyBranchView.companyId!}
                            commandName='removeRdrCommune'
                            payload={{
                              branchId: companyBranchView.branchId,
                              communeId: row.communeId,
                            }}
                            onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
                          />
                        ),
                      },
                    ]}
                  />
                </Panel>
              </>
            </RenderIf>
            <RenderIfAsync
              true={selectedCommune !== undefined}
              render={() => {
                return (
                  <Panel borderType='flat' header={`RDR: ${selectedCommune.communeName}`}>
                    <Toolbar
                      left={[
                        <Button
                          startIcon={<ArrowBackIcon />}
                          color={'primary'}
                          variant={'outlined'}
                          dataTestId={'back-button'}
                          label={t('Common.back')}
                          onClick={() => setSelectedCommune(undefined)}
                        />,
                      ]}
                      right={[
                        <AddModelButton
                          multipart={true}
                          buttonLabel={t('addNewDocument')}
                          dialogTitle={t('addNewDocument')}
                          modelName={'companyFileViews'}
                          fields={[
                            { label: t('dragAndDropAFile'), type: 'file', field: 'content' },
                            { label: t('comment'), type: 'text', field: 'comment' },
                          ]}
                          hiddenValues={{
                            type: 'RDR',
                            companyId: companyBranchView.companyId,
                            companyBranchId: companyBranchView.branchId,
                          }}
                          onBeforeSave={(formData: any) => {
                            const rdrData: any = {};
                            rdrData.communeId = selectedCommune.communeId;
                            rdrData.communeName = selectedCommune.communeName;
                            formData.rdrDataString = JSON.stringify(rdrData);
                            return formData;
                          }}
                          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                        />,
                      ]}
                    />
                    <DataTable
                      refreshKey={tableReloadKey}
                      fetchPostUrl='/api/company/rdr-files/of-branch'
                      fetchFilters={{
                        companyBranchId: companyBranchView.branchId,
                        rdrCommuneId: selectedCommune.communeId,
                      }}
                      columns={[
                        {
                          key: 'fileName',
                          label: t('file'),
                          type: 'text',
                        },
                        {
                          key: 'companyFileRdrData.communeName',
                          label: t('commune'),
                          type: 'text',
                        },
                        {
                          key: 'comment',
                          label: t('comment'),
                          type: 'text',
                        },
                        {
                          key: 'creationDate',
                          label: t('added'),
                          type: 'date',
                        },
                        {
                          key: 'createdBy',
                          label: t('introducedBy'),
                          type: 'text',
                        },
                      ]}
                      rowOptions={[
                        {
                          renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
                        },
                        {
                          renderer: (row) => (
                            <EditModelButton
                              id={row.id}
                              entity={row}
                              buttonLabel={t('edit')}
                              dialogTitle={t('edit')}
                              modelName={'companyFileViews'}
                              fields={[{ label: t('comment'), type: 'text', field: 'comment' }]}
                              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                            />
                          ),
                        },
                        {
                          renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
                        },
                      ]}
                    />
                  </Panel>
                );
              }}
            />
          </>
        );
      }}
    />
  );
}
