import axios from 'axios';

export const handleDeactivateContactForm = async (id: number) => {
  await axios({
    method: 'patch',
    url: '/api/contact-forms/' + id + '/deactivate',
    responseType: 'json',
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Unexpected response status');
      }
    })
    .catch((error) => {
      console.error('Error while sending contact form', error);
    });
};