import styled from 'styled-components';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ServiceBar } from '../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralForm, Panel, RenderIf, Snackbar, TextLabel } from '@lib/ui-components';
import { TableAdaptiveArea } from '@shared/general';
import axios from 'axios';
import { MASS_PRECISION } from '@lib/api-interface';
import { format } from 'date-fns';
import * as Yup from 'yup';
import i18n from 'i18next';

const ResponsivePaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3rem 6rem 1rem 6rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  '@media (max-width:700px)': {
    top: '0',
    left: '0',
    transform: 'none',
    width: '100vw',
    height: '100vh',
    borderRadius: '0',
  },
});

interface SubcontractorApiFetchedModel {
  clientName?: string;
  branchName?: string;
  address?: string;
  mpd?: string;
  transporterName?: string;
  receiverName?: string;
}

interface MessageContent {
  message?: string;
  severity: 'error' | 'success';
}

export const SubcontractorApi = () => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const pathParams = useParams();
  const collectionRequestId = pathParams['collectionRequestId']?.substring(1);
  const [message, setMessage] = React.useState<MessageContent | null>(null);
  const [fetchedDto, setFetchedDto] = React.useState<SubcontractorApiFetchedModel | null>(null);

  React.useEffect(() => {
    const fetchPickupInfoDto = async () => {
      const url = `${process.env['REACT_APP_API_URL']}/api/public/collection-requests/${collectionRequestId}`;
      try {
        const response = await axios.get(url);
        setFetchedDto(response.data);
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        setFetchedDto(null);
      }
    };
    fetchPickupInfoDto();
  }, [collectionRequestId]);

  async function handleSave(data: any) {
    try {
      await axios.post(`${process.env['REACT_APP_API_URL']}/api/public/collection-requests/report`, {
        collectionRequestId: collectionRequestId,
        mass: data.wasteMass,
        // prettier-ignore
        pickupDateTime: format(new Date(data.pickupDate), 'yyyy-MM-dd\'T\'HH:mm:ss'),
        licensePlates: data.licensePlates,
      });
      setMessage({ message: t('kpoStatusChanged'), severity: 'success' });
    } catch (e: any) {
      console.error(e);
      setMessage({ message: errorKeys[e.response.data.code] || t('Common.error'), severity: 'error' });
    }
  }

  return (
    <ResponsivePaper elevation={3}>
      <TableAdaptiveArea>
        <Panel header={t('Model.CompanyBranch.sender')} borderType={'none'}>
          <ServiceBar
            headers={[{ label: t('name') }, { label: t('branch') }]}
            items={[
              { value: fetchedDto?.clientName },
              {
                value: fetchedDto?.branchName,
              },
            ]}
          />
          <ServiceBar headers={[{ label: t('mpd') }, { label: t('address') }]} items={[{ value: fetchedDto?.mpd }, { value: fetchedDto?.address }]} />
        </Panel>
        <Panel header={t('Model.CompanyBranch.transporting')} borderType={'none'}>
          <TextLabel value={fetchedDto?.transporterName} valueColor={'#000000de'} valueFontFamily={'Montserrat, "Helvetica Neue", Arial, sans-serif'} />
        </Panel>
        <Panel header={t('Model.CompanyBranch.capturing')} borderType={'none'}>
          <TextLabel value={fetchedDto?.receiverName} valueColor={'#000000de'} valueFontFamily={'Montserrat, "Helvetica Neue", Arial, sans-serif'} />
        </Panel>
        <Panel borderType={'none'}>
          <GeneralForm
            fields={[
              {
                rowItems: [
                  {
                    label: t('massMg'),
                    type: 'numeric',
                    field: 'wasteMass',
                    numericPrecision: MASS_PRECISION,
                  },
                  { label: t('pickupDate'), type: 'date-time', field: 'pickupDate' },
                ],
              },
              {
                rowItems: [{ label: t('licensePlates'), type: 'text', field: 'licensePlates' }],
              },
            ]}
            validationSchema={{
              wasteMass: Yup.number().required(t('fieldRequired')).typeError(t('numberRequired')).min(0, t('notNegativeNumberRequired')),
              pickupDate: Yup.string().nullable().required(t('fieldRequired')),
              licensePlates: Yup.string().required(t('fieldRequired')),
            }}
            submitButtonTooltip={''}
            onSaved={(data) => handleSave(data)}
            hideCancelButton={true}
            variant='standard'
          />
        </Panel>
      </TableAdaptiveArea>
      <RenderIf true={message !== null}>
        <Snackbar message={message?.message || t('Common.error')} severity={message?.severity} onClose={() => setMessage(null)} />
      </RenderIf>
    </ResponsivePaper>
  );
};
