import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { CompanyBranchDecisionsOnCollecting } from './company-branch-decisions-on-collecting';
import { CompanyBranchOtherDecisions } from './company-branch-other-decisions';
import { CompanyBranchConclusions } from './company-branch-conclusions';

export interface CompanyBranchDecisionsConclusionsProps {
  companyBranchId: string;
}

export function CompanyBranchDecisionsConclusions(props: CompanyBranchDecisionsConclusionsProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('decisionsOnCollecting'), t('otherDecisions'), t('conclusions')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      {activePage === 0 && <CompanyBranchDecisionsOnCollecting companyBranchId={props.companyBranchId} />}
      {activePage === 1 && <CompanyBranchOtherDecisions companyBranchId={props.companyBranchId} />}
      {activePage === 2 && <CompanyBranchConclusions companyBranchId={props.companyBranchId} />}
    </Panel>
  );
}
