import { TFunction } from 'react-i18next';
import { Stack } from '@mui/material';
import React from 'react';
import { AggregateCommandButton, RenderIf } from '@lib/ui-components';
import { QUANTITIES_PRECISION, SettlementDetailsView, SettlementViewStatus } from '@lib/api-interface';

export interface ServiceComplaintsSettlementTableFooterProps {
  settlement: SettlementDetailsView;
  t: TFunction<'common', undefined>;
  reloadSettlements: () => void;
}

export const ServiceComplaintsSettlementTableFooter = ({ settlement, t, reloadSettlements }: ServiceComplaintsSettlementTableFooterProps) => {
  return (
    <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6}>
      <RenderIf false={settlement.settlementStatus === SettlementViewStatus.CANCELLED}>
        <AggregateCommandButton
          aggregateName='SettlementAggregate'
          aggregateId={settlement.settlementId!}
          commandName='cancel'
          buttonLabel={t('cancelSettlement')}
          forceConfirmation={true}
          onSaved={async () => reloadSettlements()}
        />
        <AggregateCommandButton
          aggregateName='SettlementAggregate'
          aggregateId={settlement.settlementId!}
          commandName='editServiceComplaintSettlement'
          buttonLabel={t('edit')}
          fields={[
            {
              label: t('receivedMass'),
              field: 'mass',
              type: 'numeric',
              defaultValue: settlement.complaintData?.mass,
            },
            {
              label: t('quantityReceived'),
              field: 'quantities',
              type: 'numeric',
              numericPrecision: QUANTITIES_PRECISION,
              defaultValue: settlement.complaintData?.quantities,
            },
          ]}
          hiddenValues={{
            complaintSettlementId: settlement.complaintData?.complaintSettlementId,
          }}
          onSaved={async () => reloadSettlements()}
        />
      </RenderIf>
    </Stack>
  );
};
