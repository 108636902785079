import { TFunction } from 'react-i18next';
import { Box, Stack, Tooltip } from '@mui/material';
import { AggregateCommandButton, Button, Edit, RenderIf, TextLabel, UsecaseButton } from '@lib/ui-components';
import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ServicePricesDialog } from './service-prices-dialog';
import * as Yup from 'yup';
import { DateTimeService } from '../../../../../../../../../services/date-time.service';
import { ServiceViewDetails } from '@lib/api-interface';
import { ServiceViewDetailsProjection } from '../../../../../../../../../libs/api-interface/models/service/service-view-details-projection';
import { CopyAllOutlined, InfoRounded } from '@mui/icons-material';
import { ServiceToBranchesCopyDialog } from './service-to-branches-copy-dialog';
import axios from 'axios';
import { GreenRedDotIndicator } from '../../../../../../../../../libs/ui-components/components/indicator/green-red-dot-indicator';
import { useProjectBranchSettingsContext } from '@context';

export interface ServiceTableFooterProps {
  service: ServiceViewDetails;
  t: TFunction<'common', undefined>;
  editButton?: JSX.Element;
  copyButton?: JSX.Element;
  reloadServices: () => void;
}

export const ServiceTableFooter = ({ service, t, reloadServices, editButton, copyButton }: ServiceTableFooterProps) => {
  const projectSettings = useProjectBranchSettingsContext();
  const [openPricesDialog, setPricesDialog] = useStateIfMounted(false);
  const [openCopyToBranchDialog, setOpenToBranchDialog] = useStateIfMounted(false);
  const [hasPendingCopyCommand, setHasPendingCopyCommand] = React.useState(null);
  const [pendingIndicatorRefreshKey, setPendingIndicatorRefreshKey] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/service/${service.serviceId}/hasPendingCopingCommand`);
        setHasPendingCopyCommand(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pendingIndicatorRefreshKey]);

  function isAcceptedButNotActive(service: ServiceViewDetailsProjection) {
    return !service.active && !service.started && service.acceptanceState === 'ACCEPTED';
  }

  return (
    <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6} style={{ pointerEvents: 'initial', opacity: '1' }} alignItems='center'>
      {service.acceptanceState === 'ACCEPTED' && !service.active && (
        <UsecaseButton
          useCaseName='UpdateServiceDeactivationDateUseCase'
          groupName='service'
          hiddenValues={{ serviceId: service.serviceId! }}
          buttonLabel={t('reactivate')}
          onSaved={async () => {
            reloadServices();
          }}
        />
      )}
      {service.acceptanceState === 'ACCEPTED' && service.active && (
        <UsecaseButton
          groupName='service'
          useCaseName='UpdateServiceDeactivationDateUseCase'
          buttonLabel={t('deactivate')}
          hiddenValues={{ serviceId: service.serviceId }}
          fields={[
            {
              field: 'date',
              label: t('deactivationDate'),
              type: 'date',
              defaultValue: service.deactivationDate,
            },
          ]}
          isDisabled={hasPendingCopyCommand ?? false}
          onSaved={async () => {
            reloadServices();
          }}
          validationSchema={{
            date: Yup.string()
              .required(t('fieldRequired'))
              .test('is-deactivation-date-after-activation-date', t('deactivationDateAfterActivationDateRequired'), function (value) {
                return value! >= service.activationDate!;
              }),
          }}
        />
      )}
      {service.acceptanceState === 'ACCEPTED' && !service.active && (
        <>
          <TextLabel value={`${t('acceptanceDate')}: ${service.acceptanceDate}`} />
          <UsecaseButton
            useCaseName={'RejectServiceUseCase'}
            groupName='service'
            hiddenValues={{ serviceId: service.serviceId! }}
            variant={'contained'}
            color={'info'}
            buttonLabel={t('removeAcceptance')}
            onSaved={async () => reloadServices()}
            forceConfirmation={true}
          />
        </>
      )}
      {!hasPendingCopyCommand && copyButton}
      <RenderIf true={(service.acceptanceState === 'ACCEPTED' && service.active) || isAcceptedButNotActive(service)}>
        <>
          <Button label={t('changePrices')} onClick={async () => setPricesDialog(true)} startIcon={<Edit yellowIcon={true} />} isDisabled={hasPendingCopyCommand ?? false} />
          <ServicePricesDialog
            t={t}
            service={service}
            openDialog={openPricesDialog}
            handleCloseDialog={() => setPricesDialog(false)}
            onSaved={async () => {
              reloadServices();
            }}
          />
        </>
      </RenderIf>
      {service.acceptanceState === 'SEND_TO_ACCEPTANCE' && projectSettings.newServicesDontRequireAdminAcceptance && (
        <UsecaseButton
          useCaseName='AcceptServiceUseCase'
          groupName='service'
          hiddenValues={{ serviceId: service.serviceId! }}
          variant={'contained'}
          color={'info'}
          buttonLabel={t('accept')}
          onSaved={async () => reloadServices()}
          forceConfirmation={true}
        />
      )}
      {(service.acceptanceState === 'REJECTED' || service.acceptanceState === 'INITIALIZED') && (
        <UsecaseButton
          useCaseName='SendToAcceptanceServiceUseCase'
          groupName='service'
          hiddenValues={{ serviceId: service.serviceId! }}
          variant={'contained'}
          color={'info'}
          buttonLabel={t('sendToAcceptance')}
          onSaved={async () => reloadServices()}
          forceConfirmation={true}
        />
      )}
      <RenderIf true={service.active || isAcceptedButNotActive(service)}>
        {!service.lockedForEdit && !hasPendingCopyCommand && editButton}
        <AggregateCommandButton
          aggregateName='ServiceAggregate'
          aggregateId={service.serviceId!}
          commandName='updateServiceCustomIdCommand'
          buttonDisabled={hasPendingCopyCommand ?? false}
          fields={[
            {
              field: 'serviceCustomId',
              label: t('serviceId'),
              type: 'text',
              defaultValue: service.customServiceId,
            },
          ]}
          variant={'text'}
          buttonLabel={t('serviceId')}
          onSaved={async () => reloadServices()}
        />
      </RenderIf>
      <RenderIf true={(service.acceptanceState === 'ACCEPTED' && service.active) || isAcceptedButNotActive(service)}>
        <Box display='flex' alignItems='center'>
          <Button
            label={t('copyServiceToAnotherBranches')}
            onClick={async () => setOpenToBranchDialog(true)}
            startIcon={<CopyAllOutlined />}
            isDisabled={hasPendingCopyCommand ?? false}
          />
          <ServiceToBranchesCopyDialog
            serviceView={service}
            openDialog={openCopyToBranchDialog}
            handleCloseDialog={() => setOpenToBranchDialog(false)}
            onSaved={async () => {
              setOpenToBranchDialog(false);
              setPendingIndicatorRefreshKey((prev) => prev + 1);
            }}
          />
          <GreenRedDotIndicator showRedLight={hasPendingCopyCommand !== null && hasPendingCopyCommand} />
          <Tooltip title={t('copyServiceToAnotherBranchesDescription')}>
            <InfoRounded style={{ marginLeft: '0.5rem', cursor: 'help' }} fontSize='small' color='action' />
          </Tooltip>
        </Box>
      </RenderIf>
      {service.lastModifiedBy != null && (
        <TextLabel value={`${t('lastModified')}: ${service.lastModifiedBy} / ${DateTimeService.isoStringDateToDateTime(service.lastModifiedDate)}`} />
      )}
    </Stack>
  );
};
