import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddModelButton, DataTable, DeleteModelButton, EditModelButton, GeneralFormSelectOption, Header, Toolbar } from '@lib/ui-components';

export const CommunesPage = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  const communesSelectOptions: GeneralFormSelectOption[] = [
    { label: 'dolnośląskie', value: 'dolnośląskie' },
    { label: 'kujawsko-pomorskie', value: 'kujawsko-pomorskie' },
    { label: 'lubelskie', value: 'lubelskie' },
    { label: 'lubuskie', value: 'lubuskie' },
    { label: 'łódzkie', value: 'łódzkie' },
    { label: 'mazowieckie', value: 'mazowieckie' },
    { label: 'małopolskie', value: 'małopolskie' },
    { label: 'opolskie', value: 'opolskie' },
    { label: 'podkarpackie', value: 'podkarpackie' },
    { label: 'podlaskie', value: 'podlaskie' },
    { label: 'pomorskie', value: 'pomorskie' },
    { label: 'śląskie', value: 'śląskie' },
    { label: 'świętokrzyskie', value: 'świętokrzyskie' },
    { label: 'warmińsko-mazurskie', value: 'warmińsko-mazurskie' },
    { label: 'wielkopolskie', value: 'wielkopolskie' },
    { label: 'zachodniopomorskie', value: 'zachodniopomorskie' },
  ];

  const rightButtons: JSX.Element[] = [
    <AddModelButton
      modelName='communes'
      buttonLabel={t('add')}
      dialogTitle={t('commune')}
      fields={[
        { field: 'name', label: t('commune'), type: 'text' },
        { field: 'province', label: t('province'), type: 'select', selectOptions: communesSelectOptions },
      ]}
      validationSchema={{
        name: Yup.string().required(t('requiredField')),
        province: Yup.string().required(t('requiredField')),
      }}
      onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
      postErrorMappings={{
        '400': t('Error.CommuneExists'),
      }}
    />,
  ];

  return (
    <>
      <Header label={t('communes')} />
      <Toolbar right={rightButtons} />
      <DataTable
        rowLink={{ linkPath: '' }}
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'communes' }}
        columns={[
          {
            key: 'name',
            label: t('commune'),
            type: 'text',
          },
          {
            key: 'province',
            label: t('province'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <EditModelButton
                id={row['id']}
                entity={row}
                modelName='communes'
                buttonLabel={t('edit')}
                dialogTitle={t('commune')}
                fields={[
                  { field: 'name', label: t('commune'), type: 'text' },
                  { field: 'province', label: t('province'), type: 'select', selectOptions: communesSelectOptions },
                ]}
                validationSchema={{
                  name: Yup.string().required(t('requiredField')),
                  province: Yup.string().required(t('requiredField')),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
                yellowIcon
                postErrorMappings={{
                  '400': t('Error.CommuneExists'),
                }}
              />
            ),
          },
          {
            renderer: (row) => (
              <DeleteModelButton
                id={row['id']}
                modelName='communes'
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
                postErrorMappings={{
                  '400': t('Error.CommuneUsed'),
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};
