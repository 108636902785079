import { DataTable, GetContentButton, Panel } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ClientDocumentsProps {
  isClientAdmin?: boolean;
  branchId?: string;
}

export const ClientDocuments = (props: ClientDocumentsProps) => {
  const { t } = useTranslation();

  return (
    <Panel header={props.isClientAdmin ? '' : t('documents')} borderType={'raised'}>
      <DataTable
        refreshKey={1}
        fetchUrl={props.isClientAdmin ? 'api/client-admin/files' : 'api/client/files'}
        fetchFilters={{ branchId: props.isClientAdmin ? props.branchId : undefined }}
        initialSort={{ columnIndex: 1, ascending: false }}
        columns={[
          {
            key: 'fileName',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'uploadDate',
            label: t('uploadDate'),
            type: 'date-time',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
          },
        ]}
      />
    </Panel>
  );
};
