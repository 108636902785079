import { Expose } from 'class-transformer';
import { ServicePrice } from '@lib/api-interface';

export class ServiceSettings {
  @Expose()
  activeFromDate?: string;
  @Expose()
  pix?: boolean;
  @Expose()
  organizationAsSide?: boolean;
  @Expose()
  clientTransportPrice?: ServicePrice;
  @Expose()
  subcontractorTransportPrice?: ServicePrice;
  @Expose()
  clientCollectionPrice?: ServicePrice;
  @Expose()
  subcontractorCollectionPrice?: ServicePrice;
  @Expose()
  communePrice?: ServicePrice;
  @Expose()
  declaredPrice?: number;
}