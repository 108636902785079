import * as React from 'react';
import styles from './styles.module.css';

export interface ToolbarProps {
    left?: JSX.Element[];
    right?: JSX.Element[];
}

export const Toolbar = (props: ToolbarProps) => {
    const leftItems = props.left?.map((item, index) => <div key={index}> {item}</div>);
    const rightItems = props.right?.map((item, index) => <div key={index}> {item}</div>);

    return (
        <div className={styles['container']}>
            <div className={styles['containerInner']}>{leftItems}</div>
            <div className={styles['containerInner']}>{rightItems}</div>
        </div>
    );
};
