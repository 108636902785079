import { TFunction } from 'react-i18next';
import { InfrastructureCategory } from '@lib/api-interface';

export class InfrastructureCategorySelectOptionsFactory {
  static getSelectOptionsWithAll(t: TFunction<'common'>) {
    return [
      ...this.getSelectOptions(t),
      {
        label: t('Model.Infrastructure.InfrastructureCategory.ALL'),
        value: InfrastructureCategory.ALL,
      },
    ];
  }

  static getSelectOptions(t: TFunction<'common'>) {
    return [
      {
        label: t('Model.Infrastructure.InfrastructureCategory.CONTAINER'),
        value: InfrastructureCategory.CONTAINER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BARREL'),
        value: InfrastructureCategory.BARREL,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BALER'),
        value: InfrastructureCategory.BALER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.STORAGE_BIN'),
        value: InfrastructureCategory.STORAGE_BIN,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BIG_BAG'),
        value: InfrastructureCategory.BIG_BAG,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.PRESS_CONTAINER'),
        value: InfrastructureCategory.PRESS_CONTAINER,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.PRESS_TRAY'),
        value: InfrastructureCategory.PRESS_TRAY,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.STATIONARY_PRESS'),
        value: InfrastructureCategory.STATIONARY_PRESS,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.TRAY'),
        value: InfrastructureCategory.TRAY,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.BAG'),
        value: InfrastructureCategory.BAG,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.IN_BULK'),
        value: InfrastructureCategory.IN_BULK,
      },
      {
        label: t('Model.Infrastructure.InfrastructureCategory.OTHER'),
        value: InfrastructureCategory.OTHER,
      },
    ];
  }
}
