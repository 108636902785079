import React from 'react';
import { DataTable, Panel } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { NavigateToRowButton } from '../../../libs/ui-components/components/data-table/navigate-to-row-button';
import { ProjectSettings } from '@lib/api-interface';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import axios from 'axios';
import { useUserState } from '../../../state/UserState';

export const PickupsBranches = () => {
  const { t } = useTranslation();
  const userState = useUserState();
  const [projectSettings, setProjectSettings] = React.useState<ProjectSettings | null>(null);

  React.useEffect(() => {
    if (userState.roles.includes(RolesEnum.CLIENT_USER) || userState.roles.includes(RolesEnum.CLIENT_ADMIN)) {
      const fetchSettings = async () => {
        const url = `${process.env['REACT_APP_API_URL']}/api/project-branch-view/settings`;
        try {
          const response = await axios.get(url);
          setProjectSettings(response.data);
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setProjectSettings(null); // handle error appropriately
        }
      };

      fetchSettings();
    }
  }, []);

  if (userState.roles.includes(RolesEnum.CLIENT_ADMIN) && !projectSettings?.userAdminCanReportCollections) {
    return <></>;
  }
  return (
    <Panel borderType='flat' header={t('selectBranch')}>
      <DataTable
        rowLink={{ linkPath: '', linkRowId: 'projectBranchId' }}
        refreshKey={1}
        fetchUrl={'/api/branches'}
        columns={[
          {
            key: 'companyBranchName',
            label: t('branchName'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'registrationAddress.commune',
            label: t('commune'),
            type: 'text',
          },
          {
            key: 'registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'registrationAddress.houseNum',
            label: t('houseNum'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <NavigateToRowButton
                row={row}
                buttonLabel={t('showPickups')}
                rowLink={{
                  linkPath: '',
                  linkRowId: 'projectBranchId',
                }}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
