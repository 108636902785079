import * as React from 'react';
import { useState } from 'react';
import { Button as MatButton, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Dialog } from '../dialog/dialog';

export type ButtonColor = 'success' | 'warning' | 'primary' | 'secondary' | 'info' | undefined;

export interface ButtonProps {
  dataTestId?: string;
  label?: string;
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  separated?: boolean;
  onClick: () => Promise<any> | void;
  confirmationMsg?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  icon?: JSX.Element;
  tooltip?: string;
  forceConfirmation?: boolean;
  isDisabled?: boolean;
  forceLoadingSign?: boolean;
}

export const Button = (props: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function handleCallCallback() {
    setLoading(true);
    await props.onClick();
    setLoading(false);
  }

  function handleCloseOk() {
    setOpen(false);
    handleCallCallback();
  }

  function mapToMUIColor(style: ButtonColor): 'success' | 'error' | 'primary' | 'secondary' | 'info' | undefined {
    if (style === 'warning') {
      return 'error';
    }
    return style;
  }

  const button = (
    <LoadingButton
      data-test-id={props.dataTestId ? props.dataTestId : props.label?.replaceAll(' ', '-') + '-btn'}
      variant={props.variant}
      color={mapToMUIColor(props.color)}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        return props.color === 'warning' || props.forceConfirmation ? setOpen(true) : handleCallCallback();
      }}
      sx={{ marginLeft: props.separated ? '3rem' : '0' }}
      loading={props.forceLoadingSign || loading}
      size='small'
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={props.isDisabled}
    >
      {props.icon}
      {props.label}
    </LoadingButton>
  );

  return (
    <React.Fragment>
      {props.tooltip ? (
        <Tooltip title={props.tooltip} enterDelay={800} enterNextDelay={800}>
          {button}
        </Tooltip>
      ) : (
        button
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('Button.confirmationTitle')}</DialogTitle>
        <DialogContent className={styles['dialog']}>
          <DialogContentText>{props.confirmationMsg !== undefined ? props.confirmationMsg : t('Button.confirmationMsg')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MatButton data-test-id={`${props.label?.replaceAll(' ', '-')}-cancel`} autoFocus onClick={() => setOpen(false)}>
            {t('Button.confirmationCancel')}
          </MatButton>
          <MatButton data-test-id={`${props.label?.replaceAll(' ', '-')}-ok`} onClick={handleCloseOk} color={'error'}>
            {t('Button.confirmationProceed')}
          </MatButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
