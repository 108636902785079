import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { SettlementDetailsView } from '@lib/api-interface';
import { TableFilterItem } from '@lib/ui-components';
import { ExtractedItems } from '../libs/ui-components/components/infinite-scroll-renderer';

const extractItemsFromEmbedded = (response: AxiosResponse): ExtractedItems<SettlementDetailsView[]> => {
  const embedded = response.data['_embedded'] ?? {};
  const data = embedded['settlementViews'] ?? [];
  const page = response.data['page'] ?? {};
  const currentPage = page['number'];
  const totalItems = page['totalElements'] ?? 0;
  const hasNextPage = data.length > 0 && currentPage + 1 < page['totalPages'];

  for (const item of data) {
    delete item['_links'];
  }
  return {
    data: data,
    currentPage: currentPage,
    hasNextPage: hasNextPage,
    totalItems: totalItems,
  } as ExtractedItems<SettlementDetailsView[]>;
};

async function queryFn(params: any) {
  const response = await axios.get('/api/settlement/fetch-settlement-view-details-projections', { params });
  if (response.status === 200) {
    return extractItemsFromEmbedded(response);
  } else {
    throw new Error(response.data);
  }
}

async function queryAllFn(params: any, totalSettlementsCount?: number) {
  try {
    const promises = [];
    params.size = 1000;
    const totalPages = (totalSettlementsCount && Math.ceil(totalSettlementsCount / params.size)) || 1;
    for (let page = 0; page < totalPages; page++) {
      params.page = page;
      promises.push(axios.get('/api/settlement/fetch-settlement-view-details-projections', { params }).then((response) => extractItemsFromEmbedded(response)));
    }
    const pagesData = await Promise.all(promises);
    return pagesData.flatMap((page) => page.data);
  } catch (error) {
    throw new Error('An error occurred while fetching data');
  }
}

export const useSettlementDetailsByFilters = async (yearMonth: string, settlementStatus?: string, filters?: TableFilterItem[], totalSettlementsCount?: number) => {
  const params = {
    yearMonth,
    settlementStatus,
  };
  filters?.forEach((value) => Object.assign(params, { [value.column.key]: value.value }));

  return await queryAllFn(params, totalSettlementsCount).then((response) => response);
};

export const useInfiniteSettlementDetailsByFilters = (
  yearMonth: string,
  settlementStatus?: string,
  filters?: TableFilterItem[],
  subcontractorCompanyId?: string,
  transporterId?: string,
  transporterKpoId?: string,
  receiverKpoId?: string,
): any => {
  const queryClient = useQueryClient();
  const queryKey = ['settlementViewDetailsInfinite', yearMonth, ...(settlementStatus !== undefined ? [settlementStatus] : []), filters];
  const params = {
    yearMonth,
    settlementStatus,
    subcontractorCompanyId,
    transporterId,
    transporterKpoId,
    receiverKpoId,
  };
  filters?.forEach((value) => Object.assign(params, { [value.column.key]: value.value }));

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  async function fetchSettlements({ pageParam = 0 }) {
    const queryParams = {
      ...params,
      page: pageParam,
      size: 20,
    };
    return queryFn(queryParams);
  }

  return [
    useInfiniteQuery(queryKey, fetchSettlements, {
      getNextPageParam: (_) => _.currentPage + 1,
    }),
    invalidateQuery,
  ];
};
