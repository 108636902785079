import React from 'react';
import { TitlePositioner } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, CheckboxLabel, Edit, EditModelButton, GridLabel, Panel, TextLabel } from '@lib/ui-components';
import { subcontractorTypesEnumToArray, subcontractorTypesEnumToObject } from '../../../../../../domain/enums/subcontractor.types.enum';
import { useCompanyView } from '../../../../../../data-source-hooks/company.data-source';
import { QueryResultRenderer } from '../../../../../../libs/ui-components/components/query-result-renderer';

export interface ContractorGeneralProps {
  companyId: string;
}

export function ContractorGeneral(props: ContractorGeneralProps) {
  const { t } = useTranslation();
  const [companyQuery, reloadCompanyQuery] = useCompanyView(props.companyId);

  return (
    <QueryResultRenderer
      query={companyQuery}
      render={(companyView) => {
        const subcontractorRoles = subcontractorTypesEnumToObject(companyView.subcontractorTypes);
        return (
          <Panel direction='row' borderType='flat'>
            <Panel flex='1'>
              <TitlePositioner>
                <TextLabel label={t('general').toUpperCase()} />
              </TitlePositioner>
              <Panel borderType='raised'>
                <GridLabel>
                  <GridLabel>
                    <TextLabel label={t('companyShortName')} value={companyView.shortName} gridColumn='1/3' />
                    <CheckboxLabel label={t('collectionDecisionNotRequired')} value={companyView.collectionDecisionNotRequired} />
                    <TextLabel label={t('Model.CompanyBranch.accessionDate')} dateFormat='d.M.yyyy / HH:mm' value={companyView.createdAt} noWrap={true} />
                    <TextLabel label={t('introducedBy')} value={companyView.createdBy} noWrap gridColumn='2/4' />
                    <CheckboxLabel label={t('subcontractorRoleTransporting')} value={subcontractorRoles?.TRANSPORTING} />
                    <CheckboxLabel label={t('subcontractorRoleReceiving')} value={subcontractorRoles?.RECEIVING} />
                    <CheckboxLabel label={t('subcontractorRoleTakingOver')} value={subcontractorRoles?.TAKING_OVER} />
                    <CheckboxLabel label={t('subcontractorRoleOther')} value={subcontractorRoles?.OTHER} />
                    <CheckboxLabel label={t('subcontractorRoleReceivingCommunalWaste')} value={subcontractorRoles?.RECEIVING_COMMUNAL_WASTE} />
                  </GridLabel>
                  <GridLabel gridTemplateColumns='none' justifyContent='end'>
                    <AggregateCommandButton
                      aggregateId={companyView.companyId!}
                      aggregateName='CompanyAggregate'
                      commandName='updateGeneralSettings'
                      startIcon={<Edit />}
                      dialogTitle={t('general').toUpperCase()}
                      fields={[
                        {
                          field: 'shortName',
                          label: t('companyShortName'),
                          type: 'text',
                          defaultValue: companyView.shortName,
                        },
                        {
                          rowItems: [
                            {
                              field: 'subcontractorRoles.TRANSPORTING',
                              label: t('subcontractorRoleTransporting'),
                              type: 'boolean',
                              defaultValue: subcontractorRoles.TRANSPORTING,
                            },
                            {
                              field: 'subcontractorRoles.RECEIVING',
                              label: t('subcontractorRoleReceiving'),
                              type: 'boolean',
                              defaultValue: subcontractorRoles.RECEIVING,
                            },
                            {
                              field: 'subcontractorRoles.TAKING_OVER',
                              label: t('subcontractorRoleTakingOver'),
                              type: 'boolean',
                              defaultValue: subcontractorRoles.TAKING_OVER,
                            },
                            {
                              field: 'subcontractorRoles.OTHER',
                              label: t('subcontractorRoleOther'),
                              type: 'boolean',
                              defaultValue: subcontractorRoles.OTHER,
                            },
                            {
                              field: 'subcontractorRoles.RECEIVING_COMMUNAL_WASTE',
                              label: t('subcontractorRoleReceivingCommunalWaste'),
                              type: 'boolean',
                              defaultValue: subcontractorRoles.RECEIVING_COMMUNAL_WASTE,
                            },
                          ],
                        },
                        {
                          field: 'collectionDecisionNotRequired',
                          label: t('collectionDecisionNotRequired'),
                          type: 'boolean',
                          defaultValue: companyView.collectionDecisionNotRequired,
                        },
                      ]}
                      onBeforeSave={(data) => {
                        return {
                          subcontractorTypes: subcontractorTypesEnumToArray(data.subcontractorRoles),
                          shortName: data.shortName,
                          collectionDecisionNotRequired: data.collectionDecisionNotRequired,
                        };
                      }}
                      onSaved={() => reloadCompanyQuery()}
                    />
                  </GridLabel>
                </GridLabel>
              </Panel>
              <TitlePositioner>
                <TextLabel label={t('bdoSettings').toUpperCase()} />
              </TitlePositioner>
              <Panel borderType='raised'>
                <GridLabel>
                  <GridLabel>
                    <TextLabel label={t('Model.CompanyBranch.bdoNumber')} value={companyView.bdoIdentificationNumber} gridColumn='1/3' />
                  </GridLabel>
                  <GridLabel>
                    <GridLabel gridTemplateColumns={'1fr'}>
                      <CheckboxLabel label={t('Model.Company.VerifiedBdo')} type='toggle' value={companyView.verifiedBdo} />
                      <CheckboxLabel label={t('notApplicable')} type='toggle' value={companyView.bdoNotApplicable} />
                    </GridLabel>
                    <GridLabel gridTemplateColumns='none' justifyContent='end'>
                      <AggregateCommandButton
                        aggregateId={companyView.companyId!}
                        aggregateName='CompanyAggregate'
                        commandName='updateCompanyBdo'
                        startIcon={<Edit />}
                        dialogTitle={t('bdoSettings').toUpperCase()}
                        fields={[
                          {
                            label: t('bdoIdentificationNumber'),
                            type: 'table-select-by-api',
                            field: 'bdoIdentificationNumber',
                            tableSelectByApiParams: {
                              apiPostEndpoint: '/api/organization/bdo/search/company',
                              dialogTitle: t('bdoIdentificationNumber'),
                              displayFormat: '{registrationNumber}',
                              selectedValueField: 'registrationNumber',
                              initialEntity: companyView,
                              columns: [
                                {
                                  label: t('bdoIdentificationNumber'),
                                  key: 'registrationNumber',
                                  type: 'text',
                                },
                                { label: t('name'), key: 'name', type: 'text' },
                                { label: t('nip'), key: 'nip', type: 'text' },
                                { label: t('postcode'), key: 'postalCode', type: 'text' },
                                { label: t('addressInformation'), key: 'address', type: 'text' },
                              ],
                              otherValuesMap: {
                                companyId: 'bdoCompanyId',
                              },
                              queryFields: [{ label: t('bdoSearch'), type: 'text', field: 'query' }],
                            },
                          },
                          {
                            field: 'verifiedBdo',
                            label: t('Model.Company.VerifiedBdo'),
                            type: 'boolean',
                            defaultValue: companyView.verifiedBdo,
                          },
                          {
                            field: 'bdoNotApplicable',
                            label: t('notApplicable'),
                            type: 'boolean',
                            defaultValue: companyView.bdoNotApplicable,
                          },
                        ]}
                        onSaved={() => reloadCompanyQuery()}
                        hiddenValues={{ companyId: companyView.companyId, bdoCompanyId: companyView.bdoCompanyId }}
                      />
                    </GridLabel>
                  </GridLabel>
                </GridLabel>
              </Panel>
            </Panel>
            <Panel flex='1'>
              <TitlePositioner>
                <TextLabel label={t('settings').toUpperCase()} />
              </TitlePositioner>
              <Panel borderType='raised'>
                <GridLabel>
                  <GridLabel>
                    <TextLabel label={t('perMonth')} value={'TODO'} />
                  </GridLabel>
                  <GridLabel gridTemplateColumns='none' justifyContent='end'>
                    <EditModelButton
                      id={0}
                      entity={''}
                      modelName={''}
                      dialogTitle={t('settings').toUpperCase()}
                      fields={[
                        {
                          field: '',
                          label: t('perMonth'),
                          type: 'select',
                          selectOptions: [
                            { value: '', label: t('perMonth') },
                            { value: '', label: t('Model.CompanyBranch.perReceipt') },
                          ],
                        },
                      ]}
                      onSaved={() => reloadCompanyQuery()}
                    />
                  </GridLabel>
                </GridLabel>
              </Panel>
            </Panel>
          </Panel>
        );
      }}
    />
  );
}
