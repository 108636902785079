import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, Snackbar } from '@lib/ui-components';
import { AuthApiService } from '../../services/auth.api.service';

export interface ResetAccountPasswordButtonProps {
  login: string;
  tooltip?: string;
  variant?: 'text' | 'outlined' | 'contained';
}

export const ResetAccountPasswordButton = (props: ResetAccountPasswordButtonProps) => {
  const { t } = useTranslation();
  const [generalFailure, setGeneralFailure] = useStateIfMounted(false);
  const [success, setSuccess] = useStateIfMounted(false);

  async function handleRestorePassword() {
    try {
      const result = await AuthApiService.resetPassword({ login: props.login });
      if (result?.httpStatus) {
        setGeneralFailure(true);
      } else {
        setSuccess(true);
      }
    } catch (e) {
      setGeneralFailure(true);
    }
  }

  return (
    <>
      <Button
        label={t('resetPassword')}
        startIcon={<WarningOutlined />}
        tooltip={props.tooltip}
        color='warning'
        variant={props.variant}
        onClick={async () => handleRestorePassword()}
      />
      <Snackbar open={generalFailure} message={t('ResetPasswordPage.resetPasswordFailed')} severity='error' onClose={() => setGeneralFailure(false)} />
      <Snackbar open={success} message={t('resetPasswordSuccessful')} severity='success' onClose={() => setSuccess(false)} />
    </>
  );
};
