import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataTable, GetContentButton } from '@lib/ui-components';
import { CompanyRole } from '@lib/api-interface';

export interface ClientProjectAgreementsProps {
  projectId: string;
}

export default function ClientAgreementsTab(props: ClientProjectAgreementsProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();
  return (
    <DataTable
      refreshKey={tableReloadKey}
      fetchPostUrl={'/api/resource/companyFileViews/by-params'}
      fetchFilters={{ projectId: props.projectId, type: 'AGREEMENT', role: CompanyRole.CLIENT }}
      columns={[
        {
          key: 'fileName',
          label: t('file'),
          type: 'text',
        },
        {
          key: 'companyBranch.companyName',
          label: t('headquarterName'),
          type: 'text',
        },
        {
          key: 'companyBranch.nip',
          label: t('nip'),
          type: 'numeric',
        },
        {
          key: 'companyBranch.name',
          label: t('Model.CompanyBranch.name'),
          type: 'text',
        },
        {
          key: 'agreementData.activeFrom',
          label: t('Projects.ViewProject.Client.Agreements.validFrom'),
          type: 'date',
        },
        {
          key: 'agreementData.forUnlimitedPeriod',
          label: t('forUnlimitedPeriod'),
          type: 'boolean',
        },
        {
          key: 'agreementData.activeTo',
          label: t('Projects.ViewProject.Client.Agreements.validUntil'),
          type: 'date',
        },
        {
          key: 'comment',
          label: t('comment'),
          type: 'text',
        },
        {
          key: 'creationDate',
          label: t('added'),
          type: 'date',
        },
      ]}
      rowOptions={[
        {
          renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
        },
      ]}
    />
  );
}
