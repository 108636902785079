import React from 'react';
import { useTranslation } from 'react-i18next';
import { RATE_MAX_VAL, RATE_PRECISION } from '@lib/api-interface';
import { DataTable, Toolbar, UsecaseButton, UsecaseDeleteButton } from '@lib/ui-components';
import * as Yup from 'yup';

export const EuroDaily = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);

  return (
    <>
      <Toolbar
        right={[
          <UsecaseButton
            useCaseName='AddExchangeRate'
            groupName='exchangerate'
            buttonLabel={t('Button.ExchangeRateModel.add')}
            dialogTitle={t('Button.ExchangeRateModel.add.dialog.title')}
            fields={[
              {
                field: 'rate',
                label: t('Model.ExchangeRateModel.rate'),
                numericPrecision: RATE_PRECISION,
                numericMinValue: 0,
                numericMaxValue: RATE_MAX_VAL,
                type: 'numeric',
              },
              {
                field: 'date',
                label: t('Model.ExchangeRateModel.date'),
                type: 'date',
              },
            ]}
            hiddenValues={{
              currency: 'EUR',
              rateType: 'DAILY',
            }}
            validationSchema={{
              rate: Yup.string().required(t('Model.ExchangeRateModel.rate.required')),
              date: Yup.string().required(t('Model.ExchangeRateModel.date.required')),
            }}
            onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
          />,
        ]}
      />
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{
          modelName: 'exchangeRates',
        }}
        fetchFilters={{
          currency: 'EUR',
          rateType: 'DAILY',
        }}
        columns={[
          {
            key: 'rate',
            label: t('Model.ExchangeRateModel.rate'),
            type: 'numeric',
            numericPrecision: RATE_PRECISION,
          },
          {
            key: 'date',
            label: t('Model.ExchangeRateModel.date'),
            type: 'date',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <UsecaseButton
                useCaseName='AddExchangeRate'
                groupName='exchangerate'
                buttonLabel={t('edit')}
                dialogTitle={t('Button.ExchangeRateModel.edit.dialog.title')}
                fields={[
                  {
                    field: 'rate',
                    label: t('Model.ExchangeRateModel.rate'),
                    numericPrecision: RATE_PRECISION,
                    numericMinValue: 0,
                    numericMaxValue: RATE_MAX_VAL,
                    type: 'numeric',
                    defaultValue: row.rate,
                  },
                ]}
                hiddenValues={{
                  currency: 'EUR',
                  rateType: 'DAILY',
                  date: row.date,
                }}
                validationSchema={{
                  rate: Yup.string().required(t('Model.ExchangeRateModel.rate.required')),
                }}
                onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
          {
            renderer: (row) => (
              <UsecaseDeleteButton
                useCaseName='DeleteExchangeRate'
                groupName='exchangename'
                payload={{
                  exchangeRateId: row.id,
                }}
                onDeleted={async () => setTableReloadKey(tableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </>
  );
};
