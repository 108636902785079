import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import { ContractorDecisionsOnCollecting } from './contractor-decisions-on-collecting';
import { ContractorOtherDecisions } from './contractor-other-decisions';
import { ContractorConclusions } from './contractor-conclusions';
import { CompanyViewModel } from '@lib/api-interface';
import { useUserState } from '../../../../../../state/UserState';
import { RolesEnum } from '../../../../../../domain/enums/roles.enum';

export interface ContractorDecisionsConclusionsProps {
  company: CompanyViewModel;
}

export function ContractorDecisionsConclusions(props: ContractorDecisionsConclusionsProps) {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const userState = useUserState();
  const subcontractor = userState.roles.includes(RolesEnum.CONTRACTOR);
  const labels = [t('decisionsOnCollecting'), t('otherDecisions')];

  if (!subcontractor) {
    labels.push(t('conclusions'));
  }

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={labels} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>

      {activePage === 0 && <ContractorDecisionsOnCollecting company={props.company} addNewDocumentButtonHidden={subcontractor} />}
      {activePage === 1 && <ContractorOtherDecisions company={props.company} addNewDocumentButtonHidden={subcontractor} />}
      {activePage === 2 && !subcontractor && <ContractorConclusions company={props.company} />}
    </Panel>
  );
}
