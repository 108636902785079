import React, { useState } from 'react';
import { useProjectBranchContext } from '@context';
import { useQuery } from '@tanstack/react-query';
import { RestUsecaseClientService } from '@lib/common-sdk';
import { useTranslation } from 'react-i18next';
import { Calendar, YearMonthFilterBar } from '@lib/ui-components';
import { CollectionRequestViewModel, ServiceType, yearMonthFormat } from '@lib/api-interface';
import styles from './styles.module.css';
import i18n from 'i18next';
import { addMonths, format } from 'date-fns';
import styled from 'styled-components';
import { FetchErrorIndicator } from '../../../../../../../../libs/ui-components/components/fetch-error-indicator';

const StyledCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 1rem;
`;

interface CollectionCalendarProps {
  forSubcontractor?: boolean;
}

interface CollectionRequestViewWithServiceDescModel {
  collectionRequestView: CollectionRequestViewModel;
  serviceDescription?: string;
}

export default function CollectionCalendar(props: CollectionCalendarProps) {
  type FetchResponse = { [key: string]: CollectionRequestViewWithServiceDescModel[] };
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [currentYearMonth, setCurrentYearMonth] = useState(format(new Date(), yearMonthFormat));
  const calendarQuery = getQuery();

  function getQuery() {
    if (props.forSubcontractor) {
      const queryKey = ['fetchSubcontractorCollectionRequestCalendar', currentYearMonth];
      return useQuery(
        queryKey,
        () =>
          RestUsecaseClientService.post<any, FetchResponse>('collection-request', 'fetch-subcontractor-collection-request-calendar', {
            yearMonth: currentYearMonth,
          }),
        { staleTime: 5000 },
      );
    }
    const projectBranch = useProjectBranchContext();
    const queryKey = ['fetchCollectionRequestCalendar', projectBranch.projectId, projectBranch.branchId, currentYearMonth];
    return useQuery(
      queryKey,
      () =>
        RestUsecaseClientService.post<any, FetchResponse>('collection-request', 'fetch-collection-request-calendar', {
          projectId: projectBranch.projectId,
          branchId: projectBranch.branchId,
          yearMonth: currentYearMonth,
        }),
      { staleTime: 5000 },
    );
  }

  if (calendarQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (calendarQuery.isError) {
    return <FetchErrorIndicator error={calendarQuery.error} />;
  }

  async function onYearMonthChange(yearMonth: string) {
    setCurrentYearMonth(yearMonth);
  }

  function toCollectionRequestElement(model: CollectionRequestViewWithServiceDescModel): JSX.Element {
    const collectionRequest = model.collectionRequestView;
    const collectionRequestStyle = collectionRequest.serviceType!.toLowerCase().replaceAll('_', '-');
    return (
      <div className={`${styles['collection-request-entry']} ${styles[collectionRequestStyle]}`}>
        <div className={styles['collection-request-entry-header']}>
          <div>{collectionRequest.serviceType !== ServiceType.COMMUNE ? collectionRequest.wasteCode : model.serviceDescription}</div>
          <div>{translations.common[`serviceType.${collectionRequest.serviceType}`]}</div>
        </div>
        <div className={styles['collection-request-entry-content']}>
          <div className={styles['collection-request-entry-content-container-name']}>{collectionRequest.container}</div>
          <div className={styles['collection-request-entry-content-containers-number']}>
            {collectionRequest.containers} {t('priceType.UNIT')}
          </div>
        </div>
      </div>
    );
  }

  const collectionRequests = Object.entries(calendarQuery.data).reduce((obj, [date, collectionRequests]) => {
    return { ...obj, [date]: collectionRequests.map(toCollectionRequestElement) };
  }, {});
  return (
    <StyledCalendarContainer>
      <YearMonthFilterBar
        onFilterChanged={(filters) => onYearMonthChange(filters.date)}
        initialMonthYear={currentYearMonth}
        maxYearMonth={addMonths(new Date(), 2)}
        minYearMonth={new Date(2020, 0, 1)}
      />
      <Calendar components={collectionRequests} yearMonth={currentYearMonth} />
    </StyledCalendarContainer>
  );
}
