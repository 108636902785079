import React from 'react';

interface RenderIfAsyncProps {
  render: () => JSX.Element;
  true: boolean;
}

export const RenderIfAsync = (props: RenderIfAsyncProps) => {
  return props.true ? <>{props.render()}</> : null;
};
