import {Expose} from 'class-transformer';
import {BaseModel} from '../../../common-sdk';

export class UserModel extends BaseModel {
  @Expose()
  name?: string;

  @Expose()
  login?: string;

  @Expose()
  uuid?: string;

  @Expose()
  email?: string;

  @Expose()
  active?: boolean;

  @Expose()
  roles?: string[];

  @Expose()
  authorities?: string[];

  @Expose()
  tags?: Map<string, string>;
}
