interface TrashcanIconProps { 
  large?: boolean;
}

export function TrashcanIcon({large}:TrashcanIconProps) {

  return (
    <svg width={large ? '24' : '18'} height={large ? '24' : '18'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.18848 6H5.17058H21.0274' stroke='#F2C94C' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.14308 6V4C8.14308 3.46957 8.35191 2.96086 8.72362 2.58579C9.09534 2.21071 9.5995 2 10.1252 2H14.0894C14.6151 2 15.1192 2.21071 15.4909 2.58579C15.8627 2.96086 16.0715 3.46957 16.0715 4V6M19.0446 6V20C19.0446 20.5304 18.8358 21.0391 18.4641 21.4142C18.0924 21.7893 17.5882 22 17.0625 22H7.15203C6.62634 22 6.12218 21.7893 5.75047 21.4142C5.37875 21.0391 5.16992 20.5304 5.16992 20V6H19.0446Z'
        stroke='#F2C94C'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.125 11V17' stroke='#F2C94C' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.0898 11V17' stroke='#F2C94C' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
