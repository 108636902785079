import React from 'react';
import { ButtonBar } from '@lib/ui-components';
import axios from 'axios';

export const Aggregates = () => {
  return (
    <div>
      <ButtonBar
        left={[
          {
            label: 'Replay All',
            onClick: async () => {
              await axios({
                url: `${process.env['REACT_APP_API_URL']}/api/admin/aggregates/replay-all`,
                method: 'POST',
                data: {},
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });
            },
          },

          {
            label: 'Action',
            onClick: async () => {
              await axios({
                url: `${process.env['REACT_APP_API_URL']}/api/admin/aggregates/do-action`,
                method: 'POST',
                data: {},
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });
            },
          },
        ]}
      />
    </div>
  );
};
