import { IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';
import { BaseModel } from '../../../common-sdk';

export enum NavResourceType {
  HARDWARE = 'HARDWARE', // Sprzęt
  EMPLOYEE = 'EMPLOYEE', // Pracownik
}

export enum NavResourceUnit {
  MG = 'MG', // Tona
  UNIT = 'UNIT', // Szt.
  SERVICE = 'SERVICE', // Usługa
}

export enum NavResourceAccountingGroup {
  CONSULTANCY = 'CONSULTANCY', // Doradztwo
  COMMODITY = 'COMMODITY', // Towar
  SERVICES = 'SERVICES', // Inne usługi
  RSI = 'RSI',
}

export class NavResourceModel extends BaseModel {
  @IsNotEmpty()
  @Expose()
  code: string | undefined;

  @IsNotEmpty()
  @Expose()
  name: string | undefined;

  @IsNotEmpty()
  @Expose()
  type: NavResourceType | undefined;

  @IsNotEmpty()
  @Expose()
  unit: NavResourceUnit | undefined;

  @IsNotEmpty()
  @Expose()
  price: number | undefined; // in PLN gr

  @IsNotEmpty()
  @Expose()
  accountingGroup: NavResourceAccountingGroup | undefined;
}
