import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, GetContentButton, Panel } from '@lib/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCompanyBranchView } from '@data-source-hooks';
import { QueryResultRenderer } from '../../../../../../../libs/ui-components/components/query-result-renderer';

export interface CompanyBranchAgreementsProps {
  companyBranchId: string;
}

export const CompanyBranchAgreements = (props: CompanyBranchAgreementsProps) => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [branchQuery] = useCompanyBranchView(props.companyBranchId);

  const noticePeriodOptions = [
    {
      label: t('month'),
      value: 'MONTH',
    },
    {
      label: t('twoMonths'),
      value: 'TWO_MONTHS',
    },
    {
      label: t('threeMonths'),
      value: 'THREE_MONTHS',
    },
    {
      label: t('sixMonths'),
      value: 'SIX_MONTHS',
    },
    {
      label: t('year'),
      value: 'YEAR',
    },
  ];

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        return (
          <Panel borderType='flat'>
            <DataTable
              refreshKey={tableReloadKey}
              fetchPostUrl={'/api/resource/companyFileViews/by-params'}
              fetchFilters={{
                companyId: companyBranchView.companyId,
                companyBranchId: companyBranchView.branchId,
                type: 'AGREEMENT',
              }}
              initialSort={{ columnIndex: 10, ascending: false }}
              columns={[
                {
                  key: 'fileName',
                  label: t('file'),
                  type: 'text',
                },
                {
                  key: 'role',
                  label: t('role'),
                  type: 'enum',
                  enumValues: [
                    { value: 'CLIENT', label: t('client') },
                    { value: 'SUBCONTRACTOR', label: t('subcontractor') },
                  ],
                },
                {
                  key: 'project.projectNumber',
                  label: t('projectNum'),
                  type: 'text-chip',
                },
                {
                  key: 'companyBranch.name',
                  label: t('Model.CompanyBranch.name'),
                  type: 'text',
                },
                {
                  key: 'companyBranch.registrationAddress.town',
                  label: t('locality'),
                  type: 'text',
                },
                {
                  key: 'companyBranch.registrationAddress.street',
                  label: t('street'),
                  type: 'text',
                },
                {
                  key: 'agreementData.activeFrom',
                  label: t('validFrom'),
                  type: 'date',
                },
                {
                  key: 'agreementData.forUnlimitedPeriod',
                  label: t('forUnlimitedPeriod'),
                  type: 'boolean',
                  align: 'center',
                },
                {
                  key: 'agreementData.activeTo',
                  label: t('validUntil'),
                  type: 'date',
                },
                {
                  key: 'agreementData.noticePeriod',
                  label: t('noticePeriod'),
                  type: 'enum',
                  enumValues: noticePeriodOptions,
                },
                {
                  key: 'comment',
                  label: t('comment'),
                  type: 'text',
                },
                {
                  key: 'uploadDate',
                  label: t('added'),
                  type: 'date-time',
                },
                {
                  key: 'uploadBy',
                  label: t('introducedBy'),
                  type: 'text',
                },
              ]}
              rowOptions={[
                {
                  renderer: (row) => <GetContentButton label={t('viewFile')} icon={<VisibilityIcon />} resourcePath={`api/resource/companyFileViews/${row.id}/content`} />,
                },
              ]}
            />
          </Panel>
        );
      }}
    />
  );
};
