import { Button, DataTableRowLink } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { extractValue } from '@lib/common-sdk';

interface NavigateToRowButtonProps {
  row: any;
  rowLink: DataTableRowLink;
  buttonLabel?: string;
}

export const NavigateToRowButton = (props: NavigateToRowButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      label={props.buttonLabel ?? t('Common.select')}
      color='primary'
      variant='outlined'
      onClick={async () => navigate(navigationLink(props.row, props.rowLink.linkPath, props.rowLink.linkRowId))}
    />
  );
};

export function navigationLink(row: any, linkPath: string, linkRowId?: string) {
  return `${linkPath}!${extractValue(row, linkRowId ?? 'id')}`;
}
