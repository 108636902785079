import React, { PropsWithChildren } from 'react';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import { DataTable, DataTableColumn } from '../data-table/data-table';
import { ClassConstructor } from 'class-transformer';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Dialog } from '../dialog/dialog';
import { Button, HStack } from '@lib/ui-components';

export interface FormInputTableMultiSelectProps extends FormInputProps {
  initialIds?: (string | number)[];
  displayFormat: string;
  dialogTitle: string;

  modelName?: string;
  modelClass?: ClassConstructor<any>;
  search?: string;

  fetchPostUrl?: string;
  fetchFilters?: any;
  columns: DataTableColumn[];
  selectedValueField?: string;
  onRowsClear?: () => void;
  getFormValues?: () => any;

  dependedFields?: string[];
  initialDisplayedValues?: string;
}

export const FormInputTableMultiSelect: React.FC<FormInputTableMultiSelectProps> = (props: PropsWithChildren<FormInputTableMultiSelectProps>) => {
  const [openDialog, setOpenDialog] = useStateIfMounted(false);
  const [displayedValues, setDisplayedValues] = useStateIfMounted(props.initialDisplayedValues || '');
  const [selectedValues, setSelectedValues] = useStateIfMounted<string[]>((props.initialIds as string[]) || []);
  const { t } = useTranslation();

  function computeFetchFilters() {
    const filters = props.fetchFilters ? { ...props.fetchFilters } : undefined;
    if (props.getFormValues && props.fetchFilters) {
      const formValues = props.getFormValues();
      Object.keys(props.fetchFilters).forEach((key) => {
        const paramVal = props.fetchFilters[key];
        if (typeof paramVal === 'string' && paramVal.startsWith('$')) {
          filters[key] = formValues[paramVal.substring(1)];
        } else {
          filters[key] = paramVal;
        }
      });
    }
    return filters;
  }

  const computedFetchFilters = computeFetchFilters();

  const updateSelectedRows = React.useCallback(
    (rows: any[], onChange: (...event: any[]) => void) => {
      const selectedRows = rows.map((item) => item[props.selectedValueField || 'id']);
      setSelectedValues(selectedRows);
      onChange(selectedRows);
      const displayedValues = rows.map((item) => props.displayFormat.replace(/{([^{}]*)}/g, (match, key) => item[key] || ''));
      setDisplayedValues(displayedValues.join(', '));
    },
    [props.displayFormat, props.selectedValueField],
  );

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const dataTable = React.useCallback(
    (onChange: (...event: any[]) => void) => {
      if (props.modelName) {
        return (
          <DataTable
            refreshKey={1}
            selectable={true}
            contentMaxHeightAsAlmostOneScreen={true}
            selectableOptions={{
              initialSelections: props.initialIds?.map((id) => ({ [props.selectedValueField || 'id']: id })),
              comparatorField: props.selectedValueField || 'id',
              onSelectedRowsChange: (rows) => updateSelectedRows(rows, onChange),
            }}
            columns={props.columns}
            modelDef={{
              modelName: props.modelName,
              modelClass: props.modelClass,
              search: props.search,
            }}
            fetchFilters={computedFetchFilters}
          />
        );
      }
      return (
        <DataTable
          refreshKey={1}
          selectable={true}
          contentMaxHeightAsAlmostOneScreen={true}
          selectableOptions={{
            initialSelections: props.initialIds?.map((id) => ({ [props.selectedValueField || 'id']: id })),
            comparatorField: props.selectedValueField || 'id',
            onSelectedRowsChange: (rows) => updateSelectedRows(rows, onChange),
          }}
          columns={props.columns}
          fetchPostUrl={props.fetchPostUrl}
          fetchFilters={computedFetchFilters}
        />
      );
    },
    [props.columns, props.modelClass, props.modelName, props.search, props.selectedValueField, props.initialIds],
  );

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <div data-test-id={`input-${props.name}-wrapper`}>
            <TextField
              inputProps={{ 'data-test-id': `input-${props.name}` }}
              helperText={error ? error.message : ' '}
              error={!!error}
              size='small'
              value={displayedValues}
              onChange={(event) => onChange(selectedValues)}
              onClick={() => setOpenDialog(true)}
              onKeyDown={(event) => event.preventDefault()}
              fullWidth
              label={props.label}
              variant={props.variant || 'outlined'}
              margin='normal'
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
              }}
              disabled={props.isDeactivated}
            />
          </div>
          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xl' fullWidth={true}>
            <DialogTitle>
              {props.dialogTitle}
              <IconButton
                data-test-id={'dialog-close-btn'}
                aria-label='close'
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {dataTable(onChange)}
              <HStack align={'right'}>
                <Button label={t('accept')} variant={'contained'} color={'secondary'} onClick={() => handleCloseDialog()} />
              </HStack>
            </DialogContent>
          </Dialog>
        </>
      )}
    />
  );
};
