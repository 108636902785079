import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddModelButton, DataTable, HStack, Panel, RenderIf, ToggleButtons, Toolbar } from '@lib/ui-components';
import { CompanyBranchViewModel, ProjectType } from '@lib/api-interface';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import * as Yup from 'yup';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';
import { useUserState } from '../../../state/UserState';
import { DataResultsDto, RestUsecaseClientService } from '@lib/common-sdk';

export const Projects = () => {
  const { t } = useTranslation();
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const userState = useUserState();
  const canCreateProjects = userState.authorities.findIndex((value) => value === AuthoritiesEnum.ORGANIZATION_ADMIN) >= 0;
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel header={t('Projects.header')} borderType='raised'>
      <HStack align='center'>
        <ToggleButtons labels={[t('active'), t('notActive')]} activeIndex={activePage} onChange={(index) => setActivePage(index)} />
      </HStack>
      <RenderIf true={canCreateProjects}>
        <Toolbar
          right={[
            <AddModelButton
              buttonLabel={t('Projects.AddProject.header')}
              dialogTitle={t('Projects.AddProject.header')}
              modelName='projects'
              fields={[
                {
                  rowItems: [
                    {
                      label: t('projectType'),
                      type: 'select',
                      field: 'type',
                      width: '22rem',
                      selectOptions: [
                        { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
                        { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
                        { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
                      ],
                    },
                    { label: t('name'), type: 'text', field: 'name' },
                  ],
                },
                {
                  label: t('Model.Project.projectNameForSubcontractor'),
                  type: 'text',
                  field: 'projectNameForSubcontractor',
                },
                {
                  rowItems: [
                    {
                      label: t('Model.Project.selectMainCompany'),
                      type: 'table-select',
                      field: 'mainCompanyBranchId',
                      tableSelectParams: {
                        modelName: 'companyBranchViews',
                        modelClass: CompanyBranchViewModel,
                        selectedValueField: 'branchId',
                        search: 'byClientAndCentral',
                        displayFormat: '{name}',
                        removeHateoasPathFromResult: true,
                        dialogTitle: t('Model.Project.selectMainCompany'),
                        fetchFilters: { central: true, roleClient: true },
                        columns: [
                          {
                            key: 'name',
                            label: t('Model.CompanyBranch.name'),
                            type: 'text',
                          },
                          {
                            key: 'nip',
                            label: t('nip'),
                            type: 'text',
                          },
                        ],
                      },
                    },
                    {
                      label: t('projectNum'),
                      type: 'text',
                      field: 'projectNumberSequence',
                      width: '29rem',
                    },
                    // temporary disabled
                    // {
                    //   label: t('projectNum'),
                    //   type: 'table-select',
                    //   field: 'projectNumberSequenceId',
                    //   width: '29rem',
                    //   tableSelectParams: {
                    //     modelName: 'sequenceMasks',
                    //     displayFormat: '{name}',
                    //     dialogTitle: t('sequences'),
                    //     removeHateoasPathFromResult: true,
                    //     fetchFilters: {
                    //       archived: false,
                    //     },
                    //     columns: [
                    //       {
                    //         key: 'name',
                    //         label: t('name'),
                    //         type: 'text',
                    //       },
                    //       {
                    //         key: 'mask',
                    //         label: t('mask'),
                    //         type: 'text',
                    //       },
                    //     ],
                    //   },
                    // },
                  ],
                },
                {
                  label: t('salesperson'),
                  type: 'table-select',
                  field: 'sellerPersonId',
                  width: '29rem',
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    dialogTitle: t('salesperson'),
                    removeHateoasPathFromResult: true,
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_SALES,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('projectLeadPerson'),
                  type: 'table-select',
                  field: 'projectLeadPersonId',
                  width: '29rem',
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    removeHateoasPathFromResult: true,
                    dialogTitle: t('projectLeadPerson'),
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_LEAD,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('invoicingPerson'),
                  type: 'table-select',
                  field: 'invoicePersonId',
                  width: '29rem',
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    dialogTitle: t('invoicingPerson'),
                    removeHateoasPathFromResult: true,
                    fetchFilters: {
                      roles: RolesEnum.ORGANIZATION,
                      authorities: AuthoritiesEnum.PROJECT_INVOICE,
                    },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('managingBdo'),
                  type: 'table-select',
                  field: 'managesBdoPersonId',
                  width: '29rem',
                  tableSelectParams: {
                    modelName: 'accounts',
                    search: 'byRolesAndAuthorities',
                    displayFormat: '{name}',
                    dialogTitle: t('managingBdo'),
                    removeHateoasPathFromResult: true,
                    fetchFilters: { roles: RolesEnum.ORGANIZATION, authorities: AuthoritiesEnum.PROJECT_BDO },
                    columns: [
                      {
                        key: 'name',
                        label: t('name'),
                        type: 'text',
                      },
                      {
                        key: 'login',
                        label: t('login'),
                        type: 'text',
                      },
                      {
                        key: 'email',
                        label: t('email'),
                        type: 'text',
                      },
                    ],
                  },
                },
                {
                  label: t('contractConclusionDate'),
                  type: 'date',
                  field: 'contractConclusionDate',
                  width: '29rem',
                },
              ]}
              hiddenValues={{
                terms: {
                  general: {
                    organizationAsSide: true,
                  },
                },
              }}
              validationSchema={{
                type: Yup.string().required(t('Model.Project.type.required')),
                name: Yup.string().required(t('Model.Project.name.required')),
                // projectNumberSequenceId: Yup.string().required(t('Model.Project.projectNumber.required')),
                projectNumberSequence: Yup.string().required(t('Model.Project.projectNumber.required')),
                projectNameForSubcontractor: Yup.string().required(t('Model.Project.projectNameForSubcontractor.required')),
                mainCompanyBranchId: Yup.string().required(t('Model.Project.selectMainCompany.required')),
                projectLeadPersonId: Yup.string().required(t('projectLeadPerson.required')),
                sellerPersonId: Yup.string().required(t('salesperson.required')),
                invoicePersonId: Yup.string().required(t('invoicingPerson.required')),
              }}
              overrideSaveCall={async (payload: any) => {
                try {
                  await RestUsecaseClientService.post<any, unknown>('project', 'create-project', payload);
                  return new DataResultsDto(false, 200, undefined, undefined, undefined);
                } catch (error: any) {
                  return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                }
              }}
              onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
            />,
          ]}
        />
      </RenderIf>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'projectViews' }}
        rowLink={{ linkPath: '', linkRowId: 'projectId' }}
        fetchFilters={{ active: activePage === 0 }}
        columns={[
          {
            key: 'projectNumber',
            label: t('projectNum'),
            type: 'text-chip',
          },
          {
            key: 'name',
            label: t('name'),
            type: 'text',
          },
          {
            key: 'nip',
            label: t('nip'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('projectType'),
            type: 'enum',
            enumValues: [
              { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
              { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
              { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
            ],
          },
          {
            key: 'mainCompanyBranchName',
            label: t('Model.Project.mainCompanyName'),
            type: 'text',
          },
          {
            key: 'sellerPerson',
            label: t('salesperson'),
            type: 'text',
          },
          {
            key: 'projectLeadPerson',
            label: t('projectLeadPerson'),
            type: 'text',
          },
          {
            key: 'invoicePerson',
            label: t('invoicingPerson'),
            type: 'text',
          },
          {
            key: 'managesBdoPerson',
            label: t('managingBdo'),
            type: 'text',
          },
          {
            key: 'contractConclusionDate',
            label: t('contractConclusionDate'),
            type: 'date',
          },
        ]}
      />
    </Panel>
  );
};
