import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LeftMenuLayout } from '../../components/layouts/left-menu-layout/left-menu-layout';
import { CollectionRequestsTab } from './collection-requests/collection-requests-tab';
import { Kpo } from '../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/kpo/kpo';
import { Reports } from '../organization/reports/reports';
import { ContactForm } from '../client-admin/contact-form/contact-form';
import { TodayPickups } from './pickups/today-pickups';
import { Documents } from './documents/documents';
import { ReceivedQuantity } from './received-quantity/received-quantity';

export const ContractorRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LeftMenuLayout />}>
        <Route index element={<Navigate to='/collection-requests' />} />
        <Route path='collection-requests' element={<CollectionRequestsTab />} />
        <Route path='quantity-received' element={<ReceivedQuantity />} />
        <Route path='archive' element={<Kpo />} />
        <Route path='today-pickups' element={<TodayPickups />} />
        <Route path='agreements' element={<Documents agreements={true} />} />
        <Route path='decisions' element={<Documents />} />
        <Route path='reports' element={<Reports />} />
        <Route path='contact-form' element={<ContactForm />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
