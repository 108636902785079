import React from 'react';
import { Button, Panel, RenderIf } from '@lib/ui-components';
import { CollapsedArea, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ServiceBar } from '../shared-components/service-bar';
import { Grid } from '@mui/material';
import { priceItem, requestEmailsFields, variableAnnotationsFields } from '../shared-components/shared';
import { ServiceTableFooter } from '../shared-components/service-table-footer';
import { AdditionalServiceButton } from './additional-service-button';
import { ServiceButtonMode } from '../service-button-props';
import { ServiceStatusLabel } from '../shared-components/service-status-label';

export interface AdditionalTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
  showButtons?: boolean;
}

export default function AdditionalTable(props: AdditionalTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);
  const service = props.serviceViewDetails;
  const showButtons = props.showButtons ?? true;

  return (
    <StyledTableBorder>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <ServiceStatusLabel active={service.active} acceptanceState={service.acceptanceState} started={service.started} />
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[
            { label: t('navResourceNumber') },
            { label: t('servicePixRelated') },
            { label: t('navResourceName') },
            { label: t('serviceDesc') },
            { label: t('activationDate') },
            { label: t('deactivationDate') },
          ]}
          items={[
            { value: service.navResourceNumber, options: { bold: true } },
            { value: service.currentSettings.pix, type: 'boolean' },
            { value: service.navResourceName },
            { value: service.description, options: { bold: true } },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[{ label: t('settledSubcontractorName') }, { label: t('reactionTime') }]}
          items={[{ value: service.subcontractor }, { value: service.reactionTime }]}
        />
        <ServiceBar
          headers={[
            { label: t('settlementType') },
            { label: t('Projects.ViewProject.clientCollectionPrice') },
            { label: t('Projects.ViewProject.subcontractorCollectionPrice') },
            { label: t('paymentMethodCode') },
            { label: t('separatedInvoice') },
            { label: t('invoicingMethod') },
            { label: t('constantCost') },
            { label: t('organizationAsSide') },
          ]}
          items={[
            { value: translations.common[`settlementType.${service.settlementType}`] },
            priceItem(service.currentSettings.clientCollectionPrice, {
              priceTypeVisible: true,
              translations,
            }),
            priceItem(service.currentSettings.subcontractorCollectionPrice, {
              priceTypeVisible: true,
              translations,
            }),
            { value: translations.common[`paymentMethodCode.${service.paymentMethod}`] },
            { value: service.separateInvoice, type: 'boolean' },
            { value: translations.common[`invoicingMethod.${service.invoicingMethod}`] },
            { value: service.constantCost, type: 'boolean' },
            { value: service.currentSettings.organizationAsSide, type: 'boolean' },
          ]}
        />
        <CollapsedArea collapse={expandCollapseSwitch}>
          <Grid container>
            <Grid item xs={6}>
              <ServiceBar headers={[{ label: t('emailForApplication') }, {}]} items={requestEmailsFields(service, t, props.reloadServices)} />
            </Grid>
            <Grid item xs={6}>
              <ServiceBar headers={[{ label: t('variableAnnotation') }, {}]} items={variableAnnotationsFields(service, t, props.reloadServices)} />
            </Grid>
          </Grid>
        </CollapsedArea>
        <RenderIf true={showButtons}>
          <ServiceTableFooter
            service={service}
            t={t}
            reloadServices={props.reloadServices}
            editButton={
              <AdditionalServiceButton
                serviceButtonMode={ServiceButtonMode.EDIT}
                service={service}
                onSaved={async () => props.reloadServices()}
                reloadServices={props.reloadServices}
              />
            }
            copyButton={
              <AdditionalServiceButton
                serviceButtonMode={ServiceButtonMode.COPY}
                service={service}
                onSaved={async () => props.reloadServices()}
                reloadServices={props.reloadServices}
              />
            }
          />
      </RenderIf>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}
