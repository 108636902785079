import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import React from 'react';
import { Grid } from '@mui/material';
import { Button, Panel, RenderIf } from '@lib/ui-components';
import { CollapsedArea, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import { ServiceBar } from './service-bar';
import { priceItem, requestEmailsFields, scheduleServiceBar, variableAnnotationsFields } from './shared';
import { ServiceTableFooter } from './service-table-footer';
import { ServiceButton } from './service-button';
import { ServiceButtonMode } from '../service-button-props';
import { ServiceStatusLabel } from './service-status-label';

export interface ServiceTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
  showButtons?: boolean;
}

export const ServiceTable = (props: ServiceTableProps) => {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);

  const service = props.serviceViewDetails;

  const showButtons = props.showButtons ?? true;

  return (
    <StyledTableBorder data-service-id={service.serviceId}>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <ServiceStatusLabel active={service.active && service.acceptanceState !== 'INITIALIZED'} acceptanceState={service.acceptanceState} started={service.started} />
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[
            { label: t('wasteCode') },
            { label: t('pix') },
            { label: t('Model.Waste.type') },
            { label: t('ownDescriptionOfWaste') },
            { label: t('resourceName') },
            { label: t('number') },
            { label: t('collectionMethod') },
            { label: t('numberOfContainers') },
            { label: t('forUtilization') },
            { label: t('activationDate') },
            { label: t('deactivationDate') },
          ]}
          items={[
            { value: service.projectWaste.wasteCode, options: { bold: true } },
            { value: service.currentSettings.pix, type: 'boolean' },
            { value: service.projectWaste.wasteDescription, options: { bold: true } },
            { value: service.projectWaste.projectWasteDescription },
            {
              value: service.resourceInBulk ? t('Model.Infrastructure.InfrastructureCategory.IN_BULK') : service.projectInfrastructureService.name,
              options: { bold: true },
            },
            { value: service.resourceInBulk ? '-' : service.infrastructureCount, options: { bold: true } },
            { value: service.collectionInfrastructureService?.name, options: { bold: true } },
            { value: service.collectionInfrastructureCount, options: { bold: true } },
            { value: service.utilization, type: 'boolean' },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('settledSubcontractorName') },
            { label: t('reactionTime') },
            { label: t('settledTransportEntity') },
            { label: t('generatesKpo') },
            { label: t('bdoStatus') },
            { label: t('licensePlates') },
            { label: t('transporterKPO') },
            { label: t('receiverKPO') },
          ]}
          items={[
            { value: service.subcontractor, options: { bold: true } },
            { value: service.reactionTime },
            { value: service.transporter },
            { value: service.generatesKpo, type: 'boolean' },
            { value: translations.common[`bdoStatus.${service.bdoStatus}`] },
            { value: service.licensePlates },
            { value: service.transporterKpo },
            { value: service.receiverKpo },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('settlementType') },
            { label: t('Projects.ViewProject.clientCollectionPrice') },
            { label: t('Projects.ViewProject.subcontractorCollectionPrice') },
            { label: t('Projects.ViewProject.clientTransportPrice') },
            { label: t('Projects.ViewProject.subcontractorTransportPrice') },
            { label: t('logisticMinimum') },
            { label: t('logisticMinimumSurcharge') },
            { label: t('paymentMethodCode') },
            { label: t('separatedInvoice') },
            { label: t('invoicingMethod') },
            { label: t('settlementUnit') },
            { label: t('constantCost') },
            { label: t('organizationAsSide') },
            { label: t('plImport') },
            { label: t('plExport') },
          ]}
          items={[
            { value: translations.common[`settlementType.${service.settlementType}`] },
            service.currentSettings?.pix
              ? {
                  value: t('pix'),
                  options: { bold: true },
                }
              : priceItem(service.currentSettings?.clientCollectionPrice, {
                  priceTypeVisible: true,
                  translations,
                }),
            priceItem(service.currentSettings?.subcontractorCollectionPrice, {
              priceTypeVisible: true,
              translations,
            }),
            priceItem(service.currentSettings?.clientTransportPrice, {
              priceTypeVisible: true,
              translations,
            }),
            priceItem(service.currentSettings?.subcontractorTransportPrice, {
              priceTypeVisible: true,
              translations,
            }),
            { value: service.logisticMinimum },
            { value: service.logisticMinimumSurcharge },
            { value: translations.common[`paymentMethodCode.${service.paymentMethod}`] },
            { value: service.separateInvoice, type: 'boolean' },
            { value: translations.common[`invoicingMethod.${service.invoicingMethod}`] },
            { value: translations.common[`settlementUnit.${service.settlementUnit}`] },
            { value: service.constantCost, type: 'boolean' },
            { value: service.currentSettings?.organizationAsSide, type: 'boolean' },
            { value: service.plImport, type: 'boolean' },
            { value: service.plExport, type: 'boolean' },
          ]}
        />
        {service.serviceType === 'SCHEDULED' && scheduleServiceBar(t, service)}
        <CollapsedArea collapse={expandCollapseSwitch}>
          <ServiceBar headers={[{ label: t('emailForApplication') }, {}]} items={requestEmailsFields(service, t, props.reloadServices)} />
          <ServiceBar headers={[{ label: t('apiLinkEmail') }]} items={[{ value: service.apiLinkEmail || '-' }]} />
          <Grid container>
            <Grid item xs={6}>
              <ServiceBar
                headers={[{ label: `${t('navResourceNumber')} - ${t('clientCollection')}` }, { label: `${t('navResourceName')} - ${t('clientCollection')}` }]}
                items={[{ value: service.clientCollectionNavResourceName || '-' }, { value: service.clientCollectionNavResourceNumber || '-' }]}
              />
            </Grid>
            <Grid item xs={6}>
              <ServiceBar
                headers={[{ label: `${t('navResourceNumber')} - ${t('subcontractorCollection')}` }, { label: `${t('navResourceName')} - ${t('subcontractorCollection')}` }]}
                items={[{ value: service.subcontractorCollectionNavResourceName || '-' }, { value: service.subcontractorCollectionNavResourceNumber || '-' }]}
              />
            </Grid>
          </Grid>
          <ServiceBar headers={[{ label: t('variableAnnotation') }, {}]} items={variableAnnotationsFields(service, t, props.reloadServices)} />
        </CollapsedArea>
        <RenderIf true={showButtons}>
          <ServiceTableFooter
            service={service}
            t={t}
            reloadServices={props.reloadServices}
            editButton={
              <ServiceButton
                serviceButtonMode={ServiceButtonMode.EDIT}
                service={service}
                type={service.serviceType}
                onSaved={async () => props.reloadServices()}
                reloadServices={props.reloadServices}
              />
            }
            copyButton={
              <ServiceButton
                serviceButtonMode={ServiceButtonMode.COPY}
                service={service}
                type={service.serviceType}
                onSaved={async () => props.reloadServices()}
                reloadServices={props.reloadServices}
              />
            }
          />
        </RenderIf>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
};
