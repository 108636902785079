interface EditProps {
  yellowIcon?: boolean;
}

export function Edit({ yellowIcon }: EditProps) {
  return (
    <svg width={yellowIcon ? '21' : '17'} height={yellowIcon ? '21' : '17'} viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' transform='translate(3)'>
      {!yellowIcon && <path d='M7.5 12.5H13.125' stroke='#183362' strokeLinecap='round' strokeLinejoin='round' />}
      <path
        d='M10.3125 2.1874C10.5611 1.93876 10.8984 1.79907 11.25 1.79907C11.4241 1.79907 11.5965 1.83337 11.7574 1.89999C11.9182 1.96662 12.0644 2.06428 12.1875 2.1874C12.3106 2.31051 12.4083 2.45667 12.4749 2.61753C12.5415 2.77838 12.5758 2.95079 12.5758 3.1249C12.5758 3.29901 12.5415 3.47141 12.4749 3.63227C12.4083 3.79313 12.3106 3.93928 12.1875 4.0624L4.375 11.8749L1.875 12.4999L2.5 9.9999L10.3125 2.1874Z'
        stroke={yellowIcon ? '#F2C94C' : '#183362'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
