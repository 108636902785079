import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyBranchViewModel } from '@lib/api-interface';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';

export const useCompanyBranchView = (branchId: string) => {
  const queryKey = ['companyBranchViews', branchId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return ReactQueryHateoasClientService.findOneByAggregateId<CompanyBranchViewModel>('companyBranchViews', 'branchId', branchId);
    },
    {
      staleTime: 5000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};
