import { useTranslation } from 'react-i18next';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectBranchSettings, useProjectBranchView } from '@data-source-hooks';
import { FetchErrorIndicator } from '../../../libs/ui-components/components/fetch-error-indicator';
import { Pickups } from './pickups';
import { ProjectBranchContextProvider, ProjectBranchSettingsContextProvider } from '@context';
import { ProjectBranchHeader } from '../projects/branches/project-branch-header';

export const PickupsBranch = () => {
  const { t } = useTranslation();
  const pathParams = useParams();
  const projectBranchId = pathParams['projectBranchId']?.substring(1);
  const [projectBranchQuery] = useProjectBranchView(projectBranchId);
  const [projectBranchSettingsQuery] = useProjectBranchSettings(projectBranchId);

  if (projectBranchQuery.isLoading || projectBranchSettingsQuery.isLoading) {
    return <div>{t('Common.loading')}</div>;
  }
  if (projectBranchQuery.isError || projectBranchSettingsQuery.isError) {
    return <FetchErrorIndicator error={projectBranchQuery.error} />;
  }
  const projectBranchView = projectBranchQuery.data;
  const projectBranchSettings = projectBranchSettingsQuery.data;

  return (
    <>
      <ProjectBranchHeader projectBranchView={projectBranchView} />
      <div style={{ marginTop: '1rem' }}></div>
      <ProjectBranchContextProvider value={projectBranchView}>
        <ProjectBranchSettingsContextProvider value={projectBranchSettings}>
          <Pickups projectBranchView={projectBranchView} />
        </ProjectBranchSettingsContextProvider>
      </ProjectBranchContextProvider>
    </>
  );
};
