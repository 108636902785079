import React from 'react';
import { useTranslation } from 'react-i18next';
import { BdoApiPage } from './bdo-api/bdo-api.page';
import { Infrastructure } from './infrastructure/infrastructure';
import { Wastes } from './wastes/wastes';
import { ExchangeRates } from './exchange-rates/exchange-rates';
import { FixedAssets } from './fixed-assets/fixed-assets';
import { NavResources } from './nav-resources/nav-resources';
import { Tabs } from '@lib/ui-components';
import { SequencesPage } from './sequences/sequences.page';

export const GlobalSettings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        panels={[
          { label: t('infrastructure'), component: <Infrastructure /> },
          { label: t('GlobalSettings.wastes'), component: <Wastes /> },
          { label: t('GlobalSettings.fixedAssets'), component: <FixedAssets /> },
          { label: t('GlobalSettings.navResources'), component: <NavResources /> },
          { label: t('bdoApi'), component: <BdoApiPage /> },
          { label: t('GlobalSettings.exchangeRates'), component: <ExchangeRates /> },
          { label: t('sequences'), component: <SequencesPage /> },
        ]}
      />
    </div>
  );
};
