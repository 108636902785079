import { Tabs } from '@lib/ui-components';
import { Map } from './map';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyRole } from '@lib/api-interface';

export const MapsContainer = () => {
  const { t } = useTranslation();

  return (
    <Tabs
      panels={[
        { label: t('Maps.clients'), component: <Map companyRole={CompanyRole.CLIENT} /> },
        { label: t('Maps.subcontractors'), component: <Map companyRole={CompanyRole.SUBCONTRACTOR} /> },
      ]}
    />
  );
};
