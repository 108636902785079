import { TFunction } from 'react-i18next';
import { Stack } from '@mui/material';
import React from 'react';
import { GreyButton } from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { AggregateCommandButton } from '@lib/ui-components';
import { QUANTITIES_PRECISION } from '@lib/api-interface';

export interface InfrastructureServicesSettlementTableFooterProps {
  settlement: any;
  t: TFunction<'common', undefined>;
  reloadSettlements: () => void;
}

export const InfrastructureServicesSettlementTableFooter = ({ settlement, t, reloadSettlements }: InfrastructureServicesSettlementTableFooterProps) => {
  function uploadNoncomplianceProtocol() {
    return undefined; // TODO: implement
  }

  if (settlement.settlementStatus === 'INVOICED') {
    return <></>;
  }

  if (settlement.settlementStatus === 'CANCELLED') {
    return <GreyButton label={t('uploadNoncomplianceProtocol')} onClick={() => uploadNoncomplianceProtocol()} />;
  }

  return (
    <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6}>
      <AggregateCommandButton
        aggregateName='SettlementAggregate'
        aggregateId={settlement.settlementId}
        commandName='cancel'
        buttonLabel={t('cancelSettlement')}
        forceConfirmation={true}
        onSaved={async () => await reloadSettlements()}
      />
      <AggregateCommandButton
        aggregateName='SettlementAggregate'
        aggregateId={settlement.settlementId}
        commandName='editInfrastructureServiceSettlement'
        buttonLabel={t('edit')}
        fields={[
          {
            label: t('quantityReceived'),
            field: 'infrastructureServiceQuantities',
            type: 'numeric',
            numericPrecision: QUANTITIES_PRECISION,
            defaultValue: settlement.infrastructureServiceQuantities,
          },
        ]}
        onSaved={async () => await reloadSettlements()}
      />
    </Stack>
  );
};
