import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { CompanyAddress, CompanyBranchViewModel, CompanyRole } from '@lib/api-interface';
import { RenderIf, ToggleButtons } from '@lib/ui-components';
import { HateoasRestApiClientService } from '@lib/common-sdk';
import { useTranslation } from 'react-i18next';
import { DataSearchField } from '../../../libs/ui-components/components/data-table/table-search-field/data-search-field';
import { getMarkerIcon, MarkerIconColor } from './marker';
import { searchLocations } from './map-search';

export interface Location {
  id: number;
  name?: string;
  companyName?: string;
  lat: number;
  lon: number;
  central?: boolean;
  nip?: string;
  regAddress?: string;
  companyId?: string;
  branchId?: string;
}

interface MapProps {
  companyRole: CompanyRole;
}

export const Map = (props: MapProps) => {
  const { t } = useTranslation();
  const [sortedLocations, setSortedLocations] = React.useState<Location[]>([]);
  const [initLocations, setInitLocations] = React.useState<Location[]>([]);
  const [activeClientTabIndex, setActiveClientTabIndex] = React.useState<number>(0);
  const [activeSubcontractorTabIndex, setActiveSubcontractorTabIndex] = React.useState<number>(0);
  const [searchField, setSearchField] = React.useState<string>('');

  function getSearchOption() {
    if (props.companyRole === CompanyRole.SUBCONTRACTOR) {
      switch (activeSubcontractorTabIndex) {
        case 1:
          return 'forKpoReceiver';
        case 2:
          return 'forKpoTransporter';
      }
    }
  }

  const fillLocations = (data: Location[]) => {
    setSearchField(searchField + ' ');
    setInitLocations(data);
  };
  const fetchLocations = () => {
    HateoasRestApiClientService.findAll<CompanyBranchViewModel>(
      'companyBranchViews',
      {
        active: props.companyRole === CompanyRole.CLIENT ? true : undefined,
        roleContractor: props.companyRole === CompanyRole.SUBCONTRACTOR && activeSubcontractorTabIndex === 0 ? true : undefined,
        roleClient: props.companyRole === CompanyRole.CLIENT ? true : undefined,
        central: props.companyRole === CompanyRole.CLIENT && activeClientTabIndex === 1 ? true : undefined,
      },
      'id',
      { search: getSearchOption() },
    ).then((response) => {
      if (!response.response) return;
      fillLocations(
        response.response
          .filter((location: CompanyBranchViewModel) => location && location.latitude && location.longitude)
          .map((location: CompanyBranchViewModel) => ({
            id: location.id as number,
            name: location.name,
            lat: location.latitude!,
            lon: location.longitude!,
            central: location.central,
            companyName: location.companyName,
            nip: location.nip,
            regAddress: location.registrationAddress ? new CompanyAddress(location.registrationAddress).addressAsString : '',
            companyId: location.companyId,
            branchId: location.branchId,
          })),
      );
    });
  };
  const toggleButtons = React.useMemo(() => {
    if (props.companyRole === CompanyRole.CLIENT) {
      return (
        <ToggleButtons
          labels={[t('Maps.clients.clientsAndBranches'), t('Maps.clients.headquarters')]}
          activeIndex={activeClientTabIndex}
          onChange={(index) => setActiveClientTabIndex(index)}
          withUseEffect={true}
        />
      );
    }
    return (
      <ToggleButtons
        labels={[t('Maps.subcontractors.collectingAndTransportingSubjects'), t('Maps.subcontractors.collectingSubjects'), t('Maps.subcontractors.transportingSubjects')]}
        activeIndex={activeSubcontractorTabIndex}
        onChange={(index) => setActiveSubcontractorTabIndex(index)}
        withUseEffect={true}
      />
    );
  }, [props.companyRole, activeClientTabIndex, activeSubcontractorTabIndex]);

  const map = React.useMemo(
    () => (
      <MapContainer center={[52.22977, 21.01178]} zoom={7} style={{ height: 'calc(100vh - 180px)', zIndex: 0 }}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&amp;copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors |
          &amp;copy; <a href="https://opencagedata.com/">OpenCage</a> geocoding |
          <a href="https://github.com/pointhi/leaflet-color-markers?tab=readme-ov-file/">Markers</a> images'
          detectRetina={true}
        />
        {sortedLocations.map((location) => (
          <Marker key={location.id} position={[location.lat, location.lon]} icon={getMarkerIcon(location.central ? MarkerIconColor.RED : MarkerIconColor.YELLOW)}>
            <Popup>
              <b>
                <RenderIf true={props.companyRole === CompanyRole.CLIENT}>
                  {location.companyName} {location.name}
                </RenderIf>
                <RenderIf true={props.companyRole === CompanyRole.SUBCONTRACTOR}>
                  <a onClick={() => window.open(`/companies/!${location.companyId}/!${location.branchId}`, '_blank')} style={{ cursor: 'pointer' }}>
                    {`${location.companyName} ${location.name}`}
                  </a>
                </RenderIf>
              </b>
              <br />
              {location.nip} <br />
              {location.regAddress}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    ),
    [sortedLocations],
  );

  React.useMemo(() => {
    if (!searchField) {
      setSortedLocations(initLocations);
      return;
    }
    setSortedLocations(searchLocations(initLocations, searchField.trim()));
  }, [searchField]);

  React.useEffect(() => {
    fetchLocations();
  }, [activeClientTabIndex, activeSubcontractorTabIndex, props]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          margin: '0.5rem',
          alignItems: 'center',
        }}
      >
        {toggleButtons}
        <div style={{ position: 'absolute', right: 0 }}>
          <DataSearchField
            onFiltersChange={(filter) => {
              setSearchField(filter);
            }}
          />
        </div>
      </div>
      {map}
    </>
  );
};
