export class DataModelResultsDto<ModelType> {
    failed: boolean;
    errorStatusCode?: number;
    errorMessage?: string | number;
    error?: string | number;
    response?: ModelType;

    constructor(failed: boolean, errorStatusCode?: number, error?: string, errorMessage?: string, response?: ModelType) {
        this.failed = failed;
        this.errorStatusCode = errorStatusCode;
        this.errorMessage = errorMessage;
        this.error = error;
        this.response = response;
    }
}
