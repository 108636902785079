import React from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Dialog, RenderIf, Snackbar } from '@lib/ui-components';
import { FinancialReportType } from '@lib/api-interface';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../state/UserState';
import { useWebSocketState } from '../state/web-socket-state';
import { DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface ReportGenerationMessageModel {
  id: string;
  message: string;
  fileName?: string;
}

export interface GeneratedReportMessageModel {
  id: string;
  message: string;
  reportType: FinancialReportType;
  fileName?: string;
  timestamp: Date;
}

export interface BranchesImportMessageModel {
  id: string;
  message: string;
  created: number;
  updated: number;
  errorRows: { [key: number]: string };
  isExcelImportError: boolean;
  isIOError: boolean;
}

export interface BaseMessageModel {
  id: string;
  message: string;
  isErrorMessage?: boolean;
}

export interface ReceivedQuantitiesImportMessageModel {
  isError: boolean;
  rowNumber?: number;
  columnAddress?: string;
}

export interface UpdateCompanyMpdBdoMessageModel {
  id: string;
  message: string;
  isNipMissing: boolean;
  companyNotFoundInBdo: boolean;
  newBdoCompanyIdentificationNumber?: string;
  updatedBranches?: { [key: string]: string };
  usedByCompanyName?: string;
}

export function WebSocketService() {
  const baseUrl = process.env['REACT_APP_API_URL'];
  const { t } = useTranslation();
  const userState = useUserState();
  const webSocketState = useWebSocketState();
  const [reportGenerationModel, setReportGenerationModel] = React.useState<ReportGenerationMessageModel>();
  const [generatedReportModel, setGeneratedReportModel] = React.useState<GeneratedReportMessageModel>();
  const [branchesImportModel, setBranchesImportModel] = React.useState<BranchesImportMessageModel>();
  const [baseModel, setBaseModel] = React.useState<BaseMessageModel>();
  const [updateCompanyMpdBdoModel, setUpdateCompanyMpdBdoModel] = React.useState<UpdateCompanyMpdBdoMessageModel>();
  const [receivedQuantitiesImportMessageModel, setReceivedQuantitiesImportMessageModel] = React.useState<ReceivedQuantitiesImportMessageModel>();

  function resolveResponseForModel() {
    if (reportGenerationModel !== undefined) {
      webSocketState.setReportGenerationStartedRefreshKey();
      const notificationMessage = t('reportGenerationStarted') + ': ' + reportGenerationModel.fileName;
      return <Snackbar message={notificationMessage} severity='info' onlyManuallyClosed={false} onClose={() => setReportGenerationModel(undefined)} />;
    }
    if (branchesImportModel !== undefined) {
      webSocketState.setBranchesImportedRefreshKey();
      if (branchesImportModel.isIOError) {
        const notificationMessage = t('branchesImportIOError');
        return <Snackbar message={notificationMessage} severity='error' onlyManuallyClosed={true} onClose={() => setBranchesImportModel(undefined)} />;
      } else {
        return <BranchesImportDialog branchesImportModel={branchesImportModel} onClose={() => setBranchesImportModel(undefined)} />;
      }
    }
    if (generatedReportModel !== undefined) {
      webSocketState.setReportGeneratedRefreshKey();
      if (generatedReportModel.fileName) {
        const notificationMessage = generatedReportModel.fileName + ' ' + t('reportGenerated');
        return <Snackbar message={notificationMessage} severity='success' onlyManuallyClosed={true} onClose={() => setGeneratedReportModel(undefined)} />;
      }
      const notificationMessage = t('reportNotGeneratedMessage');
      return <Snackbar message={notificationMessage} severity='error' onlyManuallyClosed={true} onClose={() => setGeneratedReportModel(undefined)} />;
    }
    if (baseModel !== undefined) {
      webSocketState.setSettlementImportRefreshKey();
      return (
        <Snackbar
          message={baseModel.isErrorMessage ? t('settlementExportError') : t('settlementImportFinished')}
          severity={baseModel.isErrorMessage ? 'error' : 'info'}
          onlyManuallyClosed={true}
          onClose={() => setBaseModel(undefined)}
        />
      );
    }
    if (updateCompanyMpdBdoModel !== undefined) {
      webSocketState.setUpdateCompanyMpdBdoRefreshKey();
      if (updateCompanyMpdBdoModel.usedByCompanyName) {
        const notificationMessage = t('updateCompanyMpdBdo.usedByOtherCompany', { companyName: updateCompanyMpdBdoModel.usedByCompanyName });
        return <Snackbar message={notificationMessage} severity='error' onlyManuallyClosed={true} onClose={() => setUpdateCompanyMpdBdoModel(undefined)} />;
      }
      if (updateCompanyMpdBdoModel.isNipMissing) {
        return <Snackbar message={t('updateCompanyMpdBdo.nipMissing')} severity='error' onlyManuallyClosed={true} onClose={() => setUpdateCompanyMpdBdoModel(undefined)} />;
      } else if (updateCompanyMpdBdoModel.companyNotFoundInBdo) {
        return (
          <Snackbar message={t('updateCompanyMpdBdo.companyNotFoundInBdo')} severity='error' onlyManuallyClosed={true} onClose={() => setUpdateCompanyMpdBdoModel(undefined)} />
        );
      }
      return <UpdateCompanyMpdBdoDialog updateCompanyMpdBdoModel={updateCompanyMpdBdoModel} onClose={() => setUpdateCompanyMpdBdoModel(undefined)} />;
    }
    if (receivedQuantitiesImportMessageModel !== undefined) {
      webSocketState.setRefreshReceivedQuantitiesImport();
      if (receivedQuantitiesImportMessageModel.isError) {
        let notificationMessage;
        if (receivedQuantitiesImportMessageModel.rowNumber && receivedQuantitiesImportMessageModel.columnAddress) {
          notificationMessage = t('receivedQuantitiesImport.errorWithRowColumn', {
            rowNumber: receivedQuantitiesImportMessageModel.rowNumber,
            columnAddress: receivedQuantitiesImportMessageModel.columnAddress,
          });
        } else if (receivedQuantitiesImportMessageModel.rowNumber) {
          notificationMessage = t('receivedQuantitiesImport.errorWithRow', {
            rowNumber: receivedQuantitiesImportMessageModel.rowNumber,
          });
        } else {
          notificationMessage = t('receivedQuantitiesImport.error');
        }
        return <Snackbar message={notificationMessage} severity='error' onlyManuallyClosed={true} onClose={() => setReceivedQuantitiesImportMessageModel(undefined)} />;
      }
      const notificationMessage = t('receivedQuantitiesImport.success');
      return <Snackbar message={notificationMessage} severity='success' onlyManuallyClosed={true} onClose={() => setReceivedQuantitiesImportMessageModel(undefined)} />;
    }
    return <></>;
  }

  const notification = React.useMemo(() => {
    return resolveResponseForModel();
  }, [reportGenerationModel, generatedReportModel, branchesImportModel, baseModel, updateCompanyMpdBdoModel, receivedQuantitiesImportMessageModel, t]);
  React.useEffect(() => {
    const socket = new SockJS(baseUrl + '/api/ws?userId=' + userState.userId);
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
      stompClient.subscribe('/user/report-generation-started', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: ReportGenerationMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
          fileName: parsedMessage.fileName,
        };
        setReportGenerationModel(messageModel);
      });
      stompClient.subscribe('/user/report-generated', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: GeneratedReportMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
          reportType: parsedMessage.reportType,
          fileName: parsedMessage.fileName,
          timestamp: new Date(parsedMessage.timestamp),
        };
        setGeneratedReportModel(messageModel);
      });
      stompClient.subscribe('/user/settlement-import', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: BaseMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
        };
        setBaseModel(messageModel);
      });
      stompClient.subscribe('/user/branches-import', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: BranchesImportMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
          created: parsedMessage.created,
          updated: parsedMessage.updated,
          errorRows: parsedMessage.errorRows,
          isExcelImportError: parsedMessage.excelImportError,
          isIOError: parsedMessage.ioerror,
        };
        setBranchesImportModel(messageModel);
      });
      stompClient.subscribe('/user/settlement-export', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: BaseMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
        };
        setBaseModel(messageModel);
      });
      stompClient.subscribe('/user/update-mpd-bdo', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);
        const messageModel: UpdateCompanyMpdBdoMessageModel = {
          id: parsedMessage.id,
          message: parsedMessage.message,
          isNipMissing: parsedMessage.nipMissing,
          companyNotFoundInBdo: parsedMessage.companyNotFoundInBdo,
          newBdoCompanyIdentificationNumber: parsedMessage.newBdoCompanyIdentificationNumber,
          updatedBranches: parsedMessage.updatedBranches,
          usedByCompanyName: parsedMessage.usedByCompanyName,
        };
        setUpdateCompanyMpdBdoModel(messageModel);
      });
      stompClient.subscribe('/user/received-quantities/import', (stompMessage) => {
        const parsedMessage = JSON.parse(stompMessage.body);

        function isSuccessResponse(message: any) {
          return !('rowNumber' in message);
        }

        if (isSuccessResponse(parsedMessage)) {
          const model: ReceivedQuantitiesImportMessageModel = {
            isError: false,
          };
          setReceivedQuantitiesImportMessageModel(model);
        } else {
          const model: ReceivedQuantitiesImportMessageModel = {
            isError: true,
            rowNumber: parsedMessage.rowNumber,
            columnAddress: parsedMessage.columnAddress,
          };
          setReceivedQuantitiesImportMessageModel(model);
        }
      });
    });

    return () => {
      if (stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, []);

  return notification;
}

function BranchesImportDialog({ branchesImportModel, onClose }: { branchesImportModel: BranchesImportMessageModel; onClose: () => void }) {
  const { t } = useTranslation();
  const createdCount = branchesImportModel.created;
  const updatedCount = branchesImportModel.updated;
  const errorRows = branchesImportModel.errorRows;
  const notificationMessage = t('branchesImported.content', { createdCount, updatedCount });

  return (
    <Dialog open={true} maxWidth='md' fullWidth={true} onClose={onClose}>
      <DialogTitle>
        {t('branchesImported.title')}
        <IconButton
          aria-label='close'
          onClick={onClose}
          data-test-id={'dialog-close-btn'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{notificationMessage}</DialogContentText>
        <RenderIf true={errorRows && Object.keys(errorRows).length > 0}>
          <DialogContentText>{t('branchesImported.errorRowsInfo')}</DialogContentText>
          {Object.entries(errorRows).map(([row, errorMessage]: [string, string], index: number) => (
            <DialogContentText key={index}>{`${row}: ${errorMessage}`}</DialogContentText>
          ))}
        </RenderIf>
      </DialogContent>
    </Dialog>
  );
}

function UpdateCompanyMpdBdoDialog({ updateCompanyMpdBdoModel, onClose }: { updateCompanyMpdBdoModel: UpdateCompanyMpdBdoMessageModel; onClose: () => void }) {
  const { t } = useTranslation();
  const newBdoCompanyIdentificationNumber = updateCompanyMpdBdoModel.newBdoCompanyIdentificationNumber;
  const notificationMessage = t('updateCompanyMpdBdo.content', { newBdoCompanyIdentificationNumber });

  return (
    <Dialog open={true} maxWidth='md' fullWidth={true} onClose={onClose}>
      <DialogTitle>
        {t('updateCompanyMpdBdo.title')}
        <IconButton
          aria-label='close'
          onClick={onClose}
          data-test-id={'dialog-close-btn'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{notificationMessage}</DialogContentText>
        {updateCompanyMpdBdoModel.updatedBranches && Object.keys(updateCompanyMpdBdoModel.updatedBranches).length > 0 && (
          <>
            <DialogContentText>{t('updateCompanyMpdBdo.updatedBranchesInfo')}</DialogContentText>
            {Object.entries(updateCompanyMpdBdoModel.updatedBranches).map(([branchName, branchMpd], index) => (
              <DialogContentText key={index}>{`${branchName}: ${branchMpd}`}</DialogContentText>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
