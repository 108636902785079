import React from 'react';
import { Button, RenderIf } from '@lib/ui-components';
import { LoadingIndicator } from './loading-indicator';
import { FetchErrorIndicator } from './fetch-error-indicator';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export interface ExtractedItems<T> {
  data: T;
  currentPage: number;
  hasNextPage: boolean;
  totalItems: number;
}

export interface InfiniteScrollRendererProps<RowType> {
  query: UseInfiniteQueryResult<ExtractedItems<RowType[]>>;
  rowRenderer: (row: RowType) => JSX.Element;
  headerRenderer?: (rows: RowType[]) => JSX.Element;
}

export const InfiniteScrollRenderer = <T,>({ query, rowRenderer, headerRenderer }: InfiniteScrollRendererProps<T>) => {
  const { t } = useTranslation();

  return (
    <>
      {headerRenderer?.(query.data?.pages.flatMap((value) => value.data) || [])}
      {query.data?.pages
        ?.flatMap((value) => value.data)
        ?.map((row) => {
          return rowRenderer(row);
        })}
      <RenderIf true={query.isFetching}>
        <LoadingIndicator />
      </RenderIf>
      <RenderIf true={query.error != null}>
        <FetchErrorIndicator error={query.error} />
      </RenderIf>
      <RenderIf true={query.data?.pages[query.data?.pages?.length - 1 ?? 0]?.hasNextPage}>
        <Button onClick={() => query.fetchNextPage()} label={t('more')} />
      </RenderIf>
    </>
  );
};
