import axios from 'axios';
import { ErrorResult } from '../domain/vo/ErrorResult';

export class ApiClientService {
  public static async post<CommandType, ReturnType>(url: string, command: CommandType, apiUrl = process.env['REACT_APP_API_URL']): Promise<ReturnType | ErrorResult> {
    try {
      const response = await axios.post(`${apiUrl}${url}`, command);
      return response.data;
    } catch (error) {
      return this.formatError(error);
    }
  }

  static async delete(url: string, pathParam: string, apiUrl = process.env['REACT_APP_API_URL']) {
    try {
      await axios.delete(`${apiUrl}${url}/${pathParam}`);
      return null;
    } catch (error) {
      return this.formatError(error);
    }
  }

  private static formatError(error: any) {
    return {
      code: error.response?.data?.code ?? '',
      context: error.response?.data?.context,
      httpStatus: error.response.status,
    };
  }
}
