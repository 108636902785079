import { addDays, eachDayOfInterval, eachWeekOfInterval, endOfMonth, format, parse, startOfMonth } from 'date-fns';
import { Children, useEffect, useState } from 'react';
import { dateFormat, yearMonthFormat } from '@lib/api-interface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCalendarContainer = styled.div``;
const StyledSheet = styled.div`
  border: solid 1px #0001;
  padding: 1rem;
  border-radius: 8px;
`;
const StyledSheetHeader = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const StyledSheetHeaderValue = styled.p`
  flex: 1;
  text-align: end;
  padding: 0.2rem;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  overflow: hidden;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  border-left: solid 1px #00000044;
  :last-of-type {
    border-bottom: solid 1px #00000044;
  }
`;
const StyledCell = styled.div`
  border-top: solid 1px #00000044;
  border-right: solid 1px #00000044;
  flex: 1;
  padding: 0.2rem;
  min-width: 11rem;
  min-height: 10rem;
`;
const StyledCellDate = styled.div`
  text-align: end;
`;

export interface CalendarProps {
  yearMonth: string;
  components?: { [key: string]: JSX.Element | JSX.Element[] };
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export const Calendar = (props: CalendarProps) => {
  const [rows, setRows] = useState(new Array<Array<JSX.Element>>());
  const { t } = useTranslation();

  const weekStartsOn = props.weekStartsOn === undefined ? 1 : props.weekStartsOn;
  const headerValues = [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')];
  for (let i = 0; i < weekStartsOn; i++) {
    headerValues.push(headerValues.shift()!);
  }

  function createCell(date: Date): JSX.Element {
    const dateString = format(date, dateFormat);
    return (
      <StyledCell>
        <StyledCellDate>{date.getDate()}</StyledCellDate>
        {props.components && props.components[dateString] && <div>{props.components[dateString]}</div>}
      </StyledCell>
    );
  }

  function createRow(elements: JSX.Element[]): JSX.Element {
    return <StyledRow>{elements}</StyledRow>;
  }

  useEffect(() => {
    const firstMonthDay = startOfMonth(parse(props.yearMonth, yearMonthFormat, new Date()));
    const lastMonthDay = endOfMonth(firstMonthDay);
    const newRows = eachWeekOfInterval(
      {
        start: firstMonthDay,
        end: lastMonthDay,
      },
      { weekStartsOn: props.weekStartsOn === undefined ? 1 : props.weekStartsOn },
    ).map((firstWeekDay) =>
      eachDayOfInterval({
        start: firstWeekDay,
        end: addDays(firstWeekDay, 6),
      }).map(createCell),
    );
    setRows(newRows);
  }, [props.yearMonth, props.components]);

  return (
    <StyledCalendarContainer>
      <StyledSheet>
        <StyledSheetHeader>{Children.toArray(headerValues.map((headerValue) => <StyledSheetHeaderValue>{headerValue}</StyledSheetHeaderValue>))}</StyledSheetHeader>
        {Children.toArray(rows.map(createRow))}
      </StyledSheet>
    </StyledCalendarContainer>
  );
};
