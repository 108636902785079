import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';
import { InfrastructureCategory, ProjectInfrastructureOwnerEnum } from '@lib/api-interface';

export class ProjectBranchInfrastructureServiceSettings {
  @Expose()
  activeFromDate?: string;
  @Expose()
  infrastructureCount?: number;
  @Expose()
  clientLease?: number;
  @Expose()
  subcontractorLease?: number;
}

export class ProjectBranchInfrastructureServiceViewModel extends BaseModel {
  @Expose()
  projectBranchId?: string; // Aggregate id
  @Expose()
  projectBranchInfrastructureServiceId?: string; // aggregate - nested - id (nested in branch aggregate)
  @Expose()
  projectId?: string; // Aggregate id
  @Expose()
  projectInfrastructureId?: string; // aggregate - nested - id (nested in project aggregate)
  @Expose()
  organizationInfrastructureId?: string; // aggregate - nested - id (nested in project aggregate)
  @Expose()
  subcontractorCompanyBranchId?: string; // branch aggregate id
  @Expose()
  category?: InfrastructureCategory;
  @Expose()
  name?: string;
  @Expose()
  description?: string;
  @Expose()
  mass?: Array<number>;
  @Expose()
  volume?: number;
  @Expose()
  owner?: ProjectInfrastructureOwnerEnum;
  @Expose()
  subcontractorName?: string;
  @Expose()
  fixedAsset?: boolean;
  @Expose()
  shared?: boolean;
  @Expose()
  commune?: boolean;
  @Expose()
  collectionMethod?: boolean;
  @Expose()
  activationDate?: string;
  @Expose()
  deactivationDate?: string;
  @Expose()
  resource?: boolean;
  @Expose()
  navResourceId?: string;
  @Expose()
  navResourceName?: string;
  @Expose()
  navResourceNumber?: string;
  @Expose()
  settings?: Array<ProjectBranchInfrastructureServiceSettings>;
  @Expose()
  currentSettings?: ProjectBranchInfrastructureServiceSettings;
  @Expose()
  organizationAsSide?: boolean;
  @Expose()
  estimatedMass?: string;

  isGatheringResource() {
    return (
      this.category !== InfrastructureCategory.BALER &&
      this.category !== InfrastructureCategory.STATIONARY_PRESS &&
      this.category !== InfrastructureCategory.IN_BULK &&
      this.category !== InfrastructureCategory.OTHER
    );
  }

  _formatDate(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}
