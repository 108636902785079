import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Panel, ToggleButtons } from '@lib/ui-components';
import MonthlyPayoffTab from './monthly-payoff-tab';
import SubcontractorsAgreementsTab from './subcontractors-agreements-tab';
import { ProjectView } from '@lib/api-interface';

export interface SubcontractorsTab {
  project: ProjectView;
}

export const SubcontractorsTab = (props: SubcontractorsTab) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);

  return (
    <Panel borderType='raised'>
      <HStack align='center'>
        <ToggleButtons
          labels={[t('Model.ProjectSubcontractors.monthlyPayoff'), t('Model.ProjectSubcontractors.agreements')]}
          activeIndex={activePage}
          onChange={(index) => setActivePage(index)}
        />
      </HStack>
      {activePage === 0 && <MonthlyPayoffTab />}
      {activePage === 1 && <SubcontractorsAgreementsTab />}
    </Panel>
  );
};
