import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxLabel, EnumLabel, Panel, TextLabel } from '@lib/ui-components';
import { ProjectCompanyBranchModel, ProjectType, ProjectView } from '@lib/api-interface';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';
import { useQuery } from '@tanstack/react-query';

export interface ProjectInfoPanelProps {
  project: ProjectView;
}

export const ProjectInfoPanel = (props: ProjectInfoPanelProps) => {
  const { t } = useTranslation();
  const [companyGroup, setCompanyGroup] = useState(false);

  const projectCompanyQuery = useQuery(['projectCompanyViews', props.project.projectId], () =>
    ReactQueryHateoasClientService.findAll<ProjectCompanyBranchModel>('projectCompanyViews', {
      projectId: props.project.projectId,
      deleted: false,
    }),
  );

  React.useEffect(() => {
    if (projectCompanyQuery.isSuccess) {
      setCompanyGroup(projectCompanyQuery.data.length > 1);
    }
  }, [projectCompanyQuery]);

  return (
    <Panel borderType='raised' direction={'row'} justifyContent='left' gap={'3rem'}>
      <Panel borderType='none' direction={'column'} justifyContent='left' gap='1rem'>
        <TextLabel label={t('projectNum')} value={props.project.projectNumber} highlight={true} />
        <TextLabel label={t('nip')} value={props.project.nip} />
      </Panel>

      <Panel borderType='none' direction={'column'} justifyContent='left' gap='1rem'>
        <EnumLabel
          value={props.project.type}
          enumOptions={[
            { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
            { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
            { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
          ]}
        />
        <TextLabel label={t('bdo')} value={props.project.bdo} />
      </Panel>

      <Panel borderType='none' direction={'column'} justifyContent='left' gap='1rem'>
        <TextLabel label={t('name')} value={props.project.name} highlight={true} />
        <TextLabel label={t('Model.Project.mainCompanyName')} value={props.project.mainCompanyBranchName} />
      </Panel>

      <Panel borderType='none' direction='column' justifyContent='end'>
        <CheckboxLabel label={t('Model.Company.groupOfCompanies')} value={companyGroup} />
      </Panel>
    </Panel>
  );
};
