import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DataTable, Panel, RenderIf, Toolbar } from '@lib/ui-components';
import { ProjectBranchViewModel, ProjectView } from '@lib/api-interface';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { FormInputTableSelectByApi } from '../../../libs/ui-components/components/form/form-input-table-select-by-api';

export const BdoRejectedCardsPage = () => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = React.useState<ProjectView | null>(null);
  const [tableVisible, setTableVisible] = React.useState(false);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { control, setValue, clearErrors } = useForm<any>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (selectedProject != null) {
      setTableVisible(true);
      setTableReloadKey(tableReloadKey + 1);
    } else {
      setTableVisible(false);
    }
  }, [selectedProject]);

  function handleProjectChange(row: ProjectView) {
    setSelectedProject(row);
  }

  function showCardsForBranchHandler(row: ProjectBranchViewModel) {
    navigate(`!${row.projectId!}/!${row.branchId!}`);
    return Promise.resolve(undefined);
  }

  return (
    <Panel header={t('bdoRejectedCards')} borderType='raised'>
      <Panel direction='row' gap='3rem' contentMarginTop='1.5rem' contentMarginLeft='1rem'>
        <FormInputTableSelectByApi
          apiPostEndpoint='/api/bdo/projects-with-bdo-kpo-update'
          label={t('projectNum')}
          dialogTitle={t('projectNumber')}
          displayFormat={'{projectNumber}'}
          name={'project'}
          columns={[
            { key: 'projectNumber', label: t('projectNumber'), type: 'text' },
            { key: 'name', label: t('projectName'), type: 'text' },
          ]}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          onRowSelected={handleProjectChange}
        />
      </Panel>
      <RenderIf true={tableVisible}>
        <Toolbar
          right={[<Button onClick={() => navigate(`!${selectedProject?.projectId}`)} label={t('showAllCards')} variant='contained' color={'info'} startIcon={<SearchIcon />} />]}
        />
        <Panel>
          <DataTable
            refreshKey={tableReloadKey}
            modelDef={{ modelName: 'projectBranchViews', modelClass: ProjectBranchViewModel }}
            fetchFilters={{
              projectId: selectedProject?.projectId,
              deleted: false,
            }}
            columns={[
              {
                key: 'companyName',
                label: t('companyName'),
                type: 'text',
              },
              {
                key: 'companyBranchName',
                label: t('branch'),
                type: 'text',
              },
              {
                key: 'registrationAddress.town',
                label: t('locality'),
                type: 'text',
              },
              {
                key: 'registrationAddress.street',
                label: t('street'),
                type: 'text',
              },
              {
                key: 'registrationAddress.houseNum',
                label: t('houseNum'),
                type: 'text',
              },
              {
                key: 'registrationAddress.province',
                label: t('province'),
                type: 'text',
              },
              {
                key: 'activeBdo',
                label: t('activeBdo'),
                type: 'boolean',
                align: 'center',
              },
            ]}
            rowOptions={[
              {
                renderer: (row: ProjectBranchViewModel) => <Button label={t('showCards')} color='primary' variant='text' onClick={async () => showCardsForBranchHandler(row)} />,
              },
            ]}
          />
        </Panel>
      </RenderIf>
    </Panel>
  );
};
