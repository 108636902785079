import { ReportGenerationStatusTypeEnum } from '../../../libs/api-interface/models/report/report-generation-status-type.enum';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { GetContentButton } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportViewModel } from '../../../libs/api-interface/models/report/report.model';

interface TableRowOptionsButton {
  label: string;
  icon: JSX.Element;
  isDisabled?: boolean;
}

export const ReportRowOptionsButtons = (row: ReportViewModel) => {
  const { t } = useTranslation();

  const labelIcon = (() => {
    switch (row.generationStatus) {
      case ReportGenerationStatusTypeEnum.FAILED:
        return { label: t('reportGeneration.failed'), icon: <ErrorIcon color='error' />, isDisabled: true };
      case ReportGenerationStatusTypeEnum.NO_DATA:
        return {
          label: t('reportGeneration.noData'),
          icon: <ErrorIcon color={'warning'} />,
          isDisabled: true,
        };
      case ReportGenerationStatusTypeEnum.IN_PROGRESS:
        return {
          label: t('reportGeneration.inProgress'),
          icon: <CircularProgress size={20} />,
          isDisabled: true,
        };
      default:
        return { label: t('download'), icon: <FileDownload /> };
    }
  })() as TableRowOptionsButton;

  return <GetContentButton label={labelIcon.label} icon={labelIcon.icon} resourcePath={`api/resource/reports/${row.id}/content`} isDisabled={labelIcon.isDisabled} />;
};
