import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataResultsDto, extractValue, HateoasRestApiClientService, JsonUtils } from '@lib/common-sdk';
import { Button, ButtonColor, Dialog, GeneralForm, GeneralFormItem, GeneralFormRowOfItems, Snackbar } from '../..';
import { Edit } from '../Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useStateIfMounted } from 'use-state-if-mounted';
import EditArrow from '../EditArrow';
import i18n from 'i18next';

export interface EditModelButtonProps {
  onBeforeSave?: (formData: any, entity?: any) => any;
  onSaved: (result: any) => Promise<any>;
  overrideSaveCall?: (payload: any, entity?: any) => Promise<DataResultsDto<any>>;
  modelName: string;
  id: number;
  validationSchema?: any;
  buttonLabel?: string;
  dialogTitle: string;
  bottomMessage?: string;
  fields: (GeneralFormItem | GeneralFormRowOfItems)[];
  hiddenValues?: any;
  entity: any;
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  postErrorMappings?: Record<string, string>;
  noStartIcon?: boolean;
  customIcon?: JSX.Element;
  yellowIcon?: boolean;
  contentType?: 'application/json' | 'application/json-patch+json';
  testId?: string;
  additionalFormButtons?: Array<JSX.Element>;
}

function mapEntityToDefaultValues(fields: (GeneralFormItem | GeneralFormRowOfItems)[], entity: any) {
  if (entity == null) {
    return;
  }

  function setDefaultForField(field: GeneralFormItem) {
    if (field.defaultValue == null) {
      const extractedValue = extractValue(entity, field.field);
      if (extractedValue === undefined) {
        return;
      }
      field.defaultValue = extractedValue;
    }
  }

  fields.forEach((field) => {
    if ('rowItems' in field) {
      field.rowItems.forEach((rowItem) => {
        setDefaultForField(rowItem);
      });
    } else {
      setDefaultForField(field);
    }
  });
}

export const EditModelButton = (props: EditModelButtonProps) => {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);

  mapEntityToDefaultValues(props.fields, props.entity);

  async function handleSaveDialog(formData: any) {
    try {
      if (props.onBeforeSave) {
        formData = props.onBeforeSave(formData, props.entity);
      }
      let results: DataResultsDto<any>;
      if (props.overrideSaveCall) {
        results = await props.overrideSaveCall({ ...props.hiddenValues, ...formData }, props.entity);
      } else {
        let payload = formData;
        if (props.hiddenValues != null) {
          if (props.contentType === 'application/json-patch+json') {
            Object.keys(props.hiddenValues).forEach((key) =>
              payload.append({
                op: 'replace',
                path: `/${key}`,
                value: props.hiddenValues[key],
              }),
            );
          } else {
            payload = { ...props.hiddenValues, ...payload };
          }
        }
        results = await HateoasRestApiClientService.update<any>(props.modelName, props.id, payload, { contentType: props.contentType });
      }
      if (!results.failed) {
        setDialog(false);
        setTimeout(() => props.onSaved(results.response), 0);
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.errorStatusCode}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }

        if (results.error?.code) {
          setFailureMessage(errorKeys[results.error.code] || t('Common.error'));
        } else {
          setFailureMessage(t('Common.error'));
        }
      }
    } catch (e: any) {
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(errorKeys[code] || t('Common.error'));
    }
  }

  async function handleCloseDialog() {
    setDialog(false);
  }

  return (
    <>
      {props.noStartIcon ? (
        <Button
          label={props.buttonLabel ? props.buttonLabel : ''}
          dataTestId={`${props.testId || props.modelName}-edit-btn`}
          onClick={async () => setDialog(true)}
          variant={props.variant}
          color={props.color}
        />
      ) : (
        <Button
          label={props.buttonLabel ? props.buttonLabel : ''}
          onClick={async () => setDialog(true)}
          variant={props.variant}
          color={props.color}
          dataTestId={`${props.testId || props.modelName}-edit-btn`}
          startIcon={<Edit yellowIcon={props.yellowIcon} />}
        />
      )}
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth='md' fullWidth={true}>
        <div style={{ margin: 'auto', marginTop: '2rem' }}>{props.customIcon ? props.customIcon : <EditArrow />}</div>
        <DialogTitle sx={{ margin: 'auto', fontSize: '1.7rem', color: '#183362' }}>
          {props.dialogTitle}
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            data-test-id={`${props.testId || ''}dialog-close-btn`}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GeneralForm
            fields={props.fields}
            validationSchema={props.validationSchema}
            onSaved={async (data) => handleSaveDialog(data)}
            onCanceled={async () => handleCloseDialog()}
            hideCancelButton={false}
            hiddenValues={props.hiddenValues}
            variant='standard'
            additionalFormButtons={props.additionalFormButtons}
          />
          {props.bottomMessage && (
            <DialogTitle
              sx={{
                margin: 'auto',
                fontSize: '1rem',
                maxWidth: '30rem',
                textAlign: 'center',
              }}
            >
              {props.bottomMessage}
            </DialogTitle>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};
