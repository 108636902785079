import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './form-input-props';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import plLocale from 'date-fns/locale/pl';
import enLocale from 'date-fns/locale/en-US';
import styled from 'styled-components';

const BottomLineTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInputBase-input': {
    padding: '1px 0px 5px',
  },
}));

export const FormInputDateTime = ({ name, control, label }: FormInputProps) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === 'pl' ? plLocale : enLocale;
  const [open, setOpen] = React.useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <DateTimePicker
            label={label}
            value={value || null}
            onChange={onChange}
            ampm={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderInput={(params) => (
              <BottomLineTextField {...params} variant='standard' helperText={error ? error.message : ''} error={!!error} fullWidth onClick={() => setOpen(true)} />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};
