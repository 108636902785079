import React from 'react';
import { getProjectBranchSettingsForClient, getProjectBranchViewForClient } from '@data-source-hooks';
import { ProjectBranchContextProvider, ProjectBranchSettingsContextProvider } from '@context';
import { ProjectBranchHeader } from '../../client-admin/projects/branches/project-branch-header';
import { Pickups } from '../../client-admin/pickups/pickups';
import { useTranslation } from 'react-i18next';

export const PickupsBranch = () => {
  const { t } = useTranslation();
  const [projectSettings, setProjectSettings] = React.useState(null);
  const [projectBranchView, setProjectBranchView] = React.useState(null);
  React.useEffect(() => {
    const fetchProjectBranchView = async () => {
      try {
        const projectBranchView = await getProjectBranchViewForClient();
        const projectSettings = await getProjectBranchSettingsForClient();
        setProjectBranchView(projectBranchView);
        setProjectSettings(projectSettings);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchProjectBranchView();
  }, []);

  if (projectBranchView === null || projectSettings === null) {
    return <div>{t('Common.loading')}</div>;
  }

  return (
    <>
      <ProjectBranchHeader projectBranchView={projectBranchView} />
      <div style={{ marginTop: '1rem' }}></div>
      <ProjectBranchContextProvider value={projectBranchView}>
        <ProjectBranchSettingsContextProvider value={projectSettings}>
          <Pickups projectBranchView={projectBranchView} />
        </ProjectBranchSettingsContextProvider>
      </ProjectBranchContextProvider>
    </>
  );
};
