import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Button, ButtonColor } from '../../button/button';
import { ButtonFormDialog, GeneralFormItem, GeneralFormRowOfItems, Snackbar } from '@lib/ui-components';
import { DataModelResultsDto, JsonUtils } from '@lib/common-sdk';
import axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';
import { Breakpoint } from '@mui/system';

export interface ApiCallButtonProps {
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  payload?: any;
  url: string;
  onCompleted?: (result: any) => Promise<any>;
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
  forceConfirmation?: boolean;
  buttonLabel: string;
  dialogTitle?: string;
  fields?: (GeneralFormItem | GeneralFormRowOfItems)[];
  validationSchema?: any;
  postErrorMappings?: Record<string, string>;
  startIcon?: JSX.Element;
  dialogIcon?: JSX.Element;
  maxWidth?: Breakpoint | false;
  isDisabled?: boolean;
  disableErrorSnackbar?: boolean;
}

export function ApiCallButton(props: ApiCallButtonProps) {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [openDialog, setDialog] = useStateIfMounted(false);
  const [loading, setLoading] = useState(false);

  async function handleButtonClick() {
    if (props.fields) {
      setDialog(true);
    } else {
      handleSaveDialog({});
    }
  }

  async function handleSaveDialog(formData: any) {
    try {
      setLoading(true);
      const results = await axios
        .request({
          url: props.url,
          method: props.method || 'POST',
          data: { ...props.payload, ...formData },
        })
        .then((response: AxiosResponse) => {
          return new DataModelResultsDto<any>(false, undefined, undefined, undefined, response.data);
        })
        .catch((error) => {
          return new DataModelResultsDto<any>(true, error.response.status, error.response.data?.error, error.response.data?.message, undefined);
        });
      setLoading(false);
      if (!results.failed) {
        setDialog(false);
        if (props.onCompleted) {
          await props.onCompleted(results.response);
        }
      } else {
        if (props.postErrorMappings !== undefined) {
          const errorMessage = props.postErrorMappings[`${results.errorStatusCode}`];
          if (errorMessage !== undefined) {
            setFailureMessage(errorMessage);
            return;
          }
        }

        setFailureMessage(errorKeys[results.response?.code] || t('Common.error'));
      }
    } catch (e: any) {
      setLoading(false);
      const status = e.response?.status;
      const data = e.response?.data || '{ "code": "E00001"}';
      const code = JsonUtils.extractValue(data, 'code');
      if (props.postErrorMappings !== undefined) {
        const errorMessage = props.postErrorMappings[`${status}`];
        if (errorMessage !== undefined) {
          setFailureMessage(errorMessage);
          return;
        }
      }
      setFailureMessage(errorKeys[code] || t('Common.error'));
    }
  }

  return (
    <>
      <Button
        label={props.buttonLabel}
        onClick={handleButtonClick}
        variant={props.variant}
        color={props.color}
        startIcon={props.startIcon}
        forceConfirmation={props.forceConfirmation}
        isDisabled={props.isDisabled}
        forceLoadingSign={loading}
      />
      <ButtonFormDialog
        openDialog={openDialog}
        setDialog={setDialog}
        handleSaveDialog={handleSaveDialog}
        dialogTitle={props.dialogTitle}
        fields={props.fields}
        validationSchema={props.validationSchema}
        hiddenValues={props.payload}
        dialogIcon={props.dialogIcon}
        maxWidth={props.maxWidth}
      />
      {!props.disableErrorSnackbar && (
        <Snackbar message={failureMessage} severity="error" onClose={() => setFailureMessage('')} />
      )}
    </>
  );
}
