import { useTranslation } from 'react-i18next';
import { DataTable, Header, Panel } from '@lib/ui-components';
import React from 'react';
import { ServiceType } from '@lib/api-interface';
import { CollectionRequestStatus } from '../../../libs/api-interface/models/collection-request/collection-request-status.enum';
import { getWeekGapCustomEnumMapping } from './utils/get-week-gap-custom-enum-mapping';

interface CommuneServicePickupProps {
  projectId: string;
  branchId: string;
}

export const CommuneServicePickup = (props: CommuneServicePickupProps) => {
  const { t } = useTranslation();
  return (
    <Panel borderType='none'>
      <Header label={t('upcomingPickupsDates')} />
      <DataTable
        refreshKey={1}
        fetchPostUrl={'api/client/collection-requests'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: ServiceType.COMMUNE,
          collectionRequestStatuses: [
            CollectionRequestStatus.SCHEDULED_FOR_KPO_CREATION,
            CollectionRequestStatus.SCHEDULED_FOR_NOTIFICATION,
            CollectionRequestStatus.SCHEDULED,
            CollectionRequestStatus.IN_REALIZATION,
            CollectionRequestStatus.FEEDBACK_ALLOWED,
          ],
        }}
        columns={[
          {
            key: 'frequency',
            label: t('pickupFrequency'),
            type: 'numeric',
            joinedColumns: [
              {
                key: 'weekGap',
                label: '',
                type: 'enum',
                customValueMappings: getWeekGapCustomEnumMapping(),
              },
            ],
          },
          { key: 'collectionRequestView.collectionDate', label: t('pickupDate'), type: 'date' },
          { key: 'collectionRequestView.containers', label: t('receiptSizeUnit'), type: 'numeric' },
          { key: 'collectionRequestView.container', label: t('container'), type: 'text' },
          { key: 'serviceDescription', label: t('additionalDescription'), type: 'text' },
        ]}
      />
    </Panel>
  );
};
